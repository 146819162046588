import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MembershipService {
  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  constructor(private http:HttpClient) {
  }


  getMemberships() {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'base/membership');
  }

}
