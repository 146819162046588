import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-news-blocks',
  templateUrl: './news-blocks.component.html',
  styleUrls: ['./news-blocks.component.scss']
})
export class NewsBlocksComponent implements OnInit {



  @Input() posts;
  @Input() loading;

  constructor() { }

  ngOnInit() {
  }

}
