<div class="news-block">
  <div class="row">


    <div class="col-md-4" *ngFor="let post of posts | async; let i=index">
      <a class="block" [routerLink]="['/knowledge-center/news' ,post?.slug]">
        <div class="img"
             [ngStyle]="{'background-image': 'url(' + post['better_featured_image'].source_url + ')'}"
        >
        </div>
        <span class="feature">{{'news'|translate}}</span>
        <div class="content">
          {{ post?.title?.rendered }}
        </div>
      </a>
    </div>
  </div>
</div>
