import {Component, OnInit, Injectable} from '@angular/core';
import {Contract} from '../../models/Contract';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';
import {NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import {UserService} from './../../_services/user.service';
import {DashboardService} from './../../_services/dashboard.service';
import {dropdownLists} from './../../_services/dropdown-lists.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})
export class AddContractComponent implements OnInit {


  contract:Contract = new Contract();
  contractForm:FormGroup;

  unit:FormControl;
  date_of_writing:FormControl;
  renting_start_date:FormControl;
  renting_end_date:FormControl;
  duration:FormControl;
  contract_value:FormControl;
  currency:FormControl;
  defaultCurrency:string = 'USD';
  defaultDuration:number = 12;
  renter:FormControl;
  unit_usage:FormControl;
  paymentType:FormControl;
  customersList:any;
  unitList:any;
  currencyList:any;
  unitUsageList:any;
  lang:string;
  paymentTypeList:any;
  selectedName:any;
  selectedTitle:any;
  todayDate:any;

  error = '';
  alertClass:string = '';
  alertMsg:string = '';
  alertMsgTitle:string = '';
  loading:any = false;
  alert:any = false;



  constructor(private fb:FormBuilder,
              private ngbCalendar:NgbCalendar,
              private dateAdapter:NgbDateAdapter<string>,
              private  userService:UserService,
              private  dashboardService:DashboardService,
              private  dropdownLists:dropdownLists,
              public   translate:TranslateService) {


  }

  ngOnInit() {


    this.todayDate = this.getTodayDate();
    this.lang = this.translate.currentLang;

    if (this.lang == 'en') {
      this.selectedName = 'en_name';
      this.selectedTitle = 'en_title';
    } else {
      this.selectedName = 'ar_name';
      this.selectedTitle = 'ar_title';

    }


    this.createFormControls();
    this.createForm();
    // this.date_of_writing.setValue(this.today());
    this.getCustomersList();
    this.getUnitsForRent();
    this.currencyList = this.dropdownLists.getCurrency();
    this.unitUsageList = this.dropdownLists.getUnitUsageList();
    this.paymentTypeList = this.dropdownLists.getPaymentTypeList();
  }


  getCustomersList() {

    this.userService.getCustomersList().subscribe((res:any)=> {
      this.customersList = (res.data);
      this.loading = false;
    });

  }

  getUnitsForRent() {
    this.dashboardService.getUnRentedUnitList().subscribe((res:any)=> {
      this.unitList = (res.data);
      this.loading = false;
    });

  }


  createForm() {
    this.contractForm = this.fb.group({
      unit: this.unit,
      date_of_writing: this.date_of_writing,
      renting_start_date: this.renting_start_date,
      renting_end_date: this.renting_end_date,
      duration: this.duration,
      paymentType: this.paymentType,
      contract_value: this.contract_value,
      currency: this.currency,
      renter: this.renter,
      unit_usage: this.unit_usage,
    });

  }

  createFormControls() {

    this.unit = new FormControl('', [
      Validators.required,
    ]);

    this.date_of_writing = new FormControl('', [
      Validators.required,
    ]);

    this.renting_start_date = new FormControl('', [
      Validators.required,
    ]);

    this.renting_end_date = new FormControl('', [
      Validators.required,
    ]);

    this.duration = new FormControl('', [
      Validators.required,
    ]);

    this.contract_value = new FormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]);

    this.currency = new FormControl('USD', [
      Validators.required,
    ]);

    this.renter = new FormControl('', [
      Validators.required,
    ]);

    this.unit_usage = new FormControl('', [
      Validators.required,
    ]);


    // this.third_name = new FormControl('', [
    //   // Validators.required,
    //   Validators.minLength(2)
    // ]);
    //
    // this.family_name = new FormControl('', [
    //   Validators.required,
    //   Validators.minLength(2)
    // ]);
    //
    //
    // this.email = new FormControl('', [
    //   Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'),
    //   Validators.email
    // ]);
    // this.mobile_number = new FormControl('', [
    //   Validators.required,
    //   Validators.minLength(8)
    // ]);
    //
    // const numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
    //
    // this.id_number = new FormControl('', [
    //   Validators.pattern(numericNumberReg),
    //   Validators.minLength(8)
    // ]);
  }


  getTodayDate() {
    let dt = new Date();
    let date = {year: dt.getFullYear(), month: (dt.getMonth() + 1), day: dt.getDate()};
    return date;
  }


  onSubmit():void {
    this.contract = this.contractForm.value;

    console.log(this.contract);
    if (this.contractForm.valid) {
      console.log('form submitted');
    } else {
      console.log('form error');

      this.validateAllFormFields(this.contractForm); //{7}
      this.alertClass = 'danger';
      this.alertMsgTitle = 'please check the form fields';
      this.alert = true;

      // validate all form fields
    }

  }



  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  
}
