<section class="wizard-page">
  <form (ngSubmit)="onSubmit()" [formGroup]="signUpForm" autocomplete="off">
    <aw-wizard>

      <aw-wizard-step stepTitle="Title of step 1" [canExit]="step1IsValid">
        <div class="wizard-step">
          <div class="row">
            <div class="col-md-6">
              <div class="wizard-description">
                <div class="fun">
                  <h2 class="text-center ">{{'steps.step1.mainTitle'|translate}} </h2>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="image">
                        <img src="assets/images/wizard/single-info.png">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step1.subTitle1'|translate}}</h3>
                          <p>{{'steps.step1.description1'|translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">

                    <div class="col">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step1.subTitle2'|translate}}</h3>
                          <p>{{'steps.step1.description2'|translate}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="image">
                        <img src="assets/images/wizard/pin-map2.png">
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="wizard-content">
                <h1 class="">{{'subscriptionTitle'|translate}}</h1>
                <h2>{{'The More We Know The More We Can Help'|translate}}</h2>
                <h3>{{'What are you looking for'|translate}}</h3>
                <div class="wizard-option-nav">
                  <div class="form-check" *ngFor="let list of q1Options">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="{{list.name}}"
                      value="{{list.value}}"
                      (change)="onQ1CheckboxChange(list,$event)"
                      [checked]="list.isChecked"
                    >
                    <label class="form-check-label" for="{{list.name}}">
                      {{list.name|translate}}
                    </label>
                  </div>
                </div>
                <button type="button" class="btn btn--primary" awNextStep
                        (click)="goToSecondStep()">{{'next'|translate}}</button>
                <div class="steps-counter "> 1 {{'of'|translate}} 5</div>
              </div>
            </div>
          </div>
        </div>
      </aw-wizard-step>

      <aw-wizard-step stepTitle="Title of step 2" awOptionalStep [canExit]="step2IsValid">
        <div class="wizard-step">
          <div class="row">
            <div class="col-md-6">
              <div class="wizard-description">
                <div class="fun">
                  <h2 class="text-center ">{{'steps.step2.mainTitle'|translate}} </h2>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="image">
                        <img src="assets/images/wizard/investore.png">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step2.subTitle1'|translate}}</h3>
                          <p>{{'steps.step2.description1'|translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step2.subTitle2'|translate}}</h3>
                          <p>{{'steps.step2.description2'|translate}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="image">
                        <img src="assets/images/wizard/property-file.png">
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="wizard-content">
                <h1 class="">{{'steps.step3.mainTitle'|translate}} </h1>
                <h2>{{'Which describe you best'|translate}}</h2>
                <div class="wizard-option-nav">
                  <div class="form-check" *ngFor="let q2 of q2Options">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="{{q2.id}}"
                      value="{{q2.value}}"
                      (change)="onQ2CheckboxChange(q2,$event)"
                      [checked]="q2.isChecked"
                    >
                    <label class="form-check-label" for="{{q2.id}}">
                      {{q2.name|translate}}
                    </label>
                  </div>
                </div>
                <button type="button" class="btn btn--primary" awNextStep
                        (click)="goToThirdStep()">{{'next'|translate}}</button>

                <button type="button" class="btn btn-light mr-1 ml-1" awPreviousStep>{{'previous'|translate}}</button>

                <div class="steps-counter ">2 {{'of'|translate}} 5</div>
              </div>
            </div>

          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Title of step 3" awOptionalStep [canExit]="step3IsValid">
        <div class="wizard-step">
          <div class="row">
            <div class="col-md-6">
              <div class="wizard-description">
                <div class="fun">
                  <h2 class="text-center ">{{'steps.step3.mainTitle'|translate}} </h2>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="image">
                        <img src="assets/images/wizard/services.png">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step3.subTitle1'|translate}}</h3>
                          <p>{{'steps.step3.description1'|translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">


                    <div class="col">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step3.subTitle2'|translate}}</h3>
                          <p>{{'steps.step3.description2'|translate}}</p>
                        </div>
                      </div>
                    </div>


                    <div class="col">
                      <div class="image">
                        <img src="assets/images/wizard/map-palestine.png">
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-6">

              <div class="wizard-content">
                <h1>{{'The More We Know The More We Can Help'|translate}}</h1>
                <div class="form-group">
                  <h3>{{'What is your budget'|translate}}</h3>
                  <ng-select
                    [items]="budgetList"
                    bindLabel="{{selectedName}}"
                    bindValue="value"
                    placeholder="{{'selectYourBudget'|translate}}"
                    [clearable]="false"
                    (change)="setBudget($event)"
                    (click)="$event.stopPropagation();"
                    required
                  >
                  </ng-select>
                </div>

                <div class="form-group">
                  <h3>{{'How do you want to pay'|translate}}</h3>
                  <ng-select
                    [items]="paymentMethodList"
                    bindLabel="{{selectedName}}"
                    bindValue="value"
                    placeholder="{{'selectPaymentMethod'|translate}}"
                    [clearable]="false"
                    (change)="setPaymentMethod($event)"
                    (click)="$event.stopPropagation(); "
                  >
                  </ng-select>
                </div>

                <div class="form-group">
                  <h3>{{'What is your Real estate experience'|translate}}</h3>
                  <ng-select
                    [items]="RealEstaExperienceList"
                    bindLabel="{{selectedName}}"
                    bindValue="value"
                    placeholder="{{'selectRealEstateExperience'|translate}}"
                    [clearable]="false"
                    (change)="setRealEstateExperience($event)"
                    (click)="$event.stopPropagation(); "
                  >
                  </ng-select>

                </div>
                <button type="button" class="btn btn--primary" awNextStep
                        (click)="goToFourStep(1)">{{'next'|translate}}</button>
                <button type="button" class="btn btn-light mr-1 ml-1" awPreviousStep>{{'previous'|translate}}</button>

                <div class="steps-counter ">3 {{'of'|translate}} 4</div>
              </div>
            </div>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Title of step 4" awOptionalStep [canExit]="step4IsValid">
        <div class="wizard-step">
          <div class="row">
            <div class="col-md-6">
              <div class="wizard-description">
                <div class="fun">
                  <h2 class="text-center ">{{'steps.step4.mainTitle'|translate}} </h2>


                  <div class="row">

                    <div class="col-md-6">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step4.subTitle2'|translate}}</h3>
                          <p>{{'steps.step4.description2'|translate}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="image">
                        <img src="assets/images/wizard/track.png">
                      </div>
                    </div>

                  </div>


                  <div class="row">
                    <div class="col">
                      <div class="image">
                        <img src="assets/images/wizard/properties.png">
                      </div>
                    </div>
                    <div class="col">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step4.subTitle1'|translate}}</h3>
                          <p>{{'steps.step4.description1'|translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="wizard-content">
                <h1>{{'The More We Know The More We Can Help'|translate}}</h1>
                <h3>{{'What are the locations you interested for'|translate}}</h3>
                <div class="wizard-option-nav">
                  <div class="form-check" *ngFor="let q4 of q4Options">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="{{q4.id}}"
                      value="{{q4.value}}"
                      (change)="onQ4CheckboxChange(q4,$event)"
                      [checked]="q4.isChecked"
                    >
                    <label class="form-check-label" for="{{q4.id}}">
                      {{q4.name|translate}}
                    </label>
                  </div>
                </div>

                <h3>{{'You are interested more'|translate}}</h3>
                <div class="wizard-option-nav">
                  <div class="form-check" *ngFor="let interestedMore of interestedMoreOptions">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="{{interestedMore.id}}"
                      value="{{interestedMore.value}}"
                      (change)="onInterestedMoreOptionsChange(interestedMore,$event)"
                      [checked]="interestedMore.isChecked"
                    >
                    <label class="form-check-label" for="{{interestedMore.id}}">
                      {{interestedMore.name|translate}}
                    </label>
                  </div>
                </div>

                <button type="button" class="btn btn--primary" awNextStep
                        (click)="goToFiveStep(1)">{{'next'|translate}}</button>
                <button type="button" class="btn btn-light mr-1 ml-1" awPreviousStep>{{'previous'|translate}}</button>

                <div class="steps-counter">4 {{'of'|translate}} 5</div>
              </div>
            </div>
          </div>
        </div>
      </aw-wizard-step>

      <aw-wizard-step stepTitle="Title of step 5">
        <div class="wizard-step">
          <div class="row">
            <div class="col-md-6">
              <div class="wizard-description">
                <div class="fun">
                  <h2>{{'steps.step1.mainTitle'|translate}} </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="image">
                        <img src="assets/images/wizard/single-info.png">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step1.subTitle1'|translate}}</h3>
                          <p>{{'steps.step1.description1'|translate}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">

                    <div class="col-md-6">
                      <div class="fun-content">
                        <div class="fun-text">
                          <h3>{{'steps.step1.subTitle2'|translate}}</h3>
                          <p>{{'steps.step1.description2'|translate}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="image">
                        <img src="assets/images/wizard/pin-map2.png">
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="wizard-content">
                <h1 class="">{{'CreateMyAQARAccount'|translate}}</h1>
                <p class="text-capitalize ">{{'sign_up_description'|translate}} </p>

                <div class="wizard-option-nav">


                  <div class="row">
                    <div class="col-md-6">
                      <label>{{'firstName'|translate}}</label>
                      <input type="text" class="form-control" formControlName="firstName"
                             placeholder="{{'firstName'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">
                        <p *ngIf="firstName.errors.required" class="text-left">
                          {{'validation.firstName_required'|translate}}</p>
                      </div>
                    </div>


                    <div class="col-md-6">
                      <label>{{'lastName'|translate}}</label>
                      <input type="text" class="form-control" formControlName="lastName"
                             placeholder="{{'lastName'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">
                        <p *ngIf="lastName.errors.required" class="text-left">
                          {{'validation.lastName_required'|translate}} </p>
                      </div>

                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <input type="hidden" formControlName="countryCode">
                      <div class="form-group mb-3">
                        <label>{{'mobileNumber'|translate}}</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend" style="width: 40%">

                            <ng-select [items]="countriesList$"
                                       bindLabel="label"
                                       bindValue="value"
                                       [(ngModel)]="selectedRegionId"
                                       [clearable]="false"
                                       (change)="updatePrefix($event)"
                                       formControlName="regionCode"
                                       class="w-100"
                            >
                            </ng-select>


                          </div>
                          <input
                            style="direction: ltr"
                            type="text"
                            class="form-control"
                            formControlName="mobile"
                            [attr.placeholder]="example"
                            [mask]="mask"
                            [showMaskTyped]="true"
                            [prefix]="prefix"
                          >
                          <div class="form-control-feedback text-left w-100"
                               *ngIf="mobile.errors && (mobile.touched)">
                            <p *ngIf="mobile.errors.required" class="text-left">
                              {{'validation.mobile_required'|translate}}</p>
                            <p *ngIf="mobile.invalid" class="text-left">
                              {{'validation.mobile_invalid'|translate}}</p>
                          </div>
                        </div>

                      </div>


                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{'email'|translate}}</label>

                        <input type="email" class="form-control" formControlName="email"
                               placeholder="{{'Email'|translate}} ({{'optional'|translate}} )" email>
                        <div class="form-control-feedback text-left"
                             *ngIf="email.errors && (email.dirty || email.touched)">
                          <p *ngIf="email.invalid || email.errors.required" class="text-left">
                            {{'validation.InvalidEmailAddress'|translate}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{'Password'|translate}}</label>
                        <input type="password" class="form-control" formControlName="password"
                               placeholder="{{'Password'|translate}}" autocomplete="off">
                        <div class="form-control-feedback text-left"
                             *ngIf="password.errors && (password.dirty || password.touched)">
                          <p *ngIf="password.errors.required" class="text-left">
                            {{'validation.password_required'|translate}}</p>
                          <p *ngIf="password.errors.minlength" class="text-left">
                            {{'validation.password_min_length'|translate}}
                            {{'characters'|translate}} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="acceptance-text ">
                  <p class="m-0"><small> <img  style="width: 22px; " src="/assets/images/icons/Info.svg">{{'acceptance_text'|translate}}</small></p>
                </div>
                <div class="loading w-100 text-center" *ngIf="loading">
                  <img width="90" src="./assets/images/icons/loading_login.gif" class="text-center"/>
                </div>

                <div class="mb-2 mt-2 alert alert-{{alertClass}}" *ngIf="alert">
                  {{alertMsg}}
                </div>
                <div class="backToHome" *ngIf="backToHomeLink">
                  <a routerLink="/"> <i class="fa fa-home ml-1 mr-1"></i>{{'backToHome'|translate}}</a>
                </div>

                <button *ngIf="!backToHomeLink" type="submit" class="btn btn--primary" [disabled]="!signUpForm.valid">
                  {{'save'|translate}}
                </button>
                <button *ngIf="!backToHomeLink" type="button" class="btn btn-light mr-1 ml-1" awPreviousStep>
                  {{'previous'|translate}}
                </button>
                <div *ngIf="!backToHomeLink" class="steps-counter"> 5 {{'of'|translate}} 5</div>
              </div>
            </div>
          </div>
        </div>

      </aw-wizard-step>

    </aw-wizard>
  </form>
</section>
