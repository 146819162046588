import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';


export interface TypingSEO {
  title?:string;
  titleSuffix?:string;
  description?:string;
  image?:string;
  imageWidth?:any;
  imageHeight?:any;
  keywords?:string;
  url?:string;
}

export interface TypingProperty {
  property:string;
  itemprop?:string;
  content:string;
}

export interface TypingName {
  name:string;
  itemprop?:string;
  content:string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {


  baseUrl:string = 'http://aqar.ps/';
  seoTitle:any = '';
  seoDescription:any = '';
  seoKeywords:any = '';
  lang:any = 'ar';
  seoImage:any = 'http://aqar.ps/assets/images/logo/aqar-logo.png';
  seoUrl:any = 'http://aqar.ps/';
  seoConfig:any;
  seoSite:string = 'Aqar.ps';


  constructor(private  ngMeta:Meta, private ngTitle:Title, public  translate:TranslateService) {

    let lang = translate.currentLang;
    this.lang = lang;
    if (lang == 'en') {
      this.seoTitle = 'Aqar.ps | Your Place For Everything Real Estate ';
      this.seoDescription = 'SELL, BUY AND RENT PROPERTIES IN ONE PLACE,Palestine Properties,Apartments,Lands,Villa ,Rent, Buy,Sell ';
      this.seoKeywords = 'Investments opportunity,apartments ,palestine,Ramallah,Sell,buy,lands,land,villas,none furnished apartment,furnished apartment,Tabo,pay in installments';
    } else {
      this.seoTitle = 'aqar.ps | مكانك لكل شيء عقاري';
      this.seoDescription = 'من اي مكان ومن اي زمان تابع كعقارك  بنظام سهل وبسيط ومعلوماتكم محفوظة بأمان شقق ومنازل في فلسطين ، شقق للبيع للايجار، منازل للبيع للايجار، استئجار منازل، عقارات، فلل، عمارة، فيلات، اسكان، شقق عظم، شقق مشطبة، مكتب عقاري، تخمين عقارات،طابو';
      this.seoKeywords = 'عقارات في فلسطين رام الله ، شقق للبيع ، اراضي للبيع ، البيرة ، شقق مفروشة، شقق غير مفروشة ، محلات تجارية للايجار ، فرص استثمارية،طابو،تقسيط';
    }

    this.seoConfig = {
      title: this.seoTitle,
      description: this.seoDescription,
      keywords: this.seoKeywords,
      image: 'http://aqar.ps/assets/images/logo/logo-aqar-share.png',
      url: 'http://aqar.ps/',
    };


  }


  getSiteName() {
    return this.seoSite;
  }

  getDefaultKeywords(lang) {

    if (lang == 'en') {
      return this.seoKeywords;
    } else {
      return this.seoKeywords;
    }
  }

  getDefaultTitle(lang) {

    if (lang == 'en') {
      return this.seoTitle;
    } else {
      return this.seoTitle;
    }
  }

  getDefaultDescription(lang) {

    if (lang == 'en') {
      return this.seoDescription;
    } else {
      return this.seoDescription;
    }
  }


  getDefaultImage(lang) {

    if (lang == 'en') {
      return this.seoImage;
    } else {
      return this.seoImage;
    }
  }

  get metaInstance():Meta {
    return this.ngMeta;
  }


  /**
   * @description Set General SEO Tags
   * @param config: TypingSEO
   * @return void
   */
  setTags(config:TypingSEO = this.seoConfig):void {
    if (config.title !== undefined) {
      this.title(config.title, config.titleSuffix);
    } else {
      // this.title(this.seoTitle, this.seoTitle);
    }
    if (config.description !== undefined) {
      this.description(config.description);
    } else {
      this.description(this.seoDescription)
    }
    if (config.image !== undefined) {
      this.image(config.image);
    } else {
      this.image(this.seoImage);
    }

    if (config.imageWidth !== undefined) {
      this.imageWidth(config.imageWidth);
    } else {
      this.imageWidth('auto');
    }

    if (config.imageHeight !== undefined) {
      this.imageHeight(config.imageHeight);
    } else {
      this.imageHeight('auto');
    }

    if (config.keywords !== undefined) {
      this.keywords(config.keywords);
    } else {
      this.keywords(this.seoKeywords)
    }
    if (config.url !== undefined) {
      this.url(config.url);
    } else {
      this.url(this.seoUrl);
    }
  }


  /**
   * @description Set Name Tag
   * @param name: TypingName
   * @param content
   * @return void
   */
  setNameTag(name:TypingName):void {
    const property = {
      name: name.name,
      content: name.content,
    };
    if (name.itemprop !== undefined && name.itemprop !== '') {
      property['itemprop'] = name.itemprop;
    }
    if (this.ngMeta.getTag(`name="${property.name}"`)) {
      this.ngMeta.updateTag(property);
    } else {
      this.ngMeta.addTag(property);
    }
  }


  /**
   * @description Set Name Tags
   * @param names: [TypingName]
   * @return void
   */
  setNameTags(names:[TypingName]):void {
    names.forEach(prop => {
      this.setNameTag(prop);
    });
  }


  /**
   * @description Set Property Tag
   * @param prop: TypingProperty
   * @param content
   * @return void
   */
  setPropertyTag(prop:TypingProperty):void {
    const property = {
      property: prop.property,
      content: prop.content,
    };
    if (prop.itemprop !== undefined && prop.itemprop !== '') {
      property['itemprop'] = prop.itemprop;
    }
    if (this.ngMeta.getTag(`property="${property.property}"`)) {
      this.ngMeta.updateTag(property);
    } else {
      this.ngMeta.addTag(property);
    }
  }

  /**
   * @description Set Property Tags
   * @param props: [TypingProperty]
   * @return void
   */
  setPropertyTags(props:[TypingProperty]):void {
    props.forEach(prop => {
      this.setPropertyTag(prop);
    });
  }


  /**
   * @description Set URL Tag
   * @param content: string
   * @return void
   */
  url(content:string):void {
    this.ngMeta.updateTag({property: 'og:url', itemprop: 'url', content: `${this.baseUrl}${content}`});
  }

  /**
   * @description Set Title Tag
   * @param title: string
   * @param titleSuffix: string
   * @return void
   */
  title(title:string, titleSuffix?:string):void {
    const setTitle = (titleSuffix !== undefined && titleSuffix !== '') ? `${title} ${titleSuffix}` : title;
    this.ngTitle.setTitle(setTitle);
    this.setPropertyTag({property: 'og:title', itemprop: 'title', content: setTitle});
    this.setPropertyTag({property: 'twitter:title', itemprop: 'title', content: setTitle});
  }

  /**
   * @description Set Description Tag
   * @param content: string
   * @return void
   */
  description(content:string):void {
    this.setNameTag({name: 'description', itemprop: 'description', content: content});
    this.setPropertyTag({property: 'og:description', itemprop: 'description', content: content});
    this.setPropertyTag({property: 'twitter:description', itemprop: 'description', content: content});
  }

  /**
   * @description Set Image Tag
   * @param content: string
   * @return void
   */
  image(content:string):void {
    this.setPropertyTag({property: 'twitter:image', itemprop: 'image', content: content});
    this.setPropertyTag({property: 'og:image', itemprop: 'image', content: content});
    this.setPropertyTag({property: 'og:image:secure_url', itemprop: 'image', content: content});
  }

  /**
   * @description Set Image width Tag
   * @param content: string
   * @return void
   *
   */
  imageWidth(content:string):void {
    this.setPropertyTag({property: 'og:image:width', content: content});

  }

  /**
   * @description Set Image Height Tag
   * @param content: string
   * @return void
   *
   */
  imageHeight(content:string):void {
    this.setPropertyTag({property: 'og:image:height', content: content});
  }
  


  /**
   * @description Set Keywords Tag
   * @param content: string
   * @return void
   */
  keywords(content:string):void {
    this.setNameTag({name: 'keywords', itemprop: 'keywords', content: content});
  }

}
