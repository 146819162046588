<app-top-header></app-top-header>

<section>
  <div class="inner-banner">
    <div class="overlay"></div>
    <img src="./assets/images/services-bg.jpg">
    <div class="container-fluid">
      <div class="title">
        <h1>{{'aboutAqar'|translate}}</h1>
        <p>{{'aboutUsSlogan'|translate}}</p>
      </div>
    </div>
  </div>
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-md-3">
        <div class="content-sidebar">
          <ul>
            <li *ngFor="let page of pages | reverse ;let i = index">
              <a
                [routerLink]="['/about/',page.slug]"
                routerLinkActive="active"
              >
                {{page.title.rendered}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9">
        <app-page-loader [hidden]="!loader"  ></app-page-loader>
        <div class="nav-content">
          <div class="content-header">
            <h1>{{ post?.title?.rendered }}</h1>
          </div>
          <div class="page-content" [innerHTML]=content></div>
        </div>
      </div>
    </div>
  </div>
</section>


<layout-footer></layout-footer>
