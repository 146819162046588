<app-top-header></app-top-header>
<div class="inner mt-5 mb-lg-5 full-height">
  <div class="container mt-5">
    <h1>{{ 'Add New Property'|translate}}</h1>


    <div class="wizard {{navBarLocation}}">
      <div class="wizard-navigation">
        <ul class="steps-indicator">
          <li><a routerLink="/dashboard/add-properties" class="active">1</a></li>
          <li><a class="">2</a></li>
          <li><a class="">3</a></li>
        </ul>
      </div>
      <div class="wizard-content">
        <div class="row">
          <div class="form-data mb-5">
            <p class="step-text">{{'step 1'|translate}}</p>
            <h2>{{'Choose Your Property Type'|translate }}</h2>
            <div class="type-list">
              <ul>
                <li>
                  <a routerLink="/dashboard/add-properties/land"><img src="./assets/images/icons/Land.svg"></a>
                  {{'land'|translate}}
                </li>
                <li><a routerLink="/dashboard/add-properties/apartment"><img src="./assets/images/icons/Apartment.svg"></a>
                  {{'apartment'|translate}}
                </li>
                <li><a routerLink="/dashboard/add-properties/villa_house"><img src="./assets/images/icons/Villa.svg"></a>
                  {{'villa'|translate}}
                </li>
                <li><a routerLink="/dashboard/add-properties/office"><img src="./assets/images/icons/Office.svg"></a>
                  {{'office'|translate}}
                </li>
                <li><a routerLink="/dashboard/add-properties/store"> <img src="./assets/images/icons/Store.svg"></a>
                  {{'store'|translate}}
                </li>
                <li>
                  <a routerLink="/dashboard/add-properties/building"><img src="./assets/images/icons/Building.svg"></a>
                   {{'building'|translate}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<layout-footer class="mt-5"></layout-footer>
