import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {resetPasswordModel} from '../../../models/reset-password-model';
import {AuthService} from '../../../_services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password-confirmations',
  templateUrl: './reset-password-confirmations.component.html',
  styleUrls: ['./reset-password-confirmations.component.scss']
})
export class ResetPasswordConfirmationsComponent implements OnInit {


  resetPasswordModel:resetPasswordModel = new resetPasswordModel();

  error = '';
  uData:any;
  token:any;
  alert:boolean = false;
  alertClass:string = '';

  errorMsg:any = '';
  successMsg:any = '';
  invalidTokenMsg:any = '';
  alertMsg:string = '';
  loading:boolean = false;

  resetPasswordForm:FormGroup;

  password:FormControl;
  confirmPassword:FormControl;
  password_confirmation_error:any = false;


  constructor(private auth:AuthService,
              private router:Router,
              private route:ActivatedRoute,
              public translate:TranslateService) {


  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });

    this.checkToken();
    this.createFormControls();
    this.createForm();
    this.auth.logout();

    this.translate.get('passwordChangedSuccessfully').subscribe((res:string) => {
      this.successMsg = res;
    });


    this.translate.get('invalidTokenMsg').subscribe((res:string) => {
      this.invalidTokenMsg = res;
    });

  }


  createFormControls() {
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);
    this.confirmPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);
  }

  createForm() {
    this.resetPasswordForm = new FormGroup({
      password: this.password,
      confirmPassword: this.confirmPassword
    });
  }


  onSubmit() {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.resetPasswordModel = this.resetPasswordForm.value;
    this.auth.resetPassword(this.resetPasswordModel, this.token)
      .subscribe(res => {
          this.alert = true;
          this.loading = false;
          if (res['success']) {
            this.alertClass = 'success';
            this.alertMsg = this.successMsg;
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 7000);  //5s
          } else {

            this.alertClass = 'danger';
            this.alertMsg = res['msg'];
          }

        },
        err => {
          this.loading = false;
          this.alertClass = 'danger';
          let msg = err.error.msg;
          this.translate.get(msg).subscribe((res:string) => {
            this.errorMsg = res;
            this.alertMsg = this.errorMsg;
          });

        }
      )

  }


  checkToken() {
    this.auth
      .resetPasswordCheckToken(this.token)
      .subscribe((res:any)=> {
        console.log(res);
        if (res.success) {
        } else {
          this.alertClass = 'danger';
          this.alertMsg = this.invalidTokenMsg;
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 3000);  //5s
        }
      });
  }


}
