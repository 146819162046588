import {Component, OnInit} from '@angular/core';
import {BlogService} from '../../_services/blog.service';
import {ActivatedRoute} from '@angular/router';


import {Observable} from 'rxjs';


@Component({
  selector: 'app-articles-page',
  templateUrl: './articles-page.component.html',
  styleUrls: ['./articles-page.component.scss']
})
export class ArticlesPageComponent implements OnInit {


  slug:any;
  post:any;
  posts:Observable<any[]>;
  cat:any;
  URL:any;


  constructor(private route:ActivatedRoute,
              private blogService:BlogService) {


  }

  ngOnInit() {
    this.cat = this.route.snapshot.url[1].path;
    this.getCategoryPosts(this.cat);
    this.URL='/knowledge-center/'+this.cat+'/';
  }


  getCategoryPosts(slug) {
    this.posts = this.blogService.getPostByCategorySlug(slug);
  }


}
