import {Component, OnInit, Inject} from '@angular/core';
import {AuthService} from '../../../_services/auth.service';

import {Router} from '@angular/router';
import {WINDOW} from '@ng-toolkit/universal';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  // constructor(@Inject(WINDOW) private window: Window, 
  //   private router: Router,
  //   private auth: AuthService) {
  //  
  //  
  // }

  constructor(private router:Router,
              @Inject(WINDOW) private window:Window,
              private auth:AuthService) {

  }

  ngOnInit():void {
    this.auth.logout();
    this.window.location.replace('/');

  }

}
