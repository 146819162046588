import {Component, OnInit, ViewEncapsulation, HostListener, Inject} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';


import {BlogService} from '../../_services/blog.service';
import {blogCategories} from '../../_services/blogCategories';

import {Observable} from 'rxjs';
import { WINDOW } from '@ng-toolkit/universal';


@Component({
  selector: 'app-knowledge-center',
  templateUrl: './knowledge-center.component.html',
  styleUrls: ['./knowledge-center.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbCarouselConfig]

})
export class KnowledgeCenterComponent implements OnInit {


  images:any = ['./assets/images/estat.jpg'];
  latestPost:Observable<any[]>;
  tipsPosts:Observable<any[]>;
  newsPosts:Observable<any[]>;
  myAqarPosts:Observable<any[]>;
  bannersPosts:Observable<any[]>;

  tips:any = 'tips';
  myAqar:any = 'myaqar';
  guide:any = 'guide';
  news:any = 'news';

  investmentPostCount:any;
  rentingPostCount:any = 0;
  buyingPostCount:any = 0;
  sellingPostCount:any = 0;

  latestLoader:boolean=true;
  tipsLoader:boolean=true;
  myAqarLoader:boolean=true;
  newsLoader:boolean=true;
  bannerLoader:boolean=true;


  public fixed:boolean = false;
  public offset:number = 550;

  catSlug:any;

  categories:any = [
    {id: 1, slug: 'tips'},
    {id: 2, slug: 'news'},
    {id: 3, slug: 'guide'},
    {id: 4, slug: 'myAqar'},
    {id: 5, slug: 'tips'},
    {id: 6, slug: 'realestate-palestine'},
    {id: 7, slug: 'palestine-authority'},
  ];
  
  constructor(@Inject(WINDOW) private window: Window, private config:NgbCarouselConfig,
              private blogService:BlogService) {
  }

  ngOnInit() {
    this.getBanners();
    this.getLatestArticles();
    this.getTipsArticles();
    this.getNewsArticles();
    this.getMyAqarArticles();
    this.getInvestmentPostCount(blogCategories.investment);
    this.getRentingPostCount(blogCategories.renting);
    this.getBuyingPostCount(blogCategories.buying);
    this.getSellingPostCount(blogCategories.selling);
    
  }

  getCategorySlug(categories) {
    let cat = Math.min.apply(Math, categories);
    if(cat > 7){
      cat=2;
    }
    return this.categories[cat - 1]['slug'];
  }


  getSectionUrl(categories, slug) {
    let cat = Math.min.apply(Math, categories);
    if(cat > 7){
      cat=2;
    }
    this.catSlug = this.categories[cat - 1]['slug'];
    let link = '/knowledge-center/' + this.catSlug + '/' + slug;
    return link;

  }
  
  getBanners(){
    this.bannersPosts = this.blogService.getBanners().do(
      collection => {
        this.bannerLoader=false;
      },
      error => {
        console.log('collection getTipsArticles error');
      }
    );
    
  }

  getLatestArticles() {
    this.latestPost = this.blogService.getLatestArticles().do(
      collection => {
        this.latestLoader=false;
      },
      error => {
        console.log('collection getTipsArticles error');
      }
    );
  }

  getTipsArticles() {
    this.tipsPosts = this.blogService.getTipsArticles().do(
      collection => {
        this.tipsLoader=false;
      },
      error => {
        console.log('collection getTipsArticles error');
      }
    );
  }

  getNewsArticles() {
    this.newsPosts = this.blogService.getNewsArticles().do(
      collection => {
        this.newsLoader=false;
      },
      error => {
        console.log('collection getTipsArticles error');
      }
    );
  }
  
  getMyAqarArticles() {
    this.myAqarPosts = this.blogService.getNewsArticles().do(
      collection => {
        this.myAqarLoader=false;
      },
      error => {
        console.log('collection getMyAqarArticles error');
      }
    );
  }

  getInvestmentPostCount(catId) {
    this.blogService.get_category_posts_count(catId).subscribe(res=> {
      this.investmentPostCount = res[0];
    });
  }

  getRentingPostCount(catId) {
    this.blogService.get_category_posts_count(catId).subscribe(res=> {
      this.rentingPostCount = res[0];
    });
  }

  getBuyingPostCount(catId) {
    this.blogService.get_category_posts_count(catId).subscribe(res=> {
      this.buyingPostCount = res[0];
    });
  }

  getSellingPostCount(catId) {
    this.blogService.get_category_posts_count(catId).subscribe(res=> {
      this.sellingPostCount = res[0];
    });
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    // const number = $event.target.documentElement.scrollTop;
    // if (number > 140) {
    //   this.fixed = true;
    //   this.offset = 0;
    // } else if (this.fixed && number < 10) {
    //   this.fixed = false;
    //   this.offset = 0;
    // }
  }


}
