import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import { WINDOW } from '@ng-toolkit/universal';

export interface WindowSize {
  height: number,
  width: number
}

@Injectable()
export class WindowsSizeDetectionService {


  constructor() {
  // constructor(@Inject('windowObject') private window:Window) {

    // Observable.fromEvent(window, 'resize')
    //   .auditTime(100)
    //   .map(event => <WindowSize>{
    //     // width: event['currentTarget'].innerWidth,
    //     // height: event['currentTarget'].innerHeight
    //     width: event.innerWidth,
    //     height: event.innerHeight
    //
    //
    //   })
    //   .subscribe((windowSize) => {
    //     this.windowSizeChanged.next(windowSize);
    //   })
  };

  readonly windowSizeChanged = new BehaviorSubject<WindowSize>(<WindowSize>{
    // width: this.window.innerWidth,
    // height: this.window.innerHeight
  });
  
}
