import {Component, OnInit} from '@angular/core';
import {Customer} from '../../models/Customer';
import {UserService} from './../../_services/user.service';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';
import {CustomersOrdersModalComponent} from  '../customers-orders-modal/customers-orders-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit {


  first_name:FormControl;
  second_name:FormControl;
  third_name:FormControl;
  family_name:FormControl;
  id_number:FormControl;

  email:FormControl;
  mobile_number:FormControl;
  customer:Customer = new Customer();
  error:any;
  customerForm:FormGroup;
  create_customer_success_msg:string;
  create_customer_fail_msg:string;

  alert:boolean = false;
  alertClass:string = '';
  alertMsg:string = '';
  loading:boolean = false;
  customerInfo:any;
  customerId = 100;
  lang = 'ar';

  constructor(private  userService:UserService,
              private fb:FormBuilder,
              private toastr:ToastrService,
              private translate:TranslateService,
              private route:ActivatedRoute,
              private router:Router,
              private modalService:NgbModal
  ) {

    translate.get('create_customer_success_msg').subscribe((res:string) => {
      this.create_customer_success_msg = res;
    });

    translate.get('create_customer_fail_msg').subscribe((res:string) => {
      this.create_customer_fail_msg = res;
    });
  }

  ngOnInit() {
    // this.getCustomerInfo();
    this.createFormControls();
    this.createForm();

  }


  createFormControls() {

    this.first_name = new FormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]);

    this.second_name = new FormControl('', [
      // Validators.required,
      Validators.minLength(2)
    ]);

    this.third_name = new FormControl('', [
      // Validators.required,
      Validators.minLength(2)
    ]);

    this.family_name = new FormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]);


    this.email = new FormControl('', [
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      Validators.email
    ]);
    this.mobile_number = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);

    const numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

    this.id_number = new FormControl('', [
      Validators.pattern(numericNumberReg),
      Validators.minLength(8)
    ]);
  }


  createForm() {
    this.customerForm = this.fb.group({
      first_name: this.first_name,
      second_name: this.second_name,
      third_name: this.third_name,
      family_name: this.family_name,
      email: this.email,
      mobile_number: this.mobile_number,
      id_number: this.id_number,
    });

  }

  getCustomerInfo() {

    this.userService.getCustomerDetails(this.customerId).subscribe((res:any)=> {
      let customerInfo = (res.data);
      this.customer.id_number = customerInfo.id_number;
      this.customer.first_name = customerInfo.first_name;
      this.customer.second_name = customerInfo.second_name;
      this.customer.third_name = customerInfo.third_name;
      this.customer.family_name = customerInfo.family_name;
      this.customer.email = customerInfo.email;
      this.customer.mobile_number = customerInfo.mobile_number;
    });
  }


  createCustomer() {
    this.customer = this.customerForm.value;
    let res = this.userService.createCustomer(this.customer).subscribe((response) => {
      console.log(response['success']);
      if (response['success'] == true) {
        let id = response['data']['id'];
        this.toastr.success(this.create_customer_success_msg);
        this.router.navigateByUrl('/dashboard/customers/' + id);
      } else {

        let errors = response['errors'];
        for (let error of errors) {
          this.toastr.warning(error.field, error.message);
        }
      }
    });
  }

  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);

  }

}
