<div class="home-banner">


  <div class="free-palestine">
    <img class="palestine-slogan" src="./assets/images/icons/palestine.svg">
  </div>

  <div class="arrow-down"><i class="fa fa-angle-down"></i></div>
  <div class="banner-header">
    <h1 class="title">{{'bannerText'|translate}}</h1>
    <img class="slogan" src="./assets/images/icons/logo-slogyn.svg">
  </div>


  <div class="banner-action">
    <div class="search">
      <ngb-tabset>
        <ngb-tab>
          <ng-template ngbTabTitle> {{'for_sale'|translate}}</ng-template>
          <ng-template ngbTabContent>

            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'propertyType'|translate}}</label>
                      <ng-select [items]="propertyTypes"
                                 bindLabel="{{selectedName}}"
                                 required
                                 bindValue="id"
                                 placeholder="{{'selectedPropertyType'|translate}}"
                                 [(ngModel)]="selectedPropertyType"
                                 [clearable]="false"
                                 (change)="getPropertyType($event)"
                                 [searchable]="false"
                                 [typeahead]="false"
                                 class="large-select"
                                 (click)="$event.stopPropagation(); locationDropdownBuy1.close();"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Location'|translate}}</label>
                      <div ngbDropdown #locationDropdownBuy1="ngbDropdown">
                        <button class="location-dropdown larg-dropdown" id="locationDropdownBuy" ngbDropdownToggle>
                          <span> {{locationSearchText}} </span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="locationDropdownBuy" class="location-dropdown-menu">


                          <div class="form-group mb-3">
                            <label class="mb-1">{{'Governate'|translate}}</label>
                            <ng-select
                              [items]="governateList"
                              bindLabel="{{selectedName}}"
                              bindValue="id"
                              placeholder="{{'SelectGovernorate'|translate}}"
                              [(ngModel)]="selectedCityId"
                              [clearable]="false"
                              (change)="getGovernate($event)"
                              (click)="$event.stopPropagation(); "
                            >
                            </ng-select>
                          </div>

                          <div class="form-group mb-3">
                            <label class="mb-1">{{'Town'|translate}}</label>
                            <ng-select
                              [items]="townList"
                              bindLabel="{{selectedName}}"
                              required
                              bindValue="id"
                              placeholder="{{'SelectTown'|translate}}"
                              [(ngModel)]="selectedTownId"
                              [clearable]="false"
                              (change)="getTown($event)"
                            >
                            </ng-select>
                          </div>
                          <div class="form-group mb-3">
                            <label class="mb-1">{{'Zone'|translate}}</label>
                            <ng-select
                              [items]="areaList"
                              bindLabel="{{selectedName}}"
                              required
                              bindValue="id"
                              placeholder="{{'SelectArea'|translate}}"
                              [(ngModel)]="selectedAreaId"
                              [clearable]="false"
                              (change)="getArea($event)"
                            >
                            </ng-select>
                          </div>
                          <div class="form-group">
                            <button class="btn btn--light--default btn--small"
                                    (click)="$event.stopPropagation(); locationDropdownBuy1.close();">
                              {{'close'|translate}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sub-filters">
                  <div class="row">
                    <div class="select-container">
                      <div autoClose="false" ngbDropdown #areaDropdown="ngbDropdown">
                        <button
                          class="btn  btn--light dropdown-toggle type-btn"
                          id="areaDopdownToggle2"
                          [class.dropdown-selected]="selectedMinPropertyArea!=null || selectedMaxPropertyArea!=null"
                          ngbDropdownToggle
                        >
                          {{'area'|translate}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="areaDopdownToggle2" class="area-dropdown areaDropdown">
                          <div class=" mb-3">
                            <div class="select search-dropdown-list " style="min-width: 45%">

                              <ng-select [items]="propertyAreaList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'from'|translate}}"
                                         [(ngModel)]="selectedMinPropertyArea"
                                         [clearable]="true"
                                         (change)="getMinPropertyArea($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>
                            </div>
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="propertyAreaList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'to'|translate}}"
                                         [(ngModel)]="selectedMaxPropertyArea"
                                         [clearable]="true"
                                         (change)="getMaxPropertyArea($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="select-container">
                      <div autoClose="false" ngbDropdown #priceDropdown="ngbDropdown">
                        <button
                          class="btn  btn--light dropdown-toggle type-btn"
                          id="priceDopdownToggle"
                          [class.dropdown-selected]="selectedMinPrice!=null ||selectedMaxPrice!=null"
                          ngbDropdownToggle
                        >
                          {{'price'|translate}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="priceDopdownToggle" class="price-dropdown priceDropdown">

                          <div class="form-group mb-3 pr-4">
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="priceList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'from'|translate}}"
                                         [(ngModel)]="selectedMinPrice"
                                         [clearable]="true"
                                         (change)="getMinPrice($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>
                            </div>
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="priceList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'to'|translate}}"
                                         [(ngModel)]="selectedMaxPrice"
                                         [clearable]="true"
                                         (change)="getMaxPrice($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="select-container search-dropdown-list" *ngIf="filterIsAdvanced">
                      <ng-select [items]="roomList"
                                 bindLabel="{{selectedName}}"
                                 required
                                 bindValue="id"
                                 placeholder="{{'roomsNumber'|translate}}"
                                 [(ngModel)]="selectedRoomsCount"
                                 [clearable]="false"
                                 (change)="getRoomsCounts($event)"
                                 [searchable]="false"
                                 class="transparentt  no-autocomplete"
                      >
                      </ng-select>
                    </div>
                    <div class="select-container search-dropdown-list" *ngIf="filterIsAdvanced">
                      <ng-select [items]="propertyStatusList"
                                 bindLabel="{{selectedName}}"
                                 required
                                 bindValue="id"
                                 placeholder="{{'status'|translate}}"
                                 [(ngModel)]="propertyStatus"
                                 [clearable]="false"
                                 (change)="getPropertyStatus($event)"
                                 [searchable]="false"
                                 class="transparentt  no-autocomplete"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <button class="btn btn--large btn--primary browse-btn btn-block rounded"
                        (click)="brows('buy');locationDropdownBuy1.close();">
                  {{'Browse'|translate}}
                </button>
              </div>
            </div>

          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle> {{'for_rent'|translate}}</ng-template>
          <ng-template ngbTabContent>
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'propertyType'|translate}}</label>
                      <ng-select [items]="propertyTypes"
                                 bindLabel="{{selectedName}}"
                                 required
                                 bindValue="id"
                                 placeholder="{{'SelectPropertyType'|translate}}"
                                 [(ngModel)]="selectedPropertyType"
                                 [clearable]="false"
                                 (change)="getPropertyType($event)"
                                 class="large-select"
                                 (click)="locationDropdownRent.close()"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Location'|translate}}</label>
                      <div ngbDropdown #locationDropdownRent="ngbDropdown">
                        <button class="location-dropdown larg-dropdown" id="locationDropdownRent" ngbDropdownToggle>
                          <span> {{locationSearchText}} </span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="locationDropdownRent" class="location-dropdown-menu">
                          <div class="form-group mb-3">
                            <label class="mb-1">{{'Governate'|translate}}</label>
                            <ng-select
                              [items]="governateList"
                              bindLabel="{{selectedName}}"
                              bindValue="id"
                              placeholder="{{'SelectGovernorate'|translate}}"
                              [(ngModel)]="selectedCityId"
                              [clearable]="false"
                              (change)="getGovernate($event)"
                            >
                            </ng-select>
                          </div>

                          <div class="form-group mb-3">
                            <label class="mb-1">{{'Town'|translate}}</label>
                            <ng-select
                              [items]="townList"
                              bindLabel="{{selectedName}}"
                              required
                              bindValue="id"
                              placeholder="{{'SelectTown'|translate}}"
                              [(ngModel)]="selectedTownId"
                              [clearable]="false"
                              (change)="getTown($event)"
                            >
                            </ng-select>
                          </div>
                          <div class="form-group mb-3">
                            <label class="mb-1">{{'Zone'|translate}}</label>
                            <ng-select
                              [items]="areaList"
                              bindLabel="{{selectedName}}"
                              required
                              bindValue="id"
                              placeholder="{{'SelectArea'|translate}}"
                              [(ngModel)]="selectedAreaId"
                              [clearable]="false"
                              (change)="getArea($event)"
                            >
                            </ng-select>
                          </div>
                          <div class="form-group">
                            <button class="btn btn--light--default btn--small"
                                    (click)="$event.stopPropagation(); locationDropdownRent.close();">
                              {{'close'|translate}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sub-filters">
                  <div class="row">
                    <div class="select-container">
                      <div autoClose="false" ngbDropdown #areaDropdown="ngbDropdown">
                        <button
                          class="btn  btn--light dropdown-toggle type-btn"
                          id="areaDopdownToggle"
                          [class.dropdown-selected]="selectedMinPropertyArea!=null || selectedMaxPropertyArea!=null"
                          ngbDropdownToggle
                        >
                          {{'area'|translate}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="areaDopdownToggle" class="area-dropdown areaDropdown">

                          <div class="form-group mb-3 pr-4">
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="propertyAreaList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'from'|translate}}"
                                         [(ngModel)]="selectedMinPropertyArea"
                                         [clearable]="true"
                                         (change)="getMinPropertyArea($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>
                            </div>
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="propertyAreaList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'to'|translate}}"
                                         [(ngModel)]="selectedMaxPropertyArea"
                                         [clearable]="true"
                                         (change)="getMaxPropertyArea($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="select-container">
                      <div autoClose="false" ngbDropdown #priceDropdown="ngbDropdown">
                        <button
                          class="btn  btn--light dropdown-toggle type-btn"
                          id="priceDopdownToggle2"
                          [class.dropdown-selected]="selectedMinPrice!=null ||selectedMaxPrice!=null"
                          ngbDropdownToggle
                        >
                          {{'price'|translate}}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="priceDopdownToggle" class="price-dropdown priceDropdown">

                          <div class="form-group mb-3 pr-4">
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="priceList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'from'|translate}}"
                                         [(ngModel)]="selectedMinPrice"
                                         [clearable]="true"
                                         (change)="getMinPrice($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>
                            </div>
                            <div class="select search-dropdown-list " style="min-width: 45%">
                              <ng-select [items]="priceList"
                                         bindLabel="{{selectedName}}"
                                         bindValue="id"
                                         placeholder="{{'to'|translate}}"
                                         [(ngModel)]="selectedMaxPrice"
                                         [clearable]="true"
                                         (change)="getMaxPrice($event)"
                                         [searchable]="false"
                                         class="transparentt  no-autocomplete"
                              >
                              </ng-select>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="select-container" *ngIf="filterIsAdvanced">
                      <div class="search-dropdown-list">
                        <ng-select [items]="roomList"
                                   bindLabel="{{selectedName}}"
                                   required
                                   bindValue="id"
                                   placeholder="{{'roomsNumber'|translate}}"
                                   [(ngModel)]="selectedRoomsCount"
                                   [clearable]="false"
                                   (change)="getRoomsCounts($event)"
                                   [searchable]="false"
                                   class="no-autocomplete "
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="select-container" *ngIf="filterIsAdvanced">
                      <div class="search-dropdown-list">
                        <ng-select [items]="propertyStatusList"
                                   bindLabel="{{selectedName}}"
                                   required
                                   bindValue="id"
                                   placeholder="{{'Status'|translate}}"
                                   [(ngModel)]="propertyStatus"
                                   [clearable]="false"
                                   (change)="getPropertyStatus($event)"
                                   [searchable]="false"
                                   class="no-autocomplete"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <button class="btn btn--large btn--primary browse-btn btn-block rounded" (click)="brows('rent')">
                  {{'Browse'|translate}}
                </button>
              </div>
            </div>

          </ng-template>
        </ngb-tab>

        <ngb-tab>
          <ng-template ngbTabTitle> {{'Property Value'|translate}}</ng-template>
          <ng-template ngbTabContent>
            <!--(ngSubmit)="onSubmit()"-->
            <form [formGroup]="propertyRequestForm" (ngSubmit)="onSubmit()" class="property-request-form">
              <div class="row">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{'propertyType'|translate}}</label>
                        <ng-select [items]="propertyTypes"
                                   bindLabel="{{selectedName}}"
                                   required
                                   bindValue="id"
                                   placeholder="{{'selectedPropertyType'|translate}}"
                                   [clearable]="false"
                                   (change)="getPropertyType($event)"
                                   [(ngModel)]="selectedPropertyType"
                                   formControlName="propertyType"
                                   [searchable]="false"
                                   [typeahead]="false"
                                   class="large-select"
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{'Location'|translate}}</label>
                        <div ngbDropdown #locationDropdownValue="ngbDropdown">
                          <button type="button" class="location-dropdown larg-dropdown" id="locationDropdownValue"
                                  ngbDropdownToggle>
                            <span> {{locationSearchText}} </span>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="locationDropdownValue" class="location-dropdown-menu">
                            <div class="form-group mb-3">
                              <label class="mb-1">{{'Governate'|translate}}</label>
                              <ng-select
                                [items]="governateList"
                                bindLabel="{{selectedName}}"
                                bindValue="id"
                                placeholder="{{'SelectGovernorate'|translate}}"
                                [(ngModel)]="selectedCityId"
                                [clearable]="false"
                                formControlName="government"
                                (change)="getGovernate($event)"
                              >
                              </ng-select>
                            </div>

                            <div class="form-group mb-3">
                              <label class="mb-1">{{'Town'|translate}}</label>
                              <ng-select
                                [items]="townList"
                                bindLabel="{{selectedName}}"
                                required
                                bindValue="id"
                                placeholder="{{'SelectTown'|translate}}"
                                [(ngModel)]="selectedTownId"
                                [clearable]="false"
                                formControlName="city"
                                (change)="getTown($event)"
                              >
                              </ng-select>
                            </div>
                            <div class="form-group mb-3">
                              <label class="mb-1">{{'Zone'|translate}}</label>
                              <ng-select
                                [items]="areaList"
                                bindLabel="{{selectedName}}"
                                required
                                bindValue="id"
                                placeholder="{{'SelectArea'|translate}}"
                                [(ngModel)]="selectedAreaId"
                                [clearable]="false"
                                formControlName="areaZone"
                                (change)="getArea($event)"
                              >
                              </ng-select>
                            </div>
                            <div class="form-group">
                              <button type="button" class="btn btn--light--default btn--small"
                                      (click)="$event.stopPropagation(); locationDropdownValue.close();">
                                {{'close'|translate}}
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="sub-filters">
                    <div class="row">


                      <div class="col-md-6 col-6">

                        <input
                          type="text"
                          placeholder="{{'fullName'|translate}}"
                          class="form-control form-control-sm"
                          name="fullName"
                          formControlName="fullName"
                          [(ngModel)]="propertyRequest.fullName"
                        >


                        <input
                          type="hidden"
                          name="requestType"
                          formControlName="requestType"
                          [(ngModel)]="propertyRequest.requestType"
                        >

                        <input
                          type="text"
                          placeholder="{{'mobileNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="mobileNumber"
                          formControlName="mobile"
                          [(ngModel)]="propertyRequest.mobile"

                        >
                      </div>

                      <div class="col-md-6 col-6">
                        <input
                          type="text"
                          placeholder="{{'blockNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="blockNumber"
                          formControlName="blockNumber"
                          [(ngModel)]="propertyRequest.blockNumber"
                        >
                        <input
                          type="text"
                          placeholder="{{'sectorNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="sectorNumber"
                          formControlName="sectorNumber"
                          [(ngModel)]="propertyRequest.sectorNumber"
                        >
                        <input
                          type="text"
                          placeholder="{{'parcelNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="parcelNumber"
                          formControlName="parcelNumber"
                          [(ngModel)]="propertyRequest.parcelNumber"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="loading" class="spinner text-center d-block m-auto"></div>
                    <div *ngIf="alert" class="text-center alert alert-{{alertClass}} w-100">
                      <strong>{{alertMsgTitle}}</strong><br>
                      {{alertMsg}}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <button
                    type="submit"
                    class="btn btn--large btn--primary browse-btn btn-block padding-8 rounded "
                    [disabled]="!propertyRequestForm.valid"
                  >
                    {{'RequestInfo'|translate}}
                  </button>
                </div>
              </div>
            </form>

          </ng-template>
        </ngb-tab>

      </ngb-tabset>
    </div>

  </div>
  <img src="./assets/images/home/bannerMain2019.jpg">
  <!--<div class="corner"></div>-->
</div>


