<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <h1 class="text-center">{{'PricingGuide'|translate}}</h1>
    </div>
    <div class="row flex-items-xs-middle flex-items-xs-center card-container">
      <!-- Table #1  -->
      <div class="col-xs-12 col-lg-3" *ngFor="let membership of memberships?.data ">
        <div class="card text-xs-center  {{ membership.highLight==1?'active' :'' }} ">
          <div class="card-header">
            <div class="icon">
              <img src="{{membership.icon}}">
            </div>
            <h3 class="card-title">
              {{ lang=='ar'?membership?.arName : membership?.enName }}
            </h3>
            <h4 class="display-2">
              <span class="currency">$</span>
              <span *ngIf="membership.price < 100">{{membership.price|number}}</span>
              <span *ngIf="membership.price > 100" class="custom-price">{{'customPrice'|translate}}</span>
              <span class="period" *ngIf="membership.price < 100">/{{'month'|translate}}</span>
            </h4>
          </div>
          <div class="card-block">
            <ul class="list-group">
              <li class="list-group-item"><strong>1- {{membership.numberOfVisibleProperties}} </strong>{{'AddedProperties'|translate}}
              </li>
              <li class="list-group-item"><strong> {{membership.numberOfVisibleProperties}}% </strong>{{'VisibleProperties'|translate}}
              </li>
              <li class="list-group-item" *ngIf="membership.price >= 50">
                <strong> {{membership.discount}}% </strong>
                {{'discountOfServices'|translate}}
              </li>
              <li class="list-group-item" *ngIf="membership.price > 50">
                <span *ngIf="lang=='en'">
                  <u> <strong> {{'Special'|translate}} </strong></u>{{'PropertiesAlerts'|translate}}
                </span>
                <span *ngIf="lang=='ar'">
                  {{'PropertiesAlerts'|translate}}<u> <strong> {{'Special'|translate}} </strong></u>
                </span>
              </li>
            </ul>
            <a  *ngIf="membership.price > 0" href="#" class="btn  {{ membership.high_light==1?'btn--primary':'btn--light--default' }}  mt-2">{{'ContactUS'|translate}}</a>
            <button  *ngIf="membership.price == 0"  class="btn btn--light--default" (click)="openSignUpModal()"><i class="fa fa-user"></i> {{'header.sign_up'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>




