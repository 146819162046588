import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-same-area-property',
  templateUrl: './same-area-property.component.html',
  styleUrls: ['./same-area-property.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SameAreaPropertyComponent implements OnInit {

  @Input() itemsArray: any;
  @Input() lang: any;
  @Input() isLoggedIn: any;

  constructor() {
  }

  ngOnInit() {
  }

}
