import {Component, OnInit} from '@angular/core';
import {UserService} from './../../_services/user.service';
import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';

import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-notifications-details',
  templateUrl: './notifications-details.component.html',
  styleUrls: ['./notifications-details.component.scss']
})
export class NotificationsDetailsComponent implements OnInit {

  constructor(private  userService:UserService,
              private router:Router,
              private route:ActivatedRoute,
              private fb:FormBuilder,
              private toastr:ToastrService) {

  }


  loading = true;
  btnLoading = false;
  screenLoading = true;

  messages:any;
  mainMessage:any;
  id:any;
  message:FormControl;
  messageForm:FormGroup;

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.id = +params['notification_id'];
    });

    this.createFormControls();
    this.createForm();
    this.getCustomersNotifications(this.id);
  }


  createFormControls() {

    this.message = new FormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]);
  }


  createForm() {
    this.messageForm = new FormGroup({
      message: this.message,
    });
  }

  getCustomersNotifications(id) {
    this.screenLoading = true;

    this.userService.getCustomersNotificationsDetails(id).subscribe((res:any)=> {
      if (res.success) {
        this.screenLoading = false;

        this.messages = (res.data.items);
        this.mainMessage = (res.data.main_message);

      } else {
        this.screenLoading = false;
        console.log(res)
      }
    });
  }


  sendMessage() {

    let message = this.messageForm.value;
    this.loading = true;
    this.btnLoading = true;

    let res = this.userService.notificationsReplay(message, this.id).subscribe((response) => {
      console.log(response['success']);
      if (response['success'] == true) {
        this.loading = false;
        this.btnLoading = false;
        this.getCustomersNotifications(this.id);
        this.toastr.success('success');
        this.messageForm.reset();

      } else {
        console.log('error');
        this.toastr.warning('failed');
      }

    });


  }


}
