import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {DecimalPipe} from '@angular/common';
import {GovernateService} from '../../_services/governate.service';
import {dropdownLists} from '../../_services/dropdown-lists.service';
import {TranslateService} from '@ngx-translate/core';

import {CustomersOrdersModalComponent} from '../customers-orders-modal/customers-orders-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {AuthService} from '../../_services/auth.service';
import {UserService} from '../../_services/user.service';
import {propertyRequest} from '../../models/propertyRequest';
import {CustomersOrdersService} from '../../_services/customers-orders.service';

import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class BannerComponent implements OnInit {

  /**
   * price Range
   * @type {number}
   */

  filterIsAdvanced: boolean = false;
  selectedName: string = 'ar_name';
  priceFrom: number = 0;
  selectedPriceFrom: number;
  priceTo: number = 1000000;
  selectedPriceTo: number;
  public priceRange: number[] = [0, 1000000];

  /**
   * Area Range
   * @type {number}
   */
  areaFrom: any = 0;
  selectedAreaFrom: any;
  areaTo: any = 10000;
  selectedAreaTo: any;
  public areaRange: number[] = [0, 1000000];

  /**
   *Property Type
   * @type {string}
   */
  selectedPropertyType: string = 'lands';

  selectedGovId: number;
  selectedGovString: string;

  selectedTownId: number;
  selectedTownString: string;

  selectedAreaId: number;
  selectedAreaString: string;

  selectedSearchTypeId: number;
  locationSearchText: string = 'anywhere';

  selectedRangeArea: any;
  selectedRangePrice: any;

  governateList: any = [];
  townList: any = [];
  areaList: any = [];
  propertyTypes: any;

  selectedRoomsCount: number;
  roomList: any;

  propertyStatusList: any;
  propertyStatus: string;
  selectedPropertyStatus: string;

  browsFor: string;
  /**
   *  price list
   */

  priceList = [];
  selectedMinPrice: number = null;
  selectedMaxPrice: number = null;
  /**
   *  area list
   */

  propertyAreaList = [];
  selectedMinPropertyArea: number = null;
  selectedMaxPropertyArea: number = null;


  propertyRequest: propertyRequest = new propertyRequest();

  propertyRequestForm: FormGroup;
  fullName: FormControl;
  mobile: FormControl;
  propertyType: FormControl;
  government: FormControl;
  city: FormControl;
  areaZone: FormControl;
  blockNumber: FormControl;
  sectorNumber: FormControl;
  parcelNumber: FormControl;
  requestType: FormControl;
  isLoggedIn: any;
  loading = false;
  error = '';
  alert: boolean = false;
  alertClass: string = '';
  alertMsg: string = '';
  alertMsgTitle: string = '';
  alertDisplay: boolean = true;
  thankYouForContacting: string = '';
  receivedEnquiry: string = '';

  lang: any;
  property_request_success_msg: any;
  property_request_fail_msg: any;
  selectedCityId: any;

  /**
   * @param config
   * @param http
   * @param GovernateService
   * @param router
   * @param DecimalPipe
   * @param translate
   * @param Lists
   * @param modalService
   */

  constructor(config: NgbDropdownConfig,
              public http: HttpClient,
              private GovernateService: GovernateService,
              private router: Router,
              private DecimalPipe: DecimalPipe,
              public translate: TranslateService,
              public Lists: dropdownLists,
              private modalService: NgbModal,
              private customerOrdersServices: CustomersOrdersService,
              private auth: AuthService,
              private userService: UserService) {

    config.autoClose = false;

    let lang = translate.currentLang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }
    translate.get('anywhere').subscribe((res: string) => {
      this.locationSearchText = res;
    });
    translate.get('property_request_success_msg').subscribe((res: string) => {
      this.property_request_success_msg = res;
    });

    translate.get('property_request_fail_msg').subscribe((res: string) => {
      this.property_request_fail_msg = res;
    });
  }


  ngOnInit() {
    this
      .GovernateService
      .getGovernate()
      .subscribe(res => {
        this.governateList = res['data'];
      });
    this.propertyTypes = this.Lists.getpropertyTypes();
    this.propertyStatusList = this.Lists.getPropertyStatusList();
    this.roomList = this.Lists.getRoomList();
    this.priceList = this.Lists.getPriceToBuy();
    this.propertyAreaList = this.Lists.getAreaPropertyList();

    this.createFormControls();
    this.createForm();
    // this.lang = this.translate.currentLang;
    this.isLoggedIn = this.auth.isAuthenticated();
    if (this.isLoggedIn) {
      this.propertyRequest.email = this.userService.email;
      this.propertyRequest.fullName = this.userService.fullName;
      this.propertyRequest.mobile = this.userService.phone;

    }
    this.propertyRequest.requestType = 'property_value';
  }


  createFormControls() {

    this.fullName = new FormControl('', Validators.required);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]);

    this.propertyType = new FormControl('', []);

    this.government = new FormControl('', []);
    this.city = new FormControl('', []);
    this.areaZone = new FormControl('', []);

    this.blockNumber = new FormControl('', []);
    this.sectorNumber = new FormControl('', []);
    this.parcelNumber = new FormControl('', []);
    this.requestType = new FormControl('', []);

  }

  createForm() {
    this.propertyRequestForm = new FormGroup({
      fullName: this.fullName,
      mobile: this.mobile,
      propertyType: this.propertyType,
      government: this.government,
      city: this.city,
      areaZone: this.areaZone,
      blockNumber: this.blockNumber,
      sectorNumber: this.sectorNumber,
      parcelNumber: this.parcelNumber,
      requestType: this.requestType
    });
  }

  onSubmit(): void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.propertyRequest = this.propertyRequestForm.value;


    this.customerOrdersServices.propertyRequest(this.propertyRequest)
      .subscribe(res => {
        this.loading = false;
        this.alert = true;
        if (res['success']) {
          this.propertyRequestForm.reset();
          this.alertClass = 'success';
          this.alertMsg = this.property_request_success_msg;
        } else {
          this.alertClass = 'danger';
          this.alertMsg = this.property_request_fail_msg;
        }

      });

  }


  getPriceList() {

  }

  getGovernate($event) {
    this.selectedGovId = $event['id'];
    this.selectedTownId = null;
    this.selectedTownString = null;

    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.selectedGovString = $event[this.selectedName];
    this.getTownList(this.selectedGovId);
    this.updateLocationSearchText();
  }

  getTown($event) {
    this.selectedTownId = $event['id'];
    this.selectedTownString = $event[this.selectedName];

    this.selectedAreaId = null;
    this.selectedAreaString = null;

    this.getAreaList(this.selectedGovId, this.selectedTownId);
    this.updateLocationSearchText();

  }

  getArea($event) {
    this.selectedAreaId = $event['id'];
    this.selectedAreaString = $event[this.selectedName];
    this.updateLocationSearchText();
  }

  getPropertyType($event) {
    this.selectedPropertyType = $event['id'];
    this.propertyRequest.propertyType = $event['id'];
    this.filterIsAdvanced = $event['advanced'];
  }

  getTownList(govId) {
    this
      .GovernateService
      .getTowns(govId)
      .subscribe(res => {
        this.townList = res['data'];
      });

  }

  getAreaList(govId, townId) {
    this
      .GovernateService
      .getArea(govId, townId)
      .subscribe(res => {
        this.areaList = res['data'];
      });
  }


  updateLocationSearchText() {
    let locationString = (this.selectedGovString) + (this.selectedTownString == null ? '' : ',' + this.selectedTownString) + (this.selectedAreaString == null ? '' : ', ' + this.selectedAreaString);
    this.locationSearchText = locationString;

  }


  brows(type) {
    if (type === 'buy') {
      this.browsFor = 'for_sell';
    } else {
      this.browsFor = 'for_rent';
    }
    this.router.navigate(['/search/' + this.selectedPropertyType],
      {
        queryParams: {
          offer: this.browsFor,
          gov: this.selectedGovId,
          town: this.selectedTownId,
          area: this.selectedAreaId,
          property_status: this.selectedPropertyStatus,
          rooms: this.selectedRoomsCount,
          priceFrom: this.selectedMinPrice,
          priceTo: this.selectedMaxPrice,
          areaFrom: this.selectedMinPropertyArea,
          areaTo: this.selectedMaxPropertyArea,
          page: 1
        }
      }
    );
  }


  getRoomsCounts($event) {
    this.selectedRoomsCount = $event['id'];
  }

  getMinPrice($event) {
    this.selectedMinPrice = $event['id'];
  }

  getMaxPrice($event) {
    this.selectedMaxPrice = $event['id'];
  }

  getMinPropertyArea($event) {
    this.selectedMinPropertyArea = $event['id'];
  }

  getMaxPropertyArea($event) {
    this.selectedMaxPropertyArea = $event['id'];
  }


  getPropertyStatus($event) {
    this.selectedPropertyStatus = $event['id'];
  }

  //
  // getBlockNumber(event) {
  //   this.blockNumber = event.target.value;
  // }
  //
  // getSectorNumber(event) {
  //   this.sectorNumber = event.target.value;
  // }
  //
  // getParcelNumber(event) {
  //   this.parcelNumber = event.target.value;
  // }
  //
  // getFullName(event) {
  //   this.fullName = event.target.value;
  // }
  //
  // getMobileNumber(event) {
  //   this.fullName = event.target.value;
  // }


  openOrderModal(content) {
    let selectedGovId = this.selectedGovId;
    let selectedType = this.selectedPropertyType;


    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
    modalRef.componentInstance.government = 10; // should be the id


  }


}
