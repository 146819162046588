<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'notifications'|translate}}
        </h1>
      </div>
      <div class="action">
        <!--<a class="btn btn--primary " routerLink="/dashboard/customers/create"> + {{'addCustomer'|translate}} </a>-->
      </div>
    </div>
    <div class="dashboard-details ">

      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/notifications" class="active">{{'notifications'|translate}}</a></li>
        </ul>
      </div>


      <div class="dashboard-table-details mt-2">
        <span *ngIf="screenLoading==true">loading ...</span>

        <div class="card card-bordered ">

          <div class="d-flex justify-content-center"  *ngIf="screenLoading==true">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="card-inner">


            <div class="ticket-msgs">


              <div class="ticket-msg-item mb-5">

                <div class="ticket-msg-from col-md-12">
                  <div class="ticket-msg-user user-card">
                    <div class="user-avatar bg-primary"><span>KA</span></div>
                    <div class="user-info"><span class="lead-text">{{mainMessage?.full_name}}</span><span
                      class="text-soft">Customer</span>
                    </div>
                  </div>
                  <div class="ticket-msg-date"><span class="sub-text">{{mainMessage?.created_at}}</span></div>
                </div>
                <div class="ticket-msg-comment">{{mainMessage?.notes}}</div>
              </div>


              <div class="ticket-msg-item  mb-5" *ngFor="let item of messages">

                <div class="ticket-msg-from col-md-12">
                  <div class="ticket-msg-user user-card">
                    <div *ngIf="item.is_owner==1" class="user-avatar bg-primary"><span>KA</span></div>
                    <div *ngIf="item.is_owner==0" class="user-avatar bg-secondary"><span>KA</span></div>
                    <div class="user-info"><span class="lead-text">{{item.full_name}}</span>
                      <span class="text-soft" *ngIf="item.is_owner==0">Support</span>
                      <span class="text-soft" *ngIf="item.is_owner==1">customer</span>
                    </div>
                  </div>
                  <div class="ticket-msg-date"><span class="sub-text">{{item.created_at}}</span></div>
                </div>
                <div class="ticket-msg-comment">{{item.notes}}</div>
              </div>

              <div class="ticket-msg-reply">
                <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">

                  <div class="form-group">
                    <div class="form-editor-custom">
                      <textarea
                        type="text"
                        class="form-control"
                        formControlName="message"
                        placeholder="{{'write_message'|translate}}"
                      ></textarea>
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="form-group">
                    <button type="submit" class="btn btn--primary" [disabled]="btnLoading">
                      <span *ngIf="btnLoading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                      <span *ngIf="!btnLoading">{{'send'|translate}}</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<layout-footer></layout-footer>
