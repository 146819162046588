import {Component, OnInit} from '@angular/core';
import {UserService} from '../../_services/user.service';

import {DashboardService} from './../../_services/dashboard.service';

import {combineLatest} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {
  ready = false;
  contractsList:any = [];


  private
  constructor(private userService:UserService,private dashboardService:DashboardService) {
    this.initData();

  }

  ngOnInit() {
  }


  initData() {

    combineLatest(
      this.dashboardService.getUnitsContract()
    ).pipe(
      finalize(() => {
        this.ready = true;
      })
    ).subscribe(([contractResponse]:[any])=> {

      if (contractResponse.success == true) {
        console.log('success', contractResponse);
        this.contractsList = contractResponse.data;
      } else {
        console.log('fail', contractResponse);

      }


    });

  }


}
