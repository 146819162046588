import {Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {PropertyModel} from '../../models/propertyModel';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {GovernateService} from '../../_services/governate.service';
import {TranslateService} from '@ngx-translate/core';
import {dropdownLists} from '../../_services/dropdown-lists.service';
import {LandsService} from '../../_services/lands.service';
import {FileUploader} from 'ng2-file-upload';
import {ToastrService} from 'ngx-toastr';
import {WINDOW} from '@ng-toolkit/universal';
import {isPlatformBrowser} from '@angular/common';
import {PLATFORM_ID} from '@angular/core';


const URL = "";

@Component({
  selector: 'app-add-properties',
  templateUrl: './add-properties.component.html',
  styleUrls: ['./add-properties.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // directives: [FILE_UPLOAD_DIRECTIVES, NgClass, NgStyle, CORE_DIRECTIVES, FORM_DIRECTIVES]


})


export class AddPropertiesComponent implements OnInit {

  navBarLocation:string = "vertical";
  // private values:Array<WindowSize> = [];
  private anyErrors:boolean;
  private finished:boolean;
  private subscription;
  private windowWidth;
  error = '';
  lang = 'ar';

  property:PropertyModel = new PropertyModel();
  propertyForm:FormGroup;
  parcelNumber:FormControl;
  governate:FormControl;
  city:FormControl;
  areaZone:FormControl;
  address:FormControl;
  blockNumber:FormControl;
  sectorNumber:FormControl;
  area:FormControl;
  relationType:FormControl;
  representativeType:FormControl;
  brokerRelation:FormControl;
  representativeAuthority:FormControl;

  title:FormControl;
  price:FormControl;
  floor:FormControl;
  floorNumbers:FormControl;
  roomsNumber:FormControl;
  balconiesNumber:FormControl;
  bathroomsNumber:FormControl;
  features:FormControl;


  unitFeatureList:any;
  landFeatureList:any;
  buildingFeatureList:any;
  checkedFeatures:any = [];
  featuresList:any;


  selectedName:string = 'ar_name';
  selectedBlockName:string = 'ar_name';
  locationSearchText:string = 'anywhere';
  selectedGovId:number;
  selectedGovString:string;
  propertyType:string;
  selectedCityId:number;
  selectedTownId:number;
  selectedTownString:string;
  selectedCityString:string;
  selectedAreaId:number;
  selectedAreaString:string;

  selectedBlockId:number;
  selectedBlockString:string;

  landFieldsDisplay:string = "d-block";
  unitFieldsDisplay:string = "d-none";
  buildingFieldsDisplay:string = "d-none";
  sectorDisplay:string = "d-none";
  extraRelationDisplay:string = "d-none";
  selectedSectorId:number = null;
  selectedSectorString:string;
  selectedRelationTypeString:string;
  selectedBrokerRelationString:string;
  selectedRepresentativeAuthorityString:string;
  selectedRepresentativeTypeString:string;


  cityList:any = [];
  areaList:any = [];
  blocksList:any = [];
  sectorList:any = [];
  relationTypeList:any = [];
  representativeTypeList:any = [];
  governateList:any = [];
  brokerRelationList:any = [
    {'value': 'direct', 'ar_name': 'مباشر', 'en_name': 'direct'},
    {'value': 'indirect', 'ar_name': 'غير مباشر', 'en_name': 'indirect'},
  ];

  representativeAuthorityList:any = [];
  cityLoading:boolean = false;
  blockLoading:boolean = false;
  sectorLoading:boolean = false;
  areaLoading:boolean = false;
  submitLoading:boolean = false;
  relationTypeLoading:boolean = false;
  representativeTypeLoading:boolean = true;
  representativeAuthorityLoading:boolean = true;
  currentUnixTime:number = Math.round((new Date()).getTime() / 1000);

  add_property_success_msg:string;
  add_property_failed_msg:string;
  serverErrorMsg:string = '';
  landAlreadyExistError:string = '';
  isBrowser:boolean;

  public uploader:FileUploader = new FileUploader({url: URL});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  constructor(@Inject(WINDOW) private window:Window,
              private  GovernateService:GovernateService,
              public   translate:TranslateService,
              public   Lists:dropdownLists,
              private  router:Router,
              private route:ActivatedRoute,
              private  landService:LandsService,
              private  toastr:ToastrService,
              @Inject(PLATFORM_ID) platformId,
              private  formBuilder:FormBuilder) {


    this.buildForm();
    this.isBrowser = isPlatformBrowser(platformId);


    let lang = translate.currentLang;
    if (lang == 'en') {
      this.lang = 'en';
      this.selectedName = 'en_name';
      this.selectedBlockName = 'en_name';
    } else {
      this.lang = 'ar';
      this.selectedName = 'ar_name';
      this.selectedBlockName = 'name';
    }

    translate.get("add_property_failed_msg").subscribe((res:string) => {
      this.add_property_failed_msg = res;
    });
    translate.get("add_property_success_msg").subscribe((res:string) => {
      this.add_property_success_msg = res;
    });

    translate.get("landAlreadyExistError").subscribe((res:string) => {
      this.landAlreadyExistError = res;
    });

  }

  ngOnInit() {

    this.formFieldsDisply();
    this.windowWidth = this.window.innerWidth;
    if (this.windowWidth < 798) {
      this.navBarLocation = "horizontal";
    } else {
      this.navBarLocation = "vertical";
    }

    this
      .GovernateService
      .getGovernate()
      .subscribe(res => {
        this.governateList = res['data'];
      });

    this.getRelationTypeList();
    this.getRepresentativeTypeList();
    this.getRepresentativeAuthorityList();
  }


  formFieldsDisply() {
    this.propertyType = this.route.snapshot.url[2].path;

    if (this.propertyType == 'land') {
      this.featuresList = this.Lists.getLandFeatures;
      this.landFieldsDisplay = '';
      this.unitFieldsDisplay = 'd-none';
      this.buildingFieldsDisplay = 'd-none';
      this.setLandValidation();
    }
    else if (this.propertyType == 'apartment' || this.propertyType == 'store' || this.propertyType == 'office') {
      this.featuresList = this.Lists.getUnitFeatures;

      this.landFieldsDisplay = 'd-none';
      this.unitFieldsDisplay = '';
      this.buildingFieldsDisplay = '';
      this.setUnitValidation();
    }
    else {
      this.featuresList = this.Lists.getBuildingFeatures;

      this.landFieldsDisplay = 'd-none';
      this.unitFieldsDisplay = 'd-none';
      this.buildingFieldsDisplay = '';
    }
  }


  buildForm() {
    this.propertyForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      price: [null, [Validators.required]],
      roomsNumber: [null],
      balconiesNumber: [null],
      bathroomsNumber: [null],
      offer: ['for_sell'],
      status: ['unfurnished'],
      governate: [null, [Validators.required]],
      city: [null, [Validators.required]],
      areaZone: [null],
      address: [null],
      parcelNumber: [null],
      blockNumber: [null],
      sectorNumber: [null],
      area: [null, [Validators.required]],
      relationType: ['owner', [Validators.required]],
      representativeType: [null],
      brokerRelation: [null],
      representativeAuthority: [null]
    });
  }

  get f() {
    return this.propertyForm.controls;
  }

  setLandValidation() {
    const blockNumberController = this.propertyForm.controls['blockNumber'];
    const parcelNumberController = this.propertyForm.controls['parcelNumber'];
    const sectorNumberController = this.propertyForm.controls['sectorNumber'];

    blockNumberController.setValidators([Validators.required]);
    parcelNumberController.setValidators([Validators.required]);

  }

  setUnitValidation() {
    const roomsNumberController = this.propertyForm.controls['roomsNumber'];
    const balconiesNumberController = this.propertyForm.controls['balconiesNumber'];
    const bathroomsNumberController = this.propertyForm.controls['bathroomsNumber'];
    const blockNumberController = this.propertyForm.controls['blockNumber'];
    const parcelNumberController = this.propertyForm.controls['parcelNumber'];
    const sectorNumberrController = this.propertyForm.controls['sectorNumber'];
    roomsNumberController.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
    balconiesNumberController.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
    bathroomsNumberController.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
    blockNumberController.setValidators(null);
    parcelNumberController.setValidators(null);
    sectorNumberrController.setValidators(null);
  }

  relationTypeChange($event) {
    console.log($event);

    const representativeAuthorityControl = this.propertyForm.controls['representativeAuthority'];
    const representativeTypeControl = this.propertyForm.controls['representativeType'];
    const brokerRelationDisplayControl = this.propertyForm.controls['brokerRelation'];

    if ($event['value'] == 'owner' || $event['value'] == 'shareOwner') {
      this.extraRelationDisplay = 'd-none';
      representativeAuthorityControl.setValidators(null);
      representativeTypeControl.setValidators(null);
      brokerRelationDisplayControl.setValidators(null);
    } else {
      this.extraRelationDisplay = 'd-block';
      representativeAuthorityControl.setValidators([null, Validators.required]);
      representativeTypeControl.setValidators([null, Validators.required]);
      brokerRelationDisplayControl.setValidators([null, Validators.required]);
    }
  }

  onSubmit():void {

    if (this.propertyForm.valid) {

      this.property = this.propertyForm.value;
      this.property.features = this.checkedFeatures;
      this.submitLoading = true;
      this.serverErrorMsg = "";
      this.landService.addProperty(this.property, this.propertyType).subscribe((res:any) => {
          this.submitLoading = false;
          if (res['success'] == true) {
            this.toastr.success(this.add_property_success_msg);
            let id = res['id'];
            this.router.navigateByUrl('/dashboard/add-properties/' + this.propertyType + '/upload/' + id);
          } else {
            this.toastr.warning((res['msg']));
          }
        }, err=> {
          // console.log(res);
          this.toastr.warning(this.add_property_failed_msg);
          // this.toastr.warning((res['msg']));

          if (err['error']['errors'] == "landAlreadyExist") {
            this.serverErrorMsg = this.landAlreadyExistError;
          } else {
            this.serverErrorMsg = err['error']['errors'];
          }
          this.submitLoading = false;
        }
      )
    } else {
      console.log('invalid');
      this.validateAllFormFields(this.propertyForm); //{7}
    }
  };

  validateAllFormFields(formGroup:FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  ngOnDestroy() {
    this.subscription = null;
  }


  getGovernate($event) {
    this.selectedGovId = $event['id'];
    this.selectedCityId = null;
    this.selectedCityString = null;

    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.selectedGovString = $event[this.selectedName];
    this.getCityList(this.selectedGovId);
    this.cityLoading = true;
    this.updateLocationSearchText();
  }


  getCity($event) {
    this.selectedCityId = $event['id'];
    this.selectedCityString = $event[this.selectedName];

    this.selectedAreaId = null;
    this.selectedAreaString = null;

    this.areaLoading = true;
    this.getAreaList(this.selectedGovId, this.selectedCityId);
    this.blockLoading = true;
    this.getBlockList(this.selectedGovId, this.selectedCityId);
    this.updateLocationSearchText();

  }

  getArea($event) {
    this.selectedAreaId = $event['id'];
    this.selectedAreaString = $event[this.selectedName];
    this.updateLocationSearchText();
  }

  getCityList(govId) {
    this
      .GovernateService
      .getTowns(govId)
      .subscribe(res => {
        this.cityList = res['data'];
        this.cityLoading = false;
      });

  }

  getRelationTypeList() {
    this.relationTypeLoading = true;
    this
      .GovernateService
      .getRelationType()
      .subscribe(res => {
        this.relationTypeList = res;
        this.relationTypeLoading = false;
      });

  }

  getRepresentativeTypeList() {
    this.representativeTypeLoading = true;
    this
      .GovernateService
      .getRepresentativeTypeList()
      .subscribe(res => {
        this.representativeTypeList = res;
        this.representativeTypeLoading = false;
      });

  }

  getRepresentativeAuthorityList() {
    this.representativeAuthorityLoading = true;
    this
      .GovernateService
      .getRepresentativeAuthorityList()
      .subscribe(res => {
        this.representativeAuthorityList = res;
        this.representativeAuthorityLoading = false;
      });

  }


  getAreaList(govId, townId) {
    this
      .GovernateService
      .getArea(govId, townId)
      .subscribe(res => {
        this.areaList = res['data'];
        this.areaLoading = false;
      });
  }

  getBlockList(govId, townId) {
    this
      .GovernateService
      .getBlocks(govId, townId)
      .subscribe(res => {
        this.blocksList = res['data'];
        this.blockLoading = false;
      });
  }

  getSectorList($event) {

    this.sectorLoading = true;
    let blockId = $event['id'];
    this
      .GovernateService
      .getSectors(blockId)
      .subscribe(res => {

        this.sectorList = res['data'];
        if (this.sectorList.length > 0) {
          this.sectorDisplay = "d-block";
        } else {
          this.sectorDisplay = "d-none";
        }
        this.sectorLoading = false;
      });
  }


  updateLocationSearchText() {
    let locationString = (this.selectedGovString) + (this.selectedCityString == null ? '' : ',' + this.selectedCityString) + (this.selectedAreaString == null ? '' : ', ' + this.selectedAreaString);
    this.locationSearchText = locationString;

  }

  onFeaturesCheckboxChange(option, event) {

    if (event.target.checked) {
      console.log('optionID:', option.id);
      this.checkedFeatures.push(option.id);
    } else {
      for (var i = 0; i < this.featuresList.length; i++) {
        if (this.checkedFeatures[i] == option.id) {
          this.checkedFeatures.splice(i, 1);
        }
      }
    }
  }


}
