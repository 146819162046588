import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable()
export class GovernateService {


  constructor(private http: HttpClient) {

  }

  getCountries() {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'base/countries_list');
  }

  getGovernate() {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate');
  }

  getTowns(govId) {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/' + govId + '/cities');
  }


  getArea(govId, townId) {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/' + govId + '/cities/' + townId + '/areas');
  }


  getBlocks(govId, townId) {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/' + govId + '/cities/' + townId + '/blocks');
  }


  getSectors(blockId) {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/block/' + blockId + '/sectors');

  }

  getRelationType() {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/relation_type_list');
  }

  getRepresentativeTypeList() {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/representative_type_list');
  }

  getRepresentativeAuthorityList() {
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'governorate/representative_authority_list');
  }


}
