import {Component, OnInit} from '@angular/core';
import {Land} from '../../../models/land';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-property-slider',
  templateUrl: './property-slider.component.html',
  styleUrls: ['./property-slider.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class PropertySliderComponent {

  lands:any[];
  msgs:any[];

  constructor() {

    this.msgs = [];
    this.lands = [
      {title: 'Surda Land ', address: 'Ramllah ,Surda ', area: '1500m2', price: '250,000' ,classification:'A'},
      {title: 'Alberih Land ', address: 'Ramllah ,Surda ', area: '1500m2', price: '250,000' ,classification:'A'},
      {title: 'Ramllah Land ', address: 'Ramllah ,Surda ', area: '1500m2', price: '250,000' ,classification:'A'},
      {title: 'Baloo3 Land ', address: 'Ramllah ,Surda ', area: '1500m2', price: '250,000' ,classification:'A'},
      {title: 'Surda Land ', address: 'Ramllah ,Surda ', area: '1500m2', price: '250,000' ,classification:'A'}
    ];
  }

  selectCar(land: Land) {
    this.msgs = [];
    this.msgs.push({severity: 'info', summary: 'Car Selected'});
  }

}
