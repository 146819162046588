import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

const httpOptions = new HttpHeaders({
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Max-Age': '1728000',
  'Access-Control-Allow-Headers': 'Content-Type, Content-Range, Content-Disposition, Content-Description',
  'Access-Control-Allow-Methods': 'DELETE, HEAD, GET, OPTIONS, POST, PUT'
});


@Injectable()

export class BlogService {


  private BASE_URL:string = environment.BLOG_SERVER_API_EN_URL;
  private BASE_URL_ENDPOINT:string = environment.BLOG_SERVER_ENDPOINT_API_EN_URL;
  private currentLang;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, private http:HttpClient) {
    this.currentLang = localStorage.getItem('language');
    if (this.currentLang == 'ar') {
      this.BASE_URL = environment.BLOG_SERVER_API_AR_URL;
      this.BASE_URL_ENDPOINT = environment.BLOG_SERVER_ENDPOINT_API_AR_URL;
    }

  }


  getLatestArticles():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '4',
        fields: 'id,slug,title,better_featured_image,categories',
      }
    });
  }


  getNewsArticles():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '3',
        fields: 'id,title,better_featured_image,categories,slug',
        categories: '2'
      }
    });
  }

  getBanners():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '3',
        fields: 'id,title,better_featured_image,categories,slug',
        categories: '23'
      }
    });
  }


  getGuideArticles():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '4',
        fields: 'id,title,better_featured_image,categories,slug',
        categories: '3'
      }
    });
  }


  getMyAqarArticles():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '4',
        fields: 'id,title,better_featured_image,categories,slug',
        categories: '4,5'
      }
    });
  }

  getTipsArticles():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '4',
        fields: 'id,title,better_featured_image,categories,slug',
        categories: '5',
      }
    });
  }

  getRealEstateArticles():Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        per_page: '4',
        fields: 'id,title,better_featured_image,categories,slug',
        categories: '6'
      }
    });
  }


  getCategoriesByTheirParent(categoryId) {
    return this.http.get(this.BASE_URL + 'categories?parent=6&fields=id,name', {
      params: {
        parent: categoryId,
        fields: 'id,name,slug'
      }
    });
  }


  getPostBySlug(slug) {
    return this.http.get(this.BASE_URL + 'posts?_embed', {
      params: {
        slug: slug
      }
    });
  }


  getPostByParentCategory(catId) {
    return this.http.get(this.BASE_URL + 'posts', {
      params: {
        categories: catId,
        fields: 'id,title,categories,slug'
      }
    });

  }

  get_category_posts_count(catId) {
    return this.http.get(this.BASE_URL + 'categories', {
      params: {
        include: catId,
        fields: 'id,name,count,slug'
      }
    });
  }


  getPostByCategorySlug(slug):Observable<any[]> {
    return this.http.get<any[]>(this.BASE_URL + 'posts?', {
      params: {
        fields: 'id,title,better_featured_image,categories,slug',
        category_name: slug
      }
    });
  }


  getCategoryPosts(catId) {
    return this.http.get(this.BASE_URL_ENDPOINT + 'get-category-posts', {
      params: {
        categories: catId,
        fields: 'id,title,categories,slug'
      }
    });
  }


  getRealEstateFirstPostOfCategory(catId) {
    return this.http.get(this.BASE_URL_ENDPOINT + 'get-first-post-from-category', {
      params: {
        fields: 'id,title,better_featured_image,categories,slug',
        categories: catId,

      }
    });

  }

  getFirstAboutPost(catId) {
    return this.http.get(this.BASE_URL_ENDPOINT + 'get-first-about-post', {
      params: {
        fields: 'id,title,better_featured_image,categories,slug',
        categories: catId,

      }
    });

  }


  getPageBySlug(slug) {
    return this.http.get(this.BASE_URL + 'pages', {
      params: {
        slug: slug

      }
    });
  }


  getServicesMenu(catId) {
    return this.http.get(this.BASE_URL + 'posts?', {
      params: {
        fields: 'id,title,acf,slug',
        categories: catId,
        per_page:'6'

      }
    });
  }

  getFeatures(catId) {
    return this.http.get(this.BASE_URL + 'posts?', {
      params: {
        fields: 'id,title,acf,slug',
        categories: catId,

      }
    });
  }

}
