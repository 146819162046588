import {Injectable} from '@angular/core';


import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class DashboardService {
  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  constructor(private http:HttpClient) {
  }


  getSavedPropertiesCount() {
    return this.http.get(this.BASE_URL + 'user_properties/saved_properties_count');
  }


  getUserTotalPropertieCount() {
    return this.http.get(this.BASE_URL + 'user_properties/count');
  }


  getRentedUnitCount() {
    return this.http.get(this.BASE_URL + 'rent/rented_unit_count');
  }

  getUnRentedUnitList() {
    return this.http.get(this.BASE_URL + 'rent/unrented_unit_list');
  }


  getRentedContractCount() {
    return this.http.get(this.BASE_URL + 'rent/rented_contract_count');
  }


  getExpiredContractsWithOutstandingPayments() {
    return this.http.get(this.BASE_URL + 'rent/contracts/expired_contract_with_outstanding_payments');
  }


  getUnitsContract() {
    const url = this.BASE_URL + `rent/contracts`;
    return this.http.get(url);
  }


  getContractDeatils(id) {
    const url = this.BASE_URL + `rent/contracts/details/` + id;
    return this.http.get(url);
  }







}
