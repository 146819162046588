
export class Contract {
  unit:any;
  date_of_writing:any;
  renting_start_date:any;
  renting_end_date:any;
  duration:any;
  contract_value:any;
  currency:any;
  renter:any;
  unit_usage:any;

}
