<app-top-header></app-top-header>
<div class="dashboard">

  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>
  <div class="dashboard-content">
    <div class="bg-gradient-primary">


      <div class="dashboard-header ">
        <div class="title">
          <h1 class="text-capitalize  text-white">{{'Account Overview'|translate}}</h1>
        </div>
        <div class="action">
          <button class="btn  btn--light--default ml-1 mr-1" (click)="openOrderModal()"> {{'requestInfo'|translate}} <i
            class="fa fa-info"></i></button>
          <a class="btn btn--primary " routerLink="/dashboard/add-properties"> {{'AddNewProperty'|translate}}</a>
        </div>
      </div>
      <div class="dashboard-details">
        <div class="cards mt-5 mb-3">

          <div class="row">
<!--            <div class="col-xl-3 col-lg-6 col-12">-->
<!--              <a class="card">-->
<!--                <div class="card-body">-->
<!--                  <div class="media d-flex">-->
<!--                    <div class="media-body text-left">-->
<!--                      <h6 class="text-muted">{{'PaymentsDue'|translate}} <span class="counter">(3)</span></h6>-->
<!--                    </div>-->
<!--                    <div class="align-self-center">-->
<!--                      <i class="fa  fa-dollar fa-2x"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a>-->
<!--            </div>-->

            <div class="col-xl-3 col-lg-6 col-12">
              <a routerLink="/dashboard/properties/lands" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'lands'|translate}} <span class="counter">({{userLanCount}})</span></h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape ">
                        <img src="assets/images/icons/Land.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12">
              <a routerLink="/dashboard/properties/villas_houses" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'villas_houses'|translate}} <span class="counter">({{userVillaHouseCount}})</span>
                      </h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape ">
                        <img src="assets/images/icons/Villa.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12 ">
              <a routerLink="/dashboard/properties/buildings" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'buildings'|translate}} <span
                        class="counter">({{userBuildingCount}})</span></h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape ">
                        <img src="assets/images/icons/Building.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12">
              <a routerLink="/dashboard/properties/apartments" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'apartments'|translate}} <span
                        class="counter">({{userApartmentCount}})</span></h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape ">
                        <img src="assets/images/icons/Apartment.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12">
              <a routerLink="/dashboard/properties/stores" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'stores'|translate}} <span class="counter">({{userStoresCount}})</span>
                      </h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape ">
                        <img src="assets/images/icons/Store.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12">
              <a routerLink="/dashboard/properties/offices" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'offices'|translate}} <span class="counter">({{userOfficesCount}})</span>
                      </h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape ">
                        <img src="assets/images/icons/Office.svg">
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12">
              <a routerLink="/dashboard/saved-properties" class="card">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'SavedProperties'|translate}} <span class="counter">({{savedPropertiesCount}})</span>
                      </h6>
                    </div>
                    <div class="align-self-center">
                      <div class="icon-shape " style="color: #ff0808">
                        <i class="fa  fa-heart-o fa-2x"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-table-details mt-6">
      <div class="row">

        <div class="col-xl-6 col-lg-6 col-12">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-capitalize"><strong>{{'newAddedProperties'|translate}}</strong></h3>
                </div>
                <div class="col text-right">
                  <a routerLink="/dashboard/properties/buildings" class="btn--small btn--primary btn">{{'seeAll'|translate}}</a>
                </div>
              </div>
              <hr>
              <div class="row">

                <table class="table table-borderless text-center table-responsive">
                  <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'propertyName'|translate}}</th>
                    <th scope="col">{{'propertyAddress'|translate}}</th>
                    <th scope="col">{{'rentedUnitsCount'|translate}}</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tbody *ngIf="latestBuildingReady">
                  <tr *ngFor="let building of latestBuilding.slice(0, 5);index as i">
                    <th scope="row">{{i+1}}</th>
                    <td>
                      <a routerLink="/dashboard/properties/buildings/{{building.id}}">
                        {{ lang=='ar'?building.ar_title :building.en_title }}
                      </a>
                    </td>
                    <td>
                      {{ lang=='ar'?building.ar_government :building.en_government }}
                    </td>
                    <td>{{building.rented_units_count}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>



        </div>
        <div class="col-xl-6 col-lg-6 col-12">

          <div class="card shadow  mb-3">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-capitalize"><strong>{{'newAddedUnits'|translate}}</strong></h3>
                </div>
                <div class="col text-right">
                  <a routerLink="/dashboard/properties/units"
                     class="btn--small btn--primary btn">{{'seeAll'|translate}}</a>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-borderless text-center ">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{{'theUnit'|translate}}</th>
                      <th scope="col">{{'theProperty'|translate}}</th>
                      <th scope="col">{{'unitType'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="latestUnitsReady">
                    <tr *ngFor="let unit of latestUnits.slice(0, 5);index as i">
                      <th scope="row">{{i+1}}</th>
                      <td><a routerLink="/dashboard/properties/{{unit.type}}/{{unit.id}}">{{unit.id}}</a></td>
                      <td>
                        {{ lang=='ar'?unit.ar_building_title :unit.en_building_title }}
                      </td>
                      <td>{{unit.type|translate}}</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <!--<div class="card shadow">-->
            <!--<div class="card-header border-0">-->
              <!--<div class="row align-items-center">-->
                <!--<div class="col">-->
                  <!--<h3 class="mb-0"><strong>{{'PaymentsDue'|translate}}</strong></h3>-->
                <!--</div>-->
                <!--<div class="col text-right">-->
                  <!--<a href="#" class="btn&#45;&#45;small btn&#45;&#45;primary btn">{{'seeAll'|translate}}</a>-->
                <!--</div>-->
              <!--</div>-->
              <!--<hr>-->
              <!--<div class="row">-->

                <!--<table class="table table-borderless text-center table-responsive">-->
                  <!--<thead>-->
                  <!--<tr>-->
                    <!--<th scope="col">#</th>-->
                    <!--<th scope="col">{{'name'|translate}}</th>-->
                    <!--<th scope="col">{{'installment'|translate}}</th>-->
                    <!--<th scope="col">{{'installmentType'|translate}}</th>-->
                    <!--<th scope="col">{{'dueDate'|translate}}</th>-->
                    <!--<th scope="col">{{'mobileNumber'|translate}}</th>-->
                  <!--</tr>-->
                  <!--</thead>-->
                  <!--<tbody>-->
                  <!--<tr>-->
                    <!--<td colspan="6" class="text-left td-heading blue-color"><i class="fa fa-caret-left"></i>-->
                      <!--{{'contractNumber'|translate}}-->
                      <!--<strong class="number-font"><a href="#">-->
                        <!--1690026143-->
                      <!--</a></strong><span class="contract-units"><span><span>({{'units'|translate}})</span></span></span>-->
                    <!--</td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<th scope="row">1</th>-->
                    <!--<td>رائف نصار</td>-->
                    <!--<td>500$</td>-->
                    <!--<td>ايجار شهري</td>-->
                    <!--<td>2020-04-10</td>-->
                    <!--<td>0598164229</td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<th scope="row">2</th>-->
                    <!--<td>رائف نصار</td>-->
                    <!--<td>50$</td>-->
                    <!--<td>ضريبة معارف</td>-->
                    <!--<td>2020-04-15</td>-->
                    <!--<td>0598164229</td>-->
                  <!--</tr>-->

                  <!--<tr>-->
                    <!--<td colspan="6" class="text-left td-heading blue-color"><i class="fa fa-caret-left"></i>-->
                      <!--{{'contractNumber'|translate}}-->
                      <!--<strong class="number-font"><a href="#">-->
                        <!--1690026145-->
                      <!--</a></strong><span-->
                        <!--class="contract-units"><span><span>({{'stores'|translate}})</span></span></span></td>-->
                  <!--</tr>-->
                  <!--<tr>-->
                    <!--<th scope="row">1</th>-->
                    <!--<td>محمص العودة</td>-->
                    <!--<td>900$</td>-->
                    <!--<td>ايجار شهري</td>-->
                    <!--<td>2020-04-2</td>-->
                    <!--<td>0598121212</td>-->
                  <!--</tr>-->
                  <!--</tbody>-->
                <!--</table>-->

              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        </div>


      </div>
    </div>
  </div>
</div>
<layout-footer></layout-footer>
