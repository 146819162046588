import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {CustomersOrdersService} from '../../_services/customers-orders.service';
import {contactInfo} from '../../models/contactInfo';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  contactInfo:contactInfo = new contactInfo();


  error = '';
  uData:any;
  alert:boolean = false;
  alertClass:string = '';
  alertMsg:string = '';
  alertMsgTitle:string = '';
  alertDisplay:boolean=true;
  thankYouForContacting:string = '';
  receivedEnquiry:string = '';
  loading:boolean = false;

  contactForm:FormGroup;

  fullName:FormControl;
  mobile:FormControl;
  email:FormControl;
  notes:FormControl;
  lang:any;

  constructor(private OrdersService:CustomersOrdersService, public translate:TranslateService) {


    this.createFormControls();
    this.createForm();
    this.lang = this.translate.currentLang;

  }

  ngOnInit() {
    this.translate.get("ThankYouForContactingUs").subscribe((res:string) => {
      this.thankYouForContacting = res;

    });
    this.translate.get("receivedEnquiry").subscribe((res:string) => {
      this.receivedEnquiry = res;

    });
  }


  createFormControls() {
    this.fullName = new FormControl('', Validators.required);
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      Validators.email
    ]);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]);
    this.notes = new FormControl('', [
      Validators.required,
    ]);
  }


  createForm() {
    this.contactForm = new FormGroup({
      fullName: this.fullName,
      email: this.email,
      mobile: this.mobile,
      notes: this.notes
    });
  }


  onSubmit():void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.contactInfo = this.contactForm.value;

    this.OrdersService.contactUs(this.contactInfo)
      .subscribe(res => {
        this.loading = false;
        this.alert = true;
        if (res['success']) {
          this.alertClass = 'success';
          this.alertMsg = this.receivedEnquiry;
          this.alertMsgTitle = this.thankYouForContacting;
          this.contactForm.reset()
        } else {
          this.alertClass = 'danger';
          this.alertMsg = res['msg'];
        }

      });

  }


}
