import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable()
export class CustomersOrdersService {


  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  constructor(private http:HttpClient) {
  }


  createOrder(order) {
    let url:string = `${this.BASE_URL}customers_orders/create`;
    return this.http.post(url, order);
  }

  contactUs(contact) {

    let url:string = `${this.BASE_URL}customers_orders/contact`;
    return this.http.post(url, contact);
  }

  propertyRequest(propertyRequest) {

    let url:string = `${this.BASE_URL}customers_orders/property_request`;
    return this.http.post(url, propertyRequest);
  }
  


}
