import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {UserService} from './../../_services/user.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class NotificationsComponent implements OnInit {


  loading = true;
  notifications:any;
  screenLoading=true;

  constructor(private  userService:UserService) {
  }

  ngOnInit() {
    this.getCustomersNotifications()
  }


  getCustomersNotifications() {
    this.userService.getCustomersNotifications().subscribe((res:any)=> {
      if (res.success) {
        this.notifications = (res.data);
        this.screenLoading=false;
      } else {
        this.screenLoading=false;


      }
    });
  }

}
