import {Component, OnInit, ViewChild, Input, OnChanges, Output} from '@angular/core';
import {MapTypeStyle, AgmInfoWindow, MapsAPILoader, AgmMap, MouseEvent, AgmCircle} from '@agm/core';
import {AgmDirectionModule} from 'agm-direction';
import {filter, catchError, tap, map, switchMap} from 'rxjs/operators';

import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {of} from 'rxjs/observable/of';

import {Injectable} from '@angular/core';
import {fromPromise} from 'rxjs/observable/fromPromise';


import {ViewEncapsulation} from '@angular/core';
import {Location} from './../../../models/location-model';

declare var google: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class MapsComponent implements OnInit {
  @ViewChild(AgmMap, {static: true}) private map: any;
  @ViewChild(AgmCircle, {static: true}) myCircle;

  LatLngBounds: any;

  // @Input('markers') markers:marker[];
  @Input() markers: any;
  markerIcon: string = './assets/images/icons/tag-map.png';
  @Input() lang: any;
  @Input() circleDraggable: boolean = true;
  @Output() searchByMap;
  @Input() isLoggedIn;
  @Input() showDirection: boolean;
  @Input() zoom;
  infoWindow: any;
// google maps zoom level
//   zoom:number = 40;

  // initial center position for the map
  lat = 31.906694;
  lng = 35.211501;

  // 32.00864496576431 35.29554533727264 31.804629962113356 35.127456662727354
  neLat = 32.00864496576431;
  neLng = 35.29554533727264;
  swLat = 31.804629962113356;
  swLng = 35.127456662727354;

  latlngBounds: any;

  current_latitude: number;
  current_longitude: number;
  address: string;
  private geoCoder;


  public origin: any;
  public destination: any;
  location: Location;


  constructor(private mapLoader: MapsAPILoader) {

  }

  ngOnInit() {
    // this.geoCoder = new google.maps.Geocoder();

    // console.log('ngOnInit markers',this.markers); // is set

  }

  //
  // ngAfterViewInit() {
  //   console.log(this.agmMap);
  //   this.agmMap.mapReady.subscribe(map => {
  //     const bounds: LatLngBounds = new google.maps.LatLngBounds();
  //     for (const mm of this.markers) {
  //       bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
  //     }
  //     map.fitBounds(bounds);
  //   });
  // }

  // mapReady(map) {
  //   const bonds: LatLngBounds = new google.maps.LatLngBounds();
  //   bonds.extend(new google.maps.LatLng(this.showShop.lat, this.showShop.lng));
  //   map.fitBounds(bonds);
  // }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    // ngOnChanges() {
    // this.map.mapReady.subscribe((map: any) => {
    //   const bounds: LatLngBounds = new google.maps.LatLngBounds();
    //   console.log('this.markers', this.markers);
    //   for (let mm of this.markers) {
    //     bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
    //     this.latlngBounds.extend(new window['google'].maps.LatLng(mm.lat, mm.lng));
    //     console.log('helloooo', mm.lat, mm.lng);
    //
    //   }
    //   this.map.fitBounds(bounds);
    // });
  }

  private initGeocoder() {
    console.log('Init geocoder!');
    this.geoCoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geoCoder) {
      return fromPromise(this.mapLoader.load())
        .pipe(
          tap(() => this.initGeocoder()),
          map(() => true)
        );
    }
    return of(true);
  }

  private setCurrentLocation(lat, lng) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.current_latitude = position.coords.latitude;
        this.current_longitude = position.coords.longitude;
        // this.getAddress(this.current_latitude, this.current_longitude);
        const location = {'lat': this.current_latitude, 'lng': this.current_longitude};
        this.geocodeAddress(location);

        this.origin = {lat: parseFloat(String(this.current_latitude)), lng: parseFloat(String(this.current_longitude))};
        this.destination = {lat: lat, lng: lng};
      });
    }
  }


  getDirection(lat, lng) {
    this.setCurrentLocation(lat, lng);
  }


  geocodeAddress(location: any): Observable<any> {
    console.log('Start geocoding!');

    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geoCoder.geocode({'address': location}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
            } else {
              observer.next({lat: 0, lng: 0});
            }
            observer.complete();
          });
        });
      })
    );
  }


  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder();
    this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      console.log(results);
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  triggerResize() {

    this.map.triggerResize(true);

  }


  // mapReady(map) {
  //   const bonds: LatLngBounds = new google.maps.LatLngBounds();
  //   bonds.extend(new google.maps.LatLng(this.showShop.lat, this.showShop.lng));
  //   map.fitBounds(bonds);
  // }
  mapReady(map: any) {
    this.map = map;
    this.map.addListener('idle', function () {
      let latLngBounds = map.getBounds();
      this.neLat = latLngBounds.getNorthEast().lat();
      this.neLng = latLngBounds.getNorthEast().lng();
      this.swLat = latLngBounds.getSouthWest().lat();
      this.swLng = latLngBounds.getSouthWest().lng();

    });
  }


  clickedMarker(label: string, index: number) {
    // console.log(`clicked the marker: ${label || index}`)
  }


  markerDragEnd(m: marker, $event: MouseEvent) {
    // console.log('dragEnd', m, $event);
  }


  generateBounds(markers): any {
    if (markers && markers.length > 0) {
      var bounds = new google.maps.LatLngBounds();

      markers.forEach((marker: any) => {
        bounds.extend(new google.maps.LatLng({lat: marker.latitude, lng: marker.longitude}));
      });

      //check if there is only one marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        var extendPoint = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
        bounds.extend(extendPoint);
      }

      return {
        northeast: {
          latitude: bounds.getNorthEast().lat(),
          longitude: bounds.getNorthEast().lng()
        },
        southwest: {
          latitude: bounds.getSouthWest().lat(),
          longitude: bounds.getSouthWest().lng()
        }
      };
    }
    return {};
  }

  public clusterStyle = [
    {
      'textColor': '#fff',
      'url': 'http://aqar.ps/daman/web/assets/images/circle-marker.png',
      'background-position': '0px 0px',
      'background-repeat': 'no-repeat',
      'height': 40,
      'line-height': '53px',
      'width': 40,
      'text-align': 'center'

    }
  ];

  public customStyle_old = [];
  public customStyle = [
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'featureType': 'administrative.land_parcel',
      'stylers': [
        {
          'color': '#ae9e90'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'landscape.natural',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'poi',
      'stylers': [
        {
          'color': '#dfd2ae'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'featureType': 'poi',
      'stylers': [
        {
          'color': '#93817c'
        }
      ]
    },
    {
      'elementType': 'geometry.fill',
      'featureType': 'poi.park',
      'stylers': [
        {
          'color': '#a5b076'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'featureType': 'poi.park',
      'stylers': [
        {
          'color': '#447530'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'road',
      'stylers': [
        {
          'color': '#f5f1e6'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'road.arterial',
      'stylers': [
        {
          'color': '#fdfcf8'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'road.highway',
      'stylers': [
        {
          'color': '#f8c967'
        }
      ]
    },
    {
      'elementType': 'geometry.stroke',
      'featureType': 'road.highway',
      'stylers': [
        {
          'color': '#e9bc62'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'road.highway.controlled_access',
      'stylers': [
        {
          'color': '#e98d58'
        }
      ]
    },
    {
      'elementType': 'geometry.stroke',
      'featureType': 'road.highway.controlled_access',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'featureType': 'road.local',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'transit.line',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'featureType': 'transit.line',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'featureType': 'transit.line',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'featureType': 'transit.station',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'geometry.fill',
      'featureType': 'water',
      'stylers': [
        {
          'color': '#EBE8DE'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'featureType': 'water',
      'stylers': [
        {
          'color': '#92998d'
        }
      ]
    }
  ];
}


// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  icon: string
}


interface LatLngBounds {
  extend?: any;

}

interface markerLable {
  color: string;
}
