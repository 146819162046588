import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {customerWizard} from '../../models/customer-wizard';
import {WizardService} from '../../_services/wizard.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {User} from '../../models/user-model';
import {AuthService} from '../../_services/auth.service';
import {GovernateService} from '../../_services/governate.service';
import {IOption} from '../../models/options.interface';
import {OptionService} from '../../_services/option.service';
import {Observable} from 'rxjs';

declare var require: any;

const PhoneNumber = require('awesome-phonenumber');


@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class WizardComponent implements OnInit {


  customerWizard: customerWizard = new customerWizard();
  signUpForm: FormGroup;
  user: User = new User();
  password: FormControl;
  fullName: FormControl;
  firstName: FormControl;
  lastName: FormControl;
  mobile: FormControl;
  email: FormControl;

  regionCode: FormControl;
  phoneNumber: FormControl;
  countryCode: FormControl;


  alertMsg: string = '';
  alertClass: string = '';
  userAlreadyExist: string = '';
  loading: boolean = false;
  decodeToken: string;
  successfully_signup: string = '';
  alert: boolean = false;
  backToHomeLink = false;
  mobile_id = 'mobile_id';

  step1Validation: any;
  StepValidationErrors: any = 'Please Fill The All Fields';

  step1IsValid = false;
  step2IsValid = false;
  step3IsValid = false;
  step4IsValid = false;
  step5IsValid = false;
  // countriesList: any;
  countriesList$: IOption[] = [];
  // countriesList$: Observable<IOption[]>;

  q1Options = [
    {
      id: 11,
      value: 'Browsing All properties in real estate market',
      name: 'Browsing All properties in real estate market',
      isChecked: false
    },
    {id: 13, value: 'Manage Real estate Properties', name: 'Manage Real estate Properties', isChecked: false},
    {id: 14, value: 'RealEstateServicesOptions', name: 'RealEstateServicesOptions', isChecked: false},
    {id: 15, value: 'other', name: 'other', isChecked: false}
  ];
  q1OptionsChecked: any = [];

  q2Options = [
    {id: 21, value: 'Investor', name: 'Investor', isChecked: false},
    {id: 22, value: 'Buyer For Only Once', name: 'Buyer For Only Once', isChecked: false},
    {id: 23, value: 'Searching for renting properties', name: 'Searching for renting properties', isChecked: false},
    {id: 24, value: 'Land Lord', name: 'Land Lord', isChecked: false},
    {
      id: 25,
      value: 'practitioner for real estate profession',
      name: 'practitioner for real estate profession',
      isChecked: false
    },
    {
      id: 26,
      value: 'buildingContractor',
      name: 'buildingContractor',
      isChecked: false
    }
  ];
  q2OptionsChecked: any = [];
  selectedRegionId = '970';
  options: Map<string, IOption[]> = new Map();

  q4Options = [
    {id: 41, value: 'Ramallah & Albierh', name: 'Ramallah & Albierh - The City', isChecked: false},
    {id: 42, value: 'Palestine Authority Locations', name: 'Palestine Authority Locations', isChecked: false},
    {id: 43, value: 'Searching for renting properties', name: 'Searching for renting properties', isChecked: false},
    {id: 44, value: 'Ramallah & Albierh Ground', name: 'Ramallah & Albierh Ground', isChecked: false},
    {id: 45, value: 'C Lands', name: 'C Lands', isChecked: false},
    {id: 46, value: 'Investment Opportunities', name: 'Investment Opportunities', isChecked: false},
    {id: 47, value: 'Other', name: 'Other', isChecked: false},
  ];
  q4OptionsChecked: any = [];

  budgetList: any = [
    {id: 1, value: '100,000 or less', en_name: '100,000 or less', ar_name: ' 100,000 او اقل  '},
    {id: 2, value: '100,000 - 300,000', en_name: '100,000 - 300,000', ar_name: '100,000 - 300,000'},
    {id: 3, value: '350,000 - 600,000', en_name: '350,000 - 600,000', ar_name: '350,000 - 600,000'},
    {id: 4, value: '650,000 - 1,000,000', en_name: '650,000 - 1,000,000', ar_name: '650,000 - 1,000,000'},
    {id: 4, value: '1,000,000 or above', en_name: '1,000,000 or above', ar_name: '1,000,000 او اكثر'},
  ];
  budget: any;


  paymentMethodList: any = [
    {id: 1, value: 'payingCash', en_name: 'paying Cash', ar_name: 'الدفع كاش'},
    {
      id: 2,
      value: 'preApprovedForMortgage',
      en_name: 'Preapproved For Mortgage',
      ar_name: 'موافقة  مسبقة على الرهن العقاري'
    },
    {id: 3, value: 'ApplyingForMortgage', en_name: 'Applying For Mortgage', ar_name: 'مقدم على رهن عقاري'},
    {id: 4, value: 'other', en_name: 'other', ar_name: 'اخرى'},
  ];
  paymentMethod: any;

  RealEstaExperienceList: any = [
    {id: 1, value: 'buyOnceBefore', en_name: 'buyOnceBefore', ar_name: 'شراء مرة واحدة قبل'},
    {id: 2, value: 'RealEstateInvestor', en_name: 'Real Estate Investor', ar_name: 'مستثمر عقاري'},
    {id: 3, value: 'AgentOrBroker', en_name: 'Agent Or Broker', ar_name: 'وكيل أو وسيط'},
    {id: 4, value: 'lawyer', en_name: 'lawyer', ar_name: 'محامي'},
    {id: 5, value: 'buildingContractor', en_name: 'buildingContractor', ar_name: 'مقاول بناء'},
    {id: 6, value: 'other', en_name: 'Other', ar_name: 'غير ذلك'}
  ];

  interestedMoreChecked: any = [];
  interestedMoreOptions: any = [
    {id: 61, value: 'investing lands', name: 'investing lands'},
    {id: 62, value: 'commercial lands', name: 'commercial lands'},
    {id: 63, value: 'Residential lands', name: 'Residential lands'},
    {id: 64, value: 'Villas', name: 'Villas'},
    {id: 65, value: 'stores', name: 'stores'},
    {id: 66, value: 'offices', name: 'offices'},
    {id: 67, value: 'other', name: 'other'},
  ];

  realEstateExperience: any;
  wizard_success_msg: string;
  selectedName: string;
  validationsErrors: string;
  invalidMobileNumber: string;
  headup: string;
  interestedOptionIsValid = false;
  q4OptionsIsValid = false;
  prefix = '+970';
  mask: string;
  example: string;
  separateDialCode = false;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // PhoneNumberFormat = PhoneNumberFormat;
  // preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(private fb: FormBuilder,
              private wizardService: WizardService,
              public  translate: TranslateService,
              private toastr: ToastrService,
              private router: Router,
              private auth: AuthService,
              private governateService: GovernateService,
              private optionsService: OptionService,
  ) {

    translate.get('wizard_success_msg').subscribe((res: string) => {
      this.wizard_success_msg = res;
    });
    let lang = translate.currentLang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }


    translate.get('server.userAlreadyExist').subscribe((trans: string) => {
      this.userAlreadyExist = trans;
    });


    translate.get('server.validationsErrors').subscribe((trans: string) => {
      this.validationsErrors = trans;
    });

    translate.get('server.successfully_signup').subscribe((trans: string) => {
      this.successfully_signup = trans;
    });

    translate.get('headup').subscribe((trans: string) => {
      this.headup = trans;
    });

    translate.get('invalidMobileNumber').subscribe((trans: string) => {
      this.invalidMobileNumber = trans;
    });


  }

  ngOnInit() {
    this.getCountries();
    this.selectedRegionId = '970';
    this.createFormControls();
    this.createForm();

  }

  getCountries() {
    this
      .governateService
      .getCountries()
      .subscribe(res => {
        this.countriesList$ = this.optionsService.generateIOption(res['data'], 'value', 'label');
        this.selectedRegionId = '970';
        this.mask = this.getMask('PS');
        this.signUpForm.get('mobile').setErrors(null);
      });
  }

  createFormControls() {

    this.firstName = new FormControl('', [Validators.required, Validators.minLength(2)]);
    this.lastName = new FormControl('', [Validators.required, Validators.minLength(2)]);

    this.email = new FormControl('', [
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      Validators.email
    ]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);

    this.regionCode = new FormControl('970', [
      // Validators.required,

    ]);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(9)
    ]);

    this.countryCode = new FormControl('970', [
      // Validators.required,
    ]);

  }

  createForm() {
    this.signUpForm = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      mobile: this.mobile,
      countryCode: this.countryCode,
      regionCode: this.regionCode,
    });
  }


  setMaskAndExample(regionCode: string) {
    this.example = this.getExample(regionCode);
    this.mask = this.getMask(regionCode);
  }

  getMask(regionCode: string, format = 'national'): string {
    return this.getExample(regionCode, format).replace(/\d/g, '0');
  }

  updatePrefix($event) {
    this.mobile.setValue('');
    this.prefix = '+' + $event.value;
    this.setMaskAndExample($event.data.country_code);
    this.signUpForm.get('countryCode').setValue(this.getCountryCodeForRegionCode($event.data.country_code), {
        emitEvent: false
      }
    );
  }

  getExample(regionCode: string, format = 'national'): string {
    const example = PhoneNumber.getExample(regionCode);
    const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);
    return example.getNumber(format);
  }

  getCountryCodeForRegionCode(regionCode: string): string {
    return PhoneNumber.getCountryCodeForRegionCode(regionCode);
  }

  getRegionCodeForCountryCode(regionCode: string): string {
    return PhoneNumber.getRegionCodeForCountryCode(regionCode);
  }

  stringify(json: any): string {
    return JSON.stringify(json, null, 2);
  }

  generateIOption(items: any[], valueKey: string, labelKey: string) {
    let newItems = [];
    if (items) {
      newItems = items.map(i => {
        return {
          label: i[labelKey],
          value: i[valueKey],
          selected: false,
          data: i,
        } as IOption;
      });
    }
    return newItems;
  }

  onQ1CheckboxChange(option, event) {
    if (event.target.checked) {
      this.q1OptionsChecked.push(option.value);
    } else {
      for (var i = 0; i < this.q1Options.length; i++) {
        if (this.q1OptionsChecked[i] == option.id) {
          this.q1OptionsChecked.splice(i, 1);
        }
      }
    }
    if (this.q1OptionsChecked.length > 0) {
      this.step1IsValid = true;
    } else {
      this.step1IsValid = false;
    }
    return true;
  }

  goToSecondStep() {
    if (this.q1OptionsChecked.length > 0) {
      this.step1IsValid = true;
    } else {
      this.step1IsValid = false;
      this.toastr.error(this.validationsErrors, this.headup,
        {
          positionClass: 'toast-top-center'
        });
    }
  }

  onQ2CheckboxChange(option, event) {
    if (event.target.checked) {
      this.q2OptionsChecked.push(option.value);
    } else {
      for (var i = 0; i < this.q2Options.length; i++) {
        if (this.q2OptionsChecked[i] == option.id) {
          this.q2OptionsChecked.splice(i, 1);
        }
      }
    }

    if (this.q2OptionsChecked.length > 0) {
      this.step2IsValid = true;
    } else {
      this.step2IsValid = false;
    }
  }

  goToThirdStep() {
    if (this.q2OptionsChecked.length > 0) {
      this.step2IsValid = true;
    } else {
      this.step2IsValid = false;
      this.toastr.error(this.validationsErrors, this.headup,
        {
          positionClass: 'toast-top-center'
        });
    }
  }


  onQ4CheckboxChange(option, event) {
    if (event.target.checked) {
      this.q4OptionsChecked.push(option.value);
    } else {
      for (var i = 0; i < this.q4Options.length; i++) {
        if (this.q4OptionsChecked[i] == option.id) {
          this.q4OptionsChecked.splice(i, 1);
        }
      }
    }

    if (this.q4OptionsChecked.length > 0) {
      this.q4OptionsIsValid = true;
    } else {
      this.q4OptionsIsValid = false;
    }

    if (this.q4OptionsIsValid && this.interestedOptionIsValid) {
      this.step4IsValid = true;
    } else {
      this.step4IsValid = false;
    }
  }

  goToFourStep($event) {
    if ($event === 'internal') {
      if (this.paymentMethod === undefined || this.realEstateExperience === undefined || this.budget === undefined) {
        this.step3IsValid = false;
      } else {
        this.step3IsValid = true;
      }
    } else {
      if (this.paymentMethod === undefined || this.realEstateExperience === undefined || this.budget === undefined) {
        this.step3IsValid = false;
        this.toastr.error(this.validationsErrors, this.headup,
          {
            positionClass: 'toast-top-center'
          });
      } else {
        this.step3IsValid = true;
      }
    }
  }


  goToFiveStep($event) {

    if (this.q4OptionsIsValid && this.interestedOptionIsValid) {
      this.step4IsValid = true;
    } else {
      this.step4IsValid = false;
      this.toastr.error(this.validationsErrors, this.headup,
        {
          positionClass: 'toast-top-center'
        });
    }
  }


  // InterestedMoreOptions

  onInterestedMoreOptionsChange(option, event) {
    if (event.target.checked) {
      this.interestedMoreChecked.push(option.value);
    } else {
      for (var i = 0; i < this.interestedMoreOptions.length; i++) {
        if (this.interestedMoreChecked[i] == option.id) {
          this.interestedMoreChecked.splice(i, 1);
        }
      }
    }
    if (this.interestedMoreChecked.length > 0) {
      this.interestedOptionIsValid = true;
    } else {
      this.interestedOptionIsValid = false;
    }

    if (this.q4OptionsIsValid && this.interestedOptionIsValid) {
      this.step4IsValid = true;
    } else {
      this.step4IsValid = false;
    }
  }

  setBudget($event) {
    this.budget = $event['value'];
    this.goToFourStep('internal');
  }

  setRealEstateExperience($event) {
    this.realEstateExperience = $event['value'];
    this.goToFourStep('internal');

  }

  setPaymentMethod($event) {
    this.paymentMethod = $event['value'];
    this.goToFourStep('internal');

  }


  onSubmit() {


    let wizardData = {
      lookingFor: this.removeDuplications(this.q1OptionsChecked),
      describe: this.removeDuplications(this.q2OptionsChecked),
      locations: this.removeDuplications(this.q4OptionsChecked),
      budget: this.budget,
      paymentMethod: this.paymentMethod,
      experience: this.realEstateExperience,
      interestedMore: this.removeDuplications(this.interestedMoreChecked),
      firstName: this.signUpForm.controls['firstName'].value,
      lastName: this.signUpForm.controls['lastName'].value,
      password: this.signUpForm.controls['password'].value,
      mobile: this.signUpForm.controls['countryCode'].value + this.signUpForm.controls['mobile'].value,
      email: this.signUpForm.controls['email'].value,
    };

    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';

    if (!this.signUpForm.valid) {
      this.validateAllFormFields(this.signUpForm);
      this.alertClass = 'danger';
      this.alertMsg = 'please check the form fields';
      this.alert = true;
    }


    this.auth.signup2(wizardData)
      .map(res => res.json())
      .subscribe(res => {
          this.loading = false;
          this.alert = false;
          if (res.success) {
            this.alertMsg = res.msg;
            this.alert = true;
            this.alertMsg = this.successfully_signup;
            this.alertClass = 'success';
            this.loading = false;
            this.signUpForm.reset();
            this.backToHomeLink = true;

          } else {
            this.alertClass = 'danger';
            if (res.msg === 'userAlreadyExist') {
              this.alert = true;
              this.alertMsg = this.userAlreadyExist;
            } else if (res.msg === 'validationsErrors') {
              this.alert = true;
              this.alertMsg = this.validationsErrors;

            } else if (res.msg === 'invalidMobileNumber') {
              this.alert = true;
              this.alertMsg = this.invalidMobileNumber;
            } else {
              this.alert = true;
              this.alertMsg = res.msg;
            }
          }
        },
        err => {
          console.log('Something went wrong!');
        }
      );
    // let body = JSON.stringify(wizardData);
    //
    // this.wizardService.postWizard(wizardData)
    //   .subscribe((res:any) => {
    //     if (res.success) {
    //       this.loading = false;
    //       this.showSuccess();
    //       this.router.navigateByUrl('/dashboard');
    //
    //     } else {
    //       this.loading = false;
    //       this.showSuccess();
    //     }
    //   });
  }


  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  showSuccess() {
    this.toastr.success(this.wizard_success_msg);
  }

  removeDuplications(arr) {
    // let arr = [1, 2, 1, 2, 3, 5, 4, 5, 3, 4, 4, 4, 4];
    let result = arr.sort().reduce((accumulator, current) => {
      const length = accumulator.length;
      if (length === 0 || accumulator[length - 1] !== current) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    return result;
  }


}
