<app-top-header></app-top-header>

<section class="knowledge">

  <app-blog-inner-nav></app-blog-inner-nav>

  <div class="knowledge-content mt-4">
    <div class="container">

      <h1 class="main-title">{{ post?.title?.rendered }}</h1>
      <div class="banner-img">
        <img src="{{post?.better_featured_image?.source_url}}">
      </div>

      <div class="content mt-5" [innerHTML]="post?.content?.rendered">
      </div>
    </div>
  </div>
</section>


<layout-footer></layout-footer>
