import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MembershipService} from '../../_services/membership.service';
import {TranslateService} from '@ngx-translate/core';

import {LoginModalComponent} from '../includes/login-modal/login-modal.component';
import {SignUpModalComponent} from '../includes/sign-up-modal/sign-up-modal.component';


@Component({
  selector: 'app-pricing-guide',
  templateUrl: './pricing-guide.component.html',
  styleUrls: ['./pricing-guide.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PricingGuideComponent implements OnInit {

  
  memberships:any;
  lang:string;
  constructor(public activeModal:NgbActiveModal,
              private modalService:NgbModal,
              public  translate:TranslateService,
              private membershipService:MembershipService) {

    this.lang = translate.currentLang;

  }

  ngOnInit() {
    
    this.getMemberships();
  }


  openLoginModal() {
    const modalRef = this.modalService.open(LoginModalComponent);
    // modalRef.componentInstance.name = 'World';

  }

  openSignUpModal() {
    const modalRef = this.modalService.open(SignUpModalComponent);
    // modalRef.componentInstance.name = 'World';
  }

  close() {
    this.activeModal.dismiss();
  }

   //Memberships
  getMemberships() {
    return this.membershipService
      .getMemberships().subscribe( res=> {
        this.memberships=res;
      });
  }

}
