export class Customer {
  user_id:any;
  email:any;
  first_name:any;
  second_name:any;
  third_name:any;
  family_name:any;
  mobile_number:any;
  id_number:any;

}
