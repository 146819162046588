<app-top-header></app-top-header>

<app-banner></app-banner>




<!---member-block -------->
<!--<div class="block member-block row">-->
  <!--<div class="description">-->
    <!--<div class="text">-->
      <!--<h1>{{'subscriptionTitle'|translate}}</h1>-->
      <!--<h3>{{'BecomeMemberDescription'|translate}}</h3>-->
      <!--<button *ngIf="!isLoggedIn" class="btn btn&#45;&#45;blue rounded mt-4" (click)="openSignUpModal()">{{'signupFree'|translate}}</button>-->
      <!--<div class="clearfix"></div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->

<!----- video block--->
<section class="section-1">
  <app-features></app-features>
</section>

<div class="clearfix"></div>

<!---investment-block -------->

<!---end investment block -------->


<layout-footer></layout-footer>
