import {Component, OnInit, Input, ViewEncapsulation,ViewChild, Inject} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {NgbCarousel,NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ImageGalleryComponent implements OnInit {
  @ViewChild('myCarousel',{static: true}) myCarousel: NgbCarousel;

  closeResult:string;
  selectedImage:any;
  @Input() imagesArray;
  @Input() mainImage;
  @Input() imagesCount;
  @Input() showMoreImages;
  @Input() price;
  @Input() en_title;
  @Input() ar_title;
  @Input() lang;


  constructor(@Inject(WINDOW) private window: Window, config: NgbCarouselConfig, private modalService:NgbModal) {
  // constructor( config: NgbCarouselConfig, private modalService:NgbModal) {

    config.interval = 10000;
    config.wrap = true;
    config.keyboard = true;
    // this.myCarousel.activeId='2';
  }
  ngOnInit() {
    //   this.myCarousel.activeId='2';  //<--use this, begans 0

  }

  openModal(content){
    this.modalService.open(content, {
      size: 'lg',
      // this.window: 'gallery-modal',
      backdropClass: 'gallery-backdrop'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  setSelectedImage(image) {
    this.selectedImage = image;
  }


}
