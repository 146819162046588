import {Component, OnInit, Inject} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';

import {AuthService} from '../../../_services/auth.service';
import {User} from '../../../models/user-model';
import {TranslateService} from '@ngx-translate/core';
import * as JWT from 'jwt-decode';
import {Router} from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';


@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss']
})
export class SignUpModalComponent implements OnInit {
  modalReference:any;
  closeResult:string;
  signUpForm:FormGroup;
  user:User = new User();
  password:FormControl;
  fullName:FormControl;
  firstName:FormControl;
  lastName:FormControl;
  mobile:FormControl;
  email:FormControl;
  alertMsg:string = '';
  alertClass:string = '';
  userAlreadyExist:string = '';
  loading:boolean = false;
  decodeToken:string;
  successfully_signup:string='';
  alert:boolean=false;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, public activeModal:NgbActiveModal,
              private modalService:NgbModal,
              private fb:FormBuilder,
              private auth:AuthService,
              translate:TranslateService,
              private router:Router) {

    this.createFormControls();
    this.createForm();
    translate.get('server.userAlreadyExist').subscribe((trans:string) => {
      this.userAlreadyExist = trans;
    });

    translate.get('server.successfully_signup').subscribe((trans:string) => {
      this.successfully_signup = trans;
    });

  }


  createFormControls() {

    this.firstName = new FormControl('',[Validators.required, Validators.minLength(2)]);
    this.lastName = new FormControl('',[ Validators.required, Validators.minLength(2)]);

    this.email = new FormControl('', [
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      Validators.email
    ]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]);
  }

  createForm() {
    this.signUpForm = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      mobile: this.mobile
    });
  }


  signup() {
    this.user = this.signUpForm.value;
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';

    this.auth.signup2(this.user)
      .map(res=>res.json())
      .subscribe(res => {
          this.loading = false;
          if (res.success) {
            this.alertClass = "success";
            this.alertMsg = res.msg;
            // this.localStorage.setItem('token', res.token);
            // this.localStorage.setItem('userInformation', res.token);
            // this.decodeToken=JWT(res.token);
            this.alert=true;
            this.alertMsg = this.successfully_signup;
            this.alertClass="success";
            this.loading=false;
            this.signUpForm.reset();

            // this.activeModal.dismiss();
            // this.router.navigateByUrl('/wizard');

          } else {
            this.alertClass = "danger";
            if (res.msg == 'userAlreadyExist') {
              this.alertMsg = this.userAlreadyExist;

            } else {
              this.alertMsg = res.msg;
            }
          }
        },
        err => {
          console.log('Something went wrong!');
        }
      );
  }


  openLoginAndCloseSignUp() {
    this.activeModal.dismiss();
    console.log('close sign up  modal');
    // const modalRef = this.modalService.open(LoginModalComponent);
    this.modalService.open(LoginModalComponent);
  }

  ngOnInit() {
  }

}
