import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-property-filters-form',
  templateUrl: './property-filters-form.component.html',
  styleUrls: ['./property-filters-form.component.scss']
})
export class PropertyFiltersFormComponent implements OnInit {


  @Input() type:string;
  constructor() { }

  ngOnInit() {
  }

}
