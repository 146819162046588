<!--<router-outlet name="header"></router-outlet>-->
<app-top-header></app-top-header>

<div class="dashboard">

  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>
  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">{{'Account Settings'|translate}}</h1>
      </div>
      <div class="action">
        <button class="btn  btn--light--default " (click)="openOrderModal()">{{'requestInfo'|translate}}</button>
        <a class="btn btn--primary " routerLink="/dashboard/add-properties">{{'AddNewProperty'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details ">


      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/customers/">{{'customers'|translate}}</a></li>
          <li><a routerLink="/dashboard/customers/{{customerId}}" class="active">{{customerName}}</a></li>
        </ul>
      </div>

      <div class="row">
        <div class="col-md-6 col-xs-12">

          <div class="card customer-card shadow pt-3 pb-3 pl-2 pr-2">

            <form [formGroup]="customerForm" (ngSubmit)="updateCustomer()">


              <div class="row">
                <div class="col-md-6">


                  <div class="form-group">
                    <label>{{'first_name'|translate}}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="first_name"
                      placeholder="{{'first_name'|translate}}"
                      [(ngModel)]="customer.first_name"
                    >
                    <div class="form-control-feedback"
                         *ngIf="first_name.errors && (first_name.dirty || first_name.touched)">
                      <p *ngIf="first_name.errors.required">{{'validation.required'|translate}}</p>
                      <p *ngIf="first_name.errors.minlength">{{'validation.minlength'|translate}} (2)</p>

                    </div>
                  </div>

                </div>
                <div class="col-md-6">


                  <div class="form-group">
                    <label>{{'second_name'|translate}}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="second_name"
                      placeholder="{{'second_name'|translate}}"
                      [(ngModel)]="customer.second_name"
                    >
                    <div class="form-control-feedback"
                         *ngIf="second_name.errors && (second_name.dirty || second_name.touched)">

                      <p *ngIf="second_name.errors.required">{{'validation.required'|translate}}</p>
                      <p *ngIf="second_name.errors.minlength">{{'validation.minlength'|translate}} (2)</p>

                    </div>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{'third_name'|translate}}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="third_name"
                      placeholder="{{'third_name'|translate}}"
                      [(ngModel)]="customer.third_name"
                    >
                    <div class="form-control-feedback"
                         *ngIf="third_name.errors && (third_name.dirty || third_name.touched)">

                      <p *ngIf="third_name.errors.required">{{'validation.required'|translate}}</p>
                      <p *ngIf="third_name.errors.minlength">{{'validation.minlength'|translate}} (2)</p>


                    </div>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{'family_name'|translate}}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="family_name"
                      placeholder="{{'family_name'|translate}}"
                      [(ngModel)]="customer.family_name"
                    >
                    <div class="form-control-feedback"
                         *ngIf="family_name.errors && (family_name.dirty || family_name.touched)">

                      <p *ngIf="family_name.errors.required">{{'validation.required'|translate}}</p>
                      <p *ngIf="family_name.errors.minlength">{{'validation.minlength'|translate}} (2)</p>

                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{'Phone'|translate}}</label>

                    <input
                      type="text"
                      class="form-control"
                      formControlName="mobile_number"
                      placeholder="eg:+972598-000-111"
                      [(ngModel)]="customer.mobile_number"
                    >
                    <div class="form-control-feedback text-left"
                         *ngIf="mobile_number.errors && (mobile_number.dirty || mobile_number.touched)">
                      <p *ngIf="mobile_number.errors.required" class="text-left">
                        {{'validation.mobile_required'|translate}}</p>
                      <p *ngIf="mobile_number.invalid" class="text-left">{{'validation.mobile_invalid'|translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> {{'id_number'|translate}}</label>

                    <input
                      type="text"
                      class="form-control"
                      formControlName="id_number"
                      placeholder=""
                      [(ngModel)]="customer.id_number"
                    >
                    <div class="form-control-feedback text-left"
                         *ngIf="id_number.errors && (id_number.dirty || id_number.touched)">
                      <p *ngIf="id_number.errors.required" class="text-left">{{'validation.required'|translate}}</p>
                      <p *ngIf="id_number.invalid" class="text-left">{{'validation.id_number_invalid'|translate}}</p>
                    </div>
                  </div>
                </div>
              </div>


              <div class="form-group">
                <label> {{'Email'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="email"
                  placeholder="{{'Email'|translate}}"
                  [(ngModel)]="customer.email"
                  [email]="true" email>


                <div class="form-control-feedback"
                     *ngIf="email.errors && (email.dirty || email.touched)">
                  <p *ngIf="email.errors.required">{{'validation.email_required'|translate}}</p>
                  <p *ngIf="email.invalid">{{'validation.InvalidEmailAddress'|translate}}</p>
                </div>
              </div>


              <div class="form-group">
                <button class="btn btn--primary" [disabled]="customerForm.invalid">{{'Update'|translate}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
