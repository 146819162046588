import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './../../_services/user.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {


  id:any;
  lang:any;
  customer:any;
  customerReady = false;
  loading = true;
  customerIsExist = true;

  constructor(private router:Router,
              private route:ActivatedRoute,
              public translate:TranslateService,
              private  userService:UserService) {


    this.lang = this.translate.currentLang;
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.id = +params['customerId'];
    });
    this.getCustomerDetails();
  }


  getCustomerDetails() {

    this.userService.getCustomerDetails(this.id).subscribe((res:any)=> {
      if (res.success) {
        this.customer = (res.data);
        this.customerReady = true;
        this.loading = false;
        this.customerIsExist = true;

      } else {
        this.customerIsExist = false;

      }
    });
  }

}
