<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'customers'|translate}}
        </h1>
      </div>
      <div class="action">
        <!--<button class="btn  btn&#45;&#45;light&#45;&#45;default ml-1 mr-1 " (click)="openOrderModal()">{{'requestInfo'|translate}}-->
        <!--</button>-->
        <!--<a class="btn btn&#45;&#45;primary " routerLink="/dashboard/add-properties">{{'addNewContract'|translate}}</a>-->
        <!--<a class="btn btn&#45;&#45;primary " routerLink="/dashboard/add-customer"> + {{'updateCustomer'|translate}} </a>-->
      </div>
    </div>
    <div class="dashboard-details ">

      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/customers" class="active">{{'customers'|translate}}</a></li>
        </ul>
      </div>

      <div class="dashboard-table-details mt-6">


        <div class="row " *ngIf="customerIsExist">

          <div class="col-6">
            <div class="card customer-card shadow">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col-9">
                    <h3 class="mb-0"><strong>{{'customerGeneralInfo'|translate}}</strong></h3>
                  </div>
                  <div class="col text-right">
                    <a

                      [routerLink]="['/dashboard/customers/'+customer?.user_id+ '/edit']"
                      class="btn--small btn--primary btn">{{'edit'|translate}}</a>
                  </div>
                </div>
                <hr>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-hover text-center">
                  <tbody>
                  <tr>
                    <th scope="row" class="text-left">{{'customerName'|translate}}</th>
                    <td>{{customer?.full_name}}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-left">{{'idNumber'|translate}}</th>
                    <td>{{customer?.id_number}}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-left">{{'Email'|translate}}</th>
                    <td>{{customer?.email}}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-left">{{'mobileNumber'|translate}}</th>
                    <td>{{customer?.mobile_number}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6">

            <div class="card customer-card shadow">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col-9">
                    <h3 class="mb-0"><strong>{{'customerDocuments'|translate}}</strong></h3>
                  </div>
                  <div class="col text-right">
                    <a href="#" class="btn--small btn--primary btn">{{'addDocument'|translate}}</a>
                  </div>
                </div>
                <hr>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-hover text-center">
                  <tbody>
                  <tr>
                    <!--<th scope="row">{{'customerName'|translate}}</th>-->
                    <!--<td>Abdallah Maali</td>-->
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


            <div class="card customer-card shadow mt-2">
              <div class="card-header border-0">
                <div class="row align-items-center">
                  <div class="col-9">
                    <h3 class="mb-0"><strong>{{'rentedUnits'|translate}}</strong></h3>
                  </div>
                </div>
                <hr>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-hover text-center">
                  <tbody>
                  <tr>
                    <!--<th scope="row">{{'customerName'|translate}}</th>-->
                    <!--<td>Abdallah Maali</td>-->
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </div>
      </div>


      <div class="col-md-12 card p-3 text-center" *ngIf="!customerIsExist">

        <h2>customer not found </h2>
      </div>
    </div>
  </div>
</div>
