<app-top-header></app-top-header>
<section class="inner no-margin">
  <div class="container">

    <h1>{{ 'Add New Property'|translate}}</h1>

    <div class="wizard {{navBarLocation}}">
      <div class="wizard-navigation">
        <ul class="steps-indicator">
          <li><a>1</a></li>
          <li><a class="">2</a></li>
          <li><a class="active">3</a></li>
        </ul>
      </div>
      <div class="wizard-content">
        <div class="row">
          <div class="form-data mb-5">

            <p class="step-text">{{'step 3'|translate}}</p>
            <h3>{{'Please Add Images For your Property'|translate }}</h3>
            <div class="save-notes mb-2">
              <small class=" text--secondary "><i class="fa fa-info-circle"> </i> {{ 'please upload images in jpg, gif or .png format'|translate }} </small>
            </div>
            <dropzone
              [config]="config"
              [message]="dropZoneMsg"
              (error)="onUploadError($event)"
               accept="image/jpeg"
              (success)="onUploadSuccess($event)"

            >

            </dropzone>


            <div></div>
              <!--class="dropzone"-->
              <!--(message)="dropZoneMsg"-->
              <!--[dropzone]="config"-->
              <!--(error)="onUploadError($event)"-->
              <!--(success)="onUploadSuccess($event)">-->
            <!--</div>-->
            <div class="actions mt-5">

              <button type="button" class="btn btn--primary" routerLink="/dashboard/add-properties" (click)="finish()">{{'finish'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<layout-footer></layout-footer>
