import {Injectable, Inject} from '@angular/core';
// import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import * as jwt_decode from "jwt-decode";
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LOCAL_STORAGE} from '@ng-toolkit/universal';


// @Injectable()

@Injectable({
  providedIn: 'root'
})

export class UserService {

  public username:string;
  fullName:string;
  email:string;
  phone:string;
  mobile:string;
  avatar:string;
  expireDate:any;
  currentTime:any;

  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  constructor(@Inject(LOCAL_STORAGE) private localStorage:any,
              private auth:AuthService,
              private http:HttpClient) {

    let token = localStorage.getItem('token');
    if (token) {
      let tokenInfo = this.getDecodedAccessToken(token); // decode token
      this.expireDate = tokenInfo.exp; // get token expiration dateTime
      this.currentTime = Date.now() / 1000;
      this.fullName = tokenInfo.fullName;
      this.email = tokenInfo.username;
      this.phone = tokenInfo.phone;
      this.mobile = tokenInfo.mobile;
      this.avatar = tokenInfo.avatar;
    }

  }


  getUser() {
    return this.http.get(this.BASE_URL + 'user/');

  }


  getUsername() {
    return this.username;
  }

  getPhone() {
    return this.phone;
  }

  getMobile() {
    return this.mobile;
  }

  getEmail() {
    return this.email;
  }

  getFullName() {
    return this.fullName;
  }

  getAvatar() {
    if (this.avatar == '') {
      this.avatar = './assets/images/Dummy-profile-picture.png';
    } else {
      return this.avatar;
    }
  }

  getExpireDate() {
    return this.expireDate;
  }

  getCurrentTime() {
    return this.currentTime;

  }

  getDecodedAccessToken(token:string):any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }




  getLatestAddedUnites() {
    const url = this.BASE_URL + `user_properties/latest_units`;
    return this.http.get(url);

  }

  getLatestAddedBuilding() {
    const url = this.BASE_URL + `user_properties/latest_building`;
    return this.http.get(url);

  }


  getCustomers(parms) {
    const url = this.BASE_URL + `user/customers`;
    return this.http.get(url, {params: parms});

  }

  getCustomersList() {
    const url = this.BASE_URL + `user/customers_list`;
    return this.http.get(url);

  }


  getCustomerDetails(id) {
    const url = this.BASE_URL + `user/customers/` + id;
    return this.http.get(url);
  }

  updateCustomer(customer, id) {
    const url = this.BASE_URL + `user/customers/` + id + '/edit';
    // return this.http.get(url);
    return this.http.post(url, customer);

  }

  createCustomer(customer) {
    const url = this.BASE_URL + `user/customers/create`;
    return this.http.post(url, customer);

  }


  getCustomersNotifications() {
    const url = this.BASE_URL + `user_properties/property_request_notifications`;
    return this.http.get(url);
  }


  getCustomersNotificationsDetails(id) {
    const url = this.BASE_URL + `user_properties/property_request_notifications/` + id;
    return this.http.get(url);
  }

  notificationsReplay(message, id) {
    const url = this.BASE_URL + `user_properties/property_request_notifications/` + id;
    return this.http.post(url, message);
  }


  getActivePropertyRequestCount() {
    const url = this.BASE_URL + `user_properties/active_property_request_count`;
    return this.http.get(url);
  }


  getuserPropertiesCount() {
    const url = this.BASE_URL + `user_properties/count`;
    return this.http.get(url);
  }



}
