<!--<div class="navigation {{currentLink != '/home' ?'inner-header':'home-header'}} ">-->
<div class="navigation {{headerType}} ">
  <div class="container-fluid">
    <div class="brand">
      <a class="logo" routerLink="/">
        <img class="brown" src="./assets/images/logo/logo-brown.svg">
        <img class="white" src="./assets/images/logo/logo-white.svg">
        <span class="knowledge-center" [hidden]="!knowledgeCenterRouteActive">{{'knowledge-center'|translate}}</span>
      </a>
    </div>
    <nav class="top-nav   {{viewType}}" [ngClass]="navListShow">
      <div class="nav-mobile">
        <button id="nav-toggle" (click)="navToggle()" [ngClass]="navToggleClass"><span></span></button>
      </div>

      <ul class="nav-list" [ngClass]="navListShow">

        <ng-container *ngIf="headerType!='dashboard-header-top'">


          <li><a routerLink="/search/apartments"
                 [queryParams]="{offer:'for_sell'}"> {{'header.forSale' |translate}} </a>
          </li>
          <li><a routerLink="/search/apartments" [queryParams]="{offer:'for_rent'}"> {{'header.forRent'|translate}} </a>
          </li>
          <li>
            <div ngbDropdown
                 #servicesDropdown="ngbDropdown"
                 class="d-flex"
                 autoClose="outside"
            >
              <button
                class="btn dropdown-toggle-white"
                id="servicesDropdown"
                ngbDropdownToggle
                (click)="moreDropdown.close()"
                autoClose="true"
              >
                {{'services'|translate}}
              </button>
              <div ngbDropdownMenu aria-labelledby="servicesDropdown"
                   class="arrow-top services-menu dropdown-menu-center" autoClose="true">

                <a
                  *ngFor="let service of services"
                  class="service-item"
                  [routerLink]="['/services',service?.slug]"
                  (click)="servicesDropdown.close();moreDropdown.close()"
                >
                  <img src="{{service.acf.icon}}">
                  <h2>{{service.title.rendered}}</h2>
                </a>
              </div>
            </div>
          </li>
          <li>
            <div ngbDropdown
                 #moreDropdown="ngbDropdown"
                 class="d-flex"
                 autoClose="outside"
            >
              <button
                class="btn dropdown-toggle-white"
                id="moreDropdown"
                ngbDropdownToggle
                (click)="servicesDropdown.close()"
              >
                {{'header.more'|translate}}
              </button>
              <div ngbDropdownMenu aria-labelledby="moreDropdown" class="arrow-top  more-menu dropdown-menu-center">
                <ul>
                  <li class="title"> {{'Explore'|translate}}</li>
                  <li>
                    <a routerLink="/knowledge-center/realestate-palestine">
                      <img src="./assets/images/icons/real-estate-pal.svg">{{'realestateGuide'|translate}}
                    </a>
                  </li>
                  <li>
                    <a routerLink="/knowledge-center/my-aqar">
                      <img src="./assets/images/icons/account.svg">{{'MyAQARAccountBenefit'|translate}}
                    </a>
                  </li>
                  <li>
                    <a routerLink="/knowledge-center/guide">
                      <img src="./assets/images/icons/tips.svg"> {{'tips and advice'|translate}}
                    </a>
                  </li>
                  <!--<li class="underline"><a routerLink="/knowledge-center/"> {{'CheckTheBlog'|translate}} </a></li>-->

                  <li class="title mr-3 ml-3"> {{'company'|translate}}</li>
                  <li class="bottom-link  mr-3 ml-3"><a routerLink="/about">{{'aboutAqar'|translate}} </a></li>
                  <li class="bottom-link  mr-3 ml-3"><a routerLink="/contact">{{'ContactUS'|translate}} </a></li>
                  <li class="bottom-link  mr-3 ml-3">
                    <a href="javascript:void(0)" (click)="openPricingGuideModal()">
                      {{'PricingGuide'|translate}}
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </li>


          <li>
            <a class=" " routerLink="/contact"> {{'adviceRequest'| translate}}</a>
          </li>

          <li>
            <a class="btn-radius btn--blue  mobile-d-inline "
               routerLink="/property-request"> {{'header.propertyRequest' | translate}}</a>
          </li>


          <li>
            <!--<li class="{{currentLink != '/home' ?'':'d-none'}}">-->
            <a *ngIf="isLoggedIn == true " class="btn-radius  mobile-d-inline " routerLink="/dashboard/add-properties">
              {{'AddNewProperty'|translate}}</a>
            <a *ngIf="isLoggedIn == false " class="btn-radius mobile-d-inline" (click)="openLoginModal()">
              {{'AddNewProperty'|translate}}</a>
          </li>


          <li *ngIf="isLoggedIn == false" class="li-btn-mobile text-center">

            <button class="btn btn--light--default mb-2 mr-1 ml-1" (click)="openLoginModal()">
              <i class="fa fa-user"></i> {{'header.login'|translate}}
            </button>

            <button class="btn btn-link mb-2 d-inline-block text--secondary" (click)="goToWizard()">
              <i class="fa fa-user-plus"></i> {{'header.sign_up'|translate}}
            </button>
          </li>


          <li *ngIf="isLoggedIn == false" class="li-btn-desktop">
            <a class=" btn-top-header-login " (click)="openLoginModal()">{{'header.login'|translate}}</a>
          </li>

          <li *ngIf="isLoggedIn == true ">
            <a routerLink="/dashboard" class="profile-link">
              <img src="./assets/images/icons/profile-icon2.png">
              {{fullName}}
            </a>
          </li>

        </ng-container>

        <ng-container *ngIf="headerType=='dashboard-header-top'">


          <li tabindex="0" class="li-btn-mobile">
            <a routerLink="/dashboard/">
              <i class="fa fa-object-group"></i>
              {{'Dashboard'|translate}}
            </a>
          </li>

          <li tabindex="1" class="li-btn-mobile">
            <a routerLink="/dashboard/properties/lands">
              <i class="fa  fa-key"></i>
              {{'myProperties'|translate}}
            </a>
          </li>

          <li tabindex="1" class="li-btn-mobile">
            <a routerLink="/dashboard/contracts">
              <i class="fa  fa-file-archive-o"></i>
              {{'rentContract'|translate}}
            </a>
          </li>

          <li tabindex="1" class="li-btn-mobile">
            <a routerLink="/dashboard/customers">
              <i class="fa  fa-users"></i>
              {{'customers'|translate}}
            </a>
          </li>

          <li class="li-btn-mobile">
            <a routerLink="/dashboard/saved-properties">
              <i class="fa fa-heart-o"></i>
              {{'SavedProperties'|translate}}
            </a>
          </li>

          <li class="li-btn-mobile">
            <a routerLink="/profile" class="dropdown-item"> <i class="fa fa-user"></i>
              {{'header.profile'|translate}}</a>
          </li>

          <li class="li-btn-mobile">
            <a routerLink="/logout" href="#" class="dropdown-item"><i class="fa fa-sign-out"></i>
              {{'header.logout'|translate}}</a>
          </li>

          <li *ngIf="isLoggedIn == true ">
            <a routerLink="/dashboard" class="profile-link">
              <img src="./assets/images/icons/profile-icon2.png">
              {{fullName}}
            </a>
          </li>


        </ng-container>


      </ul>
    </nav>
  </div>
</div>
