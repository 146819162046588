<div *ngIf="showAlert">
  <div class="alert alert-{{alertType}}" role="alert" *ngIf="type=='alert'">
    <h5 class="alert-heading"><img src="./assets/images/icons/{{icon}}">{{title|translate}}</h5>
    {{noticeText|translate}}
  </div>


<span *ngIf="type=='badge'">
  <span class="badge   alert-{{alertType}}">
  <img src="./assets/images/icons/{{icon}}">
    <span class="d-none d-sm-inline">{{title|translate}}</span>
</span>

</span>
</div>
