<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>
  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">{{'SavedProperties'|translate}} <span
          class="light small">({{totalPropertiesCounts }})</span></h1>
      </div>
      <div class="action">
        <button class="btn  btn--light--default ml-1 mr-1 " (click)="openOrderModal()">{{'requestInfo'|translate}}</button>
        <a class="btn btn--primary " routerLink="/dashboard/add-properties">{{'AddNewProperty'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details ">
      <div class="container-fluid">
        <div class="property-breadcrumb">
          <ul>
            <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
            <li><a href="/dashboard/properties" class="active">{{'SavedProperties'|translate}}</a></li>
          </ul>
        </div>

        <div class="section-content">
          <h2 class="text-uppercase mb-1">{{'lands'|translate}}</h2>
          <app-grid
            [itemsArray]="savedLands"
            [lang]="lang"
            [gridCol]="gridCol"
            class="row"
            [page]="page"
            [currentPage]="currentPage"
            [propertyType]="propertyTypeLand"
            [totalItems]="totalLandsItems"
            [itemsPerPage]="itemsPerPage"
            [loading]="loading"
            [savedProperty]="true"
            [unSaveButton]="unSaveButton"
            (mySaveLandEvent)="saveLandAction($event)"
            (myPaginationEvent)="pageLandChanged($event)"
          >
          </app-grid>
          <h2 class="text-uppercase mb-1">{{'buildings'|translate}}</h2>

          <app-grid
            [itemsArray]="savedBuildings"
            [lang]="lang"
            [gridCol]="gridCol"
            class="row"
            [page]="page"
            [currentPage]="currentPage"
            [propertyType]="propertyTypeBuilding"
            [totalItems]="totalBuildingsItems"
            [itemsPerPage]="itemsPerPage"
            [loading]="buildingLoading"
            [savedProperty]="true"
            [unSaveButton]="unSaveButton"
            (mySaveLandEvent)="saveLandAction($event)"
            (myPaginationEvent)="pageLandChanged($event)"
          >
          </app-grid>
          <h2 class="text-uppercase mb-1">{{'units'|translate}}</h2>
          <app-grid
            [itemsArray]="savedUnits"
            [lang]="lang"
            [gridCol]="gridCol"
            class="row"
            [page]="page"
            [currentPage]="currentPage"
            [propertyType]="propertyTypeUnits"
            [totalItems]="totalUnitsItems"
            [itemsPerPage]="itemsPerPage"
            [loading]="unitsLoading"
            [savedProperty]="true"
            [unSaveButton]="unSaveButton"
            (mySaveLandEvent)="saveLandAction($event)"
            (myPaginationEvent)="pageLandChanged($event)"
          >
          </app-grid>

        </div>

      </div>
    </div>
  </div>
</div>
<layout-footer></layout-footer>
