<app-top-header></app-top-header>


<div class="dashboard bg-white">

  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">{{'Account Overview'|translate}}</h1>
      </div>
      <div class="action">
        <button class="btn  btn--light--default " (click)="openOrderModal()">{{'requestInfo'|translate}}</button>
        <a class="btn btn--primary " routerLink="/dashboard/add-properties">{{'AddNewProperty'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details">
      <div class="container-fluid">
        <div class="property-breadcrumb mb-2" *ngIf="pageNotFound==false">
          <ul>
            <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
            <li><a routerLink="/dashboard/properties">{{'Properties'|translate}}</a></li>
            <li><a class="active">{{'land'|translate}} Block Parcel</a></li>
          </ul>
        </div>
        <div class="row">
          <div *ngIf="pageNotFound==true" class="text-xl-center">
            <app-page-not-found-alert></app-page-not-found-alert>
          </div>
          <div class="property-content" *ngIf="pageNotFound==false">
            <app-alerts-notice [aqarStatus]="aqarStatus" type="alert"></app-alerts-notice>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<layout-footer></layout-footer>
