

<section>
  <div class="inner-banner">
    <!--<div class="overlay"></div>-->
    <img src="./assets/images/property-request-header.jpg">
    <div class="container-fluid">
      <div class="title">
        <!--<h1>{{'header.propertyRequest'|translate}}</h1>-->
        <!--<p>{{'ourServicesSlogan'|translate}}</p>-->
      </div>
    </div>
  </div>
  <div class="container mb-5 mt-5">


    <h1 class="text-center ">{{'header.propertyRequest'|translate}}</h1>
    <h3 class="mb-4 text-center">
      {{'propertyRequestHeader'|translate}}
    </h3>

    <form [formGroup]="propertyRequestForm" (ngSubmit)="onSubmit()" class="property-request-form">


      <h4 class="text-center">{{'personalInformation'|translate}}</h4>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{'First & Last Name'|translate}} <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="fullName" [(ngModel)]="propertyRequest.fullName"
                   placeholder="{{'First & Last Name'|translate}}">
            <div class="form-control-feedback text-left"
                 *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
              <p *ngIf="fullName.errors.required">{{'validation.fullName_required'|translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <label>{{'mobileNumber'|translate}} <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="mobile" name="mobile"
                   [(ngModel)]="propertyRequest.mobile" placeholder="eg:+972598-000-111">
            <div class="form-control-feedback text-left"
                 *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
              <p *ngIf="mobile.errors.required">{{'validation.mobile_required'|translate}}</p>
              <p *ngIf="mobile.invalid">{{'validation.mobile_invalid'|translate}}</p>

            </div>
          </div>


        </div>
        <div class="col-md-6">

          <div class="form-group">
            <label>{{'Email'|translate}} <span class="text-muted small">({{'optional'|translate}})</span> </label>

            <input type="email" class="form-control" formControlName="email" name="email"
                   [(ngModel)]="propertyRequest.email" placeholder="{{'Email'|translate}}" email>
            <div class="form-control-feedback text-left">
              <p *ngIf="email.invalid ">{{'validation.InvalidEmailAddress'|translate}}</p>
            </div>
          </div>
        </div>
      </div>


      <h4 class="text-center">{{'propertyInformation'|translate}}</h4>


      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{'propertyType'|translate}} <span class="text-danger">*</span></label>

            <select formControlName="propertyType" class="form-control">
              <option default>
                {{'SelectPropertyType'|translate}}
              </option>
              <option *ngFor="let type of propertyTypesList" [ngValue]="type.id">
                {{ lang == 'ar' ? type.ar_name : type.en_name}}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>{{'propertyArea'|translate}}  <small>({{'approximately'|translate}})</small></label>
            <div class="input--group">
              <input
                type="text"
                class="form-control form-control-sm"
                name="propertyArea"
                formControlName="propertyArea"
                placeholder="{{'area'|translate}}"
                [(ngModel)]="propertyRequest.propertyArea"
              >
              <div class="input--group--append">
                <span>m²</span>
              </div>
            </div>


          </div>

          <div class="form-group">
            <label>{{'notes'|translate}} <span class="text-danger">*</span> </label>

            <textarea class="form-control" rows="2" formControlName="notes" name="notes"
                      [(ngModel)]="propertyRequest.notes"
                      placeholder="{{'orderFormNotesPlaceholder'|translate}} "></textarea>


            <input
              type="hidden"
              name="requestType"
              formControlName="requestType"
            >
          </div>

        </div>
        <div class="col-md-6">


          <div class="form-group">
            <label>{{'propertyFor'|translate}} <span class="text-danger">*</span> </label>

            <select formControlName="propertyFor" class="form-control" >
              <option value="" class=""  selected>
                {{'Offer'|translate}}
              </option>
              <option *ngFor="let propertyFor of propertyForList" [ngValue]="propertyFor.id">
                {{ lang == 'ar' ? propertyFor.ar_name : propertyFor.en_name}}
              </option>
            </select>
          </div>


          <div class="form-group">
            <label>{{'budget'|translate}}  <span class="text-muted small">({{'optional'|translate}})</span> </label>

            <div class="input--group">
              <input
                type="text"
                class="form-control form-control-sm"
                name="propertyArea"
                formControlName="budget"
                placeholder="{{'budget'|translate}}"
                [(ngModel)]="propertyRequest.budget"
              >
              <div class="input--group--append">
                <span>$</span>
              </div>
            </div>

            <div class="form-control-feedback text-left"
                 *ngIf="budget.errors && (budget.dirty || budget.touched)">
              <p *ngIf="budget.errors.required">{{'validation.budget_required'|translate}}</p>
              <p *ngIf="budget.invalid">{{'validation.InvalidBudget'|translate}}</p>
            </div>
          </div>


        </div>
      </div>


      <div class="form-group text-center mt-3">
        <button
          type="submit"
          class="btn btn--primary btn--large "
          [disabled]="!propertyRequestForm.valid"
        >
          {{'send'|translate}}
        </button>
      </div>
      <div *ngIf="!propertyRequestForm.valid && propertyRequestForm.dirty " class="text-center alert alert-danger">
        {{'validationsErrors'|translate}}
      </div>

      <div *ngIf="loading" class="spinner text-center d-block"></div>
      <div *ngIf="alert" class="text-center alert alert-{{alertClass}}">
        <strong>{{alertMsgTitle}}</strong><br>
        {{alertMsg}}
      </div>


    </form>

  </div>


</section>
