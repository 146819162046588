import {Component, OnInit} from '@angular/core';
import {BlogService} from './../../_services/blog.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './../../_services/auth.service';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SignUpModalComponent} from '../includes/sign-up-modal/sign-up-modal.component';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {


  isLoggedIn:any;
  features:any;
  lang:string;

  constructor(private blogService:BlogService,
              public translate:TranslateService,
              private auth:AuthService,
              private modalService:NgbModal) {
  }


  ngOnInit() {
    this.lang = this.translate.currentLang;
    this.getFeatures();
    this.isLoggedIn = this.auth.isAuthenticated();

  }


  getFeatures() {
    this.blogService.getFeatures(24).subscribe(res=> {
      this.features = res;
      let featuresArray = this.features;


      this.features.forEach(function (a) {
        a.title.rendered = a.title.rendered.replace(/\s|&nbsp;/g, ' ');
      });
    });
  }


  openSignUpModal() {
    const modalRef = this.modalService.open(SignUpModalComponent);
  }

}
