<div class="feature-sec">

  <div class="header-text">

    <div class="container">
      <div class="row">
        <div class="my-aqar">
          <img src="./assets/images/my-aqar.png">
        </div>
      </div>

      <h1 class="title">{{'subscriptionTitle'|translate}} </h1>
      <h2>{{'BecomeMemberDescription'|translate}}</h2>
    </div>
  </div>


  <div class="container">
    <div class="row features">
      <div class="col-md-3" *ngFor="let feature of features">
        <div class="feature">
          <span class="icon"><img src="{{feature.acf.icon}}"></span>
          <h2>{{feature.title.rendered}}</h2>
          <p>{{ lang == 'ar' ? feature?.acf?.arabic : feature?.acf?.english }}</p>
        </div>
      </div>
    </div>
  </div>


  <!--  <div class="block member-block row">-->


  <!--    <div class="description">-->

  <!--      <div class="text" *ngIf="!isLoggedIn">-->
  <!--        <h1>{{'featureSubHeader'|translate}}</h1>-->
  <!--        <button class="btn btn&#45;&#45;blue rounded mt-4" routerLink="/wizard">{{'signupFree'|translate}}</button>-->
  <!--        <div class="clearfix"></div>-->
  <!--      </div>-->


<!--  <div *ngIf="isLoggedIn">-->
<!--    <div class="row row justify-content-md-center mt-4 pt-4">-->
<!--      <div class="col-md-4 align-self-center">-->
<!--        <h2 class="white strong">{{'featureSubHeader'|translate}}</h2>-->
<!--        <a class="btn btn&#45;&#45;blue rounded mt-2"-->
<!--           routerLink="/dashboard/add-properties">{{'createFileForYourProperty'|translate}}</a>-->
<!--      </div>-->
<!--      <div class="col-md-4">-->
<!--        <div class="property-file">-->
<!--          <img src="./assets/images/property-file.png">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--    </div>-->


  <div class="start-now">
    <div class="overflow-bg"></div>
    <div class="container-fluid">
      <div class="row">


        <div class="col-md-6 align-self-center">

          <div class="text mb-5" *ngIf="!isLoggedIn">
            <h1>{{'featureSubHeader'|translate}}</h1>
            <button class="btn btn--blue rounded mt-4" routerLink="/wizard">{{'signupFree'|translate}}</button>
            <div class="clearfix"></div>
          </div>

          <div class="text mb-5" *ngIf="isLoggedIn">
            <h1>{{'createFileForYourProperty'|translate}}</h1>
            <button class="btn btn--blue rounded mt-4" routerLink="/dashboard/add-properties">{{'createFileForYourProperty'|translate}}</button>
            <div class="clearfix"></div>
          </div>


<!--          <div *ngIf="isLoggedIn" class="text mb-5">-->
<!--            <div class="col-md- align-self-center">-->
<!--              <h2 class="white strong">{{'featureSubHeader'|translate}}</h2>-->
<!--              <a class="btn btn&#45;&#45;blue rounded mt-2"-->
<!--                 routerLink="/dashboard/add-properties">
{{'createFileForYourProperty'|translate}}
</a>-->
<!--            </div>-->

<!--            <div class="row row justify-content-md-center mt-4 pt-4">-->
<!--              <div class="col-md-4 align-self-center">-->
<!--                <h2 class="white strong">{{'featureSubHeader'|translate}}</h2>-->
<!--                <a class="btn btn&#45;&#45;blue rounded mt-2"-->
<!--                   routerLink="/dashboard/add-properties">{{'createFileForYourProperty'|translate}}</a>-->
<!--              </div>-->
<!--              <div class="col-md-4">-->
<!--                <div class="property-file">-->
<!--                  <img src="./assets/images/property-file.png">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

        </div>

        <div class="col-md-5 offset-md-1">
          <div class="video-responsive">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/X1mwi6ssZ4o"
                    title="YouTube video player"
                    frameborder="0"
                    allow=""
                    allowfullscreen>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<!--<div class="video-responsive">-->

<!--  <iframe width="560" height="315" src="https://www.youtube.com/embed/X1mwi6ssZ4o" title="YouTube video player"-->
<!--          frameborder="0"-->
<!--          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--          allowfullscreen>-->

<!--  </iframe>-->
<!--</div>-->
