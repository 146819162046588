import {Component, OnInit,ViewEncapsulation,Inject,HostListener} from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';

import {BlogService} from '../../_services/blog.service';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import { DOCUMENT } from "@angular/common";
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-realestate-in-palestine-section',
  templateUrl: './realestate-in-palestine-section.component.html',
  styleUrls: ['./realestate-in-palestine-section.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class RealestateInPalestineSectionComponent implements OnInit {

  slug:any;
  post:any;
  posts:any;
  categoryId:number = 6;
  categories:any;
  content:any = '';
  tocNav:any;
  pathUrl:any;
  public offset:number = 215;
  spyScrollItem:any = [];
  categoriesPosts:any = [];
  catPostsArray:any;
  public sticky: boolean = false;

  constructor(@Inject(WINDOW) private window: Window, private route:ActivatedRoute,
              private blogService:BlogService,
              private router: Router,
              private sanitizer:DomSanitizer,
              @Inject(DOCUMENT) private doc: Document
  ) {
    this.pathUrl = document.location.href;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      console.log(this.slug);
      if (this.slug == 'undefined' || this.slug == null) {
        console.log('yes im empty ');
        this.getFirstPost(this.categoryId);
      } else {
        this.getPostBySlug(this.slug);
      }
    });
    this.getCategoryPosts(this.categoryId);
  }


  getPostBySlug(slug) {
    this.blogService.getPostBySlug(slug).subscribe(res=> {
      this.post = res[0];
      this.spyScrollItem = [];
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.post.content.rendered);
      this.tocNav = this.getToc(this.content);
      this.window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }

  getToc(content) {
    let contentdiv = document.createElement("div");
    contentdiv.innerHTML = content;
    let myArrayOfHeadlineNodes = [].slice.call(contentdiv.querySelectorAll("h1, h2"));
    for (var i = 0; i < myArrayOfHeadlineNodes.length; i++) {
      var slug = this.slugify(myArrayOfHeadlineNodes[i].innerText);
      var item = {'slug': slug, 'text': myArrayOfHeadlineNodes[i].innerText};
      this.spyScrollItem.push(item);
    }
  }


  getCategoryPosts(categoryId) {
    this.blogService.getCategoryPosts(categoryId).subscribe(res=> {
      this.categoriesPosts = res;
    });
  }


  getFirstPost(categoryId) {
    this.blogService.getRealEstateFirstPostOfCategory(categoryId).subscribe(res=> {
      this.post = res[0];
      this.router.navigate(['knowledge-center/realestate-palestine/', this.post.slug]);
    });
  }


  slugify(text) {
    return text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    // let num = this.doc.body.scrollTop;
    let num = $event.target.documentElement.scrollTop;
    if ( num > 280 ) {
      this.sticky = true;
    }else if (this.sticky && num < 280) {
      this.sticky = false;
    }
  }



}
