<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

  <div class="form-group">
    <label class="text-left"> {{'emailOrMobile'|translate}}</label>
    <input type="text" class="form-control" formControlName="username" placeholder=""
           [(ngModel)]="user.username">
    <div class="form-control-feedback"
         *ngIf="username.errors && (username.dirty || username.touched)">
      <p class="text-left error" *ngIf="username.errors.required">{{'validation.required'|translate}}</p>
    </div>
  </div>

  <div class="form-group">
    <label class="text-left">{{'Password'|translate}}</label>
    <input type="password" class="form-control" formControlName="password" placeholder="{{'Password'|translate}}"
           [(ngModel)]="user.password">
    <div class="form-control-feedback">
      <p
        class="error text-left"
        *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">
        {{'validation.password_required'|translate}}
      </p>
    </div>
  </div>
  <div class="form-group">

    <input type="submit" value="{{'header.login'|translate}}" [disabled]="loginForm.invalid"
           class="btn-block btn btn--primary">

    <button (click)="openForgetPassword()" class="btn-anchor anchor-gray text-left btn-block">
    <small>{{'did you forget Password'|translate}}</small>
    </button>

    <div class="clear"></div>
    <div class="loading" *ngIf="loading">
      <img width="90" src="./assets/images/icons/loading_login.gif" class="text-center">
    </div>

    <div class="alert alert-{{alertClass}}" *ngIf="alert">
      {{alertMsg}}
    </div>

  </div>

</form>

