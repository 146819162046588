<app-top-header></app-top-header>
<section class="inner">
  <div class="container-fluid">

    <div class="row justify-content-center align-items-center">

      <div class="col col-sm-6 col-md-6 col-lg-6 col-xl-4   bg-white rounded">
        <div class="reset-password-form">
          <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">


            <h1>{{'Reset Your Password'|translate}}</h1>
            <p>{{'you can reset password for your account by completing this form'|translate}} </p>
            <div class="form-group">
              <label class="text-left ">{{'Password'|translate}}</label>
              <input type="password" class="form-control" formControlName="password"
                     placeholder="{{'Password'|translate}}">
              <div class="form-control-feedback"
                   *ngIf="password.errors && (password.dirty || password.touched)">
                <p *ngIf="password.errors.required">{{'validation.password_required'|translate}}</p>
                <p *ngIf="password.errors.minlength">{{'validation.password_min_length'|translate}}
                  {{password.errors.minlength.requiredLength - password.errors.minlength.actualLength}}
                  {{'characters'|translate}} </p>
              </div>
            </div>

            <div class="form-group">
              <label class="text-left">{{'confirmPassword'|translate}}</label>
              <input type="password" class="form-control" formControlName="confirmPassword"
                     placeholder="{{'confirmPassword'|translate}}">
              <div class="form-control-feedback text-left"
                   *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)">
                <p *ngIf="confirmPassword.errors.required">{{'validation.password_confirmation_error'|translate}}</p>

              </div>
            </div>

            <div class="text-center">
              <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn btn--primary btn--large btn-block"
                [disabled]="!resetPasswordForm.valid"
              >{{'submit'|translate}}
              </button>
            </div>


            <div class="alert alert-{{alertClass}}">
              {{alertMsg}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<layout-footer></layout-footer>
