<div class="modal-header">
  <!--<h5 class="modal-title" id="exampleModalLabel">login</h5>-->
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h2>{{'CreateMyAQARAccount'|translate}}</h2>
  <p class="text-capitalize text-center">{{'sign_up_description'|translate}} </p>

  <form [formGroup]="signUpForm">


    <div class="row">
      <div class="col-md-6">
        <input type="text" class="form-control" formControlName="firstName" placeholder="{{'firstName'|translate}}">
        <div class="form-control-feedback text-left"
             *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">
          <p *ngIf="firstName.errors.required" class="text-left">{{'validation.firstName_required'|translate}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <input type="text" class="form-control" formControlName="lastName" placeholder="{{'lastName'|translate}}">
        <div class="form-control-feedback text-left"
             *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">
          <p *ngIf="lastName.errors.required" class="text-left">{{'validation.lastName_required'|translate}}</p>
        </div>
      </div>

    </div>

    <div class="form-group">
      <input type="text" class="form-control" formControlName="mobile" placeholder="eg:+972598-000-111">
      <div class="form-control-feedback text-left"
           *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
        <p *ngIf="mobile.errors.required" class="text-left">{{'validation.mobile_required'|translate}}</p>
        <p *ngIf="mobile.invalid && mobile.errors.required" class="text-left">
          {{'validation.mobile_invalid'|translate}}</p>
      </div>
    </div>

    <div class="form-group">
      <input type="email" class="form-control" formControlName="email" placeholder="{{'Email'|translate}} ({{'optional'|translate}} )" email>
      <div class="form-control-feedback text-left"
           *ngIf="email.errors && (email.dirty || email.touched)">
        <!--<p *ngIf="email.errors.required" class="text-left">{{'validation.email_required'|translate}}</p>-->
        <p *ngIf="email.invalid || email.errors.required" class="text-left">
          {{'validation.InvalidEmailAddress'|translate}}</p>
      </div>
    </div>
    <div class="form-group">
      <input type="password" class="form-control" formControlName="password" placeholder="{{'Password'|translate}}">
      <div class="form-control-feedback text-left"
           *ngIf="password.errors && (password.dirty || password.touched)">
        <p *ngIf="password.errors.required" class="text-left">{{'validation.password_required'|translate}}</p>
        <p *ngIf="password.errors.minlength || password.errors.required" class="text-left">
          {{'validation.password_min_length'|translate}}
          {{password.errors.minlength.requiredLength - password.errors.minlength.actualLength}}
          {{'characters'|translate}} </p>
      </div>
    </div>


    <!--<div class="loading" *ngIf="loading">-->
    <!--loading . . .-->
    <!--</div>-->
    <div class="loading" *ngIf="loading">
      <img width="90" src="./assets/images/icons/loading_login.gif" class="text-center">
    </div>
    <div class="form-group">
      <input
        type="submit"
        value="{{'header.sign_up'|translate}}"
        class="btn-block btn btn--primary"
        (click)="signup()"
        [disabled]="!signUpForm.valid"
      >
    </div>

    <div class="mb-0 alert alert-{{alertClass}}">
      {{alertMsg}}
    </div>
  </form>


</div>


<div class="modal-footer">
  <p>{{'already_have_account'|translate}}
    <button class="btn-anchor anchor-primary" (click)="openLoginAndCloseSignUp()">{{'header.sign_in'|translate}}
    </button>
  </p>
</div>
