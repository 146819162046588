import {Component, OnInit} from '@angular/core';
import * as jwt_decode from "jwt-decode";
import {UserService} from './../../../_services/user.service';
import {User} from '../../../models/user-model';
import {AuthService} from '../../../_services/auth.service';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {CustomersOrdersModalComponent} from  '../../customers-orders-modal/customers-orders-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  firstName:FormControl;
  secondName:FormControl;
  thirdName:FormControl;
  lastName:FormControl;
  email:FormControl;
  mobile:FormControl;
  user:User = new User();
  error:any;
  profileForm:FormGroup;
  update_success_msg:string;
  mobileNumberAlreadyExist:string;

  alert:boolean = false;
  alertClass:string = '';
  alertMsg:string = '';
  loading:boolean = false;
  userInformation:any;

  constructor(private  userService:UserService,
              private auth:AuthService,
              private fb:FormBuilder,
              private toastr:ToastrService,
              translate:TranslateService,
              private modalService:NgbModal) {

    translate.get('update_success_msg').subscribe((res:string) => {
      this.update_success_msg = res;
    });
    translate.get('mobileNumberAlreadyExist').subscribe((res:string) => {
      this.mobileNumberAlreadyExist = res;
    });


  }


  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
  }


  ngOnInit() {
    this.getUserInfo();
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {

    this.firstName = new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]);

    this.secondName = new FormControl('', []);
    this.thirdName = new FormControl('', []);

    this.lastName = new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]);


    this.email = new FormControl('', [
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      Validators.email
    ]);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);
  }

  createForm() {
    this.profileForm = this.fb.group({
      firstName: this.firstName,
      secondName: this.secondName,
      thirdName: this.thirdName,
      lastName: this.lastName,
      email: this.email,
      mobile: this.mobile,
    });

  }

  getUserInfo() {
    this.userService.getUser().subscribe((res:any)=> {
      this.userInformation = (res.userInformation);
      this.user.firstName = this.userInformation.first_name;
      this.user.secondName = this.userInformation.second_name;
      this.user.thirdName = this.userInformation.third_name;
      this.user.lastName = this.userInformation.last_name;
      this.user.email = this.userInformation.email;
      this.user.mobile = this.userInformation.username;
    });
  }

  updateProfile() {

    this.auth.updateProfile(this.user)
      .map(res=>res)
      .subscribe(res => {
          console.log(res);
          if (res['success'] == true) {
            this.showSuccess();
          } else {
            this.toastr.warning(res['msg']);
            if (res['errors'][0]['field'] == 'يوزرنيم') {
              this.toastr.error(this.mobileNumberAlreadyExist);
            } else {
              this.toastr.warning(res['errors'][0]['message']);

            }
          }
        },
        err => {
          console.log('Something went wrong!');
        }
      );
  }

  showSuccess() {
    this.toastr.success(this.update_success_msg);
  }

}
