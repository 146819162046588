import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class WizardService {


  private BASE_URL:string = environment.CORE_SERVER_API_URL;


  constructor(private http:HttpClient) {
  }



 

  postWizard(wizardData) {
    let url:string = `${this.BASE_URL}user_actions/store_wizard`;
    return this.http.post(url, wizardData);
  }



  checkUserWizard(){
    return this.http
      .get(environment.CORE_SERVER_API_URL + 'user_actions/check_wizard');

  }



}
