export class User {
  username:string;
  email:string;
  password:string;
  fullName:string;
  firstName:string;
  secondName:string;
  thirdName:string;
  lastName:string;
  mobile:string;

}
