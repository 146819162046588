<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content">
    <div class="bg-gradient-primary">
      <div class="dashboard-header">
        <div class="title">
          <h1 class="text-capitalize mt-2 text-white">
            {{'rentContract'|translate}}
          </h1>
        </div>
        <div class="action">
          <a class="btn btn--primary " routerLink="/dashboard/contracts/add-contract">{{'addNewContract'|translate}}</a>
        </div>
      </div>
      <div class="dashboard-details">

        <div class="cards mt-2 mb-3">
          <div class="row">

            <div class="col-xl-3 col-lg-6 col-12">
              <a class="card" routerLink="/dashboard/contracts/units">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'rentedUnits'|translate}} <span
                        class="counter">({{rentedUnitsCount}})</span></h6>
                    </div>
                    <div class="icon-shape ">
                      <img src="assets/images/icons/Villa.svg">
                    </div>
                  </div>
                </div>
              </a>
            </div>


            <div class="col-xl-3 col-lg-6 col-12">
              <a class="card" routerLink="/dashboard/contracts/list">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'rentContract'|translate}} <span class="counter">({{rentedContractCount}})</span>
                      </h6>
                    </div>
                    <div class="icon-shape ">
                      <img src="assets/images/icons/Folder.svg">
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-xl-3 col-lg-6 col-12">
              <a class="card" routerLink="/dashboard/customers">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'myCustomers'|translate}} <span class="counter">(3)</span></h6>
                    </div>
                    <div class="icon-shape ">
                      <i class="fa fa-users fa-2x"></i>
                      <!--<img src="assets/images/services/Broker.svg">-->
                    </div>
                  </div>
                </div>
              </a>
            </div>


            <div class="col-xl-3 col-lg-6 col-12">
              <a class="card" routerLink="/dashboard/properties/buildings">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">{{'PaymentsDue'|translate}} <span class="counter">(3)</span></h6>
                    </div>
                    <div class="icon-shape ">
                      <img src="assets/images/services/MoneyValuation.svg">
                    </div>
                  </div>
                </div>
              </a>
            </div>


          </div>
        </div>
      </div>
    </div>


    <div class="dashboard-table-details mt-6 p-3">
      <div class="row">

        <div class="col-xl-6 col-lg-6 col-12">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-9">
                  <h3 class="mb-0"><strong>{{'ContractsEndingWithOutstandingPayments'|translate}}</strong></h3>
                </div>
                <div class="col text-right">
                  <!--<a href="#" class="btn&#45;&#45;small btn&#45;&#45;primary btn">{{'seeAll'|translate}}</a>-->
                </div>
              </div>
              <hr>
              <div class="row">


                <div class="table-responsive">

                  <table class="table table-borderless text-center">
                    <thead>
                    <tr>
                      <th scope="col">{{'contractId'|translate}}</th>
                      <th scope="col">{{'lesseeName'|translate}}</th>
                      <th scope="col">{{'contractEndDate'|translate}}</th>
                      <th scope="col">{{'deservedAmount'|translate}}</th>
                      <th scope="col">{{'paymentStatus'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>


                    <tr *ngFor="let  contract of expiredContracts">

                      <td><a routerLink="/dashboard/contracts/{{contract.contract_id}}">{{contract.contract_id}}</a></td>
                      <td>{{contract.renter_name}}</td>
                      <td>{{contract.contract_end_date}}</td>
                      <td><i class="currency">{{currency}}</i>{{contract.due_payment_sum}}</td>
                      <td>
                        <span class="badge badge-pill badge-danger text-white p-2">غير مسدد</span>
                      </td>
                    </tr>



                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-12">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0"><strong>{{'ContractsExpiringThisMonth'|translate}}</strong></h3>
                </div>
                <div class="col text-right">
                  <a href="#" class="btn--small btn--primary btn">{{'seeAll'|translate}}</a>
                </div>
              </div>
              <hr>
              <div class="row">

                <div class="table-responsive">

                  <table class="table table-borderless text-center">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{{'customerName'|translate}}</th>
                      <th scope="col">{{'installment'|translate}}</th>
                      <th scope="col">{{'installmentType'|translate}}</th>
                      <th scope="col">{{'dueDate'|translate}}</th>
                      <th scope="col">{{'mobileNumber'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="6" class="text-left td-heading blue-color"><i class="fa fa-caret-left"></i>
                        {{'contractNumber'|translate}}
                        <strong class="number-font"><a href="#">
                          1690026143
                        </a></strong><span class="contract-units"><span><span>(شقق)</span></span></span></td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td>رائف نصار</td>
                      <td>500$</td>
                      <td>ايجار شهري</td>
                      <td>2020-04-10</td>
                      <td>0598164229</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>رائف نصار</td>
                      <td>50$</td>
                      <td>ضريبة معارف</td>
                      <td>2020-04-15</td>
                      <td>0598164229</td>
                    </tr>

                    <tr>
                      <td colspan="6" class="text-left td-heading blue-color"><i class="fa fa-caret-left"></i>
                        {{'contractNumber'|translate}}
                        <strong class="number-font"><a href="#">
                          1690026145
                        </a></strong><span class="contract-units"><span><span>(محل تجاري)</span></span></span></td>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td>محمص العودة</td>
                      <td>900$</td>
                      <td>ايجار شهري</td>
                      <td>2020-04-2</td>
                      <td>0598121212</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100"></div>

      </div>
    </div>
  </div>
</div>


<layout-footer></layout-footer>
