/**
 * Core
 */
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HttpModule} from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';

import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {DecimalPipe} from '@angular/common';

/**
 * plugin and library
 */
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {MatTableModule} from '@angular/material/table';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {NouisliderModule} from 'ng2-nouislider';
import {NgSelectModule} from '@ng-select/ng-select';
import {AgmCoreModule} from '@agm/core';
import {AgmJsMarkerClustererModule} from '@agm/js-marker-clusterer';
import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import {ToastrModule} from 'ngx-toastr';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
// import { FileUploadModule } from 'ng2-file-upload';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { HaversineService } from "ng2-haversine";

/**
 * Module
 */
import {SharedModule} from './module/shared.module';
import {ArchwizardModule} from 'angular-archwizard';
import { CookieService } from 'ngx-cookie-service';


/**
 * services
 */
import {LandsService} from "./_services/lands.service";
import {AuthService} from './_services/auth.service';
import {EnsureAuthenticated as AuthGuard} from './_services/ensure-authenticated.service';
import {LoginRedirect} from './_services/login-redirect.service';
import {UserService} from './_services/user.service';
import {DashboardService} from './_services/dashboard.service';
import {GovernateService} from './_services/governate.service';
import {dropdownLists} from './_services/dropdown-lists.service';
import {CustomersOrdersService} from './_services/customers-orders.service';
import {BlogService} from './_services/blog.service';
import {UserActionsService} from './_services/user-actions.service';
import {MembershipService} from './_services/membership.service';
import {WindowsSizeDetectionService} from './_services/windows-size-detection.service';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MyInterceptor} from './myinterceptor';
import { ReversePipe } from './pip/reverse.pipe';

/**
 * Custom Component
 */

import {AppComponent} from './app.component';
import {FooterComponent} from './components/includes/footer/footer.component';
import {HomeComponent} from './components/home/home.component';
import {BannerComponent} from './components/banner/banner.component';
import {LoginModalComponent} from './components/includes/login-modal/login-modal.component';
import {LoginFormComponent} from './components/includes/forms/login-form/login-form.component';
import {SignUpModalComponent} from './components/includes/sign-up-modal/sign-up-modal.component';
import {PropertySliderComponent} from './components/includes/property-slider/property-slider.component';
import {SearchComponent} from './components/search-page/search/search.component';
// import {InnerHeaderComponent} from './components/includes/inner-header/inner-header.component';
import {PropertyFiltersFormComponent} from './components/includes/forms/property-filters-form/property-filters-form.component';
import {GridComponent} from './components/includes/land-block/grid/grid.component';
import {ListComponent} from './components/includes/land-block/list/list.component';
import {ProfileComponent} from './components/user/profile/profile.component';
import {LogoutComponent} from './components/user/logout/logout.component';
import {ToolkitComponent} from './components/toolkit/toolkit.component';
import {SinglePropertyComponent} from './components/includes/land-block/single-property/single-property.component';
import {MapsComponent} from './components/search-page/maps/maps.component';
import {ImageGalleryComponent} from './components/includes/image-gallery/image-gallery.component';
import {PaginationComponent} from './components/includes/pagination/pagination.component';
import {CustomersOrdersModalComponent} from './components/customers-orders-modal/customers-orders-modal.component';
import {PricingGuideComponent} from './components/pricing-guide/pricing-guide.component';
import {KnowledgeCenterComponent} from './components/knowledge-center/knowledge-center.component';
import {ArticlesBlocksComponent} from './components/articles-blocks/articles-blocks.component';
import {RealestateInPalestineBlockComponent} from './components/realestate-in-palestine-block/realestate-in-palestine-block.component';
import {RealestateInPalestineSectionComponent} from './components/realestate-in-palestine-section/realestate-in-palestine-section.component';
import {SingleBlogPageComponent} from './components/single-blog-page/single-blog-page.component';
import {NewsBlocksComponent} from './components/news-blocks/news-blocks.component';
import {ArticlesPageComponent} from './components/articles-page/articles-page.component';
import {BlogInnerNavComponent} from './components/blog-inner-nav/blog-inner-nav.component';
import {AboutComponent} from './components/about/about.component';
import {TeamComponent} from './components/team/team.component';
import {ContactComponent} from './components/contact/contact.component';
import {CareersComponent} from './components/careers/careers.component';
import {SavedPropertiesComponent} from './components/user/saved-properties/saved-properties.component';
import {TopHeaderComponent} from './components/includes/top-header/top-header.component';
import {ServicesComponent} from './components/services/services.component';
import {WizardComponent} from './components/wizard/wizard.component';
import {WizardService} from "./_services/wizard.service";
import {SingleServiceComponent} from './components/single-service/single-service.component';
import {DashboardComponent} from './components/user/dashboard/dashboard.component';
import {AddPropertiesComponent} from './components/add-properties/add-properties.component';
import { SelectPropertyTypeToAddComponent } from './components/select-property-type-to-add/select-property-type-to-add.component';
import { ProppertyAddedSuccessfullyComponent } from './components/propperty-added-successfully/propperty-added-successfully.component';
import { UserPropertiesComponent } from './components/user-properties/user-properties.component';
import { LandFileUploadComponent } from './components/land-file-upload/land-file-upload.component';
import { UserLandsComponent } from './components/user-lands/user-lands.component';
import { AlertsNoticeComponent } from './components/alerts-notice/alerts-notice.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ForgotPasswordComponent } from './components/includes/forgot-password/forgot-password.component';
import { ResetPasswordConfirmationsComponent } from './components/includes/reset-password-confirmations/reset-password-confirmations.component';
import { PageNotFoundAlertComponent } from './components/page-not-found-alert/page-not-found-alert.component';
import { ImagePreloadDirective } from './image-preload.directive';
import { SellComponent } from './sell/sell.component';
import { FeaturesComponent } from './components/features/features.component';
import { SingleBuildingComponentsComponent } from './single-building-components/single-building-components.component';
import { AngularGalleryComponent } from './components/angular-gallery/angular-gallery.component';
import { SideMenuComponent } from './components/user/side-menu/side-menu.component';
import { UserSinglePropertyComponent } from './components/user-single-property/user-single-property.component';
import { AppModule } from './app.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient:HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");

}


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@NgModule({
  imports: [
    // FileUploadModule,
    NgbModule,
    NgxGalleryModule,
    NgxPaginationModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,

    FormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    // MatTableModule,
    SharedModule,
    NouisliderModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyAhRumSfjAby_c11qWaVJxteTD7ZXQoJSM',language:'ar'
    }),
    AgmSnazzyInfoWindowModule,
    AgmJsMarkerClustererModule,
    ArchwizardModule,
    DropzoneModule,
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      closeButton: true
    }),
    RouterModule.forRoot([
    {
        path: 'home', component: HomeComponent, children: [
            { path: '', component: TopHeaderComponent, outlet: 'header' },
            { path: '', component: FooterComponent, outlet: 'footer' }
        ]
    },
    {
        path: 'search/:type', component: SearchComponent, children: [
            { path: '', component: TopHeaderComponent, outlet: 'header' },
            { path: '', component: FooterComponent, outlet: 'footer' },
        ]
    },
    {
        path: 'profile', component: ProfileComponent,
        children: [
            { path: '', component: TopHeaderComponent, outlet: 'header' },
            { path: '', component: FooterComponent, outlet: 'footer' },
        ],
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/saved-properties', component: SavedPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    //
    // {
    //   path: 'user/dashboard/properties', component: UserPropertiesComponent,
    //   canActivate: [AuthGuard]
    // },
    {
        path: 'dashboard/properties/lands', component: UserPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/buildings', component: UserPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/villas_houses', component: UserPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/apartments', component: UserPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/offices', component: UserPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/stores', component: UserPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/lands/:id', component: UserSinglePropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/apartments/:id', component: UserSinglePropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/offices/:id', component: UserSinglePropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/stores/:id', component: UserSinglePropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/buildings/:id', component: UserSinglePropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/properties/villas_houses/:id', component: UserSinglePropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties', component: SelectPropertyTypeToAddComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/land', component: AddPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/apartment', component: AddPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/villa_house', component: AddPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/office', component: AddPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/store', component: AddPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/building', component: AddPropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/land/upload/:id', component: LandFileUploadComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/apartment/upload/:id', component: LandFileUploadComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/store/upload/:id', component: LandFileUploadComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/office/upload/:id', component: LandFileUploadComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/building/upload/:id', component: LandFileUploadComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/add-properties/villa_house/upload/:id', component: LandFileUploadComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/property-successfully-added', component: ProppertyAddedSuccessfullyComponent,
        canActivate: [AuthGuard]
    },
    { path: 'knowledge-center/', component: KnowledgeCenterComponent },
    { path: 'knowledge-center/realestate-palestine', component: RealestateInPalestineSectionComponent },
    { path: 'knowledge-center/realestate-palestine/:slug', component: RealestateInPalestineSectionComponent },
    { path: 'knowledge-center/tips', component: ArticlesPageComponent },
    { path: 'knowledge-center/tips/:slug', component: SingleBlogPageComponent },
    { path: 'knowledge-center/news', component: ArticlesPageComponent },
    { path: 'knowledge-center/news/:slug', component: SingleBlogPageComponent },
    { path: 'knowledge-center/guide', component: ArticlesPageComponent },
    { path: 'knowledge-center/guide/:slug', component: SingleBlogPageComponent },
    { path: 'knowledge-center/my-aqar', component: ArticlesPageComponent },
    { path: 'knowledge-center/my-aqar/:slug', component: SingleBlogPageComponent },
    { path: 'top-header', component: TopHeaderComponent },
    { path: 'about', component: AboutComponent },
    { path: 'about/:slug', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'careers', component: CareersComponent },
    { path: 'sell', component: SellComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'services/:slug', component: ServicesComponent },
    { path: 'reset-password-confirmation/:token', component: ResetPasswordConfirmationsComponent },
    {
        path: 'wizard',
        component: WizardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'toolkit', component: ToolkitComponent, children: [
            // {path: '', component: TopHeaderComponent, outlet: 'header'},
            { path: '', component: FooterComponent, outlet: 'footer' }
        ]
    },
    {
        path: 'lands/:id', component: SinglePropertyComponent, children: []
    },
    {
        path: 'buildings/:id', component: SinglePropertyComponent, children: []
    },
    {
        path: 'villa_houses/:id', component: SinglePropertyComponent, children: []
    },
    {
        path: 'stores/:id', component: SinglePropertyComponent, children: []
    },
    {
        path: 'apartments/:id', component: SinglePropertyComponent, children: []
    },
    {
        path: 'offices/:id', component: SinglePropertyComponent, children: []
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full',
    },
], { relativeLinkResolution: 'legacy' }),
    AppModule,
    BrowserTransferStateModule,
  ],
  entryComponents: [
    LoginModalComponent,
    SignUpModalComponent,
    CustomersOrdersModalComponent,
    ForgotPasswordComponent,
    PricingGuideComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    LoginRedirect,
    LandsService,
    UserService,
    DashboardService,
    GovernateService,
    DecimalPipe,
    dropdownLists,
    CustomersOrdersService,
    BlogService,
    WindowsSizeDetectionService,
    UserActionsService,
    WizardService,
    MembershipService,
    HaversineService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true,
    },
    { provide: 'windowObject', useValue: window},
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {
}
