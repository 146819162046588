import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot} from '@angular/router';
import {ViewEncapsulation} from '@angular/core';
// import {Observable} from 'rxjs/Observable';

import 'rxjs/add/observable/combineLatest';
import {ToastrService} from 'ngx-toastr';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

import {LandsService} from '../../../_services/lands.service';
import {UserActionsService} from '../../../_services/user-actions.service';
import {DecimalPipe} from '@angular/common';
import {GovernateService} from '../../../_services/governate.service';
import {dropdownLists} from '../../../_services/dropdown-lists.service';

import {TranslateService} from '@ngx-translate/core';

import {MapsComponent} from '../maps/maps.component';
import {HostListener, Inject} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from './../../../_services/auth.service';

import {SeoService} from './../../../_services/seo.service';

import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginModalComponent} from '../../includes/login-modal/login-modal.component';
import {WINDOW} from '@ng-toolkit/universal';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None

})


export class SearchComponent implements OnInit {

  @ViewChild('SearchMap', {static: true}) public searchMap: MapsComponent;


  displayMode: string;
  // displayMode:string = 'grid';
  public isGrid: boolean = true;
  public isList: boolean = false;
  mobile: boolean = false;
  mapOn: any;
  markersArray: any;
  gridCol: any;
  urlparams: any;
  lands: any;
   landsTest$: any;
  showDestination: boolean = false;

  type: string;
  selectedName: string;
  locationSearchText: string;
  selectedPropertyType: string;
  selectedRoomsCount: number;
  roomList: any;

  selectedPropertyStatus: string;
  selectedRangeArea: any;

  /***
   * Price Range
   * @type {number}
   */
  priceFrom: number = 0;
  selectedPriceFrom: number;
  priceTo: number = 1000000;
  selectedPriceTo: number;
  selectedRangePrice: string;
  public priceRange: number[] = [0, 1000000];

  /**
   * Area Range
   * @type {number}
   */
  areaFrom: any = 0;
  selectedAreaFrom: any;
  areaTo: any = 10000;
  selectedAreaTo: any;
  public areaRange: number[] = [0, 10000];
  lang: string = '';

  /**
   * location Dropdowns
   */
  selectedGovId: number;
  selectedGovString: string;

  selectedTownId: number;
  selectedTownString: string;

  selectedAreaId: number;
  selectedAreaString: string;

  sortingBy: string;
  selectedSortingBy: string;

  governateList: any = [];
  townList: any = [];
  areaList: any = [];
  propertyStatusList: any = [];
  loading: boolean = true;
  unSaveButton: boolean = false;
  queryParameters: any = [];

  pageDisabled: boolean = false;
  noResultDisplay: boolean = false;

  propertyForList: any = [

    {
      id: 'for_sell',
      ar_name: 'للبيع',
      en_name: 'For Sell',
    },
    {
      id: 'for_rent',
      ar_name: 'للايجار',
      en_name: 'For Rent',
    }
  ];
  offerChecked: any;

  page: number = 1;
  currentPage: any = 1;
  totalItems: number;
  itemsPerPage: number = 12;
  saveToFavorite: string = 'addLandSuccess';
  unsaveToFavorite: string = 'addLandSuccess';

  landSaveStatus: any = '';
  filtersDropdownToggle: any = false;


  // Declare height and width variables
  scrHeight: any;
  scrWidth: any;
  // isLoggedIn:boolean;

  isLoggedIn: boolean = true;
  propertyTypesList = ['lands', 'buildings', 'villas_houses', 'stores', 'offices', 'apartments'];

  /**
   *  price list
   */

  priceList = [];
  selectedMinPrice: number = null;
  selectedMaxPrice: number = null;
  /**
   *  area list
   */

  propertyAreaList = [];
  selectedMinPropertyArea: number = null;
  selectedMaxPropertyArea: number = null;
  filterIsAdvanced: boolean = true;

  propertiesCounts: any;
  locationSelected = false;
  priceSelected = false;
  areaSelected = false;
  filterParametersCount = 0;

  constructor(@Inject(WINDOW) private window: Window, public http: HttpClient,
              private landService: LandsService,
              private route: ActivatedRoute,
              private router: Router,
              private seo: SeoService,
              public  translate: TranslateService,
              private DecimalPipe: DecimalPipe,
              private GovernateService: GovernateService,
              private toastr: ToastrService,
              private UserActionsService: UserActionsService,
              private activeRoute: ActivatedRoute,
              private cookieService: CookieService,
              private auth: AuthService,
              private modalService: NgbModal,
              public Lists: dropdownLists,
              config: NgbDropdownConfig) {


    config.autoClose = 'outside';
    let lang = translate.currentLang;
    this.lang = lang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }
    translate.get('Location').subscribe((res: string) => {
      this.locationSearchText = res;
    });

    translate.get('saveToFavorite').subscribe((res: string) => {
      this.saveToFavorite = res;
    });
    translate.get('unsaveToFavorite').subscribe((res: string) => {
      this.unsaveToFavorite = res;
    });

  }

  ngOnInit() {

    /**
     * Handling Property Types
     */
    this.seo.setTags();
    this.route.params.subscribe(params => {
      this.type = params['type'];
      const is_allow = this.propertyTypesList.includes(this.type);

      if (is_allow === false) {
        this.router.navigateByUrl('/');
      }
      if (this.type === 'lands') {
        this.filterIsAdvanced = false;
      } else {
        this.filterIsAdvanced = true;
      }
    });

    /**
     * cataloge view mode
     */
    this.isLoggedIn = this.auth.isAuthenticated();

    this.cookiesVaribale();
    this.propertyAreaList = this.Lists.getAreaPropertyList();
    this.priceList = this.Lists.getPriceToBuy();
    this.propertyStatusList = this.Lists.getPropertyStatusList();
    this.roomList = this.Lists.getRoomList();


    this.handlingQueryParameters();

    this.filter();

    this.getScreenSize();

    this.getPropertiesCount();

  }


  getPropertiesCount() {
    this.landService.getPropertiesCount().subscribe((res: any) => {
      if (res.success) {
        this.propertiesCounts = (res.data);

      }
    });

  }


  cookiesVaribale() {
    const cookieDisplayModeExists: boolean = this.cookieService.check('displayMode');
    if (cookieDisplayModeExists) {
      this.displayMode = this.cookieService.get('displayMode');
      if (this.displayMode == 'grid') {
        this.isList = false;
        this.isGrid = true;
      } else {
        this.isList = true;
        this.isGrid = false;
      }
    } else {
      this.cookieService.set('displayMode', 'grid');
      this.displayMode = 'grid';
      this.isList = true;
      this.isGrid = false;
    }

    const cookieMapOnExists: boolean = this.cookieService.check('mapOn');
    if (cookieDisplayModeExists) {
      const mapOnTxt = this.cookieService.get('mapOn');
      this.mapOn = (mapOnTxt === 'true');

      if (this.mapOn === false) {
        this.gridCol = 'col-md-4 col-sm-6 col-xs-6';
      } else {
        this.gridCol = 'col-md-6 col-sm-6 col-xs-6';
      }
    } else {
      this.mapOn = true;
      this.cookieService.set('mapOn', 'true');
      this.gridCol = 'col-md-6 col-sm-6 col-xs-6';

    }


  }


  handlingQueryParameters() {


    /*price*/
    this.route.queryParams.subscribe((params: any) => {
      // this.currentPage = params['page'];
      // console.log('this.currentPage:', this.currentPage);
      this.filterParametersCount = Object.keys(params).length;
    });

    const page = this.route.snapshot.queryParams['page'];
    if (page != undefined) {
      this.currentPage = Number(page);

    }


    const priceFrom = this.route.snapshot.queryParams['priceFrom'];
    if (priceFrom != undefined) {
      this.selectedMinPrice = Number(priceFrom);
    }

    const priceTo = this.route.snapshot.queryParams['priceTo'];
    // tslint:disable-next-line:triple-equals
    if (priceTo != undefined) {
      this.selectedMaxPrice = Number(priceTo);
    }

    let areaFrom = this.route.snapshot.queryParams['areaFrom'];
    if (areaFrom != undefined) {
      this.selectedMinPropertyArea = Number(areaFrom);
    }

    let areaTo = this.route.snapshot.queryParams['areaTo'];
    if (areaTo != undefined) {
      this.selectedMaxPropertyArea = Number(areaFrom);
    }

    let offer = this.route.snapshot.queryParams['offer'];
    if (offer != undefined) {
      this.offerChecked = offer;
    }
    let propertyStatus = this.route.snapshot.queryParams['property_status'];
    if (propertyStatus != undefined) {
      this.selectedPropertyStatus = propertyStatus;
    }

    let rooms = this.route.snapshot.queryParams['rooms'];
    if (rooms != undefined) {
      this.selectedRoomsCount = Number(rooms);
    }
    let sortingBy = this.route.snapshot.queryParams['sorting_by'];
    if (sortingBy != undefined) {
      this.selectedSortingBy = sortingBy;
    }

    /**
     * Government
     */
    this.selectedGovId = (this.route.snapshot.queryParams['gov'] == null ? this.selectedGovId : this.route.snapshot.queryParams['gov']);
    this
      .GovernateService
      .getGovernate()
      .subscribe(res => {
        this.governateList = res['data'];
        if (this.selectedGovId != null) {
          this.selectedGovString = this.governateList[this.selectedGovId - 1][this.selectedName];
          let gov = this.governateList[this.selectedGovId - 1];
          this.getGovernate(gov);
        }
      });

    /**
     * Town
     */
    this.selectedTownId = (this.route.snapshot.queryParams['town'] == null ? this.selectedTownId : this.route.snapshot.queryParams['town']);
    this
      .GovernateService
      .getTowns(this.selectedGovId)
      .subscribe(res => {
        this.townList = res['data'];
      });

    /**
     * Area
     */
    this.selectedAreaId = (this.route.snapshot.queryParams['area'] == null ? this.selectedAreaId : this.route.snapshot.queryParams['area']);
    this
      .GovernateService
      .getArea(this.selectedGovId, this.selectedTownId)
      .subscribe(res => {
        this.areaList = res['data'];
      });


  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = this.window.innerHeight;
    this.scrWidth = this.window.innerWidth;
    if (this.scrWidth <= 798) {
      this.mobile = true;
      this.mapOn = false;
    } else {
      this.mobile = false;
    }
  }


  /****
   * functions
   * */


  getSortingBy(value: any) {
    this.sortingBy = value;
    this.selectedSortingBy = value;
  }


  getGovernate($event) {
    if ($event != undefined) {
      this.selectedGovId = $event['id'];
      this.selectedTownId = null;
      this.selectedTownString = null;
      this.selectedAreaId = null;
      this.selectedAreaString = null;
      this.selectedGovString = $event[this.selectedName];
      this.getTownList(this.selectedGovId);
      this.updateLocationSearchText();
    }

  }

  getTownList(govId) {
    if (govId != undefined) {
      this
        .GovernateService
        .getTowns(govId)
        .subscribe(res => {
          this.townList = res['data'];
          let selectedTownId = (this.route.snapshot.queryParams['town'] == null ? this.selectedTownId : this.route.snapshot.queryParams['town']);
          this.selectedTownId = selectedTownId;
          if (selectedTownId != null) {
            for (let value of res['data']) {
              if (value['id'] == selectedTownId) {
                this.getTown(value);
              }
            }
          }
        });
    }
  }

  getTown($event) {
    if ($event != undefined) {
      this.selectedTownId = $event['id'];
      this.selectedTownString = $event[this.selectedName];
      this.selectedAreaId = null;
      this.selectedAreaString = null;
      this.getAreaList(this.selectedGovId, this.selectedTownId);
      this.updateLocationSearchText();
    }
  }

  onClearGov() {
    this.selectedTownId = null;
    this.selectedTownString = null;
    this.areaList = [];
    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.selectedGovString = null;
    this.selectedGovId = null;
    this.translate.get('Location').subscribe((res: string) => {
      this.locationSearchText = res;
    });
    this.updateLocationSearchText();

  }


  onClearTown() {
    this.selectedTownId = null;
    this.selectedTownString = null;
    this.areaList = [];
    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.updateLocationSearchText();
  }

  getArea($event) {
    this.selectedAreaId = $event['id'];
    this.selectedAreaString = $event[this.selectedName];
    this.updateLocationSearchText();
  }

  onClearArea() {
    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.updateLocationSearchText();

  }


  getAreaList(govId, townId) {
    this
      .GovernateService
      .getArea(govId, townId)
      .subscribe(res => {
        let selectedAreaId = (this.route.snapshot.queryParams['area'] == null ? this.selectedAreaId : this.route.snapshot.queryParams['area']);
        this.areaList = res['data'];
        this.selectedAreaId = selectedAreaId;
        if (selectedAreaId != null) {
          for (let value of res['data']) {
            if (value['id'] == selectedAreaId) {
              this.getArea(value);
            }
          }
        }
      });
  }


  updateLocationSearchText() {
    let locationString = (this.selectedGovString == null ? '' : this.selectedGovString) + (this.selectedTownString == null ? '' : ',' + this.selectedTownString) + (this.selectedAreaString == null ? '' : ', ' + this.selectedAreaString);
    if (locationString == null || locationString == '') {
      this.translate.get('Location').subscribe((res: string) => {
        this.locationSearchText = res;
        this.locationSelected = false;

      });

    } else {
      this.locationSearchText = locationString;
      this.locationSelected = true;

    }
  }


  pageLandChanged($event) {

    this.currentPage = $event;
    this.page = $event;
    this.filter($event);
    this.loading = true;

    this.showLimitationScreen();


  }


  filter(pagainationPage?: number) {

    if (pagainationPage == undefined) {
      this.currentPage = 1;
    } else {
      this.currentPage = pagainationPage;
    }


    if (this.mobile) {
      // this.filtersToggle();
    }


    if (this.selectedMaxPrice != null || this.selectedMinPrice != null) {
      this.priceSelected = true;
    } else {
      this.priceSelected = false;
    }

    if (this.selectedMinPropertyArea != null || this.selectedMaxPropertyArea != null) {
      this.areaSelected = true;
    } else {
      this.areaSelected = false;
    }


    this.router.navigate(['/search/' + this.type],
      {
        queryParams: {
          gov: this.selectedGovId,
          town: this.selectedTownId,
          area: this.selectedAreaId,
          property_status: this.selectedPropertyStatus,
          rooms: this.selectedRoomsCount,
          priceFrom: this.selectedMinPrice,
          priceTo: this.selectedMaxPrice,
          areaFrom: this.selectedMinPropertyArea,
          areaTo: this.selectedMaxPropertyArea,
          offer: this.offerChecked,
          page: this.currentPage,
          sorting_by: this.selectedSortingBy
        }
      }
    );
    this.getProperties();
  }


  getProperties() {

    this.loading = true;
    this.queryParameters = [];

    const page = this.currentPage;
    if (page != null) {
      this.queryParameters['page'] = page;
      this.showLimitationScreen();

    }
    if (this.selectedSortingBy != null) {
      this.queryParameters['sorting_by'] = this.selectedSortingBy;
    }

    if (this.selectedGovId != null) {
      this.queryParameters['gov'] = this.selectedGovId;
    }

    if (this.selectedTownId != null) {
      this.queryParameters['town'] = this.selectedTownId;
    }

    if (this.selectedAreaId != null) {
      this.queryParameters['area'] = this.selectedAreaId;
    }

    if (this.selectedPropertyStatus != null) {
      this.queryParameters['property_status'] = this.selectedPropertyStatus;
    }
    if (this.selectedMinPrice != null) {
      this.queryParameters['priceFrom'] = this.selectedMinPrice;
    }
    if (this.selectedMaxPrice != null) {
      this.queryParameters['priceTo'] = this.selectedMaxPrice;
    }

    if (this.selectedMinPropertyArea != null) {
      this.queryParameters['areaFrom'] = this.selectedMinPropertyArea;
    }

    if (this.selectedMaxPropertyArea != null) {
      this.queryParameters['areaTo'] = this.selectedMaxPropertyArea;
    }
    if (this.offerChecked != null) {
      this.queryParameters['offer'] = this.offerChecked;
    }

    if (this.selectedRoomsCount != null) {
      this.queryParameters['rooms'] = this.selectedRoomsCount;
    }


    this
      .landService
      .getProperties(this.queryParameters, this.type)
      .subscribe((res: any) => {

        this.landsTest$ = res;
        const MapsArray = this.landsTest$.items;
        this.totalItems = res.pagination.total_count;
        if (res.pagination.total_count === 0) {
          this.noResultDisplay = true;
        } else {
          this.noResultDisplay = false;
        }

        this.window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        let other = []; // your other array...


        MapsArray.map(item => {

          if (item['lat'] !== '' || item['lat'] == 'undefined') {
            return {
              lat: item['lat'],
              lng: item['lng'],
              ar_title: item['ar_title'],
              en_title: item['en_title'],
              propertyType: this.type,
              ar_governmentName: item['ar_governmentName'],
              en_governmentName: item['en_governmentName'],
              ar_cityName: item['ar_cityName'],
              en_cityName: item['en_cityName'],
              en_zoneName: item['en_zoneName'],
              ar_zoneName: item['ar_zoneName'],
              mainImage: item['mainImage'],
              id: item['id'],
              price: item['price'],
              label: {fontSize: '12x', color: '#fff', text: ('$' + this.abbreviateNumber(item['price'])).toString()}
            };
          }

        }).forEach(
          item => other.push(item)
        );
        this.markersArray = (other);
        this.loading = false;
      });

  }


  showLimitationScreen() {

    if (this.currentPage >= 2 && !this.isLoggedIn) {
      // this.pageDisabled = true;
    }


  }


  clearFilter() {

    this.router.navigate(['/search/' + this.type],
      {
        queryParams: {
          gov: this.selectedGovId = null,
          town: this.selectedTownId = null,
          area: this.selectedAreaId = null,
          property_status: this.selectedPropertyStatus = null,
          rooms: this.selectedRoomsCount = null,
          priceFrom: this.selectedMinPrice = null,
          priceTo: this.selectedMaxPrice = null,
          areaFrom: this.selectedMinPropertyArea = null,
          areaTo: this.selectedMaxPropertyArea = null,
          offer: this.offerChecked = null,
          page: this.currentPage = null,
          sorting_by: this.selectedSortingBy = null
        }
      }
    );
    this.filter(this.currentPage);

  }

  changeDisplayMode($event) {
    this.displayMode = $event;
    if ($event == 'grid') {
      this.cookieService.set('displayMode', 'grid');
      this.isGrid = true;
      this.isList = false;
    } else {
      this.cookieService.set('displayMode', 'list');
      this.isList = true;
      this.isGrid = false;
    }
  }

  mapToggle() {
    if (this.mapOn == false) {
      this.mapOn = true;
      this.cookieService.set('mapOn', 'true');
      this.gridCol = 'col-md-6 col-sm-6 col-xs-6';

    } else {
      this.cookieService.set('mapOn', 'false');
      this.mapOn = false;
      this.gridCol = 'col-md-4 col-sm-6 col-xs-6';
    }
  }


  setPropertyFor($event) {
    if ($event == undefined) {
      this.offerChecked = null;
      this.filter();
    } else {
      this.offerChecked = $event['id'];
      this.filter();
    }

  }

  changePropertyType($event) {
    this.type = $event;
    // this.router.navigate(['/search/' + this.type]);
    this.filter(1);

  }

  getPropertyStatus($event) {
    if ($event == undefined) {
      this.selectedPropertyStatus = null;
      this.filter();
    } else {
      this.selectedPropertyStatus = $event['id'];
      this.filter();
    }
  }

  getRoomsCounts($event) {
    this.filter();
    if ($event == undefined) {
      this.selectedRoomsCount = null;
      this.filter();
    } else {
      this.selectedRoomsCount = $event['id'];
      this.filter();
    }
  }

  getPropertyType($event) {

    this.selectedPropertyType = $event['id'];
    this.filterIsAdvanced = $event['advanced'];
  }

  getMinPrice($event) {
    if ($event == undefined) {
      this.selectedMinPrice = null;
      this.filter();
    } else {
      this.selectedMinPrice = $event['id'];
      this.filter();
    }

  }

  getMaxPrice($event) {
    if ($event == undefined) {
      this.selectedMaxPrice = null;
      this.filter();
    } else {
      this.selectedMaxPrice = $event['id'];
      this.filter();
    }

  }

  getMinPropertyArea($event) {
    if ($event == undefined) {
      this.selectedMinPropertyArea = null;
      this.filter();
    } else {
      this.selectedMinPropertyArea = $event['id'];
      this.filter();
    }

  }

  getMaxPropertyArea($event) {
    if ($event == undefined) {
      this.selectedMaxPropertyArea = null;
      this.filter();
    } else {
      this.selectedMaxPropertyArea = $event['id'];
      this.filter();
    }

  }


  filtersToggle() {

    if (this.filtersDropdownToggle == true) {
      this.filtersDropdownToggle = false;
    } else {
      this.filtersDropdownToggle = true;
    }
  }


  saveLandAction(event) {
    let id = event[0];
    let type = event[1];
    if (this.isLoggedIn) {
      this.UserActionsService
        .saveLand(id, this.type)
        .subscribe((res: any) => {
          let tr = res.msg;
          this.translate.get(tr).subscribe((res: string) => {
            const landSaveStatus = res;
            this.toastr.success(landSaveStatus);
          });
        });
    } else {
      const modalRef = this.modalService.open(LoginModalComponent);
    }
  }


  showLoginModal() {
    const modalRef = this.modalService.open(LoginModalComponent);

  }


  abbreviateNumber(number) {
    var SI_PREFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    // what tier? (determines SI prefix)
    var tier = Math.log10(number) / 3 | 0;
    // if zero, we don't need a prefix
    if (tier == 0) {
      return number;
    }
    // get prefix and determine scale
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = number / scale;
    // format number and add prefix as suffix
    return scaled.toFixed(1) + prefix;
  }

}


// tslint:disable-next-line:class-name
export interface landsTest$<T> {
  items: any;
  data: any;
}
