import {Component, OnInit} from '@angular/core';
import {UserActionsService} from '../../../_services/user-actions.service';
import {HttpClient} from '@angular/common/http';

import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {CustomersOrdersModalComponent} from  '../../customers-orders-modal/customers-orders-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-saved-properties',
  templateUrl: './saved-properties.component.html',
  styleUrls: ['./saved-properties.component.scss']
})
export class SavedPropertiesComponent implements OnInit {


  savedLands:any;
  savedBuildings:any;
  savedUnits:any;

  displayMode:string = 'grid';
  gridCol:string = 'col-md-4';
  page:any = 1;
  currentPage:any = 1;
 
  itemsPerPage:number = 100;
  lang:string = '';
  selectedName:any = '';
  public isGrid:boolean = true;
  loading:boolean = true;
  buildingLoading:boolean = true;
  unitsLoading:boolean = true;
  unSaveButton:boolean = true;
  propertyTypeLand:string = "lands";
  propertyTypeBuilding:string = "buildings";
  propertyTypeUnits:string = "units";

  totalLandsItems:any=0;
  totalBuildingsItems:any=0;
  totalUnitsItems:any=0;
  totalPropertiesCounts:any=0;


  constructor(public http:HttpClient,
              private UserActionsService:UserActionsService,
              public translate:TranslateService,
              private toastr:ToastrService,
              private modalService:NgbModal) {


    let lang = translate.currentLang;
    this.lang = lang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }
    this.totalPropertiesCounts=this.totalLandsItems+this.totalBuildingsItems+this.totalUnitsItems;

  }

  ngOnInit() {
    this.getSavedLands();
    this.getSavedBuildings();
    this.getSavedUnits();
  }


  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
  }
  


  getSavedUnits(){

    this.UserActionsService
      .gedSavedUnits()
      .subscribe((res:any)=> {
        this.savedUnits = res;
        this.totalUnitsItems = this.savedUnits.pagination.total_count;
        this.unitsLoading = false;
        this.totalPropertiesCounts=this.totalPropertiesCounts + this.totalUnitsItems;

      })
  }
  getSavedBuildings() {
    this.UserActionsService
      .getSavedBuildings()
      .subscribe((res:any)=> {
        this.savedBuildings = res;
        this.totalBuildingsItems = this.savedBuildings.pagination.total_count;
        this.buildingLoading = false;
        this.totalPropertiesCounts=this.totalPropertiesCounts + this.totalBuildingsItems;

      });
  }

  getSavedLands() {
    this.UserActionsService
      .getSavedLands()
      .subscribe((res:any)=> {
        this.savedLands = res;
        this.totalLandsItems = this.savedLands.pagination.total_count;
        this.loading = false;
        this.totalPropertiesCounts=this.totalPropertiesCounts + this.totalLandsItems;

      });
  }


  pageLandChanged($event) {

    this.currentPage = $event;
    this.page = $event;
    // this.filter();
    this.loading = true;
  }

  saveLandAction(event) {
    let id = event[0];
    let type = event[1];
    this.UserActionsService.saveLand(id, type).subscribe((res:any)=> {
      this.translate.get('ThePropertyHasBeenSuccessfullyRemoved').subscribe((res:string) => {
        let landSaveStatus = res;
        this.toastr.success(landSaveStatus);
      });

      this.getSavedLands();
    });

  }


}
