export class propertyRequest {
  fullName:string;
  email:string;
  mobile:string;
  notes:string;
  propertyFor:string;
  propertyType:string;
  propertyArea:any;
  blockNumber:number;
  sectorNumber:number;
  budget:number;
  parcelNumber:number;
  government:number;
  city:number;
  areaZone:number;
  requestType:any;


}
