import {Injectable} from '@angular/core';
import {IOption} from '../models/options.interface';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  constructor() {
  }

  generateIOption(items: any[], valueKey: string, labelKey: string, iconKey?: string) {
    let newItems: IOption[] = [];
    if (items) {
      newItems = items.map(i => {
        return {
          label: i[labelKey],
          value: i[valueKey],
          selected: false,
          icon: i[iconKey],
          data: i,
        } as IOption;
      });
    }

    return newItems;
  }

}
