<div class="knowledge-nav">
  <nav class="navbar navbar-expand-lg ">
    <!--<button class="navbar-toggler" type="button" (click)="!!navToggle()">-->
      <!--<i class="fa fa-navicon"></i>-->
    <!--</button>-->

    <button class="navbar-toggler" type="button" (click)="navToggle()">
      <i class="fa fa-navicon"></i>
    </button>

    <div class="collapse navbar-collapse {{navListShow}}">

      <ul class="navbar-nav">
        <!--<li class="nav-fill">-->
          <!--<a routerLink="/knowledge-center/" class="nav-link" > {{'home'|translate}}</a>-->
        <!--</li>-->

        <li class="nav-fill">
          <a routerLink="/knowledge-center/realestate-palestine"  routerLinkActive="active" class="nav-link">
            {{'realestateGuide'|translate}}
          </a>
        </li>

        <li class="nav-fill"><a routerLink="/knowledge-center/guide"  class="nav-link" routerLinkActive="active">{{'tips and advice'|translate}}</a></li>
        <li class="nav-fill"><a routerLink="/knowledge-center/my-aqar"  class="nav-link" routerLinkActive="active">MYAQAR {{'account'|translate}}</a></li>

        <!--<li class="nav-fill"><a routerLink="/knowledge-center/tips" class="nav-link" routerLinkActive="active">{{'tips'|translate}}</a></li>-->

        <!--<li class="nav-fill"><a routerLink="/knowledge-center/news" routerLinkActive="active"  class="nav-link"> {{'news'|translate}}</a></li>-->
      </ul>
    </div>
  </nav>
</div>
