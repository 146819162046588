<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'rentContract'|translate}}
        </h1>
      </div>
      <div class="action">
        <a class="btn btn--primary " routerLink="/dashboard/contracts/add-contract">{{'addNewContract'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details ">

      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/contracts">{{'rentContract'|translate}}</a></li>
          <li><a routerLink="/dashboard/contracts/list" class="active">{{'allContracts'|translate}}</a></li>
        </ul>
      </div>
      <div class=" mt-4">
        <div class="row">
          <div class="col">



            <div class="card shadow">




              <table class="table table-borderless  table-hover text-center">
                <thead class="">
                <tr>
                  <th scope="col">{{'contractId'|translate}}</th>
                  <th scope="col">{{'unit'|translate}}</th>
                  <th scope="col">{{'renter'|translate}}</th>
                  <th scope="col">{{'rentStartDate'|translate}}</th>
                  <th scope="col">{{'rentEndDate'|translate}}</th>
                  <th scope="col">{{'contractPeriod'|translate}}</th>
                  <!--<th scope="col">{{'monthlyPayment'|translate}}</th>-->
                  <th scope="col">{{'contractStatus'|translate}}

                  </th>
                </tr>
                </thead>
                <tbody>


                <tr *ngFor="let  contract of contractsList">
                  <th scope="row">
                    <a routerLink="/dashboard/contracts/{{contract.contract_number}}">{{contract.contract_number}}</a>
                  </th>
                  <td>{{contract.unit_id}}</td>
                  <td>{{contract.renter_name}}</td>
                  <td>{{contract.contract_start_date}}</td>
                  <td>{{contract.contract_end_date}}</td>
                  <td>{{contract.duration }}  {{'month'|translate}}</td>
                  <!--<td>12 {{'month'|translate}}</td>-->
                  <td>{{contract.contract_monthly_payment}}</td>
                  <td>
                    <span
                      class="badge badge-pill  text-white p-2"
                      [ngClass]="{
                      'badge-success':contract?.contract_status_name === 'active',
                      'badge-primary':contract.contract_status_name === 'canceled',
                      'badge-warning':contract.contract_status_name === 'expired',
                      'badge-danger':contract.contract_status_name  === 'end_soon'
                      }"
                    >
                      {{contract.contract_status_name|translate}}
                    </span>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
            <div class="w-100 text-center">
              <span class="spinner text-center" [ngClass]="{ 'hidden': ready }"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
