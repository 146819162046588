<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'customers'|translate}}
        </h1>
      </div>
      <div class="action">
        <a class="btn btn--primary " routerLink="/dashboard/customers/create"> + {{'addCustomer'|translate}} </a>
      </div>
    </div>
    <div class="dashboard-details ">

      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/customers" class="active">{{'customers'|translate}}</a></li>
        </ul>
      </div>


      <div class="dashboard-table-details mt-2">


        <div class="search-bar">


          <form [formGroup]="customerSearchForm" (ngSubmit)="search()">

            <div class="row">
              <div class="col-md-3">

                  <input
                    type="text"
                    class="form-control"
                    formControlName="full_name"
                    placeholder="{{'name'|translate}}"
                  >

              </div>

              <div class="col-md-3">

                  <input
                    type="text"
                    class="form-control"
                    formControlName="mobile_number"
                    placeholder="{{'mobileNumber'|translate}}"
                  >

              </div>


              <div class="col-md-3">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control "
                    formControlName="id_number"
                    placeholder="{{'idNumber'|translate}}"
                  >
                </div>
              </div>


              <div class="col-md-3">
                <button class="btn btn--primary" >{{'search'|translate}}
                </button>
              </div>


            </div>


          </form>

        </div>
        <div class="table-responsive mt-2">
          <table class="table table--paginate bg-white ">
            <thead>
            <tr>
              <th scope="col" style="width: 3%">#</th>
              <th scope="col" style="width: 25%">{{'customerName'|translate}}</th>
              <!--<th scope="col">{{'customerType'|translate}}</th>-->
              <th scope="col">{{'idNumber'|translate}}</th>
              <th scope="col">{{'mobileNumber'|translate}}</th>
              <th>


              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              *ngFor="let item of customers   | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems:totalItems,id:'grid_pagination' };let i=index"
              class="{{gridCol}}" id="myElement-{{item?.id}}"
            >
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.full_name}}</td>
              <!--<td></td>-->
              <td>{{item.id_number}}</td>
              <td>{{item.mobile_number}}</td>
              <td>
                <div class="btn-actions pull-right">
                  <a [routerLink]="['/dashboard/customers/'+item?.user_id]"
                     class="btn btn btn--light--default btn--small"><span class="fa fa-cog"></span>
                    {{'show'|translate}}
                  </a>
                </div>

              </td>
            </tr>
            <tr *ngIf="customers.length<1">
              <td colspan="5" class="text-center">
                {{'no_customer_found'|translate}}
                <a class="text--primary " routerLink="/dashboard/customers/create"> + {{'addCustomer'|translate}} </a>

              </td>
            </tr>

            </tbody>
          </table>
          <div class="pagination text-center">
            <div class="spinner text-center" [ngClass]="{ 'hidden': !loading }"></div>
            <pagination-controls
              (pageChange)="pageChanged($event)"
              previousLabel="<"
              nextLabel=">"
              directionLinks="true"
              id="grid_pagination"
              maxSize="20"
              autoHide="true"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<layout-footer></layout-footer>
