<app-top-header></app-top-header>
<section class="inner mb-5 mt-2 ">
  <div class="container mt-5">
    <h1> {{'add'|translate}} {{propertyType|translate}} </h1>
    <div class="wizard {{navBarLocation}}">
      <div class="wizard-navigation">
        <ul class="steps-indicator">
          <li><a>1</a></li>
          <li><a class="active" routerLink="/dashboard/add-properties/{{propertyType}}">2</a></li>
          <li><a>3</a></li>

        </ul>
      </div>


      <div class="wizard-content">

        <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()" class="add-property-form">

          <div class="row">

            <div class="col-md-8">
              <div class="form-data">
                <p class="step-text">{{'step 2'|translate}}</p>

                <h2>{{'Add Your Property Details'|translate}}</h2>

                <div class="row">

                  <div class="col-md-8">
                    <div class="form-group">
                      <label class="mb-1">{{'propertiesBrief'|translate}} </label>
                      <input type="text" class="form-control form-control-sm" formControlName="title"
                             placeholder="{{'briefExample'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="f.title?.errors && (f.title?.dirty || f.title?.touched)">
                        <p *ngIf="f.title?.errors?.required">{{'validation.required'|translate}}</p>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label class="d-flex">{{'offer'|translate}}</label>

                      <label class="radio-container d-inline-block"> {{'forSale'|translate}}
                        <input type="radio" formControlName="offer" value="for_sell" checked="checked">
                        <span class="checkmark"></span>
                      </label>

                      <label class="radio-container d-inline-block"> {{'forRent'|translate}}
                        <input type="radio" formControlName="offer" value="for_rent">
                        <span class="checkmark"></span>
                      </label>

                    </div>
                  </div>
                </div>

                <div class="row ">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="mb-1">{{'price'|translate}} </label>
                      <div class="input--group">
                        <input type="number"
                               class="form-control form-control-sm"
                               formControlName="price"
                               placeholder="{{'price'|translate}}"

                        >
                        <div class="input--group--append">
                          <span>$</span>
                        </div>
                      </div>
                      <div class="form-control-feedback text-left"
                           *ngIf="f.price?.errors && (f.price?.dirty || f.price?.touched)">
                        <p *ngIf="f.price?.errors?.required">{{'validation.priceRequired'|translate}}</p>
                        <p *ngIf="f.price?.invalid && !f.price?.errors?.required ">
                          {{'validation.invalidPrice'|translate}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="mb-1">{{'area'|translate}} </label>
                      <div class="input--group">
                        <input type="number" class="form-control form-control-sm" formControlName="area"
                               placeholder="{{'area'|translate}}">
                        <div class="input--group--append">
                          <span>m²</span>
                        </div>
                      </div>

                      <div class="form-control-feedback text-left"
                           *ngIf="f.area?.errors && (f.area?.dirty || f.area?.touched)">
                        <p *ngIf="f.area?.errors?.required">{{'validation.required'|translate}}</p>
                        <p *ngIf="f.area?.invalid && !f.area?.errors?.required ">
                          {{'validation.invalidArea'|translate}}</p>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group {{unitFieldsDisplay}}">
                      <label class="d-flex">{{'propertyStatus'|translate}}</label>

                      <label class="radio-container d-inline-block"> {{'unfurnished'|translate}}
                        <input type="radio" formControlName="status" value="unfurnished" checked="checked">
                        <span class="checkmark"></span>
                      </label>

                      <label class="radio-container d-inline-block"> {{'furnished'|translate}}
                        <input type="radio" formControlName="status" value="furnished">
                        <span class="checkmark"></span>
                      </label>
                      <label class="radio-container d-inline-block"> {{'bone'|translate}}
                        <input type="radio" formControlName="status" value="bone">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>


                <div class="row {{unitFieldsDisplay}}">
                  <div class="col-6 col-sm-4">
                    <div class="form-group">
                      <label class="mb-1">{{'roomsNumber'|translate}} </label>
                      <input type="number" class="form-control form-control-sm" formControlName="roomsNumber"
                             placeholder="{{'roomsNumber'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="f.roomsNumber.errors && (f.roomsNumber.dirty || f.roomsNumber.touched)">
                        <p *ngIf="f.roomsNumber.errors.required">{{'validation.required'|translate}}</p>
                        <p *ngIf="f.roomsNumber.invalid && !f.roomsNumber.errors.required  ">
                          {{'validation.roomsNumber'|translate}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-sm-4">
                    <div class="form-group">
                      <label class="mb-1">{{'bathroomsNumber'|translate}} </label>
                      <input type="number" class="form-control form-control-sm" formControlName="bathroomsNumber"
                             placeholder="{{'bathroomsNumber'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="f.bathroomsNumber.errors && (f.bathroomsNumber.dirty || f.bathroomsNumber.touched)">
                        <p *ngIf="f.bathroomsNumber.errors.required">{{'validation.required'|translate}}</p>
                        <p *ngIf="f.bathroomsNumber.invalid && !f.bathroomsNumber.errors.required  ">
                          {{'validation.bathroomsNumber'|translate}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4">
                    <div class="form-group">
                      <label class="mb-1">{{'balconiesNumber'|translate}} </label>
                      <input type="number" class="form-control form-control-sm" formControlName="balconiesNumber"
                             placeholder="{{'balconiesNumber'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="f.balconiesNumber.errors && (f.balconiesNumber.dirty || f.balconiesNumber.touched)">
                        <p *ngIf="f.balconiesNumber.errors.required">{{'validation.required'|translate}}</p>
                        <p *ngIf="f.balconiesNumber.invalid && !f.balconiesNumber.errors.required  ">
                          {{'validation.balconiesNumber'|translate}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-12">
                    <div class="form-group">
                      <label class="mb-1 text-capitalize">{{'features'|translate}} </label>
                      <div class="wizard-option-nav">
                        <div class="form-check-inline mb-2" *ngFor="let feature of featuresList">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="{{feature.id}}"
                            value="{{feature.value}}"
                            (change)="onFeaturesCheckboxChange(feature,$event)"
                            [checked]="feature.isChecked"
                          >
                          <label class="form-check-label text-capitalize" for="{{feature.id}}">
                            {{lang == 'en' ?feature.en_name:feature.ar_name}}
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>


                <h2 class="mt-4"> {{'propertyRelations'|translate}}</h2>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label class="mb-1">{{'relationTypeForProperty'|translate}}</label>
                      <ng-select
                        formControlName="relationType"
                        [items]="relationTypeList"
                        bindLabel="{{selectedName}}"
                        searchable="true"
                        bindValue="value"
                        placeholder="{{'relationTypeListPlaceholder'|translate}}"
                        [(ngModel)]="selectedRelationTypeString"
                        [clearable]="false"
                        [loading]="relationTypeLoading"
                        (change)="relationTypeChange($event)"

                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col {{extraRelationDisplay}}">
                    <div class="form-group">
                      <label class="mb-1">{{'representativeType'|translate}}</label>
                      <ng-select
                        formControlName="representativeType"
                        [items]="representativeTypeList"
                        bindLabel="{{selectedName}}"
                        searchable="true"
                        bindValue="value"
                        placeholder="{{'representativeTypePlaceholder'|translate}}"
                        [(ngModel)]="selectedRepresentativeTypeString"
                        [clearable]="false"
                        [loading]="relationTypeLoading"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="{{extraRelationDisplay}}">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="mb-1">{{'brokerRelation'|translate}}</label>
                        <ng-select
                          formControlName="brokerRelation"
                          [items]="brokerRelationList"
                          bindLabel="{{selectedName}}"
                          searchable="true"
                          bindValue="value"
                          placeholder="{{'brokerRelationListPlaceholder'|translate}}"
                          [(ngModel)]="selectedBrokerRelationString"
                          [clearable]="false"
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="mb-1">{{'representativeAuthority'|translate}}</label>
                        <ng-select
                          formControlName="representativeAuthority"
                          [items]="representativeAuthorityList"
                          bindLabel="{{selectedName}}"
                          searchable="true"
                          bindValue="value"
                          placeholder="{{'representativeAuthorityPlaceholder'|translate}}"
                          [(ngModel)]="selectedRepresentativeAuthorityString"
                          [clearable]="false"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


                <div class="row">
                  <h2 class="mt-3">{{'propertyLocation'|translate}}</h2>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group mb-3">
                      <label class="mb-1">{{'Governate'|translate}} </label>
                      <ng-select
                        formControlName="governate"
                        [items]="governateList"
                        bindLabel="{{selectedName}}"
                        bindValue="id"
                        placeholder="{{'SelectGovernorate'|translate}}"
                        [(ngModel)]="selectedGovId"
                        [clearable]="false"
                        (change)="getGovernate($event)"
                        (click)="$event.stopPropagation(); "
                      >
                      </ng-select>
                      <div class="form-control-feedback text-left"
                           *ngIf="f.governate.errors && (f.governate.dirty || f.governate.touched)">
                        <p *ngIf="f.governate.errors.required">{{'validation.required'|translate}}</p>
                      </div>

                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1">{{'Town'|translate}}</label>
                      <ng-select
                        [items]="cityList"
                        formControlName="city"
                        bindLabel="{{selectedName}}"
                        required
                        bindValue="id"
                        placeholder="{{'selectCity'|translate}}"
                        [(ngModel)]="selectedCityId"
                        [clearable]="false"
                        (change)="getCity($event)"
                        [loading]="cityLoading"
                      >
                      </ng-select>

                      <div class="form-control-feedback text-left"
                           *ngIf="f.city.errors && (f.city.dirty || f.city.touched)">
                        <p *ngIf="f.city.errors.required">{{'validation.required'|translate}}</p>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col">
                    <div class="form-group mb-3">
                      <label class="mb-1 ">{{'Zone'|translate}} </label>
                      <ng-select
                        formControlName="areaZone"
                        [items]="areaList"
                        bindLabel="{{selectedName}}"
                        bindValue="id"
                        searchable="true"
                        placeholder="{{'SelectArea'|translate}}"
                        [(ngModel)]="selectedAreaId"
                        [clearable]="false"
                        (change)="getArea($event)"
                        [loading]="areaLoading"
                      >
                      </ng-select>

                      <div class="form-control-feedback text-left"
                           *ngIf="f.areaZone.errors && (f.areaZone.dirty || f.areaZone.touched)">
                        <p *ngIf="f.areaZone.errors.required">{{'validation.required'|translate}}</p>
                      </div>

                    </div>
                  </div>

                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1">{{'address'|translate}}</label>
                      <input type="text" class="form-control" placeholder="{{'nameOfAreaOrStreet'|translate}}" formControlName="address">
                    </div>

                  </div>

                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group {{landFieldsDisplay}}">
                      <label class="mb-1">{{'blockNumber'|translate}} </label>
                      <ng-select
                        formControlName="blockNumber"
                        [items]="blocksList"
                        bindLabel="{{selectedName}}"
                        searchable="true"
                        bindValue="id"
                        placeholder="{{'SelectBlock'|translate}}"
                        [(ngModel)]="selectedBlockId"
                        [clearable]="false"
                        (change)="getSectorList($event)"
                        [loading]="blockLoading"
                      >
                      </ng-select>
                      <div class="form-control-feedback text-left"
                           *ngIf="blockNumber?.errors && (blockNumber?.dirty || blockNumber?.touched)">
                        <p *ngIf="blockNumber?.errors?.required">{{'validation.blockNumber_Required'|translate}}</p>
                        <p *ngIf="blockNumber?.invalid">{{'validation.InvalidBlockNumber'|translate}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 {{sectorDisplay}}">
                    <div class="form-group">
                      <label class="mb-1">{{'sectorNumber'|translate}}</label>
                      <ng-select
                        formControlName="sectorNumber"
                        [items]="sectorList"
                        bindLabel="{{selectedName}}"
                        searchable="true"
                        bindValue="id"
                        placeholder="{{'SelectSector'|translate}}"
                        [(ngModel)]="selectedSectorId"
                        [clearable]="false"
                        [loading]="sectorLoading"
                      >
                      </ng-select>
                      <div class="form-control-feedback text-left"
                           *ngIf="sectorNumber?.errors && (sectorNumber?.dirty || sectorNumber?.touched)">
                        <p *ngIf="sectorNumber?.errors?.required">{{'validation.sectorNumber_Required'|translate}}</p>
                        <p *ngIf="sectorNumber?.invalid">{{'validation.InvalidSectorNumber'|translate}}</p>
                      </div>
                    </div>

                  </div>
                  <div class="col-6 {{landFieldsDisplay}} ">
                    <div class="form-group {{landFieldsDisplay}} ">
                      <label class="mb-1">{{'parcelNumber'|translate}} </label>

                      <input type="text" class="form-control " formControlName="parcelNumber"
                             placeholder="{{'parcelNumber'|translate}}">
                      <div class="form-control-feedback text-left"
                           *ngIf="parcelNumber?.errors && (parcelNumber?.dirty || parcelNumber?.touched)">
                        <p *ngIf="parcelNumber?.errors.required">{{'validation.parcelNumber_Required'|translate}}</p>
                        <p *ngIf="parcelNumber?.invalid">{{'validation.InvalidParcelNumber'|translate}}</p>
                      </div>
                    </div>
                  </div>
                </div>




              <div class="text-center">
                <div class="spinner text-center" [ngClass]="{ 'hidden': !submitLoading }"></div>
              </div>

              <div class="clearfix"></div>

            </div>
            <div class="col-md-4">
            </div>
          </div>

          <div class="actions">
            <div class="save-notes mb-2">
              <small class=" text-capitalize text--secondary "><i class="fa fa-info-circle"> </i>
                {{ 'please fill all required fields to enable save button'|translate }}
              </small>
              <br>
              <span class="text-danger">{{serverErrorMsg}}</span>
            </div>
            <button type="button" class="btn btn--light--default" routerLink="/dashboard/add-properties"> <
              {{'back'|translate}}
            </button>
            <button type="submit" class="btn btn--primary mr-1 ml-1">{{'next'|translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<layout-footer></layout-footer>
