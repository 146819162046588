import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

/**
 * services
 */
import {AuthService} from './../../../_services/auth.service';
import {UserService} from './../../../_services/user.service';
import {BlogService} from './../../../_services/blog.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ViewEncapsulation, Inject} from '@angular/core';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {SignUpModalComponent} from '../sign-up-modal/sign-up-modal.component';
import {PricingGuideComponent} from '../../pricing-guide/pricing-guide.component';
import {TranslateService} from '@ngx-translate/core';
import {WINDOW} from '@ng-toolkit/universal';


@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class TopHeaderComponent implements OnInit {


  navToggleClass: any = '';
  navListShow: any = false;
  username: string;
  avatar: string;
  fullName: string;
  currentLink: string;
  isLoggedIn: boolean;
  section: any;
  knowledgeCenterRouteActive: boolean = false;
  userData: any;
  services: any;
  servicesCategoryId: any = 21;
  lang: string;
  viewType: any;
  headerType: any;

  letterThumb: any = '';

  constructor(@Inject(WINDOW) private window: Window, private modalService: NgbModal,
              private auth: AuthService,
              private userService: UserService,
              public translate: TranslateService,
              private _router: Router,
              private blogService: BlogService,
              private activeRoute: ActivatedRoute) {


    this.lang = this.translate.currentLang;
    this.currentLink = this._router.url;
    console.log('currentLink', this.currentLink);


    if (this._router.url.includes('/dashboard')) {
      this.currentLink = 'dashboard';
      this.headerType = 'dashboard-header-top';
    } else if (this._router.url.includes('/home')) {
      this.headerType = 'home-header';
    } else {
      this.headerType = 'inner-header';
    }
    console.log(this.headerType);

    if (this._router.url.indexOf('/knowledge-center/') > -1) {
      this.knowledgeCenterRouteActive = true;
    } else {
      this.knowledgeCenterRouteActive = false;
    }

    this.isLoggedIn = this.auth.isAuthenticated();

    if (this.isLoggedIn) {
      this.fullName = this.userService.getFullName();
      this.avatar = this.userService.getAvatar();
      // this.letterThumb=this.fullName.charAt(0);

      if (this.fullName) {
        this.fullName = this.userService.getFullName();
        this.avatar = this.userService.getAvatar();
        // this.letterThumb=this.fullName.charAt(0);
        // }
        // this.userService.getUser().subscribe((res:any)=> {
        //   this.fullName = res.userInformation.fullName;
        //   this.avatar = res.userInformation.avatar;
        //   this.letterThumb = res.userInformation.fullName.charAt(0);
        //   console.log('letterThumb', this.letterThumb);
        //
        // })
      } else {
        this.userService.getUser().subscribe((res: any) => {
          this.fullName = res.userInformation.first_name;
          this.avatar = res.userInformation.avatar;
          console.log('letterThumb', this.letterThumb);
        });

      }
    }

    // if (this.isLoggedIn) {
    //   this.fullName = this.userService.getFullName();
    //   this.avatar = this.userService.getAvatar();
    //
    //   if (!this.fullName) {
    //     this.userService.getUser().subscribe((res:any)=> {
    //       this.fullName = res.userInformation.fullName;
    //       this.avatar = res.userInformation.avatar;
    //     })
    //   }
    //
    //   this.letterThumb=this.fullName.charAt(0);
    //   console.log(this.letterThumb);
    // }


    this.getServices();

  }

  ngOnInit() {


  }

  getServices() {
    this.blogService.getServicesMenu(this.servicesCategoryId).subscribe(res => {
      this.services = res;
    });
  }

  navToggle() {

    if (this.navToggleClass == '') {
      this.navToggleClass = 'active';
      this.navListShow = 'active';
      this.viewType = 'mobile';
    } else {
      this.navToggleClass = '';
      this.navListShow = '';
      this.viewType = 'desktop';

    }


  }


  openLoginModal() {
    const modalRef = this.modalService.open(LoginModalComponent);
    // modalRef.componentInstance.name = 'World';

  }

  openSignUpModal() {
    const modalRef = this.modalService.open(SignUpModalComponent);
    // modalRef.componentInstance.name = 'World';
  }

  goToWizard() {
    this._router.navigateByUrl('/wizard');
  }

  openPricingGuideModal() {
    const modalRef = this.modalService.open(PricingGuideComponent, {
      size: 'lg',
      centered: false,
      // this.window: 'price-guide'
    });
    // modalRef.componentInstance.name = 'World';
  }

}
