<div class="list-view">
  <div class="table-responsive">


    <div
      class="card-list-item"
      *ngFor="let item of itemsArray?.items   | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems:totalItems,id:'grid_pagination' }"
    >

      <div class="card">
        <a [routerLink]="['/'+propertyType,item.id]" class="card-image">
            <div class="card__img  ">
              <img
                class="img"
                height="100"
                src="{{item?.mainImage}}"
                alt=""
                default="./assets/images/aqar-thumb.jpg"
              />
            </div>
            <div class="card__image-count-area "></div>
        </a>
        <div class="card-content">
          <a class="card__header" [routerLink]="['/'+propertyType,item.id]">
            <h2 class="card__title">{{ lang=='ar'?item?.ar_title : item?.en_title }} <span class="badge badge-light">{{item?.offer|translate}}</span></h2>
            <p class="card__location">
              <img width="10" src="assets/images/icons/address.svg">{{ lang=='ar'?item?.ar_cityName :
              item?.en_cityName }}
              <span *ngIf="item?.ar_zoneName!=null">-</span>
              {{ lang=='ar'?item?.ar_zoneName : item?.en_zoneName}}
            </p>
          </a>
          <a [routerLink]="['/'+propertyType,item.id]" class="card__info">
            <div class="card__info__content">
              <div class="card__price">${{item.price |number}}</div>
              <div class="card__amenities">
                <span class="type"> {{item.type|translate}}</span>
                <span class="bedrooms" *ngIf="item?.roomsNumber"> <img class="icon"
                                                                       src="assets/images/icons/icon-bed.svg"> {{item?.roomsNumber}}</span>
                <span class="bathrooms" *ngIf="item?.bathroomsNumber"><img class="icon"
                                                                           src="assets/images/icons/icon-bath.svg"> {{item?.bathroomsNumber}}</span>
                <span class="area" *ngIf="item?.area"><img class="icon" src="assets/images/icons/icon-area.svg"> {{item?.area | number}} <span
                  class="areaSymbol">m²</span></span>
              </div>
            </div>
          </a>
          <div class="card__action">
             <span class="save">
             <button
               *ngIf="savedProperty==true"
               class="btn btn--small btn-outline-danger"
               (click)="saveLand(item?.id)"
               id="saveBtn-{{item?.id}}"
             >
              <i class="fa fa-trash"></i> {{'remove'|translate}}
            </button>

            <button
              *ngIf="item.saved==false && savedProperty==false"
              class="btn btn--small btn--light--default"
              (click)="saveLand(item?.id)"
              id="saveBtn-{{item?.id}}"
            >
              <i class="fa fa-star"></i> {{'save'|translate}}
            </button>

             <button
               *ngIf="item.saved==true && isLoggedIn==true && savedProperty==false "
               class="btn btn--small btn btn-outline-warning"
               (click)="saveLand(item?.id)"
               id="saveBtn-{{item?.id}}"
             >
              <i class="fa fa-star"></i> {{'saved'|translate}}
            </button>
          </span>

          </div>
        </div>
      </div>
    </div>


    <!--<table class="table table-hover">-->
    <!--<tbody>-->
    <!--<tr-->
    <!--*ngFor="let item of itemsArray?.items   | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems:totalItems,id:'grid_pagination' }"-->
    <!--&gt;-->

    <!--<td>-->
    <!--<img-->
    <!--class="img"-->
    <!--height="100"-->
    <!--src="{{item?.mainImage}}"-->
    <!--alt=""-->
    <!--default="./assets/images/aqar-thumb.jpg"-->
    <!--/>-->
    <!--</td>-->
    <!--<td class="text-left">-->
    <!--<a [routerLink]="['/'+propertyType,item.id]">-->
    <!--<div> {{ lang=='ar'?item?.ar_title : item?.en_title }}</div>-->
    <!--</a>-->
    <!--</td>-->
    <!--<td>-->

    <!--{{ lang=='ar'?item?.ar_cityName : item?.en_cityName }}-->
    <!--<span *ngIf="item?.ar_zoneName!=null">-</span>-->
    <!--{{ lang=='ar'?item?.ar_zoneName : item?.en_zoneName}}-->
    <!--</td>-->
    <!--<td>${{item.price |number}}</td>-->
    <!--<td>{{item.area}} <span class="areaSymbol">(m²)</span></td>-->
    <!--</tr>-->
    <!--</tbody>-->
    <!--</table>-->


    <div class="clear"></div>


    <div class="pagination">
      <div class="spinner text-center" [ngClass]="{ 'hidden': !loading }"></div>

      <pagination-controls
        (pageChange)="PageChanged($event)"
        previousLabel="<"
        nextLabel=">"
        directionLinks="true"
        id="grid_pagination"
        maxSize="6"
      >
      </pagination-controls>
    </div>
  </div>
</div>



