<app-top-header></app-top-header>


<section class="knowledge pb-5" >

  <app-blog-inner-nav></app-blog-inner-nav>


  <div class="knowledge-content">
    <div class="inner-banner">
      <img src="./assets/images/real-estate-banner.jpg">
      <div class="title">
        <h1>{{'Real Estate In Palestine'|translate}}</h1>
        <p>{{'All the operation you need to know about Real Estate In Palestine'|translate}}</p>
      </div>
    </div>
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3  order-xs-last">
          <div class="content-sidebar">

            <div class="make-me-sticky">
              <!--<form>-->
                <!--<div class="form-group">-->
                  <!--<input type="text" class="form-control search-input" placeholder="Search . . . . ">-->
                <!--</div>-->
              <!--</form>-->
              <div *ngFor="let data of categoriesPosts">
                <h3>{{data.catName}}</h3>
                <ul>
                  <li *ngFor="let post of data.cat_posts;let i = index">
                    <a
                      [routerLink]="['/knowledge-center/realestate-palestine/',post.slug]"
                      routerLinkActive="active"
                    >
                      <span>{{i + 1}}.</span>
                      {{ post?.title?.rendered }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-9 order-xs-first  pb-5 ">
          <div class="nav-content">
            <div class="content-header">
              <h1>{{ post?.title?.rendered }}</h1>
            </div>
            <div class="page-content" [innerHTML]=content></div>
            <div class="toc-container " [class.sticky]="sticky">
              <div class="toc-inner">
                <h2>{{'content'|translate}}</h2>
                <ul class="toc-list">
                  <li *ngFor="let item of spyScrollItem ">
                    <a href="javascript:void(0)" [ngx-scroll-to]="item.slug" [ngx-scroll-to-offset]="offset">
                      {{item.text}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<layout-footer></layout-footer>
