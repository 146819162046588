import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-angular-gallery',
  templateUrl: './angular-gallery.component.html',
  styleUrls: ['./angular-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AngularGalleryComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  // images:NgxGalleryImage[];
  @Input() images: NgxGalleryImage[];

  @Input() showThumbEnabled;
  imagesTest: any;
  showThumbValue = true;

  constructor() {


  }

  ngOnInit() {

    this.galleryOptions = [

      {
        // imageSize: "contain",
        width: '100%',
        height: '500px',
        thumbnailsColumns: 4,
        lazyLoading: true,
        // imageAnimation: NgxGalleryAnimation.Slide,
        fullWidth: false,
        'imageArrowsAutoHide': true,
        'thumbnailsArrowsAutoHide': true,
        'thumbnails': this.showThumbEnabled

      },
      {
        breakpoint: 800,
        width: '100%',
        height: '300px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        'thumbnails': this.showThumbEnabled

      },
      {
        breakpoint: 400,
        preview: false,
      }
    ];
  }


}
