<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>


  <div class="dashboard-content">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'rentContract'|translate}}
           <span class="badge text-white"
                 [ngClass]="{
                             'badge-success':contractDetails?.status === 'active',
                              'badge-primary':contractDetails?.status === 'canceled',
                              'badge-warning':contractDetails?.status === 'expired',
                              'badge-danger':contractDetails?.status  === 'end_soon'
                              }">
                                {{contractDetails?.status|translate}}
           </span>
        </h1>
      </div>
      <div class="action">
        <!--<button class="btn  btn&#45;&#45;light&#45;&#45;default ml-1 mr-1 " (click)="openOrderModal()">{{'requestInfo'|translate}}-->
        <!--</button>-->
        <!--<a class="btn btn&#45;&#45;primary " routerLink="/dashboard/add-properties">{{'addNewContract'|translate}}</a>-->
        <a class="btn btn--primary " routerLink="/dashboard/contracts/add-contract">{{'addNewContract'|translate}}</a>
      </div>
    </div>

    <app-alert404  *ngIf="error404==true && loadingDone == true" ></app-alert404>




    <div class="dashboard-details "  *ngIf="error404==false && loadingDone == true">


      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/contracts">{{'rentContract'|translate}}</a></li>
          <li><a routerLink="/dashboard/contracts/{{contractDetails?.contract_id}}" class="active">{{'contractNumber'|translate}}
            ({{contractDetails?.contract_id}}) </a></li>
        </ul>
      </div>

      <div class="p-2">


        <div class="general-info">

          <div class="row">

            <div class="col-md-3">
              <div class="card">
                <p>{{'payment_type'|translate}}</p>
                <span *ngIf="payment_type==1"> {{ 'monthly'|translate}}</span>
                <span *ngIf="payment_type==3"> {{ 'quarterly'|translate}}</span>
                <span *ngIf="payment_type==6"> {{ 'midterm'|translate}}</span>
                <span *ngIf="payment_type==12"> {{ 'yearly'|translate}}</span>

              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <p>{{'periodicPayment'|translate}}</p>
                <span> <i class="currency">{{currency}}</i> {{periodic_payment}}</span>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <p>{{'duePaymentSum'|translate}} </p>
                <span><i class="currency">{{currency}}</i>{{due_payment_sum}}</span>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <p>{{'paymentsReceived'|translate}}</p>
                <span><i class="currency">{{currency}}</i>{{payment_amount}}</span>
              </div>
            </div>


          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="card shadow p-4">

              <ngb-tabset>

                <ngb-tab>

                  <ng-template ngbTabTitle> {{'contractInfo'|translate}}</ng-template>
                  <ng-template ngbTabContent>

                    <div class="row mt-4">
                      <div class="col">

                        <table class="table table-bordered table-hover">
                          <thead class="thead-light">
                          <tr class="active">
                            <th colspan="2" class="text-center">{{'contractInfo'|translate}}</th>
                          </tr>
                          </thead>

                          <tr>
                            <td>{{'status'|translate}}</td>
                            <td class="strong">
                              <span class="badge text-white" [ngClass]="{
                              'badge-success':contractDetails?.status === 'active',
                              'badge-primary':contractDetails?.status === 'canceled',
                              'badge-warning':contractDetails?.status === 'expired',
                              'badge-danger':contractDetails?.status  === 'end_soon'
                              }"
                              >
                                {{contractDetails?.status|translate}}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td>{{'contractWriteDate'|translate}}</td>
                            <td class="strong">{{contractDetails?.contract_write_date}}</td>
                          </tr>

                          <tr>
                            <td>{{'rentStartDate'|translate}}</td>
                            <td class="strong">{{contractDetails?.contract_start_date}}</td>
                          </tr>

                          <tr>
                            <td>{{'contractEndDate'|translate}}</td>
                            <td class="strong"> {{contractDetails?.contract_end_date}}</td>
                          </tr>

                          <tr>
                            <td>{{'contractPeriod'|translate}}</td>
                            <td class="strong">{{contractDetails?.duration}} {{'month'|translate}}</td>
                          </tr>

                          <tr>
                            <td>{{'contractAmount'|translate}}</td>
                            <td class="strong" style="direction: ltr">
                              <i class="currency"> {{contractDetails?.currency}}</i>
                              {{contractDetails?.contract_monthly_payment}}
                            </td>
                          </tr>

                        </table>
                      </div>
                      <div class="col">
                        <table class="table table-bordered table-hover">
                          <thead class="thead-light">
                          <tr class="active">
                            <th colspan="2" class="text-center">{{'ownerInfo'|translate}}</th>
                          </tr>
                          </thead>

                          <tr>
                            <td>{{'propertyName'|translate}}</td>

                            <td class="strong">
                              {{ lang=='ar'?contractDetails?.unit_ar_title : contractDetails?.unit_en_title }}
                            </td>

                          </tr>

                          <tr>
                            <td>{{'ownerName'|translate}}</td>
                            <td class="strong">{{contractDetails?.owner_name}}</td>
                          </tr>

                        </table>

                        <table class="table table-bordered table-hover">
                          <thead class="thead-light">
                          <tr class="active">
                            <th colspan="2" class="text-center">{{'renterIno'|translate}}</th>
                          </tr>
                          </thead>
                          <tr>
                            <td>{{'renterName'|translate}}</td>
                            <td class="strong">{{contractDetails?.renter_name}}</td>
                          </tr>
                          <tr>
                            <td>{{'idNumber'|translate}}</td>
                            <td class="strong">{{contractDetails?.id_number}}</td>
                          </tr>
                          <tr>
                            <td>{{'mobileNumber'|translate}}</td>
                            <td class="strong"> {{contractDetails?.renter_phone}}</td>
                          </tr>

                        </table>

                      </div>
                    </div>


                  </ng-template>
                </ngb-tab>


                <ngb-tab>
                  <ng-template ngbTabTitle> {{'contractPayments'|translate}}</ng-template>
                  <ng-template ngbTabContent>


                    <table class="table table-striped table-bordered  table-hover mt-4" id="">
                      <thead>
                      <tr>
                        <th style="width:10%">{{'theNumber'|translate}}</th>
                        <th style="width:20%">{{'paymentDueDate'|translate}}</th>
                        <th style="width:20%">{{'deservedAmount'|translate}}</th>
                        <th style="width:20%">{{'statement'|translate}}</th>
                        <th style="width:20%">{{'notes'|translate}}</th>
                        <!--<th style="width:15%">{{'status'|translate}}</th>-->
                      </tr>
                      </thead>
                      <tbody>

                      <tr *ngFor="let  payment of payments">

                        <td>{{payment.payment_id}}</td>
                        <td>{{payment.due_date}}</td>
                        <td><i class="currency">{{currency}}</i>{{payment.amount}}</td>
                        <td>{{payment.payment_type|translate}}</td>
                        <td>{{payment.notes}}</td>
                      </tr>

                      <tr *ngIf="payments.length > 0">
                        <td class="strong">{{'total'|translate}}</td>
                        <td></td>
                        <td class="strong"><i class="currency">{{currency}}</i>{{totalPayment}}</td>
                        <td></td>
                        <td></td>
                      </tr>


                      </tbody>
                    </table>

                  </ng-template>
                </ngb-tab>


                <ngb-tab>
                  <ng-template ngbTabTitle> {{'receipts'|translate}}</ng-template>
                  <ng-template ngbTabContent>


                    <hr>


                    <table class="table table-striped table-bordered mt-4">
                      <thead>
                      <tr>
                        <th width="10%">{{'theNumber'|translate}}</th>
                        <th width="15%">{{'amount'|translate}}</th>
                        <th width="15%">{{'payment_date'|translate}}</th>
                        <th width="25%">{{'duration'|translate}}</th>
                        <th>{{'statement'|translate}}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr *ngFor="let  receipt of receipts">
                        <td>{{receipt.receipt_id}}</td>
                        <td><i class="currency">{{currency}}</i> {{receipt.amount}}</td>
                        <td>{{receipt.created_at}}</td>
                        <td>
                          {{'from'|translate}}
                          <span style="direction: ltr; display: inline-block">
                          {{receipt.from_date}}
                          </span>
                          {{'to'|translate}}
                          <span style="direction: ltr; display: inline-block">
                          {{receipt.to_date}}</span>
                        </td>
                        <td>{{receipt.statement|translate}}</td>
                        <td>
                          <a target="_blank" href="/daman/app_dev.php/admin/print/unit_receipt_print/1/1">
                            <i class="fa fa-print"></i>
                          </a>
                        </td>
                      </tr>


                      <tr *ngIf="receipts.length>0">
                        <td class="strong">{{'total'|translate}}</td>
                        <td class="strong"><i class="currency">{{currency}}</i>{{totalReceipts}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>




                      </tbody>
                    </table>

                  </ng-template>
                </ngb-tab>

                <ngb-tab>
                  <ng-template ngbTabTitle> {{'financialTransactions'|translate}}</ng-template>
                  <ng-template ngbTabContent>

                    <table class="table  table-striped table-bordered mt-4">
                      <thead>
                      <tr>
                        <td>#</td>
                        <td>{{'amount'|translate}}</td>
                        <td>{{'date'|translate}}</td>
                        <td>{{'statement'|translate}}</td>
                      </tr>
                      </thead>
                      <tbody>


                      <tr *ngFor="let  mov of financialMovementArray">
                        <td>

                          <i class="fa fa-arrow-circle-o-up text-success" *ngIf="mov.type=='receipt'"></i>
                          <i class="fa fa-arrow-circle-o-down text-danger" *ngIf="mov.type=='payment'"></i>

                        </td>
                        <td><i class="currency">{{currency}}</i> {{mov.amount}}</td>
                        <td>{{mov.date}}</td>
                        <td>{{mov.statement|translate}}</td>
                      </tr>


                      </tbody>
                    </table>
                  </ng-template>
                </ngb-tab>


              </ngb-tabset>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
