<div class="realestate-palestine-block">
  <div class="row">
    <div class="col-md-8">
      <h1>{{'Real Estate In Palestine'|translate}}</h1>
      <p>{{'All the operation you need to know about Real Estate In Palestine'|translate}}</p>
      <a class="btn btn--primary btn-large" routerLink="/knowledge-center/realestate-palestine">{{'readMore'|translate}}</a>

    </div>
    <div class="col-md-4">
      <img src="./assets/images/palestine-realestate.svg">
    </div>
  </div>
</div>
