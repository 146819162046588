import {Component, OnInit, Inject} from '@angular/core';
import {BlogService} from '../../_services/blog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {WINDOW} from '@ng-toolkit/universal';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {


  slug:any = 'about-us';
  page:any;
  categoryId:any = 22;
  pages:any;
  post:any;
  pathUrl:any;
  content:any;
  loader:boolean = false;


  // constructor(@Inject(WINDOW) private window: Window, private route:ActivatedRoute,
  //             private blogService:BlogService,
  //             private sanitizer:DomSanitizer,
  //             private router:Router) {
  //
  // }


  constructor(private route:ActivatedRoute,
              private blogService:BlogService,
              private sanitizer:DomSanitizer,
              private router:Router,
              @Inject(WINDOW) private window:Window) {

  }


  ngOnInit() {
    this.getPage(this.slug);
    this.window.scrollTo({
      top: 0,
      behavior: "smooth"
    });


    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      if (this.slug == 'undefined' || this.slug == null) {
        this.getFirstPost(this.categoryId);
      } else {
        this.getPostBySlug(this.slug);
      }
    });

    this.getPages();

  }


  getPages() {
    this.loader = true;

    this.blogService.getServicesMenu(this.categoryId).subscribe(res=> {
      this.pages = res;
      this.loader = false;

    });
  }

  getPage(slug) {
    this.loader = true;

    this.blogService.getPageBySlug(slug).subscribe(res=> {
      this.page = res[0];
      this.loader = false;

    });
  }


  getFirstPost(categoryId) {
    this.loader = true;
    this.blogService.getFirstAboutPost(categoryId).subscribe(res=> {
      this.post = res[0];
      this.router.navigate(['about/', this.post.slug]);
      this.loader = false;

    });
  }


  getPostBySlug(slug) {
    this.loader = true;

    this.blogService.getPostBySlug(slug).subscribe(res=> {
      this.post = res[0];
      // this.spyScrollItem = [];
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.post.content.rendered);
      this.loader = false;
      this.window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }


}
