<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'notifications'|translate}}
        </h1>
      </div>
      <div class="action">
        <!--<a class="btn btn--primary " routerLink="/dashboard/customers/create"> + {{'addCustomer'|translate}} </a>-->
      </div>
    </div>
    <div class="dashboard-details ">

      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/notifications" class="active">{{'notifications'|translate}}</a></li>
        </ul>
      </div>


      <div class="dashboard-table-details mt-2">


        <div class="table-responsive mt-2 col-md-10">

          <div class="card-table card-bordered ">
            <table class="table ">
              <thead class="tb-ticket-head">
              <tr class="tb-ticket-title">
                <th class="tb-ticket-id"><span>#</span></th>
                <th class="tb-ticket-desc"><span>{{'subject'|translate}}</span></th>
                <th class="tb-ticket-date tb-col-md"><span>{{'Submitted'|translate}}</span></th>
                <th class="tb-ticket-status"><span>{{'status'|translate}}</span></th>
                <th class="tb-ticket-action" width="50px">&nbsp;</th>
              </tr>
              </thead>
              <tbody class="tb-ticket-body">


              <tr *ngFor="let item of notifications ;index as i">
                <td class=""><a routerLink="/dashboard/notifications/{{item.id}}" class="table-link">{{i+1}}</a>
                </td>

                <td class=""><a routerLink="/dashboard/notifications/{{item.id}}" class="table-link"><span
                  class="title">{{item.request_type|translate}}</span></a>
                </td>

                <td class="tb-ticket-date tb-col-md"><span class="date date small text-default">{{item.created_at}}</span></td>


                <td class="tb-ticket-status">
                  <span class="badge aqar-badge aqar-badge-success" *ngIf="item.active==1">{{'active'|translate}}</span>
                  <span class="badge aqar-badge aqar-badge-light" *ngIf="item.active==0">{{'closed'|translate}}</span>
                </td>

                <td class="tb-ticket-action">
                  <a routerLink="/dashboard/notifications/{{item.id}}" class="btn-icon-circle">
                    <em class="fa fa-angle-right"></em>
                  </a>
                </td>
              </tr>


              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<layout-footer></layout-footer>
