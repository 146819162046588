<footer>
  <div class="container">
    <div class="row pb-3">
      <div class="col-md-3">
        <div class="logo">
          <img src="./assets/images/logo/aqar-logo-gray.svg">
          <div class="clearfix"></div>
        </div>
        <p class="logo-slogan desktop-only">
          {{'footer_slogan'|translate}}
        </p>

        <p class="text-center ssl-images">
          <img src="./assets/images/logo/ssl.png">
          <img src="./assets/images/logo/comodo-secure.png">
        </p>

      </div>
      <div class="col-md-9">
        <div class="row">

          <div class="col-md-2 desktop-only text-center">
            <h3 class="text-center">{{'AboutUS'|translate}}</h3>
            <ul class="text-center">
              <li><a routerLink="/about">{{'aboutAqar'|translate}} </a></li>
              <li><a routerLink="/services/legal-management-of-real-estate">{{'services'|translate}} </a></li>
              <li><a routerLink="/contact">{{'ContactUS'|translate}} </a></li>
            </ul>
          </div>


          <div class="col-md-3 desktop-only">
            <h3 class="text-center">{{'knowledge-center'|translate}} </h3>
            <ul class="text-center">
              <li><a routerLink="/knowledge-center/realestate-palestine">{{'realestateGuide'|translate}}</a></li>
              <li><a routerLink="/knowledge-center/my-aqar">{{'MyAQARAccountBenefit'|translate}}</a></li>
              <li><a routerLink="/knowledge-center/guide">{{'BuyingAndRentingTips'|translate}}</a></li>
            </ul>
          </div>


          <div class="col-md-3">
            <h3 class="text-center">{{'FollowUS'|translate}} </h3>
            <ul class="footer-social">
              <li><a href="https://www.facebook.com/aqarps-1465219743723297/" target="_blank"><i
                class="fa fa-facebook"></i></a></li>
              <li><a href="#"><i class="fa fa-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/company/aqar/" target="_blank"><i class="fa fa-linkedin"></i></a>

              <li><a class="whatsapp" href="https://api.whatsapp.com/send?phone=970599222332" target="_blank"><i
                class="fa fa-whatsapp"></i></a>
              </li>
            </ul>
            <ul class="footer-contacts text-center mt-2">
              <li>
                <a  class="mobile-number" href="tel:+972599222332">
                  <i class="fa fa-phone"></i> +972599222332
                </a>
              </li>
            </ul>

          </div>

          <div class="col-md-2">
            <h3 class="text-center">{{'language'|translate}} </h3>
            <div class="footer-form">
              <div class="language-switch">
                <button (click)="switchLanguage('ar')" class=" {{translate.currentLang=='ar'?'active':''}}"> عربي
                </button>
                <button (click)="switchLanguage('en')" class="  {{translate.currentLang=='en'?'active':''}}"> English
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="copy-right">
    <div class="container">
      <p class="text-center"> Aqar.ps All Rights Reserved © {{year}}</p>

    </div>
  </div>
</footer>
