import {Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {LandsService} from '../../_services/lands.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersOrdersModalComponent} from '../customers-orders-modal/customers-orders-modal.component';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {WINDOW} from '@ng-toolkit/universal';
import {GovernateService} from '../../_services/governate.service';
import {empty} from 'rxjs/index';
import {dropdownLists} from '../../_services/dropdown-lists.service';
import {UserService} from './../../_services/user.service';


@Component({
  selector: 'app-user-properties',
  templateUrl: './user-properties.component.html',
  styleUrls: ['./user-properties.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class UserPropertiesComponent implements OnInit {
  urlparams: any;
  landsTest$: any;
  totalItems: number;
  MapsArray: any;
  markersArray: any;
  loading: boolean = true;
  page: any = 1;
  currentPage: any = 1;
  itemsPerPage: number = 20;
  selectedName: string;
  lang: string = '';
  propertyType: any;
  queryParameters: any = [];
  noResultDisplay: boolean = false;
  selectedGovId: number = null;
  selectedGovString: string;

  selectedTownId: number;
  selectedTownString: string;

  selectedAreaId: number;
  selectedAreaString: string;

  sortingBy: string;
  selectedSortingBy: string;

  governateList: any = [];
  townList: any = [];
  areaList: any = [];
  type: string;
  locationSearchText: string;
  propertyNumberText: string;
  filterIsAdvanced: boolean;
  selectedPropertyType: string;

  selectedRoomsCount: number;
  roomList: any;

  selectedPropertyStatus: string;
  selectedRangeArea: any;


  /***
   * Price Range
   * @type {number}
   */
  priceFrom: number = 0;
  selectedPriceFrom: number;
  priceTo: number = 1000000;
  selectedPriceTo: number;
  selectedRangePrice: string;
  public priceRange: number[] = [0, 1000000];

  filterIsReday = false;

  /**
   * Area Range
   * @type {number}
   */
  areaFrom: any = 0;
  selectedAreaFrom: any;
  areaTo: any = 10000;
  selectedAreaTo: any;
  public areaRange: number[] = [0, 10000];
  propertyForChecked: any = [];

  blockList: any;
  block_number: any;
  blockName: any;

  blockDisabled = true;
  sectorList: any = null;
  sector_number: any;
  sectorName: any;
  sectorDisabled = true;

  parcel_number: any;
  offer: any;


  /**
   *  price list
   */

  priceList = [];
  price_from: number = null;
  price_to: number = null;

  priceText: any;
  areaText: any;


  propertyNumberSelected = false;
  locationSelected = false;
  propertiesCounts: any;

  /**
   *  area list
   */

  propertyAreaList = [];
  area_from: number = null;
  area_to: number = null;
  collapse: boolean = true;
  gridCol: any;

  propertyForList: any = [

    {
      id: 'for_sell',
      ar_name: 'للبيع',
      en_name: 'For Sell',
    },
    {
      id: 'for_investment',
      ar_name: 'للاستثمار',
      en_name: 'Investmentّ',
    },
    {
      id: 'record',
      ar_name: 'سجل',
      en_name: 'record',
    },
    {
      id: 'for_rent',
      ar_name: 'للايجار',
      en_name: 'For Rent',
    }
  ];


  constructor(@Inject(WINDOW) private window: Window,
              private landService: LandsService,
              public  http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private modalService: NgbModal,
              private GovernateService: GovernateService,
              public Lists: dropdownLists,
              private  userService: UserService,
              config: NgbDropdownConfig) {
    config.autoClose = 'outside';

    this.handleAreaList();

  }

  ngOnInit() {


    let lang = this.translate.currentLang;
    this.lang = lang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }
    this.propertyType = this.route.snapshot.url[2].path;

    this.translate.get('Location').subscribe((res: string) => {
      this.locationSearchText = res;
    });

    this.translate.get('propertyNumber').subscribe((res: string) => {
      this.propertyNumberText = res;
    });

    this.translate.get('priceText').subscribe((res: string) => {
      this.priceText = res;
    });

    this.translate.get('areaText').subscribe((res: string) => {
      this.areaText = res;
    });


    this.priceList = this.Lists.getPriceToBuy();
    this.propertyAreaList = this.Lists.getAreaPropertyList();


    this.route.queryParams.subscribe((params: any) => {
      this.currentPage = params['page'] || 1;
      this.selectedGovId = params['gov'] || 1;

      this.selectedTownId = params['town'] || 2;
      this.selectedAreaId = params['zone'] || null;
      this.block_number = params['block_number'] || null;
      this.sector_number = params['sector_number'] || null;
      this.parcel_number = params['parcel_number'] || null;

      if (params['area_from']) {
        this.area_from = Number(params['area_from']);
      }

      if (params['area_to']) {
        this.area_to = Number(params['area_to']);
      }

      if (params['price_from']) {
        this.price_from = Number(params['price_from']);
      }

      if (params['price_to']) {
        this.price_to = Number(params['price_to']);
      }

      if (params['offer']) {
        this.offer = params['offer'];
      }

    });

    this.getGovernateList();
    this.getProperties();
    this.getuserPropertiesCount();

  }


  getuserPropertiesCount() {
    this.userService.getuserPropertiesCount().subscribe((res: any) => {
      if (res.success) {
        this.propertiesCounts = (res.data);
        console.log('this.propertiesCounts:', this.propertiesCounts);

      }
    });

  }

  handleAreaList() {

    this.propertyAreaList = this.Lists.getAreaPropertyList();


  }


  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
  }


  getGovernateList() {
    this.selectedGovId = (this.route.snapshot.queryParams['gov'] == null ? this.selectedGovId : this.route.snapshot.queryParams['gov']);
    this.GovernateService
      .getGovernate()
      .subscribe(res => {
        this.governateList = res['data'];
        if (this.selectedGovId != null) {
          this.selectedGovString = this.governateList[this.selectedGovId - 1][this.selectedName];
          let gov = this.governateList[this.selectedGovId - 1];
          this.onGovChange(gov);
        }
      });
  }

  onGovChange($event) {
    if ($event != undefined) {
      this.selectedGovId = $event['id'];
      this.selectedTownId = null;
      this.selectedTownString = null;
      this.selectedAreaId = null;
      this.selectedAreaString = null;
      this.selectedGovString = $event[this.selectedName];
      this.getTownList(this.selectedGovId);
      this.updateLocationSearchText();
    }

  }


  getTownList(govId) {
    if (govId != undefined) {
      this
        .GovernateService
        .getTowns(govId)
        .subscribe(res => {
          this.townList = res['data'];
          let selectedTownId = (this.route.snapshot.queryParams['town'] == null ? this.selectedTownId : this.route.snapshot.queryParams['town']);
          this.selectedTownId = selectedTownId;
          if (this.selectedTownId != null) {
            for (let value of res['data']) {
              if (value['id'] == selectedTownId) {
                this.getTown(value);
              }
            }
          }
        });
    }
  }

  getBlocksList() {

    let govId = this.selectedGovId;
    let townId = this.selectedTownId;
    this
      .GovernateService
      .getBlocks(govId, townId)
      .subscribe(res => {
        this.blockList = res['data'];
        this.block_number = (this.route.snapshot.queryParams['block_number'] == null ? this.block_number : this.route.snapshot.queryParams['block_number']);

        if (this.block_number != null) {
          for (let value of res['data']) {
            if (value['id'] == this.block_number) {
              this.onBlockChange(value);
            }
          }
        }
      });
  }

  getTown($event) {
    if ($event != undefined) {
      this.selectedTownId = $event['id'];
      this.selectedTownString = $event[this.selectedName];
      this.selectedAreaId = null;
      this.selectedAreaString = null;
      this.getAreaList(this.selectedGovId, this.selectedTownId);
      this.getBlocksList();
      this.updateLocationSearchText();
    }
  }

  getAreaList(govId, townId) {
    this
      .GovernateService
      .getArea(govId, townId)
      .subscribe(res => {
        let selectedAreaId = (this.route.snapshot.queryParams['zone'] == null ? this.selectedAreaId : this.route.snapshot.queryParams['zone']);
        this.areaList = res['data'];
        this.selectedAreaId = selectedAreaId;
        if (selectedAreaId != null) {
          for (let value of res['data']) {
            if (value['id'] == selectedAreaId) {
              this.getArea(value);
            }
          }
        }
      });
  }

  getArea($event) {
    this.selectedAreaId = $event['id'];
    this.selectedAreaString = $event[this.selectedName];
    this.updateLocationSearchText();
  }


  getSector($event) {
    this.sector_number = $event['id'];
    this.sectorName = $event[this.selectedName];
    this.updatePropertyNumberText();

  }

  updatePropertyNumberText() {
    let propertyNumberText = (this.blockName == null ? '' : this.blockName) + (this.sectorName == null ? '' : ',' + this.sectorName);

    if (propertyNumberText == null || propertyNumberText == '') {
      this.translate.get('propertyNumber').subscribe((res: string) => {
        this.propertyNumberText = res;
        this.propertyNumberSelected = false;

      });
    } else {
      this.propertyNumberText = propertyNumberText;
      this.propertyNumberSelected = true;
    }


  }

  onBlockChange($event) {

    if ($event != undefined) {
      this.block_number = $event['id'];
      this.blockName = $event[this.selectedName];
      this.getSectorsList();
      this.updatePropertyNumberText();
    } else {
      this.block_number = null;
      this.blockName = null;
      this.sector_number = null;
      this.sectorName = null;
      this.updatePropertyNumberText();
    }


  }

  onSectorChange($event) {
    this.sector_number = $event['id'];
    this.sectorName = $event[this.selectedName];
    this.updatePropertyNumberText();

  }

  onClearBlock() {
    this.block_number = null;
    this.sector_number = null;
    this.updatePropertyNumberText();
  }

  onClearSector() {
    this.sector_number = null;
    this.updatePropertyNumberText();
  }


  getSectorsList() {
    this
      .GovernateService
      .getSectors(this.block_number)
      .subscribe(res => {
        this.sectorList = res['data'];
        if (this.sectorList.length > 1) {
          this.sectorDisabled = false;
          this.sector_number = (this.route.snapshot.queryParams['sector_number'] == null ? this.sector_number : this.route.snapshot.queryParams['sector_number']);

          if (this.sector_number != null) {
            for (let value of res['data']) {
              if (value['id'] == this.sector_number) {
                this.onSectorChange(value);
              }
            }
          }
        } else {
          this.sectorDisabled = true;
        }
      });
  }

  updateLocationSearchText() {
    let locationString = (this.selectedGovString == null ? '' : this.selectedGovString) + (this.selectedTownString == null ? '' : ',' + this.selectedTownString) + (this.selectedAreaString == null ? '' : ', ' + this.selectedAreaString);
    if (locationString == null || locationString == '') {
      this.translate.get('Location').subscribe((res: string) => {
        this.locationSearchText = res;
        this.locationSelected = false;
      });

    } else {
      this.locationSearchText = locationString;
      this.locationSelected = true;

    }


  }


  getProperties() {

    this.loading = true;
    this.queryParameters = [];

    this.queryParameters['page'] = this.currentPage;

    if (this.block_number) {
      this.queryParameters['block_number'] = this.block_number;
    }

    if (this.selectedGovId) {
      this.queryParameters['gov'] = this.selectedGovId;
    }

    if (this.selectedTownId) {
      this.queryParameters['town'] = this.selectedTownId;
    }

    if (this.selectedAreaId) {
      this.queryParameters['zone'] = this.selectedAreaId;
    }

    if (this.block_number) {
      this.queryParameters['block_number'] = this.block_number;
    }

    if (this.sector_number) {
      this.queryParameters['sector_number'] = this.sector_number;
    }

    if (this.parcel_number) {
      this.queryParameters['parcel_number'] = this.parcel_number;
    }

    if (this.area_from) {
      this.queryParameters['area_from'] = this.area_from;
    }
    if (this.area_to) {
      this.queryParameters['area_to'] = this.area_to;
    }

    if (this.price_from) {
      this.queryParameters['price_from'] = this.price_from;
    }
    if (this.price_to) {
      this.queryParameters['price_to'] = this.price_to;
    }
    if (this.offer) {
      this.queryParameters['offer'] = this.offer;
    }


    this.loading = true;
    this.handlingPropertyNumber();

    this
      .landService
      .getUserProperties(this.queryParameters, this.propertyType)
      .subscribe((res: any) => {
        this.landsTest$ = res;
        let MapsArray = this.landsTest$.items;
        this.totalItems = res.pagination.total_count;
        if (res.pagination.total_count == 0) {
          this.noResultDisplay = true;
        } else {
          this.noResultDisplay = false;
        }
        this.window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        let other = []; // your other array...
        MapsArray.map(item => {
          return {
            lat: item['lat'],
            lng: item['lng'],
            ar_title: item['ar_title'],
            en_title: item['en_title'],
            propertyType: this.propertyType,
            ar_cityName: item['ar_cityName'],
            en_cityName: item['en_cityName'],
            en_zoneName: item['en_zoneName'],
            ar_zoneName: item['ar_zoneName'],
            mainImage: item['mainImage'],
            id: item['id'],
            price: item['price'],
            label: {fontSize: '12x', color: '#fff', text: ('$' + this.abbreviateNumber(item['price'])).toString()}
          };
        }).forEach(
          item => other.push(item)
        );
        this.markersArray = (other);
        this.loading = false;
      });

  }


  handlingPropertyNumber() {

    if (this.selectedGovId && this.selectedTownId) {

      console.log('handlingPropertyNumber true:', this.selectedGovId, this.selectedTownId);
      this.blockDisabled = false;
      this.getBlocksList();
    } else {
      console.log('handlingPropertyNumber false:', this.selectedGovId, this.selectedTownId);

      this.blockList = [];
      this.blockDisabled = true;
      this.sectorList = [];
      this.sectorDisabled = true;
    }
  }


  onClearGov() {

    this.selectedGovString = null;
    this.selectedGovId = null;

    this.areaList = [];
    this.selectedTownId = null;
    this.selectedTownString = null;

    this.selectedAreaId = null;
    this.selectedAreaString = null;

    this.block_number = null;
    this.blockList = [];
    this.blockDisabled = true;

    this.translate.get('Location').subscribe((res: string) => {
      this.locationSearchText = res;
    });

    this.updateLocationSearchText();
    this.updatePropertyNumberText();

  }

  onClearTown() {
    this.selectedTownId = null;
    this.selectedTownString = null;
    this.areaList = [];
    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.block_number = null;
    this.blockName = null;
    this.blockList = [];
    this.blockDisabled = true;
    this.updateLocationSearchText();
    this.updatePropertyNumberText();

  }

  onClearArea() {
    this.selectedAreaId = null;
    this.updateLocationSearchText();

  }

  abbreviateNumber(number) {
    var SI_PREFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    // what tier? (determines SI prefix)
    var tier = Math.log10(number) / 3 | 0;
    // if zero, we don't need a prefix
    if (tier == 0) {
      return number;
    }
    // get prefix and determine scale
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = number / scale;
    // format number and add prefix as suffix
    return scaled.toFixed(1) + prefix;
  }

  PageChanged($event) {

    this.currentPage = $event;
    this.page = $event;
    this.search();
    this.loading = true;
  }

  parcelOutPut($e) {
    this.parcel_number = $e;
  }

  search() {
    this.queryParameters = [];

    if (this.selectedGovId != undefined && this.selectedGovId != null) {
      this.queryParameters['gov'] = this.selectedGovId;
    }

    if (this.selectedTownId != undefined && this.selectedTownId != null) {
      this.queryParameters['town'] = this.selectedTownId;
    }

    if (this.selectedAreaId != undefined) {
      this.queryParameters['zone'] = this.selectedAreaId;
    }

    if (this.block_number != undefined) {
      this.queryParameters['block_number'] = this.block_number;
    }

    if (this.sector_number != undefined) {
      this.queryParameters['sector_number'] = this.sector_number;
    }

    if (this.parcel_number != undefined) {
      this.queryParameters['parcel_number'] = this.parcel_number;
    }

    if (this.area_from != undefined) {
      this.queryParameters['area_from'] = this.area_from;
    }

    if (this.area_to != undefined) {
      this.queryParameters['area_to'] = this.area_to;
    }


    if (this.price_from != undefined) {
      this.queryParameters['price_from'] = this.price_from;
    }

    if (this.price_to != undefined) {
      this.queryParameters['price_to'] = this.price_to;
    }

    if (this.offer != undefined) {
      this.queryParameters['offer'] = this.offer;
    }

    let page = this.currentPage;
    this.queryParameters['page'] = this.currentPage || 1;


    this.router.navigate(['/dashboard/properties/' + this.propertyType],
      {
        queryParams: this.queryParameters
      }
    );
    this.getGovernateList();
    this.getProperties();
  }


  getMinPropertyArea($event) {
    if ($event == undefined) {
      this.area_from = null;
    } else {
      this.area_from = $event['id'];
    }
    this.collapse = !this.collapse;
    this.search();

  }

  getMaxPropertyArea($event) {
    if ($event == undefined) {
      this.area_to = null;
    } else {
      this.area_to = $event['id'];
    }
    this.collapse = !this.collapse;
    this.search();

  }


  updateAreaText() {

    // let AreaString = (this.price_from == null ? '' : this.selectedGovString) + (this.selectedTownString == null ? '' : ',' + this.selectedTownString) + (this.selectedAreaString == null ? '' : ', ' + this.selectedAreaString);
    // if (locationString == null || locationString == '') {
    //   this.translate.get("Location").subscribe((res:string) => {
    //     this.locationSearchText = res;
    //   });
    //
    // } else {
    //   this.locationSearchText = locationString;
    // }
  }


  getMinPrice($event) {
    if ($event == undefined) {
      this.price_from = null;
    } else {
      this.price_from = $event['id'];
    }
    this.collapse = !this.collapse;
    this.search();


  }

  getMaxPrice($event) {
    if ($event == undefined) {
      this.price_to = null;
    } else {
      this.price_to = $event['id'];
    }
    this.collapse = !this.collapse;
    this.search();

  }

  setPropertyFor($event) {
    if ($event == undefined) {
      this.offer = null;
    } else {
      this.offer = $event['id'];
    }
    this.collapse = !this.collapse;
    this.search();
  }


}
