<app-top-header></app-top-header>
<section class="search-page">
  <div class="properties-type-list">
    <ul>

      <li>
        <a class=" with-icons {{ type=='lands'?'active':'' }}"
           (click)="changePropertyType('lands')"
        >
          <span class="img-circle"><img src="./assets/images/icons/Land.svg"></span>
          {{'land'|translate}} <span class="text-muted small">{{propertiesCounts?.land}}</span>
        </a>
      </li>
      <li>
        <a class=" with-icons {{ type=='apartments'?'active':'' }}"
           (click)="changePropertyType('apartments')">
          <span class="img-circle"><img src="./assets/images/icons/Apartment.svg"></span>
          {{'apartment'|translate}} <span class="text-muted small">{{propertiesCounts?.apartment}}</span>
        </a>
      </li>

      <li>
        <a class=" with-icons {{ type=='villas_houses'?'active':'' }}"
           (click)="changePropertyType('villas_houses')">
          <span class="img-circle"><img src="./assets/images/icons/Villa.svg"></span>
          {{'house'|translate}} <span class="text-muted small">{{propertiesCounts?.villa_house}}</span>
        </a>
      </li>

      <li>
        <a class=" with-icons {{ type=='offices'?'active':'' }}"
           (click)="changePropertyType('offices')">
          <span class="img-circle"><img src="./assets/images/icons/Office.svg"></span>
          {{'office'|translate}} <span class="text-muted small">{{propertiesCounts?.office}}</span>
        </a>
      </li>

      <li>
        <a class=" with-icons {{ type=='stores'?'active':'' }}"
           (click)="changePropertyType('stores')">
          <span class="img-circle"> <img src="./assets/images/icons/Store.svg"></span>
          {{'store'|translate}} <span class="text-muted small">{{propertiesCounts?.store}}</span>
        </a>
      </li>
      <li>
        <a class=" with-icons {{ type=='buildings'?'active':'' }}"
           (click)="changePropertyType('buildings')">
          <span class="img-circle"><img src="./assets/images/icons/Building.svg"></span>
          {{'building'|translate}} <span class="text-muted small">{{propertiesCounts?.building}}</span>
        </a>
      </li>
    </ul>


    <div class="icon-actions desktop ">
      <div class="select-sorting">
        <div
          ngbDropdown
          #sortingDropdown="ngbDropdown"
          autoClose="outside"
        >
          <button
            class="btn btn--small btn--light--default dropdown-toggle"
            id="sortingDropdown2"
            (click)="$event.stopPropagation();locationsDropdown.close()"
            ngbDropdownToggle
          >
            <span *ngIf="selectedSortingBy==null"><i class="fa fa-sort-amount-asc"></i>{{'sortingBy'|translate}}</span>
            <span class="fa fa-sort-amount-asc"
                  *ngIf="(selectedSortingBy=='price_asc') ||(selectedSortingBy=='area_asc')"></span>
            <span class="fa fa-sort-amount-desc"
                  *ngIf="(selectedSortingBy=='price_desc')||(selectedSortingBy=='area_desc')"></span>
            {{selectedSortingBy|translate}}
          </button>
          <div ngbDropdownMenu aria-labelledby="sortingDropdown" class="sortingDropdown" style="max-width:200px">
            <h6 class="dropdown-header">
              {{'sortingBy'|translate}}
            </h6>

            <label class="radio-container">{{'price_desc'|translate}}
              <input type="radio" checked="{{selectedSortingBy =='price_desc'?'checked':''}}" name="sorting_by"
                     value="price_desc" (click)="getSortingBy('price_desc')">
              <span class="checkmark"></span>
            </label>
            <label class="radio-container"> {{'price_asc'|translate}}
              <input type="radio" name="sorting_by" checked="{{selectedSortingBy =='price_asc'?'checked':''}}"
                     value="price_asc" (click)="getSortingBy('price_asc')">
              <span class="checkmark"></span>
            </label>
            <label class="radio-container">{{'area_desc'|translate}}
              <input type="radio" name="sorting_by" checked="{{selectedSortingBy =='area_desc'?'checked':''}}"
                     value="area_desc" (click)="getSortingBy('area_desc')">
              <span class="checkmark"></span>
            </label>
            <label class="radio-container">{{'area_asc'|translate}}
              <input type="radio" name="sorting_by" checked="{{selectedSortingBy =='area_asc'?'checked':''}}"
                     value="area_asc" (click)="getSortingBy('area_asc')">
              <span class="checkmark"></span>
            </label>
            <div class="dropdown-divider"></div>


            <div class="dropdown-footer">
              <button class="btn btn--light--default btn--small"
                      (click)="filter();sortingDropdown.close()">{{'apply'|translate}}
              </button>
              <button class="btn btn--default btn--small" (click)="sortingDropdown.close()">{{'cancel'|translate}}
              </button>

            </div>
          </div>
        </div>
      </div>

      <ul>
        <!--        <li *ngIf="mobile">-->
        <!--          <button (click)="changeDisplayMode('list')" [ngClass]="{'active':isList}" class="bg">-->
        <!--            <img src="./assets/images/icons/List.svg">-->
        <!--          </button>-->
        <!--          <button (click)="changeDisplayMode('grid')" [ngClass]="{'active':isGrid}" class="bg">-->
        <!--            <img src="./assets/images/icons/Grid.svg">-->
        <!--          </button>-->
        <!--        </li>-->
        <li class="map-toggle  ">
          <span>{{'showMap'|translate}}</span>
          <button (click)="mapToggle()" [ngClass]="{'active':mapOn}" class="map-toggle"></button>
        </li>
      </ul>
    </div>

  </div>
  <div class="container-fluid text-center search-container" [ngClass]="{'map-on':mapOn}">

    <div class="filter-menu" id="filters-container">
      <div class="filters-dropdowns" [ngClass]="{'active':filtersDropdownToggle}">


        <div class="search-dropdown-list select ml-2 mr-2">
          <div autoClose="false" ngbDropdown #locationsDropdown="ngbDropdown">
            <button
              class="btn  btn--light dropdown-toggle type-btn"
              id="locationsDopdownToggle"
              [class.dropdown-selected]="locationSelected"
              ngbDropdownToggle
            >
              {{locationSearchText}}
            </button>
            <div ngbDropdownMenu aria-labelledby="locationsDopdownToggle" class="location-dropdown locationsDropdown">
              <div class="form-group mb-3">
                <label class="mb-1">{{'Governate'|translate}}</label>
                <ng-select
                  [items]="governateList"
                  bindLabel="{{selectedName}}"
                  bindValue="id"
                  placeholder="{{'SelectGovernorate'|translate}}"
                  [(ngModel)]="selectedGovId"
                  [clearable]="true"
                  (clear)="onClearGov()"
                  (change)="getGovernate($event)"
                >
                </ng-select>
              </div>
              <div class="form-group mb-3">
                <label class="mb-1">{{'Town'|translate}}</label>
                <ng-select
                  [items]="townList"
                  bindLabel="{{selectedName}}"
                  required
                  bindValue="id"
                  placeholder="{{'SelectTown'|translate}}"
                  [(ngModel)]="selectedTownId"
                  [clearable]="true"
                  (clear)="onClearTown()"
                  (change)="getTown($event)"
                >
                </ng-select>
              </div>
              <div class="form-group mb-3">
                <label class="mb-1">{{'Zone'|translate}}</label>
                <ng-select
                  [items]="areaList"
                  bindLabel="{{selectedName}}"
                  required
                  bindValue="id"
                  placeholder="{{'SelectArea'|translate}}"
                  [(ngModel)]="selectedAreaId"
                  [clearable]="true"
                  (clear)="onClearArea()"
                  (change)="getArea($event)"
                >
                </ng-select>
              </div>
              <div class="dropdown-divider"></div>
              <div class="clearfix"></div>
              <div class="dropdown-footer">
                <button class="btn btn--light--default btn--small"
                        (click)="filter();$event.stopPropagation();locationsDropdown.close();">{{'apply'|translate}}
                </button>
                <button
                  class="btn btn--default btn--small"
                  (click)="$event.stopPropagation();locationsDropdown.close();"
                >
                  {{'cancel'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>


        <div class="search-dropdown-list select ml-2 mr-2">
          <div autoClose="false" ngbDropdown #priceDropdown="ngbDropdown">
            <button
              class="btn  btn--light dropdown-toggle type-btn"
              id="priceDopdownToggle"
              [class.dropdown-selected]="priceSelected"
              ngbDropdownToggle
            >{{'price'|translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="priceDopdownToggle" class="price-dropdown priceDropdown">

              <div class="form-group mb-3">
                <div class="select search-dropdown-list w-mobile-90 " style="min-width: 45%">
                  <ng-select [items]="priceList"
                             bindLabel="{{selectedName}}"
                             bindValue="id"
                             placeholder="{{'from'|translate}}"
                             [(ngModel)]="selectedMinPrice"
                             [clearable]="true"
                             (change)="getMinPrice($event)"
                             [searchable]="false"
                             class="transparentt  no-autocomplete"
                  >
                  </ng-select>
                </div>
                <div class="select search-dropdown-list w-mobile-90" style="min-width: 45%">
                  <ng-select [items]="priceList"
                             bindLabel="{{selectedName}}"
                             bindValue="id"
                             placeholder="{{'to'|translate}}"
                             [(ngModel)]="selectedMaxPrice"
                             [clearable]="true"
                             (change)="getMaxPrice($event)"
                             [searchable]="false"
                             class="transparentt  no-autocomplete"
                  >
                  </ng-select>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="select search-dropdown-list ml-2 mr-2">
          <div autoClose="false" ngbDropdown #areaDropdown="ngbDropdown">
            <button
              class="btn  btn--light dropdown-toggle type-btn"
              id="areaDopdownToggle"
              [class.dropdown-selected]="areaSelected"
              ngbDropdownToggle
            >{{'area'|translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="areaDopdownToggle" class="area-dropdown areaDropdown">

              <div class="form-group mb-3">
                <div class="select search-dropdown-list w-mobile-90" style="min-width: 45%">

                  <ng-select [items]="propertyAreaList"
                             bindLabel="{{selectedName}}"
                             bindValue="id"
                             placeholder="{{'minArea'|translate}}"
                             [(ngModel)]="selectedMinPropertyArea"
                             [clearable]="true"
                             (change)="getMinPropertyArea($event)"
                             [searchable]="false"
                             class="transparentt  no-autocomplete"
                  >
                  </ng-select>

                </div>
                <div class="select search-dropdown-list w-mobile-90 " style="min-width: 45%">

                  <ng-select [items]="propertyAreaList"
                             bindLabel="{{selectedName}}"
                             bindValue="id"
                             placeholder="{{'maxArea'|translate}}"
                             [(ngModel)]="selectedMaxPropertyArea"
                             [clearable]="true"
                             (change)="getMaxPropertyArea($event)"
                             [searchable]="false"
                             class="transparentt  no-autocomplete"
                  >
                  </ng-select>

                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="select search-dropdown-list ml-2 mr-2" *ngIf="filterIsAdvanced">
          <ng-select [items]="roomList"
                     bindLabel="{{selectedName}}"
                     bindValue="id"
                     placeholder="{{'roomsNumber'|translate}}"
                     [(ngModel)]="selectedRoomsCount"
                     [clearable]="true"
                     (change)="getRoomsCounts($event)"
                     [searchable]="false"
                     class="transparentt  no-autocomplete"
          >
          </ng-select>
        </div>

        <div class="select search-dropdown-list ml-2 mr-2" *ngIf="filterIsAdvanced">
          <ng-select [items]="propertyStatusList"
                     bindLabel="{{selectedName}}"
                     required
                     bindValue="id"
                     placeholder="{{'Status'|translate}}"
                     [(ngModel)]="selectedPropertyStatus"
                     [clearable]="true"
                     (change)="getPropertyStatus($event)"
                     [searchable]="false"
                     class="transparentt  no-autocomplete"
          >
          </ng-select>
        </div>

        <div class="select search-dropdown-list ml-2 mr-2 ">
          <ng-select [items]="propertyForList"
                     bindLabel="{{selectedName}}"
                     bindValue="id"
                     placeholder="{{'Offer'|translate}}"
                     [(ngModel)]="offerChecked"
                     [clearable]="true"
                     (change)="setPropertyFor($event)"
                     [searchable]="false"
                     class="transparentt  no-autocomplete"
          >
          </ng-select>
        </div>


        <div class="show-result">
          <button class="btn btn--primary " (click)="filtersToggle()">{{'show_result'|translate}}</button>
          <button *ngIf="filterParametersCount>1" class="btn btn--small btn-outline-info"
                  (click)="clearFilter()">{{'clearFilter'|translate}}</button>
        </div>
      </div>
      <div class="icon-actions mobile ">
        <div class="select-sorting">
          <div
            ngbDropdown
            #sortingDropdown="ngbDropdown"
            autoClose="outside"
          >
            <button
              class="btn btn--small btn--light dropdown-toggle"
              id="sortingDropdown"
              (click)="$event.stopPropagation();locationsDropdown.close()"
              ngbDropdownToggle
            >
              <span *ngIf="selectedSortingBy==null"><i
                class="fa fa-sort-amount-asc"></i>{{'sortingBy'|translate}}</span>
              <span class="fa fa-sort-amount-asc"
                    *ngIf="(selectedSortingBy=='price_asc') ||(selectedSortingBy=='area_asc')"></span>
              <span class="fa fa-sort-amount-desc"
                    *ngIf="(selectedSortingBy=='price_desc')||(selectedSortingBy=='area_desc')"></span>
              {{selectedSortingBy|translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="sortingDropdown" class="sortingDropdown">
              <h6 class="dropdown-header">
                {{'sortingBy'|translate}}
              </h6>

              <label class="radio-container">{{'price_desc'|translate}}
                <input type="radio" checked="{{selectedSortingBy =='price_desc'?'checked':''}}" name="sorting_by"
                       value="price_desc" (click)="getSortingBy('price_desc')">
                <span class="checkmark"></span>
              </label>
              <label class="radio-container"> {{'price_asc'|translate}}
                <input type="radio" name="sorting_by" checked="{{selectedSortingBy =='price_asc'?'checked':''}}"
                       value="price_asc" (click)="getSortingBy('price_asc')">
                <span class="checkmark"></span>
              </label>
              <label class="radio-container">{{'area_desc'|translate}}
                <input type="radio" name="sorting_by" checked="{{selectedSortingBy =='area_desc'?'checked':''}}"
                       value="area_desc" (click)="getSortingBy('area_desc')">
                <span class="checkmark"></span>
              </label>
              <label class="radio-container">{{'area_asc'|translate}}
                <input type="radio" name="sorting_by" checked="{{selectedSortingBy =='area_asc'?'checked':''}}"
                       value="area_asc" (click)="getSortingBy('area_asc')">
                <span class="checkmark"></span>
              </label>
              <div class="dropdown-divider"></div>


              <div class="dropdown-footer">
                <button class="btn btn--light--default btn--small"
                        (click)="filter();sortingDropdown.close()">{{'apply'|translate}}
                </button>
                <button class="btn btn--default btn--small" (click)="sortingDropdown.close()">{{'cancel'|translate}}
                </button>

              </div>
            </div>
          </div>
        </div>

        <ul>
          <!--          <li  *ngIf="mobile">-->
          <!--          <button (click)="changeDisplayMode('list')" [ngClass]="{'active':isList}" class="bg">-->
          <!--          <img src="./assets/images/icons/List.svg">-->
          <!--          </button>-->
          <!--          <button (click)="changeDisplayMode('grid')" [ngClass]="{'active':isGrid}" class="bg">-->
          <!--          <img src="./assets/images/icons/Grid.svg">-->
          <!--          </button>-->
          <!--          </li>-->

          <li class="map-toggle  ">
            <span>{{'showMap'|translate}}</span>
            <button (click)="mapToggle()" [ngClass]="{'active':mapOn}" class="map-toggle"></button>
          </li>
        </ul>
      </div>
      <button [ngClass]="{'active':filtersDropdownToggle}" class="filter-toggle btn btn--small btn--light  "
              (click)="filtersToggle()"><i class="fa fa-filter"></i> {{'filters'|translate}}
      </button>


    </div>


    <div class="number-of-result">
      <h2 class="text-left text-capitalize"> ({{totalItems}})  {{type|translate}} {{'found'|translate}}</h2>
    </div>

    <div *ngIf="displayMode === 'grid'" class="p-2">
      <app-grid
        [itemsArray]="landsTest$"
        [lang]="lang"
        [gridCol]="gridCol"
        [isLoggedIn]="isLoggedIn"
        class="row"
        [page]="page"
        [propertyType]="type"
        [currentPage]="currentPage"
        [totalItems]="totalItems"
        [itemsPerPage]="itemsPerPage"
        [loading]="loading"
        [savedProperty]="false"
        [unSaveButton]="unSaveButton"
        (mySaveLandEvent)="saveLandAction($event)"
        (myPaginationEvent)="pageLandChanged($event)"
        (pleaseLogin)="showLoginModal()"
      >
      </app-grid>
    </div>
    <div class="row" *ngIf="displayMode === 'list'">
      <app-list
        [isLoggedIn]="isLoggedIn"
        style="width: 100%"
        [itemsArray]="landsTest$"
        [lang]="lang"
        [page]="page"
        [propertyType]="type"
        (myPaginationEvent)="pageLandChanged($event)"
        [loading]="loading"
        [itemsPerPage]="itemsPerPage"
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [savedProperty]="false"
        (mySaveLandEvent)="saveLandAction($event)"
        (pleaseLogin)="showLoginModal()"
      >
      </app-list>
    </div>

    <div class="no_results_page" [ngClass]="{ 'show':noResultDisplay }">
      <img src="./assets/images/icons/home.svg" class="no_results_icon">
      <h3><strong>{{'No Matching Properties'|translate}}</strong></h3>
      <p> {{'no_result_notes'|translate}}</p>
    </div>


    <div class="pageLoading" [ngClass]="{ 'hidden': !loading }"></div>
    <div class="pageDisabled" [ngClass]="{ 'hidden': !pageDisabled }">
      <span class="search-icon"><i class="fa fa-search"></i></span>
      <h2>{{'propertiesLimitTitle'|translate}}</h2>
      <p>{{'propertiesLimitText'|translate}}</p>

    </div>

  </div>

  <div class="map-container " [ngClass]="{'map-on':mapOn}">
    <!--<div class="map-search-box">-->
    <!--<div class="form-check">-->
    <!--<input class="form-check-input" type="checkbox" id="mapSearch" value="1">-->
    <!--<label class="form-check-label" for="mapSearch">{{'searchOnMap'|translate}}</label>-->
    <!--</div>-->
    <!--</div>-->
    <app-maps [ngClass]="{'map-on':mapOn}" [markers]="markersArray" [lang]="lang" [zoom]="14" [isLoggedIn]="isLoggedIn"></app-maps>
  </div>


</section>
<layout-footer></layout-footer>
