<app-top-header></app-top-header>
<section class="knowledge">

  <app-blog-inner-nav></app-blog-inner-nav>

  <div class="knowledge-content mt-5">
    <div class="container">

      <div class="row">

        <div class="col-md-4" *ngFor="let post of posts | async ">
          <a class="post-block" [routerLink]="[URL,post.slug]">
            <div>
              <img src="{{post?.better_featured_image['media_details'].sizes['medium']?.source_url}}">
            </div>
            <span class="feature {{cat}}">{{cat}}</span>
            <h3>{{ post?.title?.rendered }}</h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<layout-footer></layout-footer>
