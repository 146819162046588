import {Component, OnInit, Inject} from '@angular/core';
import {WINDOW} from '@ng-toolkit/universal';

@Component({
  selector: 'app-select-property-type-to-add',
  templateUrl: './select-property-type-to-add.component.html',
  styleUrls: ['./select-property-type-to-add.component.scss']
})
export class SelectPropertyTypeToAddComponent implements OnInit {


  navBarLocation:string = "vertical";
  private subscription;
  private windowWidth;


  constructor(@Inject(WINDOW) private window:Window) {


  }

  ngOnInit() {
    this.windowWidth = this.window.innerWidth;
    if (this.windowWidth < 798) {
      this.navBarLocation = "horizontal";
    } else {
      this.navBarLocation = "vertical";
    }

  }

}
