import {Component, OnInit, Input ,SimpleChanges} from '@angular/core';
import { DoCheck, KeyValueDiffers } from '@angular/core';

@Component({
  selector: 'app-alerts-notice',
  templateUrl: './alerts-notice.component.html',
  styleUrls: ['./alerts-notice.component.scss']
})
export class AlertsNoticeComponent implements OnInit {





  alertType: string;
  title: string;
  noticeText: string;
  icon: string='building_loader.gif';
  showAlert:boolean=false;
  differ: any;

  @Input() aqarStatus:number;
  @Input() type:string;

  constructor(private differs: KeyValueDiffers) {
    this.differ = differs.find({}).create();
  }

  ngOnInit() {
    this.alertHandling(this.aqarStatus);
  }

  // ngDoCheck() {
  //   var changes = this.differ.diff(this.aqarStatus);
  //
  //   if(changes) {
  //     console.log('changes detected');
  //     changes.forEachChangedItem(r => console.log('changed ', r.currentValue));
  //     changes.forEachAddedItem(r => console.log('added ' + r.currentValue));
  //     changes.forEachRemovedItem(r => console.log('removed ' + r.currentValue));
  //   } else {
  //     console.log('nothing changed');
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {

    this.alertHandling(changes.aqarStatus.currentValue);
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }
  alertHandling(aqarStatus) {
    switch (aqarStatus) {

      case 0:// not completed
      {
        this.title='pending';
        this.noticeText='pendingPropertyNotice';
        this.alertType="warning";
        this.icon="pending.svg";
        this.showAlert=true;
        break;
      }
      case 1:// need to check
      {
        this.title='pending';
        this.noticeText='pendingPropertyNotice';
        this.alertType="warning";
        this.icon="pending.svg";
        this.showAlert=true;
        break;
      }
      case 2:
      {
        this.title='pending';
        this.noticeText='pendingPropertyNotice';
        this.alertType="warning";
        this.icon="pending.svg";
        this.showAlert=true;
        break;
      }
      case 3:
      {
        this.title='displayed';
        this.noticeText='successPropertyNotice';
        this.alertType="success";
        this.icon="success.svg";
        this.showAlert=true;
        break;
      }
      case 4:
      {
        this.title='rejected';
        this.noticeText='rejectedPropertyNotice';
        this.alertType="danger";
        this.icon="rejected.svg";
        this.showAlert=true;
        break;
      }
      default:
      {
        this.title='pending';
        this.noticeText='pendingPropertyNotice';
        this.alertType="warning";
        this.showAlert=true;
        this.icon="pending.svg";
        break;
      }
    }
  }

}
