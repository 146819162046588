import {Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import {BlogService} from './../../_services/blog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {WINDOW} from '@ng-toolkit/universal';
import {SeoService} from '../../_services/seo.service';
import {CustomersOrdersService} from '../../_services/customers-orders.service';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {propertyRequest} from '../../models/propertyRequest';
import {AuthService} from './../../_services/auth.service';
import {UserService} from './../../_services/user.service';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {dropdownLists} from '../../_services/dropdown-lists.service';
import {GovernateService} from '../../_services/governate.service';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class ServicesComponent implements OnInit {


  servicesCategoryId: any = 21;
  services: any;
  slug: any;
  post: any;
  pathUrl: any;
  content: any;
  loader: boolean = false;
  selectedName: string = 'ar_name';


  /**
   *  Form
   */


  selectedPropertyType: string = 'lands';

  selectedGovId: number;
  selectedGovString: string;

  selectedTownId: number;
  selectedTownString: string;

  selectedAreaId: number;
  selectedAreaString: string;

  selectedSearchTypeId: number;
  locationSearchText: string = 'address';


  propertyRequest: propertyRequest = new propertyRequest();
  propertyRequestForm: FormGroup;
  fullName: FormControl;
  mobile: FormControl;
  propertyType: FormControl;
  government: FormControl;
  city: FormControl;
  areaZone: FormControl;
  blockNumber: FormControl;
  sectorNumber: FormControl;
  parcelNumber: FormControl;
  requestType: FormControl;
  isLoggedIn: any;

  lang: any;
  property_request_success_msg: any;
  property_request_fail_msg: any;


  loading = false;
  error = '';
  alert: boolean = false;
  alertClass: string = '';
  alertMsg: string = '';

  propertyTypes: any;
  governateList: any;
  townList: any = [];
  areaList: any = [];
  alertMsgTitle: any;
  selectedCityId: any;

  constructor(@Inject(WINDOW) private window: Window,
              private blogService: BlogService,
              private route: ActivatedRoute,
              private router: Router,
              private seo: SeoService,
              private sanitizer: DomSanitizer,
              private customerOrdersServices: CustomersOrdersService,
              private auth: AuthService,
              private userService: UserService,
              config: NgbDropdownConfig,
              public translate: TranslateService,
              public Lists: dropdownLists,
              private GovernateService: GovernateService) {

    this.pathUrl = document.location.href;

    config.autoClose = false;

    let lang = translate.currentLang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }
    translate.get('address').subscribe((res: string) => {
      this.locationSearchText = res;
    });
    translate.get('property_request_success_msg').subscribe((res: string) => {
      this.property_request_success_msg = res;
    });

    translate.get('property_request_fail_msg').subscribe((res: string) => {
      this.property_request_fail_msg = res;
    });

  }

  ngOnInit() {

    this
      .GovernateService
      .getGovernate()
      .subscribe(res => {
        this.governateList = res['data'];
      });

    this.seo.setTags();
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      console.log(this.slug);
      if (this.slug == 'undefined' || this.slug == null) {
        console.log('yes im empty ');
        this.getFirstPost(this.servicesCategoryId);
      } else {
        this.getPostBySlug(this.slug);
      }
    });

    this.getServices();
    this.propertyTypes = this.Lists.getpropertyTypes();

    this.createFormControls();
    this.createForm();


    this.isLoggedIn = this.auth.isAuthenticated();
    if (this.isLoggedIn) {
      this.propertyRequest.email = this.userService.email;
      this.propertyRequest.fullName = this.userService.fullName;
      this.propertyRequest.mobile = this.userService.phone;

    }
    this.propertyRequest.requestType = 'property_value';
  }


  createFormControls() {

    this.fullName = new FormControl('', Validators.required);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]);

    this.propertyType = new FormControl('', []);

    this.government = new FormControl('', []);
    this.city = new FormControl('', []);
    this.areaZone = new FormControl('', []);

    this.blockNumber = new FormControl('', []);
    this.sectorNumber = new FormControl('', []);
    this.parcelNumber = new FormControl('', []);
    this.requestType = new FormControl('', []);

  }

  createForm() {
    this.propertyRequestForm = new FormGroup({
      fullName: this.fullName,
      mobile: this.mobile,
      propertyType: this.propertyType,
      government: this.government,
      city: this.city,
      areaZone: this.areaZone,
      blockNumber: this.blockNumber,
      sectorNumber: this.sectorNumber,
      parcelNumber: this.parcelNumber,
      requestType: this.requestType
    });
  }


  onSubmit(): void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.propertyRequest = this.propertyRequestForm.value;

    this.customerOrdersServices.propertyRequest(this.propertyRequest)
      .subscribe(res => {
        this.loading = false;
        this.alert = true;
        if (res['success']) {
          this.propertyRequestForm.reset();
          this.alertClass = 'success';
          this.alertMsg = this.property_request_success_msg;
        } else {
          this.alertClass = 'danger';
          this.alertMsg = this.property_request_fail_msg;
        }

      });
  }


  getServices() {
    this.blogService.getServicesMenu(this.servicesCategoryId).subscribe(res => {
      this.services = res;
    });
  }


  getFirstPost(categoryId) {
    this.loader = true;
    this.blogService.getRealEstateFirstPostOfCategory(categoryId).subscribe(res => {
      this.post = res[0];
      this.router.navigate(['services/', this.post.slug]);
    });
  }

  getPostBySlug(slug) {
    this.loader = true;
    this.content = '';
    this.post = '';
    this.blogService.getPostBySlug(slug).subscribe(res => {
      this.post = res[0];
      // this.spyScrollItem = [];
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.post.content.rendered);
      this.loader = false;

      // this.tocNav = this.getToc(this.content);
      this.window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }


  getGovernate($event) {
    this.selectedGovId = $event['id'];
    this.selectedTownId = null;
    this.selectedTownString = null;

    this.selectedAreaId = null;
    this.selectedAreaString = null;
    this.selectedGovString = $event[this.selectedName];
    this.getTownList(this.selectedGovId);
    this.updateLocationSearchText();
  }

  getTownList(govId) {
    this
      .GovernateService
      .getTowns(govId)
      .subscribe(res => {
        this.townList = res['data'];
      });

  }


  updateLocationSearchText() {
    let locationString = (this.selectedGovString) + (this.selectedTownString == null ? '' : ',' + this.selectedTownString) + (this.selectedAreaString == null ? '' : ', ' + this.selectedAreaString);
    this.locationSearchText = locationString;

  }

  getTown($event) {
    this.selectedTownId = $event['id'];
    this.selectedTownString = $event[this.selectedName];

    this.selectedAreaId = null;
    this.selectedAreaString = null;

    this.getAreaList(this.selectedGovId, this.selectedTownId);
    this.updateLocationSearchText();

  }


  getAreaList(govId, townId) {
    this
      .GovernateService
      .getArea(govId, townId)
      .subscribe(res => {
        this.areaList = res['data'];
      });
  }

  getArea($event) {
    this.selectedAreaId = $event['id'];
    this.selectedAreaString = $event[this.selectedName];
    this.updateLocationSearchText();
  }


}
