<app-top-header></app-top-header>


<div class="dashboard bg-white">

  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">{{'Account Overview'|translate}}</h1>
      </div>
      <div class="action">
        <button class="btn  btn--light--default " (click)="openOrderModal()">{{'requestInfo'|translate}}</button>
        <a class="btn btn--primary m-1" routerLink="/dashboard/add-properties">{{'AddNewProperty'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details">

      <div class="property-breadcrumb mb-2" *ngIf="pageNotFound==false">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a routerLink="/dashboard/properties/{{propertyType}}">{{propertyType|translate}}</a></li>
        </ul>
      </div>
      <app-page-not-found-alert *ngIf="pageNotFound==true"></app-page-not-found-alert>
      <app-alerts-notice [aqarStatus]="aqarStatus" type="alert" *ngIf="pageNotFound==false"></app-alerts-notice>
      <div class="row" *ngIf="pageNotFound==false">
        <div class="col-md-5 mb-2">
          <app-angular-gallery
            [images]="images"
          >
          </app-angular-gallery>
        </div>
        <div class="col-md-6">
          <div class="facts property-details" id="details">
            <div class=" mb-2 ">
              <div class="row">
                <div class="col-md-8 col-sm-12 mb-4">
                  <h2 class="text-left under-line-header" id="facts">{{'facts'|translate}}</h2>

                  <dl>
                    <dt class="">{{'title'|translate}}</dt>
                    <dd class="">{{lang=='ar'?item?.data?.ar_title : item?.data?.en_title}}</dd>
                  </dl>

                  <dl>
                    <dt class="">{{'propertyType'|translate}}</dt>
                    <dd class="">{{propertyType|translate}}</dd>
                  </dl>

                  <dl>
                    <dt class="">{{'offer'|translate}}</dt>
                    <!--<dd class="">{{item?.data?.offer!=null ? item?.data?.offer:'for_buy' |translate}}</dd>-->
                    <dd class="">{{item?.data?.offer|translate}}</dd>
                  </dl>


                  <dl>
                    <dt class="">{{'area'|translate}}</dt>
                    <dd class="">{{item?.data?.area| number}} m²</dd>
                  </dl>
                  <dl>
                    <dt class="">{{'price'|translate}}</dt>
                    <dd class="price">{{ item?.data?.price|currency:'$' :'symbol' : '1.0' }}</dd>
                  </dl>

                  <dl>
                    <dt class="">{{'Governate'|translate}}</dt>
                    <dd class="">{{ lang=='ar'?item?.data.ar_governmentName : item?.data?.en_governmentName }}
                    </dd>
                  </dl>

                  <dl>
                    <dt class="">{{'Zone'|translate}}</dt>
                    <dd class="">
                      {{ lang=='ar'?item?.data.ar_cityName : item?.data?.en_cityName }}
                      <span *ngIf="item?.data?.ar_zoneName">-</span>
                      {{ lang=='ar'?item?.data.ar_zoneName: item?.data?.en_zoneName }}
                    </dd>
                  </dl>

                  <dl>
                    <dt class="">{{'address'|translate}}</dt>
                    <dd class="">{{ lang=='ar'?item?.data.arAddress : item?.data?.enAddress }}
                    </dd>
                  </dl>




                  <dl *ngIf="item?.data?.blockNumber">
                    <dt class="">{{'parcelDetails'|translate}}:</dt>
                    <dd class="">
                      <span>{{'parcel'|translate}}({{item?.data?.parcelNumber}})</span>
                      <span>{{'block'|translate}}({{item?.data?.blockNumber}})</span>
                      <span *ngIf="item?.data?.sectorNumber !=null">-{{'sector'|translate}}({{item?.data?.sectorNumber}})</span>
                    </dd>
                  </dl>

                  <dl *ngIf="item?.data?.deed_issuance">
                    <dt class="">{{'deedType'|translate}}</dt>
                    <dd class="">{{item?.data?.deed_issuance|translate}}</dd>
                  </dl>


                  <dl *ngIf="item?.data?.general_classification" >
                    <dt class="">{{'land_classification'|translate}}</dt>
                    <dd class="">{{item?.data?.general_classification|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.bestProjects">
                    <dt class="">{{'bestUse'|translate}}:</dt>
                    <dd class="">{{item?.data?.bestProjects|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.building_percentage">
                    <dt>{{'construction_area'|translate}}</dt>
                    <dd>{{item?.data?.building_percentage}}%</dd>
                  </dl>

                  <dl *ngIf="item?.data?.floors_counts">
                    <dt>{{'permitted_floor'|translate}}</dt>
                    <dd>{{item?.data?.floors_counts}} {{'floors'|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.classification">
                    <dt>{{'municipality_classification'|translate}}</dt>
                    <dd>{{item?.data?.classification|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.security_classification">
                    <dt>{{'securityClassification'|translate}}</dt>
                    <dd>{{item?.data?.security_classification =='undefined'?'':item?.data?.security_classification|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.buildingCategory">
                    <dt>{{'buildingCategory'|translate}}</dt>
                    <dd>{{item?.data?.buildingCategory|translate}}</dd>
                  </dl>


                  <dl *ngIf="item?.data?.front_offset">
                    <dt>{{'offset'|translate}}</dt>
                    <dd>
                      <span>{{'front_offset'|translate}} ({{item?.data?.front_offset}})</span>
                      <span>-{{'side_offset'|translate}} ({{item?.data?.side_offset}})</span>
                      <span>-{{'back_offset'|translate}} ({{item?.data?.back_offset}})</span>
                    </dd>
                  </dl>
                  <dl *ngIf="item?.data?.roomsNumber">
                    <dt>{{'roomsNumber'|translate}}</dt>
                    <dd>{{item?.data?.roomsNumber}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.bathroomsNumber">
                    <dt>{{'bathroomsNumber'|translate}}</dt>
                    <dd>{{item?.data?.bathroomsNumber}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.parkingNumber">
                    <dt>{{'parkingNumber'|translate}}</dt>
                    <dd>{{item?.data?.parkingNumber}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.balconiesNumber">
                    <dt>{{'balconiesNumber'|translate}}</dt>
                    <dd>{{item?.data?.balconiesNumber}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.furnishings">
                    <dt>{{'furnishings'|translate}}</dt>
                    <dd>{{item?.data?.furnishings|translate}}</dd>
                  </dl>


                </div>
                <div class="col-md-4 col-sm-12 mb-2">

                  <h2 class="text-left under-line-header">{{'features'|translate}}</h2>

                  <div class="features">
                    <ul>
                      <li *ngFor="let feature of features">
                      <i class="fa fa-check"></i> {{feature|translate}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="clearfix"></div>
      <div class="property-details" *ngIf="pageNotFound==false">
        <div class="container">
          <div class="mb-2">
            <h3 class="under-line-header mt-5">{{'property_description'|translate}}</h3>
            <p class="color-typography-default mt-3">
              {{ lang=='ar'?item?.data.ar_description:item?.data?.en_description }}
            </p>
            <hr class="mt-1">
            <!--<h2 class="text-left" id="advice">{{'advice'|translate}}</h2>-->
            <h3 class="under-line-header" id="advice">{{'advice'|translate}}</h3>

            <!--<h3 class="under-line-header">Meters</h3>-->
            <div class="mt-1 mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label>{{'readiness'|translate}}</label>
                  <div class="progress mb-4">
                    <div
                      class="progress-bar bg-secondary"
                      role="progressbar"
                      [style.width]="item?.data?.readiness + '%'"
                      aria-valuenow="item?.data?.readiness"
                      aria-valuemin="0"
                      aria-valuemax="100">{{item?.data?.readiness}}%
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>{{'investment_quality'|translate}}</label>
                  <div class="progress mb-4">
                    <div
                      class="progress-bar bg-secondary"
                      role="progressbar"
                      [style.width]="item?.data?.investment_quality + '%'"
                      aria-valuenow="item?.data?.investment_quality"
                      aria-valuemin="0"
                      aria-valuemax="100">{{item?.data?.investment_quality}}%
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>{{'supply_on_the_market'|translate}}</label>
                  <div class="progress mb-4">
                    <div
                      class="progress-bar bg-secondary"
                      role="progressbar"
                      [style.width]="item?.data?.supply + '%'"
                      aria-valuenow="item?.data?.supply"
                      aria-valuemin="0"
                      aria-valuemax="100">{{item?.data?.supply}}%
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>{{'demand_for_the_area'|translate}}</label>

                  <div class="progress mb-4">
                    <div
                      class="progress-bar bg-secondary"
                      role="progressbar"
                      [style.width]="item?.data?.area_demand + '%'"
                      aria-valuenow="item?.data?.area_demand"
                      aria-valuemin="0"
                      aria-valuemax="100">{{item?.data?.area_demand}}%
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label>{{'area_development'|translate}}</label>
                  <div class="progress mb-4">
                    <div
                      class="progress-bar bg-secondary"
                      role="progressbar"
                      [style.width]="item?.data?.area_development + '%'"
                      aria-valuenow="item?.data?.area_development"
                      aria-valuemin="0"
                      aria-valuemax="100">{{item?.data?.area_development}}%
                    </div>
                  </div>
                </div>
              </div>
            </div>


          <div class="mb-3 mt-3">
            <!--<h2 class="text-left" id="map">{{'map'|translate}}</h2>-->
            <h3 class="under-line-header mb-3" id="map">{{'map'|translate}}</h3>

            <div style="height: 500px">
              <app-maps [ngClass]="{'map-on':mapOn}" [markers]="markersArray" [lang]="lang"></app-maps>

            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<layout-footer></layout-footer>
