import {Component, OnInit} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {AuthService} from '../../../_services/auth.service';
import {forgotPasswordModel} from '../../../models/forgot-password-model';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Rx';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})


export class ForgotPasswordComponent implements OnInit {

  // emailData = new EmailData();
  forgotPassword: forgotPasswordModel = new forgotPasswordModel();

  error = '';
  uData: any;
  alert: boolean = false;
  alertClass: string = '';
  alertMsg: string = '';
  loading: boolean = false;

  forgotPasswordForm: FormGroup;
  mobile: FormControl;
  email: FormControl;
  resetBy: FormControl;
  username: string;

  isMobile = true;
  isEmail = false;

  constructor(public activeModal: NgbActiveModal,
              private auth: AuthService) {

  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }


  createFormControls() {
    this.email = new FormControl('', [
      // Validators.required,
      // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      // Validators.email
    ]);
    this.mobile = new FormControl('', [

    ]);
    this.resetBy = new FormControl('mobile', [
      Validators.required,
    ]);
  }

  createForm() {
    this.forgotPasswordForm = new FormGroup({
      mobile: this.mobile,
      email: this.email,
      resetBy: this.resetBy,
    });
  }

  changeResetBy($event) {
    console.log($event);
    if ($event === 'mobile') {
      this.isMobile = true;
      this.isEmail = false;

      this.email.updateValueAndValidity();
      this.email.clearValidators();
      this.email.setErrors(null);

      this.mobile.setValidators([
        Validators.required,
        Validators.minLength(9)]
      );
    }
    if ($event === 'email') {
      this.isMobile = false;
      this.isEmail = true;

      this.mobile.updateValueAndValidity();
      this.mobile.clearValidators();
      this.mobile.setErrors(null);

      this.email.setValidators([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        Validators.email]
      );
    }
  }

  onSubmit(): void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;

    if (this.resetBy.value === 'mobile') {
      this.username = this.mobile.value;
      // this.forgotPassword = this.forgotPasswordForm.value;
    } else {
      this.username = this.email.value;
    }
    this.auth.forgotPassword(this.username)
      .catch(error => {
        // here we can show an error message to the user,
        // for example via a service
        // console.error("error catched", error.error['msg']);
        this.alertClass = 'danger';
        // this.alertMsg = error.error['msg']+"test";
        this.alertMsg = 'Error';
        this.alert = true;
        // return Observable.of({description: "Error Value Emitted"});
        return Observable.of(error.error['msg']);

      })
      .subscribe(res => {
          this.loading = false;
          this.alert = true;
          if (res['success']) {
            this.alertClass = 'success';
            this.alertMsg = res['msg'];
          } else {
            this.alertClass = 'danger';
            console.log(res);
            this.alertMsg = res;
          }
        },
        error => {
          // console.error("This line is never called ", error);
        }
        // () => console.log("HTTP Observable completed...")
      );

  }


}
