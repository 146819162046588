<h2 *ngIf="itemsArray.length > 0" class="under-line-header mb-3 mt-5">{{'morePropertyInSameArea'|translate}}</h2>
<div class="grid-row">

  <div
    *ngFor="let item of itemsArray"
    class="grid-item"
  >
    <a [routerLink]="['/'+ item?.type,item?.id]">
      <img
        class="img"
        src="{{item?.mainImage}}"
        alt=""
        default="./assets/images/aqar-thumb.jpg"
      >
    </a>

    <span class="badge badge-light" style=" top: 10px; left: 5px;">
      {{item?.offer|translate}}
    </span>
    <span class="badge badge-daman" *ngIf="item?.type" style=" top: 10px; left: 55px;">
      {{item?.type|translate}}
    </span>

    <div class="info-area">
      <div class="caption">
        <a [routerLink]="['/'+item?.type,item?.id]">
          <h4>
            <span class="">{{ lang == 'ar' ? item?.ar_title : item?.en_title }}</span>
          </h4>
        </a>
        <div class="address">
          <span class="aq aq-address">
            <!--<img width="10" src="./assets/images/icons/address.svg">-->
          </span>
          <span class="">   {{ lang == 'ar' ? item?.ar_governmentName : item?.en_governmentName }}</span>
          <span> - {{ lang == 'ar' ? item?.ar_cityName : item?.en_cityName }}</span>
          <span *ngIf="item?.data?.ar_zoneName"> - </span>
          <span>, {{ lang == 'ar' ? item?.ar_zoneName : item?.en_zoneName}}</span>
        </div>
        <div class="classification" *ngIf="item?.classification">
          <img class="icon" src="./assets/images/icons/list-grid.svg"> {{item?.classification|translate}}
        </div>
      </div>

      <div class="details">
        <div class="detail">
          <div class="bed ml-1 mr-1" *ngIf="item?.roomsNumber">
            <img class="icon" src="./assets/images/icons/icon-bed.svg">{{item?.roomsNumber}}
          </div>
          <div class="bath ml-1 mr-1" *ngIf="item?.bathroomsNumber">
            <img class="icon" src="./assets/images/icons/icon-bath.svg">{{item?.bathroomsNumber}}
          </div>
          <div class="area ml-1 mr-1" *ngIf="item?.area"><span class="aq aq-area"></span>{{item?.area | number}}<span
            class="areaSymbol">m²</span>
          </div>
        </div>
        <div class="price" *ngIf="isLoggedIn">${{item.price | number }}<span
          *ngIf="item?.offer=='for_rent'">/{{'month'|translate}} </span>
        </div>

      </div>

    </div>
  </div>
</div>
