import {Injectable} from '@angular/core';
// import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class dropdownLists {

  propertyTypesList:any = [
    {id: 'lands', en_name: 'Lands', ar_name: 'ارض', advanced: false},
    {id: 'apartments', en_name: 'Apartments', ar_name: 'شقق', advanced: true},
    {id: 'villas', en_name: 'Villas & Houses', ar_name: 'فلل وبيوت مستقلة', advanced: true},
    {id: 'stores', en_name: 'Stores', ar_name: 'محلات تجاري', advanced: true},
    {id: 'offices', en_name: 'Offices', ar_name: 'مكاتب', advanced: true},
    {id: 'buildings', en_name: 'Buildings', ar_name: 'عمائر', advanced: false},

  ];


  getUnitFeatures:any = [
    {id: 'parking', en_name: 'parking', ar_name: 'باركينج'},
    {id: 'elevator', en_name: 'elevator', ar_name: 'مصعد كهربائي'},
    {id: 'central_heating', en_name: 'central heating', ar_name: 'تدفئة مركزية'},
    {id: 'garden', en_name: 'garden', ar_name: 'حديقة'},
    {id: 'public_transportation', en_name: 'public transportation', ar_name: 'مواصلات عامة'},
    {id: 'guard', en_name: 'guard', ar_name: 'حارس'},
    {id: 'kitchen', en_name: 'kitchen', ar_name: 'مطبخ'},
    {id: 'service_hall', en_name: 'service hall', ar_name: 'قاعة خدمات'},
    {id: 'central_gas', en_name: 'central gas', ar_name: 'غاز مركزي'}
  ];

  getLandFeatures:any = [
    {id: 'water_service', en_name: 'water service', ar_name: 'خدمة المياه'},
    {id: 'electricity_service', en_name: 'electricity service', ar_name: 'خدمة الكهرباء'},
    {id: 'sewage_service', en_name: 'sewage service', ar_name: 'خدمة الصرف الصحي'},
    {id: 'located_in_the_main_street', en_name: 'located in the main street', ar_name: 'تقع على شارع رئيسي'},
    {id: 'located_in_the_sub_street', en_name: 'located in the sub street', ar_name: 'تقع على شارع فرعي'},
    {id: 'planted_land', en_name: 'planted land', ar_name: 'ارض مزروعة'},
    {id: 'public_transportation', en_name: 'public transportation', ar_name: 'مواصلات عامة'},
    {id: 'beautiful_view', en_name: 'beautiful view', ar_name: 'اطلالة جميلة'},
  ];

  getBuildingFeatures:any = [
    {id: 'parking', en_name: 'parking', ar_name: 'باركينج'},
    {id: 'elevator', en_name: 'elevator', ar_name: 'مصعد كهربائي'},
    {id: 'central_heating', en_name: 'central_heating', ar_name: 'تدفئة مركزية'},
    {id: 'garden', en_name: 'garden', ar_name: 'حديقة'},
    {id: 'public_transportation', en_name: 'public_transportation', ar_name: 'مواصلات عامة'},
    {id: 'guard', en_name: 'guard', ar_name: 'حارس'},
    {id: 'kitchen', en_name: 'kitchen', ar_name: 'مطبخ'},
    {id: 'service_hall', en_name: 'service_hall', ar_name: 'قاعة خدمات'},
    {id: 'central_gas', en_name: 'central_gas', ar_name: 'غاز مركزي'}];


  propertyStatusList:any = [
    {id: 'furnished', en_name: 'furnished', ar_name: 'مفروش'},
    {id: 'unfurnished', en_name: 'unfurnished', ar_name: 'غير مفروش'}
  ];

  roomList:any = [
    {id: 1, en_name: 'studio', ar_name: 'استديو'},
    {id: 2, en_name: '2 Rooms', ar_name: 'غرفتين'},
    {id: 3, en_name: '3+ Rooms', ar_name: '3 غرف واكثر'}
  ];


  PriceToRentList:any = [
    {id: 0, en_name: 'any', ar_name: 'الكل'},
    {id: 250, en_name: '$250', ar_name: '$250'},
    {id: 500, en_name: '$500', ar_name: '$500'},
    {id: 750, en_name: '$750', ar_name: '$750'},
    {id: 1000, en_name: '$1,000', ar_name: '$1,000'},
    {id: 1500, en_name: '$1,500', ar_name: '$1,500'},
    {id: 2000, en_name: '$2,000', ar_name: '$2,000'},
    {id: 2500, en_name: '$2,500', ar_name: '$2,500'},
    {id: 3000, en_name: '$3,000', ar_name: '$3,000'},
    {id: 3500, en_name: '$3,500', ar_name: '$3,500'},
    {id: 4000, en_name: '$4,000', ar_name: '$4,000'},
    {id: 4500, en_name: '$4,500', ar_name: '$4,500'},
    {id: 5000, en_name: '$5,000', ar_name: '$5,000'},
  ];

  PriceToBuyList:any = [
    {id: 0, en_name: 'any', ar_name: 'الكل'},
    {id: 5000, en_name: '$5,000', ar_name: '$5,000'},
    {id: 10000, en_name: '$10,000', ar_name: '$10,000'},
    {id: 20000, en_name: '$20,000', ar_name: '$20,000'},
    {id: 40000, en_name: '$40,000', ar_name: '$40,000'},
    {id: 60000, en_name: '$60,000', ar_name: '$60,000'},
    {id: 80000, en_name: '$80,000', ar_name: '$80,000'},
    {id: 100000, en_name: '$100,000', ar_name: '$100,000'},
    {id: 200000, en_name: '$200,000', ar_name: '$200,000'},
    {id: 400000, en_name: '$400,000', ar_name: '$400,000'},
    {id: 600000, en_name: '$600,000', ar_name: '$600,000'},
    {id: 800000, en_name: '$800,000', ar_name: '$800,000'},
    {id: 1000000, en_name: '$1,000,000+', ar_name: '$1,000,000+'},
  ];

  areaPropertyList:any = [
    {id: null, en_name: 'any', ar_name: 'الكل'},
    {id: 50, en_name: '50/m²', ar_name: '50/m²'},
    {id: 100, en_name: '100/m²', ar_name: '100/m²'},
    {id: 150, en_name: '150/m²', ar_name: '150/m²'},
    {id: 200, en_name: '200/m²', ar_name: '200/m²'},
    {id: 300, en_name: '300/m²', ar_name: '300/m²'},
    {id: 400, en_name: '400/m²', ar_name: '400/m²'},
    {id: 500, en_name: '500/m²', ar_name: '500/m²'},
    {id: 750, en_name: '750/m²', ar_name: '750/m²'},
    {id: 1000, en_name: '1,000/m²', ar_name: '1,000/m²'},
    {id: 1500, en_name: '1,500/m²', ar_name: '1,500/m²'},
    {id: 2000, en_name: '2,000/m² +', ar_name: '2,000/m² +'},
  ];


  currency:any = [
    {id: 'USD', symbol: '$'},
    {id: 'NIS', symbol: '₪'},
    {id: 'JOD', symbol: 'JOD'},
  ];


  unitUsageList:any = [
    {id: 1, en_name: 'residential', ar_name: 'اسكان'},
    {id: 2, en_name: 'commercial', ar_name: 'تجاري'},
    {id: 3, en_name: 'industrial', ar_name: 'صناعي'}
  ];


  paymentType:any = [
    {id: 1, en_name: 'monthly', ar_name: 'شهري'},
    {id: 3, en_name: 'quarterly', ar_name: 'ربع سنوي'},
    {id: 6, en_name: 'midterm', ar_name: 'نصف سنوي'},
    {id: 12, en_name: 'yearly', ar_name: 'سنوي'},
  ];


  getPriceToBuy() {
    return this.PriceToBuyList;
  }

  getPriceToRent() {
    return this.PriceToRentList;
  }

  getAreaPropertyList() {
    return this.areaPropertyList;
  }

  getpropertyTypes() {
    return this.propertyTypesList;
  }

  getPropertyStatusList() {
    return this.propertyStatusList;
  }

  getRoomList() {
    return this.roomList;
  }


  getCurrency() {
    return this.currency;
  }

  getUnitUsageList() {
    return this.unitUsageList;
  }
  
  getPaymentTypeList() {
    return this.paymentType;
  }

}
