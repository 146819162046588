import {Component, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';

import {UserService} from './../../_services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {SortType, ColumnMode} from '@swimlane/ngx-datatable';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CustomersComponent implements OnInit {

  // @ViewChild('customerTable') table: any;
  expanded: any = {};

  sortType = SortType;
  columnMode = ColumnMode;

  lang: string;
  customers: any;
  customersListReady = false;
  page: any = 1;
  currentPage: any = 1;
  itemsPerPage: number = 20;
  queryParameters: any = [];
  loading = true;
  totalItems: number;
  customer: any;
  gridCol: any;

  columns = [
    {prop: 'user_id'},
    {prop: 'full_name'},
    {prop: 'mobile_number'},
    {prop: 'id_number'},
    {prop: 'email'},
    {prop: 'user_id'},

  ];
  rows = [];


  full_name: FormControl;
  se_full_name: any;
  mobile_number: FormControl;
  se_mobile_number: any;
  id_number: FormControl;
  se_id_number: any;
  email: FormControl;
  selected_email: any;

  customerSearchForm: FormGroup;


  constructor(private  userService: UserService,
              public translate: TranslateService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder) {

  }

  ngOnInit() {


    this.createFormControls();
    this.createForm();
    this.lang = this.translate.currentLang;

    this.route.queryParams.subscribe((params: any) => {

      this.currentPage = params['page'] || 1;
      this.setFormValueFromSearchParameter();
      this.search();
    });

  }


  createFormControls() {
    this.full_name = new FormControl('', []);
    this.mobile_number = new FormControl('', []);
    this.id_number = new FormControl('', []);
    this.email = new FormControl('', []);
  }

  createForm() {
    this.customerSearchForm = this.fb.group({
      full_name: this.full_name,
      mobile_number: this.mobile_number,
      id_number: this.id_number,
      email: this.email,
    });
  }


  setFormValueFromSearchParameter() {
    let se_full_name = this.route.snapshot.queryParams['full_name'];
    this.customerSearchForm.get('full_name').patchValue(se_full_name);

    let se_id_number = this.route.snapshot.queryParams['id_number'];
    this.customerSearchForm.get('id_number').patchValue(se_id_number);

    let se_mobile_number = this.route.snapshot.queryParams['mobile_number'];
    this.customerSearchForm.get('mobile_number').patchValue(se_mobile_number);
  }


  getCustomersList() {
    let page = this.currentPage;
    if (page != null) {
      this.queryParameters['page'] = page;
    }


    let full_name = this.route.snapshot.queryParams['full_name'];

    if (full_name != undefined) {
      this.queryParameters['full_name'] = this.route.snapshot.queryParams['full_name'];
      this.se_full_name = this.queryParameters['full_name'];
    }

    let id_number = this.route.snapshot.queryParams['id_number'];
    if (id_number != undefined) {
      this.queryParameters['id_number'] = this.route.snapshot.queryParams['id_number'];
      this.se_id_number = this.queryParameters['id_number'];
    }

    let mobile_number = this.route.snapshot.queryParams['mobile_number'];

    if (mobile_number != undefined) {
      this.queryParameters['mobile_number'] = this.route.snapshot.queryParams['mobile_number'];
      this.se_id_number = this.queryParameters['id_number'];
    }

    this.userService.getCustomers(this.queryParameters).subscribe((res: any) => {
      this.customers = (res.data.items);
      this.rows = res.data.items;
      this.totalItems = res.data.pagination.total_count;

      this.customersListReady = true;
      this.loading = false;
    });
  }


  pageChanged(e) {
    this.currentPage = e;
    this.search();
  }

  search() {
    let page = this.currentPage;
    if (page != null) {
      this.queryParameters['page'] = page;
    }

    this.customer = this.customerSearchForm.value;

    let full_name = this.customer['full_name'];
    let mobile_number = this.customer['mobile_number'];
    let id_number = this.customer['id_number'];


    if (full_name != undefined) {
      this.se_full_name = full_name;
      this.queryParameters['full_name'] = this.se_full_name;
    }

    if (id_number != undefined) {
      this.se_id_number = id_number;
      this.queryParameters['id_number'] = this.se_id_number;
    }


    if (mobile_number != undefined) {
      this.se_mobile_number = mobile_number;
      this.queryParameters['mobile_number'] = this.se_mobile_number;
    }
    this.router.navigate(['/dashboard/customers'],
      {
        queryParams: this.queryParameters
      }
    );
    this.getCustomersList();
  }


}
