import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {propertyRequest} from '../../models/propertyRequest';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import {dropdownLists} from '../../_services/dropdown-lists.service';
import {CustomersOrdersService} from '../../_services/customers-orders.service';
import {AuthService} from './../../_services/auth.service';
import {UserService} from './../../_services/user.service';


@Component({
  selector: 'app-property-request',
  templateUrl: './property-request.component.html',
  styleUrls: ['./property-request.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class PropertyRequestComponent implements OnInit {


  propertyRequest: propertyRequest = new propertyRequest();


  error = '';
  uData: any;
  alert: boolean = false;
  alertClass: string = '';
  alertMsg: string = '';
  alertMsgTitle: string = '';
  alertDisplay: boolean = true;
  thankYouForContacting: string = '';
  receivedEnquiry: string = '';
  loading: boolean = false;

  propertyRequestForm: FormGroup;

  fullName: FormControl;
  mobile: FormControl;
  email: FormControl;
  notes: FormControl;
  propertyType: FormControl;
  propertyFor: FormControl;
  propertyArea: FormControl;
  budget: FormControl;
  requestType: FormControl;

  propertyForList: any = [

    {
      id: 'for_sell',
      ar_name: 'للبيع',
      en_name: 'For Sell',
    },
    {
      id: 'for_rent',
      ar_name: 'للايجار',
      en_name: 'For Rent',
    }
  ];


  propertyTypeList: any;


  lang: any;
  selectedName: any;
  propertyTypesList: any;
  property_request_success_msg: any;
  property_request_fail_msg: any;
  isLoggedIn: any;

  constructor(public translate: TranslateService,
              public Lists: dropdownLists,
              public customerOrdersServices: CustomersOrdersService,
              private auth: AuthService,
              private userService: UserService) {

    let lang = translate.currentLang;
    this.lang = lang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }

    translate.get('property_request_success_msg').subscribe((res: string) => {
      this.property_request_success_msg = res;
    });

    translate.get('property_request_fail_msg').subscribe((res: string) => {
      this.property_request_fail_msg = res;
    });


    this.propertyTypesList = Lists.propertyTypesList;

  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.lang = this.translate.currentLang;
    this.isLoggedIn = this.auth.isAuthenticated();
    if (this.isLoggedIn) {
      this.propertyRequest.email = this.userService.email;
      this.propertyRequest.fullName = this.userService.fullName;
      this.propertyRequest.mobile = this.userService.phone;

    }
    this.propertyRequest.requestType = 'property_request';

  }


  createFormControls() {
    this.fullName = new FormControl('', Validators.required);
    this.email = new FormControl('', [
      // Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      Validators.email
    ]);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]);
    this.notes = new FormControl('', [
      Validators.required,
    ]);

    this.propertyFor = new FormControl('', [
      Validators.required,
    ]);

    this.propertyArea = new FormControl('', [
      // Validators.required,
    ]);
    this.propertyType = new FormControl('', [
      // Validators.required,
    ]);

    this.budget = new FormControl('', []);
    this.requestType = new FormControl('', []);

  }


  createForm() {
    this.propertyRequestForm = new FormGroup({
      fullName: this.fullName,
      email: this.email,
      mobile: this.mobile,
      notes: this.notes,
      propertyFor: this.propertyFor,
      propertyArea: this.propertyArea,
      propertyType: this.propertyType,
      budget: this.budget,
      requestType: this.requestType,
    });
  }


  setPropertyFor() {

  }

  setPropertyType() {

  }

  onSubmit(): void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.propertyRequest = this.propertyRequestForm.value;

    this.customerOrdersServices.propertyRequest(this.propertyRequest)
      .subscribe(res => {
        this.loading = false;
        this.alert = true;
        if (res['success']) {
          this.propertyRequestForm.reset();


          this.alertClass = 'success';
          this.alertMsg = this.property_request_success_msg;
        } else {
          this.alertClass = 'danger';
          this.alertMsg = this.property_request_fail_msg;
        }

      });

  }


}
