import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-propperty-added-successfully',
  templateUrl: './propperty-added-successfully.component.html',
  styleUrls: ['./propperty-added-successfully.component.scss']
})
export class ProppertyAddedSuccessfullyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
