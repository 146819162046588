<app-top-header></app-top-header>
<section class="property">
  <div class="breadcrumb-container">
    <div class="container">
      <nav aria-label="breadcrumb" class="breadcrumb-section">
        <ol class="breadcrumb">
          <!--<li class="back" *ngIf="this.referrerUrl"><a routerLink="{{this.referrerUrl}}"> < {{'backToSearch'|translate}} <span>|</span> </a></li>-->
          <li class="breadcrumb-item"><a routerLink="/">{{'home'|translate}}</a></li>
          <li class="breadcrumb-item"><a class="active"
                                         routerLink="/search/{{propertyType}}">{{propertyType|translate}}</a></li>
          <!--<li class="breadcrumb-item active" aria-current="page">{{ lang=='ar'?item?.data?.ar_title :item?.data?.en_title}}</li>-->
        </ol>


        <div class="actions-btns shrink mobile-only">
          <ul class="nav justify-content-end">
            <li>
              <button
                class="btn  btn--small saveBtn"
                (click)="saveLandAction(item?.data?.id)"
                [ngClass]="{ 'btn-outline-warning': isSaved, 'btn--light': !isSaved  }"
              >
                <i class="fa fa-star"></i>
              </button>
            </li>
            <li *ngIf="item?.data?.arPrintable!=null">
              <a class="btn btn--light btn--small" href="{{item?.data?.arPrintable}}" target="_blank"><i
                class="fa fa-print"></i></a>
            </li>
            <li>
              <a class="btn btn--light btn--small" target="_blank"
                 href="https://www.facebook.com/sharer/sharer.php?u={{this.pageUrl}}">
                <i class="fa fa-share-alt"></i>
              </a>
            </li>
            <li class="shrink">
              <button class="btn btn--primary btn--small text-uppercase" (click)="openOrderModal()">
                {{'requestInfo'|translate}}
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

  <div class="property-header" [class.fixed]="fixed">
    <div class="container">
      <div class="general-info">
        <div class="container">
          <div class="row">
            <div class="col-md-9">
              <div class="main-title">
                <h1 class="text-capitalize strong mb-1">
                  {{ lang == 'ar' ? item?.data?.ar_title : item?.data?.en_title}}
                </h1>
              </div>
              <div class="clearfix"></div>
              <div class="sub-info none-shrink-no">
                <ul>
                  <li>
                    <h3>
                      <img src="./assets/images/icons/address.svg">
                      {{ lang == 'ar' ? item?.data.ar_cityName : item?.data?.en_cityName }}
                      <span *ngIf="item?.data?.ar_zoneName!=null">-</span>
                      {{lang == 'ar' ? item?.data?.ar_zoneName : item?.data?.en_zoneName }}
                    </h3>
                  </li>
                  <li class="areaIndeed"><img src="./assets/images/icons/icon-area.svg">
                    <strong> {{item?.data?.area|number}}  m²</strong></li>
                  <li *ngIf="item?.data?.classification"><img
                    src="./assets/images/icons/Info.svg">{{item?.data?.classification|translate}}
                  </li>


                </ul>
              </div>
            </div>
            <div class="col-md-3 ">
              <div class="price-div">
                <h2 class="price" *ngIf="isLoggedIn==false">
                  <span class="text-secondary">{{'thePrice'|translate}}</span>
                  <span>(***)</span>
                </h2>
                <h2 class="price" *ngIf="isLoggedIn==true">
                  <span class="text-secondary">USD</span>
                  {{item?.data?.price |number}}
                </h2>

                <button class="btn btn--primary pull-right none-shrink desktop-only text-uppercase"
                        (click)="openOrderModal()">{{'requestInfo'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="property-fixed-nav">
          <div class="actions-nav">
            <div class="container">
              <div class="pull-left">
                <ul class="nav link">
                  <li class="nav-item">
                    <a [ngx-scroll-to]="'#photos'"
                       [ngx-scroll-to-offset]="offset"
                       href="javascript:void(0)" class="nav-link "> {{'photos'|translate}}</a>
                  </li>
                  <li class="nav-item">
                    <a [ngx-scroll-to]="'#details'"
                       [ngx-scroll-to-offset]="offset"
                       href="javascript:void(0)" class="nav-link">
                      {{'details'|translate}}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a [ngx-scroll-to]="'#advice'"
                       [ngx-scroll-to-offset]="offset"
                       href="javascript:void(0)" class="nav-link"> {{'advice'|translate}}</a>
                  </li>
                  <li class="nav-item">
                    <a [ngx-scroll-to]="'#neighborhood'"
                       [ngx-scroll-to-offset]="offset"
                       href="javascript:void(0)" class="nav-link">{{'neighborhood'|translate}}</a>
                  </li>
                  <li class="nav-item">
                    <a
                      [ngx-scroll-to]="'#map'"
                      [ngx-scroll-to-offset]="offset"
                      href="javascript:void(0)" class="nav-link">
                      {{'map'|translate}}</a>
                  </li>
                </ul>
              </div>
              <div class="pull-right ">
                <div class="actions-btns desktop-only">
                  <ul class="nav justify-content-end">
                    <li>
                      <button
                        class="btn  btn--small saveBtn"
                        (click)="saveLandAction(item?.data?.id)"
                        [ngClass]="{ 'btn-outline-warning': isSaved, 'btn--light': !isSaved  }"
                      >
                        <i class="fa fa-star"></i>
                      </button>
                    </li>
                    <li *ngIf="item?.data?.arPrintable!=null">
                      <a class="btn btn--light btn--small" href="{{item?.data?.arPrintable}}" target="_blank"><i
                        class="fa fa-print"></i></a>
                    </li>
                    <li>
                      <a class="btn btn--light btn--small " target="_blank"
                         href="https://www.facebook.com/sharer/sharer.php?u={{this.pageUrl}}">
                        <i class="fa fa-share-alt"></i>
                      </a>
                    </li>
                    <li class="shrink">
                      <button class="btn btn--primary btn--small text-uppercase" (click)="openOrderModal()">
                        {{'requestInfo'|translate}}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="container">
    <div class="row main-property-details">

      <div class="col-md-6 mb-2">
        <app-angular-gallery
          [images]="images"
        >
        </app-angular-gallery>
      </div>
      <div class="col-md-6">
        <div class="facts property-details" id="details">
          <div class=" mb-2 ">
            <div class="row">
              <div class="col-md-8 col-sm-12 mb-4">
                <h2 class="text-left under-line-header" id="facts">{{'facts'|translate}}</h2>
                <dl>
                  <dt class="">{{'propertyType'|translate}}</dt>
                  <dd class="">{{item?.data?.type|translate}}</dd>
                </dl>

                <dl>
                  <dt class="">{{'offer'|translate}}</dt>
                  <dd class="">{{item?.data?.offer|translate}}</dd>
                </dl>


                <dl>
                  <dt class="">{{'area'|translate}}</dt>
                  <dd class="">{{item?.data?.area| number}} <span style="text-transform: lowercase">m²</span></dd>
                </dl>
                <dl>
                  <dt class="">{{'price'|translate}}</dt>
                  <dd class="price">

                    <span *ngIf="isLoggedIn==false">***</span>
                    <span *ngIf="isLoggedIn==true"> {{ item?.data?.price|currency:'$' :'symbol' : '1.0' }} </span>


                  </dd>
                </dl>

                <dl>
                  <dt class="">{{'Governate'|translate}}</dt>
                  <dd class="">{{ lang == 'ar' ? item?.data.ar_governmentName : item?.data?.en_governmentName }}
                  </dd>
                </dl>

                <dl>
                  <dt class="">{{'city'|translate}}</dt>
                  <dd class="">{{ lang == 'ar' ? item?.data.ar_cityName : item?.data?.en_cityName }}
                  </dd>
                </dl>

                <dl>
                  <dt class="">{{'Zone'|translate}}</dt>
                  <dd class="">
                    {{ lang == 'ar' ? item?.data.ar_cityName : item?.data?.en_cityName }}
                    <span *ngIf="item?.data?.ar_zoneName">-</span>
                    {{ lang == 'ar' ? item?.data.ar_zoneName : item?.data?.en_zoneName }}
                  </dd>
                </dl>

                <ng-container *ngIf="isLoggedIn==true">


                  <dl *ngIf="item?.data?.blockNumber">
                    <dt class="">{{'parcelDetails'|translate}}:</dt>
                    <dd class="">
                      <span>{{'parcel'|translate}}({{item?.data?.parcelNumber}})</span>
                      <span>{{'block'|translate}}({{item?.data?.blockNumber}})</span>
                      <span *ngIf="item?.data?.sectorNumber !=null">-{{'sector'|translate}}({{item?.data?.sectorNumber}}
                        )</span>
                    </dd>
                  </dl>

                  <dl *ngIf="item?.data?.deed_issuance">
                    <dt class="">{{'deedType'|translate}}</dt>
                    <dd class="">{{item?.data?.deed_issuance|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.bestProjects">
                    <dt class="">{{'bestUse'|translate}}:</dt>
                    <dd class="">{{item?.data?.bestProjects|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.building_percentage">
                    <dt>{{'construction_area'|translate}}</dt>
                    <dd>{{item?.data?.building_percentage}}%</dd>
                  </dl>

                  <dl *ngIf="item?.data?.floors_counts">
                    <dt>{{'permitted_floor'|translate}}</dt>
                    <dd>{{item?.data?.floors_counts}} {{'floors'|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.general_classification">
                    <dt>{{'land_classification'|translate}}</dt>
                    <dd>{{item?.data?.general_classification|translate}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.security_classification">
                    <dt>{{'securityClassification'|translate}}</dt>
                    <dd>{{item?.data?.security_classification}}</dd>
                  </dl>

                  <dl *ngIf="item?.data?.buildingCategory">
                    <dt>{{'buildingCategory'|translate}}</dt>
                    <dd>{{item?.data?.buildingCategory|translate}}</dd>
                  </dl>


                  <dl *ngIf="item?.data?.front_offset">
                    <dt>{{'offset'|translate}}</dt>
                    <dd>
                      <span>{{'front_offset'|translate}} ({{item?.data?.front_offset}})</span>
                      <span>-{{'side_offset'|translate}} ({{item?.data?.side_offset}})</span>
                      <span>-{{'back_offset'|translate}} ({{item?.data?.back_offset}})</span>
                    </dd>
                  </dl>

                </ng-container>

                <ng-container *ngIf="isLoggedIn == false && propertyType=='lands'">
                  <dl>
                    <div class="img-blur">
                      <div class="text"><a class="" (click)="openLoginModal()">{{'pleaseLoginORSignUp'|translate}}</a>
                      </div>
                      <img src="./assets/images/landBlur2.jpeg">
                    </div>
                  </dl>
                </ng-container>

                <dl *ngIf="item?.data?.roomsNumber">
                  <dt>{{'roomsNumber'|translate}}</dt>
                  <dd>{{item?.data?.roomsNumber}}</dd>
                </dl>

                <dl *ngIf="item?.data?.bathroomsNumber">
                  <dt>{{'bathroomsNumber'|translate}}</dt>
                  <dd>{{item?.data?.bathroomsNumber}}</dd>
                </dl>

                <dl *ngIf="item?.data?.parkingNumber">
                  <dt>{{'parkingNumber'|translate}}</dt>
                  <dd>{{item?.data?.parkingNumber}}</dd>
                </dl>

                <dl *ngIf="item?.data?.balconiesNumber">
                  <dt>{{'balconiesNumber'|translate}}</dt>
                  <dd>{{item?.data?.balconiesNumber}}</dd>
                </dl>

                <dl *ngIf="item?.data?.finishingType">
                  <dt>{{'finishing'|translate}}</dt>
                  <dd>{{item?.data?.finishingType|translate}}</dd>
                </dl>

                <dl *ngIf="item?.data?.furnishings">
                  <dt>{{'furnishings'|translate}}</dt>
                  <dd>{{item?.data?.furnishings|translate}}</dd>
                </dl>


                <div class="call-to-action-block">
                  <div class="call-btns">
                    <a class="btn-call" href="tel:+970599222332"><i class="fa fa-phone"></i>{{'call'|translate}}</a>
                    <a class="btn-email" (click)="openOrderModal()"><i class=" fa fa-envelope"></i>{{'email'|translate}}
                    </a>
                    <a class="btn-whatsapp"
                       href="https://api.whatsapp.com/send?phone=+970599222332&text={{whatsappURL}}"
                       target="_blank">
                      <i class="fa fa-whatsapp"></i>{{'whatsapp'|translate}}
                    </a>
                  </div>
                </div>


              </div>
              <div class="col-md-4 col-sm-12 mb-2">

                <h2 class="text-left under-line-header">{{'features'|translate}}</h2>

                <div class="features">
                  <ul>
                    <li *ngFor="let feature of features">
                      <i class="fa fa-check"></i> {{feature|translate}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>

  <div class="clearfix"></div>

  <div class="property-details">
    <div class="container">
      <div class="mb-2">
        <h3 class="under-line-header mt-5">{{'property_description'|translate}}</h3>
        <p class="color-typography-default mt-3">
          {{ lang == 'ar' ? item?.data.ar_description : item?.data?.en_description }}
        </p>
        <hr class="mt-1">
        <!--<h2 class="text-left" id="advice">{{'advice'|translate}}</h2>-->
        <h3 class="under-line-header" id="advice">{{'advice'|translate}}</h3>

        <!--<h3 class="under-line-header">Meters</h3>-->
        <div class="mt-1 mb-2">
          <div class="row">
            <div class="col-md-4">
              <label>{{'readiness'|translate}}</label>
              <div class="progress mb-4">
                <div
                  class="progress-bar bg-secondary"
                  role="progressbar"
                  [style.width]="item?.data?.readiness + '%'"
                  aria-valuenow="item?.data?.readiness"
                  aria-valuemin="0"
                  aria-valuemax="100">{{item?.data?.readiness}}%
                </div>
              </div>
            </div>
            <!--<div class="col-md-4">-->
            <!--<label>{{'investment_quality'|translate}}</label>-->
            <!--<div class="progress mb-4">-->
            <!--<div-->
            <!--class="progress-bar bg-secondary"-->
            <!--role="progressbar"-->
            <!--[style.width]="item?.data?.investment_quality + '%'"-->
            <!--aria-valuenow="item?.data?.investment_quality"-->
            <!--aria-valuemin="0"-->
            <!--aria-valuemax="100">{{item?.data?.investment_quality}}%-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->

            <div class="col-md-4">
              <label>{{'demand_for_the_area'|translate}}</label>

              <div class="progress mb-4">
                <div
                  class="progress-bar bg-secondary"
                  role="progressbar"
                  [style.width]="item?.data?.area_demand + '%'"
                  aria-valuenow="item?.data?.area_demand"
                  aria-valuemin="0"
                  aria-valuemax="100">{{item?.data?.area_demand}}%
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label>{{'area_development'|translate}}</label>
              <div class="progress mb-4">
                <div
                  class="progress-bar bg-secondary"
                  role="progressbar"
                  [style.width]="item?.data?.area_development + '%'"
                  aria-valuenow="item?.data?.area_development"
                  aria-valuemin="0"
                  aria-valuemax="100">{{item?.data?.area_development}}%
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label>{{'services_availability'|translate}}</label>
              <div class="progress mb-4">
                <div
                  class="progress-bar bg-secondary"
                  role="progressbar"
                  [style.width]="item?.data?.services_availability + '%'"
                  aria-valuenow="item?.data?.services_availability"
                  aria-valuemin="0"
                  aria-valuemax="100">{{item?.data?.services_availability}}%
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label>{{'area_grade'|translate}}</label>
              <div class="progress mb-4">
                <div
                  class="progress-bar bg-secondary"
                  role="progressbar"
                  [style.width]="item?.data?.area_grade + '%'"
                  aria-valuenow="item?.data?.area_grade"
                  aria-valuemin="0"
                  aria-valuemax="100">{{item?.data?.area_grade}}%
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label>{{'population_density'|translate}}</label>
              <div class="progress mb-4">
                <div
                  class="progress-bar bg-secondary"
                  role="progressbar"
                  [style.width]="item?.data?.population_density + '%'"
                  aria-valuenow="item?.data?.population_density"
                  aria-valuemin="0"
                  aria-valuemax="100">{{item?.data?.population_density}}%
                </div>
              </div>
            </div>
          </div>
        </div>

        <!----end investment----->

        <hr class="mt-5" id="neighborhood">
        <div class="mb-2 mt-4">
          <h3 class="under-line-header mb-3">{{'What is Near By'|translate}}</h3>
          <div class="table-responsive">
            <table class="table ">
              <thead>
              <tr class="table-active">
                <th>{{'name'|translate}}</th>
                <th>{{'type'|translate}}</th>
                <th>{{'distance'|translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let nearby of nearbies">
                <th>{{ lang == 'ar' ? nearby?.arName : nearby?.enName }}</th>
                <td><img src="{{nearby.icon}}" width="30"> {{ lang == 'ar' ? nearby?.arCat : nearby?.enCat }}</td>
                <td>
                  {{getDistanceBetweenTowPoint(nearby.lat, nearby.lan)|number:'1.2-2'}} km
                </td>
              </tr>
              <tr *ngIf="nearbies.length==0">
                <td colspan="3" class="text-center">
                  <p>{{'no_data_available'|translate}}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="mb-3 mt-3">
        <!--<h2 class="text-left" id="map">{{'map'|translate}}</h2>-->
        <h3 class="under-line-header mb-3" id="map">{{'map'|translate}}</h3>

        <div style="height: 500px">
          <app-maps [ngClass]="{'map-on':mapOn}" [markers]="markersArray" [lang]="lang" [zoom]="15"></app-maps>

        </div>
      </div>

      <div class="sam-property" *ngIf="samePropertyArray">
        <app-same-area-property [itemsArray]="samePropertyArray" [lang]="lang"
                                [isLoggedIn]="isLoggedIn"></app-same-area-property>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>


  <div class="call-to-action mobile-only">
    <div class="call-btns">
      <a class="btn-call" href="tel:+970599222332"><i class="fa fa-phone"></i>{{'call'|translate}}</a>
      <a class="btn-email" (click)="openOrderModal()"><i class=" fa fa-envelope"></i>{{'email'|translate}}</a>
      <a class="btn-whatsapp" href="https://api.whatsapp.com/send?phone=+970599222332&text={{whatsappURL}}"
         target="_blank">
        <i class="fa fa-whatsapp"></i>{{'whatsapp'|translate}}
      </a>
    </div>
  </div>


</section>
<layout-footer></layout-footer>
