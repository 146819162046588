import {Component,OnInit, Inject} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {AuthService} from './_services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {

  title = 'Aqar';
  isAuthenticated:boolean;
  private currentLang:string;
  bodyClass:string;
  // @Inject(WINDOW) private window: Window,
  constructor(@Inject(WINDOW) private window: Window, @Inject(LOCAL_STORAGE) private localStorage: any, private auth:AuthService, private translate:TranslateService ,private router: Router) {
    this.isAuthenticated = false;
    this.currentLang=localStorage.getItem('language');
    if(this.currentLang==null){
      translate.setDefaultLang('ar');
      localStorage.setItem('language', 'ar');
      this.translate.use('ar');
      this.bodyClass='rtl';
    }else{
      this.translate.use(this.currentLang);
    }


    /**
     * add Class to body
     */
    if(this.currentLang=='en'){
      this.bodyClass='ltr';

    }else{
      this.bodyClass='rtl';
    }
    document.body.classList.add(this.bodyClass);

  }


  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }


}
