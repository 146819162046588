import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {NgModel} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ForgotPasswordComponent} from '../../forgot-password/forgot-password.component';
import {TranslateService} from '@ngx-translate/core';


import * as JWT from 'jwt-decode';


import {AuthService} from '../../../../_services/auth.service';
import {WizardService} from '../../../../_services/wizard.service';
import {User} from '../../../../models/user-model';
import {LOCAL_STORAGE} from '@ng-toolkit/universal';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})


export class LoginFormComponent implements OnInit {

  loginForm: FormGroup;
  user: User = new User();
  error = '';
  uData: any;
  invalidUsernameOrPassword: any;
  userAccountIsDisabled: any;
  decodeToken: string;
  alert: boolean = false;
  alertClass: string = '';
  alertMsg: string = '';
  loading: boolean = false;
  username: FormControl;
  password: FormControl;


  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, private fb: FormBuilder,
              private auth: AuthService,
              private router: Router,
              public  activeModal: NgbActiveModal,
              private WizardService: WizardService,
              private modalService: NgbModal,
              public translate: TranslateService) {

    this.createFormControls();
    this.createForm();

  }

  ngOnInit() {
    this.translate.get('validation.invalidUsernameOrPassword').subscribe((res: string) => {
      this.invalidUsernameOrPassword = res;
    });


    this.translate.get('validation.userAccountIsDisabled').subscribe((res: string) => {
      this.userAccountIsDisabled = res;
    });


  }

  createFormControls() {
    this.username = new FormControl('', [
      Validators.required,
    ]);
    this.password = new FormControl('', [
      Validators.required,
    ]);
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: this.username,
      password: this.password
    });
  }


  onSubmit(): void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.auth.login(this.user)
      .then((user) => {
        this.localStorage.setItem('token', user.json().token);
        this.decodeToken = JWT(user.json().token);
        this.alert = true;
        this.alertMsg = 'Your logged in successfully ';
        this.alertClass = 'success';
        this.activeModal.dismiss();
        this.loading = false;

        // this.router.navigateByUrl('dashboard');

        this.WizardService.checkUserWizard().subscribe((res: any) => {
          console.log(res);
          if (res.status == 1) {
            this.router.navigateByUrl('dashboard');
          } else {
            this.router.navigateByUrl('/wizard');
          }
        });
      })
      .catch((err) => {
        var errResponse = err.json();
        if (errResponse.code == 401) {
          this.alert = true;
          this.alertClass = 'danger';
          this.loading = false;
          if (errResponse.message == 'User account is disabled.') {
            this.alertMsg = this.userAccountIsDisabled;

          } else {
            this.alertMsg = this.invalidUsernameOrPassword;
          }

        }
      });

  }

  openForgetPassword() {
    console.log('open openForgetPassword Modal');
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(ForgotPasswordComponent);
  }


}
