// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  // production: false,
  // CORE_SERVER_URL: 'http://localhost/daman/app_dev.php/api/',
  // CORE_SERVER_DEV_URL: 'http://localhost/daman/app_dev.php/api/',
  // CORE_SERVER_API_URL: 'http://localhost/daman/app_dev.php/api/',
  production: true,
  CORE_SERVER_URL: 'https://aqar.ps/daman/api/',
  CORE_SERVER_API_URL: 'https://aqar.ps/daman/api/',
  BLOG_SERVER_API_EN_URL: 'https://blog.aqar.ps/en_US/wp-json/wp/v2/',
  BLOG_SERVER_API_AR_URL: 'https://blog.aqar.ps/ar/wp-json/wp/v2/',
  BLOG_SERVER_ENDPOINT_API_AR_URL: 'https://blog.aqar.ps/ar/wp-json/deep /',
  BLOG_SERVER_ENDPOINT_API_EN_URL: 'https://blog.aqar.ps/en_US/wp-json/deep/',
  whatsapp: '+970599222332',
  mobile: '+972598164229',
  email: 'hello@aqar.ps'

};



