import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class GridComponent implements OnInit {

  @Input() lang: any;
  @Input() itemsArray: any;
  @Input() gridCol: any;

  @Input() currentPage: any;
  @Input() savedProperty: boolean;
  @Input() page: any;
  @Input() isLoggedIn: any;
  @Input() totalItems: any;
  @Input() itemsPerPage: any;
  @Input() propertyType: any;
  @Input() loading: any;
  @Input() unSaveButton: any;
  @Output() myPaginationEvent: EventEmitter<any> = new EventEmitter();
  @Output() mySaveLandEvent: EventEmitter<any> = new EventEmitter();
  @Output() myUnsaveLandEvent: EventEmitter<any> = new EventEmitter();
  @Output() pleaseLogin: EventEmitter<any> = new EventEmitter();

  save: string;
  saved: string;

  constructor(public  translate: TranslateService) {

    this.translate.get('saved').subscribe((res: string) => {
      this.saved = res;
    });

    this.translate.get('save').subscribe((res: string) => {
      this.save = res;
    });
  }

  ngOnInit() {
  }

  PageChanged($event) {
    this.myPaginationEvent.emit($event);
  }

  saveLand(event) {
    let id = event[0];
    let type = event[1];

    console.log('id grid', id);
    console.log('type grid', type);

    this.mySaveLandEvent.emit(event);
    let btnId = 'saveBtn-' + id;
    console.log(btnId);
    if (document.getElementById(btnId).classList.contains('btn--light--default') && this.isLoggedIn == true) {
      document.getElementById(btnId).classList.remove('btn--light--default');
      document.getElementById(btnId).classList.add('btn-outline-warning');
      document.getElementById(btnId).innerHTML = '<i class=\'fa fa-star\'></i> ' + this.saved;
    } else {
      document.getElementById(btnId).classList.remove('btn-outline-warning');
      document.getElementById(btnId).classList.add('btn--light--default');
      document.getElementById(btnId).innerHTML = '<i class=\'fa fa-star\'></i> ' + this.save;
    }
  }

  unSaveLand($event) {
    console.log('unSaveLand');
    this.myUnsaveLandEvent.emit($event);
  }

  showLoginModal() {
    console.log('showLoginModal');
    this.pleaseLogin.emit();
  }

  lastPage() {
    console.log('last page');
  }
}
