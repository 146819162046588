import {Component, OnInit} from '@angular/core';
import {UserService} from './../../../_services/user.service';
import {DashboardService} from './../../../_services/dashboard.service';
import {ViewEncapsulation} from '@angular/core';
import {CustomersOrdersModalComponent} from  '../../customers-orders-modal/customers-orders-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None


})
export class DashboardComponent implements OnInit {

  userInformation:any;
  latestUnits:any;
  latestBuilding:any;
  liveProperties:any = 5;
  rejected:any = 1;
  pending:any = 0;
  detailsRequested:any = 14;
  savedPropertiesCount:any = 0;
  userPropertiesCount:any = 0;
  userApartmentCount:any = 0;
  userOfficesCount:any = 0;
  userStoresCount:any = 0;
  userUnitsCount:any = 0;
  userLanCount:any = 0;
  userVillaHouseCount:any = 0;
  userBuildingCount:any = 0;
  publishedProperties:any = 10;
  unpublishedProperties:any = 2;
  latestUnitsReady = false;
  latestBuildingReady = false;
  lang:string;

  constructor(private  userService:UserService,
              private  dashboardService:DashboardService,
              private modalService:NgbModal,
              public translate:TranslateService) {


  }

  ngOnInit() {

    this.lang = this.translate.currentLang;
    this.getUserInfo();
    this.getSavedPropertiesCount();
    this.getUserTotalPropertiesCount();
    this.getLatestUnits();
    this.getLatestBuilding();
  }

  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
  }


  getUserInfo() {
    this.userService.getUser().subscribe((res:any)=> {
      this.userInformation = (res.userInformation);
    });
  }

  getSavedPropertiesCount() {
    this.dashboardService.getSavedPropertiesCount().subscribe((res:any)=> {
      console.log(res['total_count']);
      this.savedPropertiesCount = res['total_count'];
    });
  }

  getUserTotalPropertiesCount() {
    this.dashboardService.getUserTotalPropertieCount().subscribe((res:any)=> {
      let data= res.data;
      console.log(data);
      this.userLanCount = data['land'];
      this.userBuildingCount = data['building'];
      this.userVillaHouseCount = data['villa_house'];
      this.userApartmentCount = data['apartment'];
      this.userOfficesCount = data['office'];
      this.userStoresCount = data['store'];
      this.userPropertiesCount = data['total_count'];

    });
  }

  getLatestUnits() {
    this.userService.getLatestAddedUnites().subscribe((res:any)=> {
      this.latestUnits = (res.data);
      this.latestUnitsReady = true;
    });
  }

  getLatestBuilding() {
    this.userService.getLatestAddedBuilding().subscribe((res:any)=> {
      this.latestBuilding = (res.data);
      this.latestBuildingReady = true;
    });
  }

}
