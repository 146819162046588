import {Component, OnInit, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {BlogService} from '../../_services/blog.service';
import { WINDOW } from '@ng-toolkit/universal';


@Component({
  selector: 'app-single-service',
  templateUrl: './single-service.component.html',
  styleUrls: ['./single-service.component.scss']
})
export class SingleServiceComponent implements OnInit {

  slug:any;
  post:any;


  constructor(@Inject(WINDOW) private window: Window, private blogService:BlogService, private route:ActivatedRoute) {

  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      this.getPostBySlug(this.slug);
      this.window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }


  getPostBySlug(slug) {
    this.blogService.getPostBySlug(slug).subscribe(res=> {
      this.post = res[0];
      console.log(this.post[0]);
    });
  }

}
