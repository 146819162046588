import { Component, OnInit ,ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-blog-inner-nav',
  templateUrl: './blog-inner-nav.component.html',
  styleUrls: ['./blog-inner-nav.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class BlogInnerNavComponent implements OnInit {



  navToggleClass:any;
  navListShow:any;
  constructor() { }

  ngOnInit() {
  }



  navToggle() {

    if (this.navToggleClass == '') {
      this.navToggleClass = 'active';
      this.navListShow = 'show';
    } else {
      this.navToggleClass = '';
      this.navListShow = '';
    }
  }

}
