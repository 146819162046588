import { Component, OnInit ,Input,Output,EventEmitter,ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ListComponent implements OnInit {
  @Input() itemsArray: any;
  @Input() lang:any;
  @Input() gridCol:any;
  @Input() currentPage:any;
  @Input() page:any;
  @Input() totalItems:any;
  @Input() itemsPerPage:any;
  @Input() loading:any;
  @Input() propertyType:any;
  @Output() myPaginationEvent:EventEmitter<any> = new EventEmitter();
  @Output() mySaveLandEvent:EventEmitter<any> = new EventEmitter();
  @Input() isLoggedIn:any;
  @Input() savedProperty:boolean;
  @Output() pleaseLogin: EventEmitter<any> = new EventEmitter();

  save:string;
  saved:string;


  constructor(public  translate: TranslateService ) {

    this.translate.get("saved").subscribe((res:string) => {
      this.saved = res;
    });

    this.translate.get('save').subscribe((res:string) => {
      this.save = res;
    });
  }

  ngOnInit() {
  }

  PageChanged($event) {
    this.myPaginationEvent.emit($event);
  }

  saveLand(event) {

    let id   = event[0];
    let type = event[1];

    this.mySaveLandEvent.emit(id);
    let btnId = "saveBtn-" + id;
    console.log(btnId);
    if (document.getElementById(btnId).classList.contains('btn--light--default') && this.isLoggedIn==true) {
      document.getElementById(btnId).classList.remove('btn--light--default');
      document.getElementById(btnId).classList.add('btn-outline-warning');
      document.getElementById(btnId).innerHTML = "<i class='fa fa-star'></i> " + this.saved;
    } else {
      document.getElementById(btnId).classList.remove('btn-outline-warning');
      document.getElementById(btnId).classList.add('btn--light--default');
      document.getElementById(btnId).innerHTML = "<i class='fa fa-star'></i> " + this.save;
    }
  }

}
