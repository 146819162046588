export class PropertyModel {
  governate:number;
  city:number;
  areaZone:number;
  title:string;
  price:string;
  area:number;
  status:string;
  offer:string;
  roomsNumber:number;
  bathroomsNumber:number;
  balconiesNumber:number;
  floorNumber:number;
  blockNumber:number;
  sectorNumber:number;
  parcelNumber:number;
  features:any;
  address:any;
  
}
