import {Component, OnInit} from '@angular/core';
import {LandsService} from '../../_services/lands.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersOrdersModalComponent} from '../customers-orders-modal/customers-orders-modal.component';

@Component({
  selector: 'app-user-single-property',
  templateUrl: './user-single-property.component.html',
  styleUrls: ['./user-single-property.component.scss']
})
export class UserSinglePropertyComponent implements OnInit {

  id: number;
  land: any;
  item: any;
  purpose_of_realestate: any;
  payment_method: any;
  lang: string;
  dirName: string;
  images: any;
  mainImage: any;
  imagesCount: number;
  showMoreImages: boolean;
  public fixed: boolean = false;
  public offset: number = -250;
  lat: number = 31.906590;
  lng: number = 35.211145;
  markersArray: any;
  en_title: any;
  ar_title: any;
  price: any;
  aqarStatus: number = null;
  propertyType: any = '';
  features: any = null;
  pageNotFound = false;
  showThumb = true;
  mapOn: true;

  constructor(private landService: LandsService,
              public translate: TranslateService,
              private route: ActivatedRoute,
              private modalService: NgbModal) {


  }

  ngOnInit() {
    this.lang = this.translate.currentLang;
    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.propertyType = this.route.snapshot.url[2].path;
    this.getPropertyDetails();
    this.getPropertyGallery();
  }

  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
  }


  getPropertyDetails() {

    this.landService
      .getUserPropertyDetailByTypeAndId(this.propertyType, this.id)
      .subscribe((res: any) => {
        console.log(res);
        if (res.success == false) {
          this.pageNotFound = true;
        } else {
          this.pageNotFound = false;
          this.item = res;
          console.log(this.item.data);
          this.aqarStatus = this.item.data.aqarStatus;


          this.features = this.item.data.features;
          if (this.features != null || this.features != '') {
            this.features = this.features.split(',');
          }

          let mapArray = [{
            lat: this.item.data.lat,
            lng: this.item.data.lng,
            ar_title: this.item.data.ar_title,
            en_title: this.item.data.en_title,
            ar_cityName: this.item.data.ar_cityName,
            en_cityName: this.item.data.en_cityName,
            en_zoneName: this.item.data.en_zoneName,
            ar_zoneName: this.item.data.ar_zoneName,
            id: this.item.data.id,
            propertyType: this.propertyType,
            mainImage: this.item.data.mainImage,
            price: this.item.data.price,
            label: {
              fontSize: '12x',
              color: '#fff',
              text: ('$' + this.abbreviateNumber(this.item.data.price)).toString()
            }
          }];
          this.markersArray = mapArray;

        }
      });
  }


  getPropertyGallery() {


    // if(this.propertyType == 'apartments'){
    //   this.propertyType='units';
    // }
    this
      .landService
      .getPropertyGallery(this.id, this.propertyType)
      .subscribe(res => {

        if (res['items'].length > 0) {
          this.images = res['items'];
          this.showThumb = true;
        } else {
          let defaultImage = [{
            path: 'https://via.placeholder.com/500X400/efebe3/808080%20?text=No%20image%20Avlaible',
            big: 'https://via.placeholder.com/500X400/efebe3/808080%20?text=No%20image%20Avlaible',
            medium: 'https://via.placeholder.com/500X400/efebe3/808080%20?text=No%20image%20Avlaible',
            small: 'https://via.placeholder.com/500X400/efebe3/808080%20?text=No%20image%20Avlaible',
            name: 'Default Image'

          }];
          this.images = defaultImage;
          this.showThumb = false;
          console.log('showThumb:', this.showThumb);
        }

        this.imagesCount = this.images.imagesCount;
        if (this.imagesCount > 5) {
          this.showMoreImages = true;
        } else {
          this.showMoreImages = false;
        }

      });

  }


  getLandDetails(id) {
    this
      .landService
      .getUserLand(id)
      .subscribe((res: any) => {
        console.log(res);
        this.land = res;

        if (res.success == false) {

          this.pageNotFound = true;

        } else {
          this.pageNotFound = false;
          let purpose_of_realestate = this.land.data.purpose_of_realestate.split(',');
          let payment_method = this.land.data.payment_method.split(',');
          this.purpose_of_realestate = purpose_of_realestate;
          this.payment_method = payment_method;
          this.dirName = this.land.data.dirName;

          this.en_title = this.land.data.en_title;
          this.ar_title = this.land.data.ar_title;
          this.price = this.land.data.price;
          this.mainImage = this.land.data.mainImage;
          this.aqarStatus = this.land.data.aqarStatus;
          this.getLandGallery(this.dirName);
          let mapArray = [{
            lat: this.land.data.lat,
            lng: this.land.data.lng,
            ar_title: this.land.data.ar_title,
            en_title: this.land.data.en_title,
            ar_cityName: this.land.data.ar_cityName,
            en_cityName: this.land.data.en_cityName,
            en_zoneName: this.land.data.en_zoneName,
            ar_zoneName: this.land.data.ar_zoneName,
            generalInfoId: this.land.data.generalInfoId,
            mainImage: this.land.data.mainImage,
            price: this.land.data.lng,
            label: {
              fontSize: '12x',
              color: '#fff',
              text: ('$' + this.abbreviateNumber(this.land.data.price)).toString()
            }
          }];
          this.markersArray = mapArray;

        }
      });
  }


  getLandGallery(dirName) {
    this
      .landService
      .getPropertyGallery(dirName, 'Lands')
      .subscribe(res => {
        this.images = res;
        this.imagesCount = this.images.imagesCount;
        if (this.imagesCount > 5) {
          this.showMoreImages = true;
        } else {
          this.showMoreImages = false;
        }

      });

  }

  abbreviateNumber(number) {
    var SI_PREFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    // what tier? (determines SI prefix)
    var tier = Math.log10(number) / 3 | 0;
    // if zero, we don't need a prefix
    if (tier == 0) {
      return number;
    }
    // get prefix and determine scale
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = number / scale;
    // format number and add prefix as suffix
    return scaled.toFixed(1) + prefix;
  }


}
