import {Injectable} from '@angular/core';
import {Headers, Http} from '@angular/http';
import {User} from '../models/user-model';
import 'rxjs/add/operator/toPromise';
import * as jwt_decode from "jwt-decode";
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { ErrorHandler, Inject} from '@angular/core';
import {environment} from '../../environments/environment';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';


@Injectable()
export class AuthService {

  // private BASE_URL:string = 'http://localhost/daman/app_dev.php/api';
  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  private headers = new Headers({'Content-Type': 'application/json'});

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, private http:Http,private http_client: HttpClient) {
  }

  login(user:User):Promise<any> {
    let url:string = `${this.BASE_URL}login_check`;
    return this.http.post(url, user, {headers: this.headers}).toPromise();
  }


  signup(user:User):Promise<any> {
    let url:string = `${this.BASE_URL} signup`;
    return this.http.post(url, user, {headers: this.headers}).toPromise();
  }


  signup2(user) {
    let url:string = `${this.BASE_URL}signup`;
    return this.http.post(url, user);
  }

  updateProfile(user){
    let url:string = `${this.BASE_URL}user/1`;
    return this.http_client.post(url, user);
  }



  forgotPassword(email){
    let url:string = `${this.BASE_URL}password/reset/request`;
    return this.http_client.post(url, email);

  }


  resetPassword(resetPasswordData,token){
    let url:string = `${this.BASE_URL}password/reset/confirm?token=`+token;
    return this.http_client.post(url, resetPasswordData);

  }


  ensureAuthenticated(token):Promise<any> {
    let url:string = `${this.BASE_URL}user`;
    let headers:Headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    });
    return this.http.get(url, {headers: headers}).toPromise();
  }


  public  isAuthenticated():boolean {
    let token = this.localStorage.getItem('token');
    if (token) {
      let tokenInfo = this.getDecodedAccessToken(token); // decode token
      let expireDate = tokenInfo.exp; // get token expiration dateTime
      var current_time = Date.now() / 1000;
      if (expireDate < current_time) {
        return false
      } else {
        return true;
      }
    } else {
      return false
    }
  }


  public logout():void {
    this.localStorage.removeItem('token');
    this.localStorage.removeItem('userInformation');
    this.localStorage.clear();

  }


  getDecodedAccessToken(token:string):any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  resetPasswordCheckToken(token) {
    let url:string = `${this.BASE_URL}password/find/token/` + token;
    return this.http_client.get(url);

  }


}
