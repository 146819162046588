import {Component, OnInit, Input} from '@angular/core';
import {UserService} from './../../../_services/user.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {


  userInformation:any;
  activeNotificationsCount = 0;

  constructor(private  userService:UserService) {
  }

  ngOnInit() {
    this.getActivePropertyRequestCount();
  }
  
  getActivePropertyRequestCount() {
    this.userService.getActivePropertyRequestCount().subscribe((res:any)=> {
      if (res.success) {
        this.activeNotificationsCount = (res.data.count);
      } 
    });
  }

}
