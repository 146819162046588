import {Component, OnInit, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {BlogService} from '../../_services/blog.service';
import { WINDOW } from '@ng-toolkit/universal';


@Component({
  selector: 'app-single-blog-page',
  templateUrl: './single-blog-page.component.html',
  styleUrls: ['./single-blog-page.component.scss']
})
export class SingleBlogPageComponent implements OnInit {

  slug:any;
  post:any;
  cat:any;

  constructor(@Inject(WINDOW) private window: Window, private route:ActivatedRoute,
              private blogService:BlogService) {


  }


  ngOnInit() {
    this.cat = this.route.snapshot.url[1].path;
    this.route.params.subscribe(params => {
      this.slug = params['slug'];
      this.getPostBySlug(this.slug);
      this.window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }


  getPostBySlug(slug) {
    this.blogService.getPostBySlug(slug).subscribe(res=> {
      this.post = res[0];
      console.log(this.post[0]);
    });
  }

}
