<app-top-header></app-top-header>
<section class="inner">


  <div class="container">
    <h1 class="strong">Buttons</h1>
    <h2 class="strong">Status</h2>
    <br>
    <br>
    <div class="row" style="margin-bottom: 20px">
      <div class="col text-left"><strong>Normal</strong></div>
      <div class="col text-left"><strong>Hover</strong></div>
      <div class="col text-left"><strong>Pressed</strong></div>
      <div class="col text-left"><strong>Disabled</strong></div>
      <div class="col text-left"><strong>Loading</strong></div>
    </div>
    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <button class="btn btn--secondary btn--large">Button</button>
      </div>
      <div class="col">
        <button class="btn btn--secondary btn--large hover"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--secondary btn--large focus"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--secondary btn--large disabled"> Button</button>
      </div>
      <div class="col">
        <button class="btn btn--secondary btn--large">Button</button>
      </div>
    </div>


    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <button class="btn btn--primary btn--large">Button</button>
      </div>
      <div class="col">
        <button class="btn btn--primary btn--large hover"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--primary btn--large focus"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--primary btn--large disabled"> Button</button>
      </div>
      <div class="col">
        <button class="btn btn--primary btn--large">Button</button>
      </div>
    </div>


    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <button class="btn btn--light  btn--large"> Button</button>
      </div>
      <div class="col">
        <button class="btn btn--light  btn--large hover"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--light  btn--large focus"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--light  btn--large disabled"> Button</button>
      </div>
      <div class="col">
        <button class="btn btn--light  btn--large"> Button</button>
      </div>
    </div>

    <div class="row" style="margin-bottom: 20px">
      <div class="col">
        <button class="btn btn--light--default btn--large  "> Button</button>
      </div>
      <div class="col">
        <button class="btn btn--light--default btn--large  hover"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--light--default  btn--large focus"> Button</button>
      </div>

      <div class="col">
        <button class="btn btn--light--default btn--large  disabled"> Button</button>
      </div>
      <div class="col">
        <button class="btn btn--light--default btn--large  "> Button</button>
      </div>
    </div>


    <div class="row btn-grid" style="margin-bottom: 40px">
      <h2 class="strong">Styles</h2>
      <button type="button" class="btn btn--primary"> btn--primary</button>
      <button type="button" class="btn btn--secondary"> btn--secondary</button>
      <button type="button" class="btn btn--light"> btn--light</button>
      <button type="button" class="btn btn--light--default"> btn--light--default</button>
      <button type="button" class="btn btn-danger"> btn-danger</button>
      <button type="button" class="btn btn-primary"> btn-primary</button>
      <button type="button" class="btn btn-success"> btn-success</button>
      <button type="button" class="btn btn-warning"> btn-warning</button>
      <button type="button" class="btn btn-default"> btn-default</button>
    </div>
    <div class="row btn-grid" style="margin-bottom: 40px">
      <h2 class="strong">Outline buttons</h2>
      <button type="button" class="btn btn-outline-primary">Primary</button>
      <button type="button" class="btn btn-outline-secondary">Secondary</button>
      <button type="button" class="btn btn-outline-success">Success</button>
      <button type="button" class="btn btn-outline-danger">Danger</button>
      <button type="button" class="btn btn-outline-warning">Warning</button>
      <button type="button" class="btn btn-outline-info">Info</button>
      <button type="button" class="btn btn-outline-light">Light</button>
      <button type="button" class="btn btn-outline-dark">Dark</button>
    </div>

    <div class="row btn-grid" style="margin-bottom: 40px">
      <h2 class="strong">Sizes</h2>
      <button type="button" class="btn btn--primary btn--large"> btn--large</button>
      <button type="button" class="btn btn--primary"> default</button>
      <button type="button" class="btn btn--primary btn--small"> btn--small</button>


    </div>

  </div>


</section>
<router-outlet name="footer"></router-outlet>
