import {Component, OnInit} from '@angular/core';
import {UserService} from '../../_services/user.service';
import {DashboardService} from './../../_services/dashboard.service';


import {combineLatest} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss']
})
export class ContractDetailsComponent implements OnInit {


  ready = false;
  contractDetails:any;
  contract_id:any;
  payments:any;
  receipts:any;
  due_payment_sum:any;
  payment_amount:any;
  payment_type:any;
  periodic_payment:any;
  currency:any;
  lang:string;
  financialMovementArray:any;
  totalPayment = 0;
  totalReceipts = 0;
  error404 = false;
  loadingDone = false;

  constructor(private userService:UserService,
              private route:ActivatedRoute,
              public translate:TranslateService,
              private dashboardService:DashboardService) {
    this.route.params.subscribe(params => {
      this.contract_id = +params['contractId'];
    });
    this.lang = this.translate.currentLang;

    this.initData();

  }

  ngOnInit() {
  }

  initData() {

    combineLatest(
      this.dashboardService.getContractDeatils(this.contract_id)
    ).pipe(
      finalize(() => {
        this.ready = true;
      })
    ).subscribe(([contractResponse]:[any])=> {

      if (contractResponse.success == true) {
        this.contractDetails = contractResponse.data.contract;
        this.payments = contractResponse.data.payments;
        this.receipts = contractResponse.data.receipts;
        this.due_payment_sum = contractResponse.data.due_payment_sum;
        this.payment_amount = contractResponse.data.payment_amount;
        this.payment_type = contractResponse.data.payment_type;
        this.periodic_payment = contractResponse.data.periodic_payment;
        this.currency = contractResponse.data.currency;
        this.createFinincailMovmentArray();

      } else {

        this.error404 = true;
        console.log('fail', contractResponse);
      }
      this.loadingDone = true;
    });

  }


  createFinincailMovmentArray() {

    let movementArray = [];

    let payments = this.payments;
    let totalPayment = 0;
    if (this.payments.length > 0) {
      for (let payment of payments) {

        this.totalPayment = this.totalPayment + payment['amount'];

        let myObj = {
          amount: payment['amount'],
          date: payment['due_date'],
          statement: payment['payment_type'],
          type: 'payment'
        };
        movementArray.push(myObj)
      }
    }


    let receipts = this.receipts;

    if (this.receipts.length > 0) {
      for (let receipt of receipts) {

        this.totalReceipts = this.totalReceipts + receipt['amount'];
        let myObj2 = {
          amount: receipt['amount'],
          date: receipt['created_at'],
          statement: receipt['statement'],
          type: 'receipt'
        };
        movementArray.push(myObj2)
      }
    }

    this.financialMovementArray = movementArray;


    this.financialMovementArray.sort(function (a, b) {
      var c = new Date(a.date).valueOf();
      var d = new Date(b.date).valueOf();
      // return c-d;
      return d - c;
    });


  }


}
