import {Injectable, Inject} from "@angular/core";
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from "@angular/common/http";
import {Observable} from 'rxjs/Observable';
import {HttpHeaders} from "@angular/common/http";

import 'rxjs/add/operator/do';
import {HttpErrorResponse} from "@angular/common/http";
import {HttpResponse} from "@angular/common/http";
import {AuthService} from './_services/auth.service';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';


@Injectable()


export class MyInterceptor implements HttpInterceptor {
  isLoggedIn:boolean;

  constructor(@Inject(WINDOW) private window: Window, @Inject(LOCAL_STORAGE) private localStorage: any, private auth:AuthService) {

  }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    let $token = this.localStorage.getItem('token');
    this.isLoggedIn = this.auth.isAuthenticated();
    if (this.isLoggedIn) {
      let request = req.clone({
        headers: new HttpHeaders().append('Authorization', `Bearer ${this.localStorage.getItem('token')}`)
      });

      return next.handle(request).do((event:any) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }

        },(error:any)=> {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 501) {
              //complex error handling over here
              console.error(error);
              console.log('Please show Modal');
            }

            if(error.status == 401){
              console.error(error);
              console.log('unauthorized  show Modal');
              this.auth.logout();
              this.window.location.replace('/');

            }
          }
        }
      );
    } else {
      let request = req.clone({});
      return next.handle(request).do(
        (event:any) => {
        },
        (error:any)=> {

          if (error instanceof HttpErrorResponse) {
            console.error('my errors ',error);

            if (error.status == 501) {
              //complex error handling over here
              console.error(error);
              console.log('Please show Modal');
            }
            if(error.status == 401){
              console.error(error);
              console.log('Please show Modal');

            }
          }
        }
      );
    }


  }
}
