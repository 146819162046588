<div
  *ngFor="let item of itemsArray?.items   | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems:totalItems,id:'grid_pagination' }"
  class="{{gridCol}}" id="myElement-{{item?.id}}"
>
  <div class="land-grid-view">
    <a [routerLink]="['/'+ item?.type,item?.id]">
<!--      <span class="verified" pTooltip="{{'verifiedTooltip'|translate}}"><i-->
<!--        class="fa fa-check"></i> {{'verifiedLabel'|translate}}</span>-->

      <span class="verified"><i
        class="fa fa-check"></i> {{'verifiedLabel'|translate}}</span>
      <img
        class="img"
        src="{{item?.mainImage}}"
        alt=""
        default="./assets/images/aqar-thumb.jpg"
      >
    </a>
    <div class="info-area">
      <div class="caption mb-1">
        <a [routerLink]="['/'+item?.type,item?.id]">
          <h4>
            <span class="">{{ lang == 'ar' ? item?.ar_title : item?.en_title }}</span>
            <div class="badges">
              <span class="badge badge-daman type" *ngIf="item?.type">{{item.type|translate}}</span>
              <span class="badge badge-light mr-1">{{item?.offer|translate}}</span>
            </div>
          </h4>
        </a>
        <div class="address mb-2">
          <span class="aq aq-address"></span>
          <span class="gov">   {{ lang == 'ar' ? item?.ar_governmentName : item?.en_governmentName }}</span>
          <span class="city"> - {{ lang == 'ar' ? item?.ar_cityName : item?.en_cityName }}</span>
          <span class="zone-dash" *ngIf="item?.data?.ar_zoneName"> - </span>
          <span class="zone"
                *ngIf="item?.ar_zoneName!=null">, {{ lang == 'ar' ? item?.ar_zoneName : item?.en_zoneName}} </span>
        </div>

        <div class="classification address  mb-2" *ngIf="item?.general_classification">
          <img class="icon" src="./assets/images/icons/list-grid.svg"> {{'designation'|translate}} : {{item?.general_classification|translate}}
        </div>

      </div>
      <div class="details">
        <div class="detail">

          <div class="area" *ngIf="item?.area" >
            <span class="aq aq-area"></span>  {{item?.area | number}} <span class="areaSymbol"> m²</span>
          </div>

          <div class="bed" *ngIf="item?.roomsNumber">
            <img class="icon" src="./assets/images/icons/icon-bed.svg">  {{item?.roomsNumber}}
          </div>

          <div class="bath" *ngIf="item?.bathroomsNumber">
            <img class="icon" src="./assets/images/icons/icon-bath.svg">  {{item?.bathroomsNumber}}
          </div>


          <!--          <div class="classification" *ngIf="item?.general_classification">-->
          <!--            {{item?.general_classification|translate}}-->
          <!--          </div>-->

        </div>
        <div class="price" *ngIf="isLoggedIn==true ">${{item.price | number }}<span
          *ngIf="item?.offer=='for_rent'">/{{'month'|translate}} </span></div>
        <div class="price" *ngIf="isLoggedIn==false "><span> {{'thePrice'|translate}}  (****) </span></div>
      </div>

    </div>
    <div class="row b-footer d-flex justify-content-between">
          <div class="save ">
             <button
               *ngIf="savedProperty==true"
               class="btn btn--small btn-outline-danger"
               (click)="saveLand([item?.id,item?.type])"
               id="saveBtn-{{item?.id}}"
             >
              <i class="fa fa-trash"></i> {{'remove'|translate}}
            </button>

            <button
              *ngIf="item.saved==false && savedProperty==false"
              class="btn btn--small btn--light"
              (click)="saveLand([item?.id,item?.type])"
              id="saveBtn-{{item?.id}}"
            >
              <i class="fa fa-star"></i> {{'save'|translate}}
            </button>

             <button
               *ngIf="item.saved==true && isLoggedIn==true && savedProperty==false "
               class="btn btn--small btn btn-outline-warning"
               (click)="saveLand([item?.id,item?.type])"
               id="saveBtn-{{item?.id}}"
             >
              <i class="fa fa-star"></i> {{'saved'|translate}}
            </button>
          </div>

      <div class="btn-note" *ngIf="isLoggedIn==false">
        <button class="btn btn--small btn--blue" (click)="showLoginModal()">{{'login_notes'|translate}}</button>
      </div>


    </div>
  </div>
</div>


<div class="clear"></div>


<div class="pagination">
  <div class="spinner text-center" [ngClass]="{ 'hidden': !loading }"></div>
  <pagination-controls
    (pageChange)="PageChanged($event)"
    previousLabel="<"
    nextLabel=">"
    directionLinks="true"
    id="grid_pagination"
    maxSize="10"
    autoHide="true"
  >
  </pagination-controls>


</div>
