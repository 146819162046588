import {Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {DropzoneComponent, DropzoneDirective, DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
// import {WindowsSizeDetectionService, WindowSize} from '../../_services/windows-size-detection.service';
import {LOCAL_STORAGE, WINDOW} from '@ng-toolkit/universal';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';


@Component({
  selector: 'app-land-file-upload',
  templateUrl: './land-file-upload.component.html',
  styleUrls: ['./land-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class LandFileUploadComponent implements OnInit {


  public type:string = 'component';
  public propertyId:number;
  public propertyType:string;
  public propertyTypeURL:string;
  public dropZoneMsg:string = "dropZoneMsg";
  public disabled:boolean = false;
  // $token:any = localStorage.getItem('token');
  $token:any = '';
  imgPlaceholder:string = 'assets/images/icons/img-placeholder.png';

  add_property_success_msg:string;
  add_property_failed_msg:string;

  navBarLocation:string = "vertical";
  private subscription;
  isBrowser:any;
  public config:DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    url: null,
    acceptedFiles: 'image/*',
  };


  constructor(@Inject(LOCAL_STORAGE) private localStorage:any,
              private  route:ActivatedRoute,
              @Inject(WINDOW) private window:Window,
              public   translate:TranslateService,
              private  router:Router,
              private  toastr:ToastrService,
              @Inject(PLATFORM_ID) private platformId:any) {


    this.isBrowser = isPlatformBrowser(this.platformId);


  }


  ngOnInit() {

    this.translate.get('dropZoneMsg').subscribe((res:string) => {
      this.dropZoneMsg = res;
    });

    this.propertyType = this.route.snapshot.url[2].path;
    this.propertyTypeURL = this.propertyType + 's';

    this.route.params.subscribe(params => {
      this.propertyId = +params['id'];
      let URL = environment.CORE_SERVER_API_URL + "properties/" + this.propertyType + "s/" + this.propertyId + "/uploads?bearer=" + this.localStorage.token + "";
      this.config.url = URL;
    });

    if (this.window.innerWidth < 798) {
      this.navBarLocation = "horizontal";
    } else {
      this.navBarLocation = "vertical";
    }
  }


  finish() {

    if (this.propertyTypeURL == 'villa_houses') {
      let propertyType = 'villas_houses';
      console.log(propertyType);
      this.router.navigateByUrl('/dashboard/properties/' + propertyType + '/' + this.propertyId);
    } else {
      let propertyType = this.propertyTypeURL;
      console.log(propertyType);
      this.router.navigateByUrl('/dashboard/properties/' + propertyType + '/' + this.propertyId);

    }
  }

  public onUploadError(args:any):void {
    console.log('onUploadError:', args);
  }

  public onUploadSuccess(args:any):void {
    console.log('onUploadSuccess:', args);
  }

}
