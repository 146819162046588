<app-top-header></app-top-header>

<div class="contact">


  <div class="inner-banner">
    <div class="overlay"></div>
    <img src="./assets/images/home/contact-us-new.jpg" style="">
    <div class="container-fluid">
      <div class="title">
        <h1>{{'ContactUS'|translate}}</h1>
        <p>{{'WE WOULD LOVE TO HEAR FROM YOU'|translate}}</p>
      </div>
    </div>
  </div>

  <!--<div class="banner">-->

  <!--<h1>WE WOULD LOVE TO HEAR FROM YOU </h1>-->

  <!--</div>-->

  <div class="container">
    <div class="row pt-5 mb-5">

      <div class="col-md-6 col-sm-12">
        <h2 class=" text-uppercase">{{'sendUsMessage'|translate}}</h2>
        <div class="contact-form">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <input type="text" class="form-control" formControlName="fullName" [(ngModel)]="contactInfo.fullName"
                     placeholder="{{'First & Last Name'|translate}}">
              <div class="form-control-feedback text-left"
                   *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
                <p *ngIf="fullName.errors.required">{{'validation.fullName_required'|translate}}</p>
              </div>
            </div>

            <div class="form-group">
              <input type="email" class="form-control" formControlName="email" name="email"
                     [(ngModel)]="contactInfo.email" placeholder="{{'Email'|translate}}" email>
              <div class="form-control-feedback text-left"
                   *ngIf="email.errors && (email.dirty || email.touched)">
                <p *ngIf="email.errors.required">{{'validation.email_required'|translate}}</p>
                <p *ngIf="email.invalid">{{'validation.InvalidEmailAddress'|translate}}</p>
              </div>
            </div>

            <div class="form-group">
              <input type="text" class="form-control" formControlName="mobile" name="mobile"
                     [(ngModel)]="contactInfo.mobile" placeholder="eg:+972598-000-111">
              <div class="form-control-feedback text-left"
                   *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
                <p *ngIf="mobile.errors.required">{{'validation.mobile_required'|translate}}</p>
                <p *ngIf="mobile.invalid">{{'validation.mobile_invalid'|translate}}</p>

              </div>
            </div>

            <div class="form-group">
      <textarea class="form-control" rows="6" formControlName="notes" name="notes" [(ngModel)]="contactInfo.notes"
                placeholder="{{'orderFormNotesPlaceholder'|translate}} "></textarea>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn btn--blue btn--large btn-block"
                [disabled]="!contactForm.valid"
              >
                {{'send'|translate}}
              </button>
            </div>
            <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
            <div class="alert alert-{{alertClass}}" [ngClass]="{ 'hidden': !alertDisplay }">
              <strong>{{alertMsgTitle}}</strong><br>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <h2 class=" text-uppercase">{{'getInTouch'|translate}}</h2>

        <div class="contact-info">
          <ul>
            <li><i class="fa fa-envelope-open"></i> hello@aqar.ps</li>
            <li><i class="fa fa-phone"></i> <span style="direction: ltr;display: inline-block"> +972595115511</span></li>
            <li><i class="fa fa-address-book"></i>{{'aqarAddress'|translate}}</li>
          </ul>
          <ul class="contact-social">
            <li><a href="https://www.facebook.com/aqarps-1465219743723297/" target="_blank"><i
              class="fa fa-facebook"></i></a></li>
            <li><a href="#"><i class="fa fa-instagram"></i></a></li>
            <li><a href="https://www.linkedin.com/company/aqar/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="whatsapp" href="https://api.whatsapp.com/send?phone=970599222332" target="_blank"><i class="fa fa-whatsapp"></i></a>


          </ul>
        </div>

      </div>

    </div>
  </div>
</div>


<layout-footer></layout-footer>
