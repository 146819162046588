<app-top-header></app-top-header>

<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>
  <div class="dashboard-content bg-white">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">{{'Account Settings'|translate}}</h1>
      </div>
      <div class="action">
        <button class="btn  btn--light--default " (click)="openOrderModal()">{{'requestInfo'|translate}}</button>
        <a class="btn btn--primary " routerLink="/dashboard/add-properties">{{'AddNewProperty'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details bg-white">
      <div class="container-fluid">
        <div class="profile-content">
          <ngb-tabset>

            <ngb-tab>
              <ng-template ngbTabTitle>{{'Profile'|translate}}</ng-template>

              <ng-template ngbTabContent>
                <div class="tab--content">
                  <div class="row">
                    <div class="col-md-6 col-xs-12">
                      <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">


                        <div class="row">
                          <div class="col-md-6">

                            <div class="form-group">
                              <label>{{'firstName'|translate}}</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="firstName"
                                placeholder="{{'firstName'|translate}}"
                                [(ngModel)]="user.firstName"
                              >
                              <div class="form-control-feedback"
                                   *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">
                                <p *ngIf="firstName.errors.required">{{'validation.firstName_required'|translate}}</p>
                                <p *ngIf="firstName.invalid">{{'validation.invalid_firstName'|translate}}</p>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label>{{'secondName'|translate}}</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="secondName"
                                placeholder="{{'secondName'|translate}}"
                                [(ngModel)]="user.secondName"
                              >
                              <div class="form-control-feedback"
                                   *ngIf="secondName.errors && (secondName.dirty || secondName.touched)">
                                <p *ngIf="secondName.errors.required">{{'validation.secondName_required'|translate}}</p>
                                <p *ngIf="secondName.invalid">{{'validation.invalid_secondName'|translate}}</p>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div class="row">
                          <div class="col-md-6">

                            <div class="form-group">
                              <label>{{'thirdName'|translate}}</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="thirdName"
                                placeholder="{{'thirdName'|translate}}"
                                [(ngModel)]="user.thirdName"
                              >
                              <div class="form-control-feedback"
                                   *ngIf="thirdName.errors && (thirdName.dirty || thirdName.touched)">
                                <p *ngIf="thirdName.errors.required">{{'validation.thirdName_required'|translate}}</p>
                                <p *ngIf="thirdName.invalid">{{'validation.invalid_thirdName'|translate}}</p>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label>{{'lastName'|translate}}</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="lastName"
                                placeholder="{{'lastName'|translate}}"
                                [(ngModel)]="user.lastName"
                              >
                              <div class="form-control-feedback"
                                   *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">
                                <p *ngIf="lastName.errors.required">{{'validation.lastName_required'|translate}}</p>
                                <p *ngIf="lastName.invalid">{{'validation.invalid_lastName'|translate}}</p>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="form-group">
                          <label> {{'Email'|translate}}</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="email"
                            placeholder="{{'Email'|translate}}"
                            [(ngModel)]="user.email"
                            [email]="true" email>


                          <div class="form-control-feedback"
                               *ngIf="email.errors && (email.dirty || email.touched)">
                            <p *ngIf="email.invalid">{{'validation.InvalidEmailAddress'|translate}}</p>
                          </div>
                        </div>

                        <!--<div class="form-group">-->
                        <!--<label> {{'Phone'|translate}}</label>-->
                        <!--<input-->
                        <!--type="text"-->
                        <!--class="form-control"-->
                        <!--placeholder="{{'Phone'|translate}}"-->
                        <!--[(ngModel)]="user.mobile"-->
                        <!--formControlName="mobile"-->

                        <!--&gt;-->
                        <!--</div>-->


                        <div class="form-group">
                          <label> {{'Phone'|translate}}</label>

                          <input
                            type="text"
                            class="form-control"
                            formControlName="mobile"
                            placeholder="eg:+972598-000-111"
                            [(ngModel)]="user.mobile"
                          >
                          <div class="form-control-feedback text-left"
                               *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
                            <p *ngIf="mobile.errors.required" class="text-left">
                              {{'validation.mobile_required'|translate}}</p>
                            <p *ngIf="mobile.invalid" class="text-left">{{'validation.mobile_invalid'|translate}}</p>
                          </div>
                        </div>

                        <div class="form-group">
                          <button class="btn btn--primary" [disabled]="profileForm.invalid">{{'Update'|translate}}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab>
              <ng-template ngbTabTitle>{{'Password'|translate}}</ng-template>
              <ng-template ngbTabContent>

                <div class="tab--content">
                  <div class="row">
                    <div class="col-md-6 col-xs-12">
                      <div class="form-group">
                        <label> {{'Old Password'|translate}}</label>
                        <input type="text" class="form-control" name="old_password"
                               placeholder="{{'Old Password'|translate}}">
                      </div>
                      <div class="form-group">
                        <label> {{'New Password'|translate}}</label>
                        <input type="text" class="form-control" name="new_password"
                               placeholder="{{'New Password'|translate}}">
                      </div>

                      <div class="form-group">
                        <label> {{'Repeat New Password'|translate}}</label>
                        <input type="text" class="form-control" name="password_confirmation"
                               placeholder="{{'Repeat New Password'|translate}}">
                      </div>
                      <div class="form-group">
                        <button class="btn btn--primary">{{'Update'|translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
            </ngb-tab>

            <ngb-tab>
              <ng-template ngbTabTitle>{{'Membership'|translate}}</ng-template>
              <ng-template ngbTabContent>
                <div class="tab--content">

                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th>{{'name'|translate}}</th>
                      <th>{{'startAt'|translate}}</th>
                      <th>{{'expireAt'|translate}}</th>
                      <th>{{'VisibleProperties'|translate}}</th>
                      <th>{{'AddedProperties'|translate}}</th>
                      <th>{{'price'|translate}}</th>
                      <th>{{'Status'|translate}}</th>
                      <th>{{'paid'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="userInformation.user_membership!=null">
                      <td align="center">
                        {{userInformation.user_membership.enName}}
                        <br>
                        <img src="{{userInformation.user_membership.icon}}">
                      </td>
                      <td>{{userInformation.user_membership.startAt|date :'y-M-dd'}}</td>
                      <td>{{userInformation.user_membership.expireAt|date :'y-M-dd'}}</td>
                      <td>{{userInformation.user_membership.numberOfVisibleProperties}}</td>
                      <td>{{userInformation.user_membership.numberOfAddedProperties}}</td>
                      <td>{{userInformation.user_membership.price}} $</td>
                      <td>

                    <span *ngIf="userInformation.user_membership.status=='active'"
                          class="badge  badge-success text-white p-2">
                      {{userInformation.user_membership.status|translate}}
                    </span>
                     <span *ngIf="userInformation.user_membership.status=='pending'"
                           class="badge badge-warning text-white p-2">
                      {{userInformation.user_membership.status|translate}}
                    </span>
                     <span *ngIf="userInformation.user_membership.status=='expired'"
                           class="badge badge-danger text-white p-2">
                      {{userInformation.user_membership.status|translate}}
                    </span>
                     <span *ngIf="userInformation.user_membership.status=='cancelled'"
                           class="badge badge-light text-white p-2">
                      {{userInformation.user_membership.status|translate}}
                    </span>

                      </td>
                      <td>
                        <span *ngIf="userInformation.user_membership.paid!=1">{{'notPaid'|translate}}</span>
                        <span *ngIf="userInformation.user_membership.paid==1">{{'paid'|translate}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="userInformation.user_membership.length==0">
                      <td colspan="8" align="center">no membership found</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab>
              <ng-template ngbTabTitle> {{'Notifications'|translate}}</ng-template>
              <ng-template ngbTabContent>
                <div class="tab--content">
                  Notifications
                </div>
              </ng-template>
            </ngb-tab>

          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
