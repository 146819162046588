<app-top-header></app-top-header>

<section>
  <div class="inner-banner">
    <div class="overlay"></div>
    <img src="./assets/images/services-bg.jpg">
    <div class="container-fluid">
      <div class="title">
        <h1>{{'ourServices'|translate}}</h1>
        <p>{{'ourServicesSlogan'|translate}}</p>
      </div>
    </div>
  </div>
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-md-3">
        <div class="content-sidebar">
          <ul>
            <li *ngFor="let service of services | reverse ;let i = index">
              <a
                [routerLink]="['/services/',service.slug]"
                routerLinkActive="active"
              >
                {{service.title.rendered}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9">
        <app-page-loader [hidden]="!loader"></app-page-loader>
        <div class="nav-content">
          <div class="content-header">
            <h1>{{ post?.title?.rendered }}</h1>
          </div>
          <div class="page-content" [innerHTML]=content></div>


          <div class="row" *ngIf="slug=='real-estate-appraisal'">

            <form [formGroup]="propertyRequestForm" (ngSubmit)="onSubmit()" class="property-request-form">
              <div class="row">
                <h1 class="service-form-title">{{'appraiseFormTitle'|translate}}</h1>
                <div class="clearfix"></div>
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{'propertyType'|translate}}</label>
                        <ng-select [items]="propertyTypes"
                                   bindLabel="{{selectedName}}"
                                   required
                                   bindValue="id"
                                   placeholder="{{'selectedPropertyType'|translate}}"
                                   [clearable]="false"
                                   [(ngModel)]="selectedPropertyType"
                                   formControlName="propertyType"
                                   [searchable]="false"
                                   [typeahead]="false"
                                   class=""
                        >
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{'Location'|translate}}</label>
                        <div ngbDropdown #locationDropdownValue="ngbDropdown">
                          <button type="button" class="location-dropdown larg-dropdown" id="locationDropdownValue"
                                  ngbDropdownToggle>
                            <span> {{locationSearchText}} </span>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="locationDropdownValue" class="location-dropdown-menu">
                            <div class="form-group mb-3">
                              <label class="mb-1">{{'Governate'|translate}}</label>
                              <ng-select
                                [items]="governateList"
                                bindLabel="{{selectedName}}"
                                bindValue="id"
                                placeholder="{{'SelectGovernorate'|translate}}"
                                [(ngModel)]="selectedCityId"
                                [clearable]="false"
                                formControlName="government"
                                (change)="getGovernate($event)"
                              >
                              </ng-select>
                            </div>

                            <div class="form-group mb-3">
                              <label class="mb-1">{{'Town'|translate}}</label>
                              <ng-select
                                [items]="townList"
                                bindLabel="{{selectedName}}"
                                required
                                bindValue="id"
                                placeholder="{{'SelectTown'|translate}}"
                                [(ngModel)]="selectedTownId"
                                [clearable]="false"
                                formControlName="city"
                                (change)="getTown($event)"
                              >
                              </ng-select>
                            </div>
                            <div class="form-group mb-3">
                              <label class="mb-1">{{'Zone'|translate}}</label>
                              <ng-select
                                [items]="areaList"
                                bindLabel="{{selectedName}}"
                                required
                                bindValue="id"
                                placeholder="{{'SelectArea'|translate}}"
                                [(ngModel)]="selectedAreaId"
                                [clearable]="false"
                                formControlName="areaZone"
                                (change)="getArea($event)"
                              >
                              </ng-select>
                            </div>
                            <div class="form-group">
                              <button type="button" class="btn btn--light--default btn--small"
                                      (click)="$event.stopPropagation(); locationDropdownValue.close();">
                                {{'close'|translate}}
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="sub-filters">
                    <div class="row">


                      <div class="col-md-6">

                        <input
                          type="text"
                          placeholder="{{'fullName'|translate}}"
                          class="form-control form-control-sm"
                          name="fullName"
                          formControlName="fullName"
                          [(ngModel)]="propertyRequest.fullName"
                        >


                        <input
                          type="hidden"
                          name="requestType"
                          formControlName="requestType"
                          [(ngModel)]="propertyRequest.requestType"
                        >

                        <input
                          type="text"
                          placeholder="{{'mobileNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="mobileNumber"
                          formControlName="mobile"
                          [(ngModel)]="propertyRequest.mobile"

                        >
                      </div>

                      <div class="col-md-6">
                        <input
                          type="text"
                          placeholder="{{'blockNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="blockNumber"
                          formControlName="blockNumber"
                          [(ngModel)]="propertyRequest.blockNumber"
                        >
                        <input
                          type="text"
                          placeholder="{{'sectorNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="sectorNumber"
                          formControlName="sectorNumber"
                          [(ngModel)]="propertyRequest.sectorNumber"
                        >
                        <input
                          type="text"
                          placeholder="{{'parcelNumber'|translate}}"
                          class="form-control form-control-sm"
                          name="parcelNumber"
                          formControlName="parcelNumber"
                          [(ngModel)]="propertyRequest.parcelNumber"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="w-25 m-auto ">
                      <button
                        type="submit"
                        class="btn btn--large btn--primary browse-btn btn-block padding-8 rounded "
                        [disabled]="!propertyRequestForm.valid"
                      >
                        {{'RequestInfo'|translate}}
                      </button>
                    </div>

                    <div *ngIf="loading" class="spinner text-center d-block m-auto"></div>
                    <div *ngIf="alert" class="text-center alert alert-{{alertClass}} w-100">
                      <strong>{{alertMsgTitle}}</strong><br>
                      {{alertMsg}}
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<layout-footer></layout-footer>
