import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {AuthService} from './../_services/auth.service';
import {LoginModalComponent} from '../components/includes/login-modal/login-modal.component';
import {SeoService} from '../_services/seo.service';


@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class SellComponent implements OnInit {


  isLoggedIn:boolean;


  constructor(private auth:AuthService, private modalService:NgbModal, private  seo:SeoService) {

    this.isLoggedIn = this.auth.isAuthenticated();

  }

  ngOnInit() {
    this.seo.setTags();

  }


  openLoginModal() {
    const modalRef = this.modalService.open(LoginModalComponent);
    // modalRef.componentInstance.name = 'World';

  }


}
