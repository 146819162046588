import {Observable} from "rxjs/Rx"
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../environments/environment';


import 'rxjs/add/operator/map';

@Injectable()
export class LandsService {
  lands:any;
  data:any;
  // private BASE_URL:string = 'http://localhost/daman/app_dev.php/api/';
  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  constructor(private http:HttpClient) {
  }

  getLands(parms):Observable<any> {
    return this.http
      .get(this.BASE_URL + 'lands', {params: parms})
  }

  getProperties(parms, type) {

    let PropertyType = 'land';

    switch (type) {
      case 'lands':
        PropertyType = 'lands';
        break;
      case 'stores':
        PropertyType = 'units/stores';
        break;
      case 'apartments':
        PropertyType = 'units/apartments';
        break;
      case 'villas_houses':
        PropertyType = 'buildings/villas_houses';
        break;
      case 'buildings':
        PropertyType = 'buildings/buildings';
        break;
      case 'offices':
        PropertyType = 'units/offices';
        break;
      default:
        PropertyType = 'lands';
        break;
    }

    return this.http
      .get(this.BASE_URL + PropertyType + '/', {params: parms})
  }


  getUserProperties(parms, type) {

    let PropertyType = 'land';

    switch (type) {
      case 'lands':
        PropertyType = 'lands';
        break;
      case 'stores':
        PropertyType = 'units/stores';
        break;
      case 'apartments':
        PropertyType = 'units/apartments';
        break;
      case 'villas_houses':
        PropertyType = 'buildings/villas_houses';
        break;
      case 'buildings':
        PropertyType = 'buildings/buildings';
        break;
      case 'offices':
        PropertyType = 'units/offices';
        break;
      default:
        PropertyType = 'lands';
        break;
    }

    return this.http
      .get(this.BASE_URL + 'user_properties/' + PropertyType, {params: parms})
  }


  getRentedUnits(parms, type) {
    return this.http
      .get(this.BASE_URL +'rent/user_rented_units', {params: parms})

  }

  getPropertyById(id, type) {

    let propertyType = '';

    if (type == 'lands') {
      propertyType = 'lands';

    } else if (type == 'stores') {
      propertyType = 'store';

    } else if (type == 'offices') {
      propertyType = 'office';

    } else if (type == 'apartments') {
      propertyType = 'apartments';

    }
    else if (type == 'offices') {
      propertyType = 'offices';
    }
    else if (type == 'buildings') {
      propertyType = 'buildings/buildings';
    }
    else if (type == 'villas_houses') {
      propertyType = 'buildings/villas_houses';
    }

    console.log('propertyType :', type);

    return this.http
      .get(this.BASE_URL + propertyType + '/' + id);

  }

  getBuildingById(id, type) {
    return this.http
      .get(this.BASE_URL + 'buildings/' + type + '/' + id);
  }

  getUnitById(id, type) {
    return this.http
      .get(this.BASE_URL + 'units/' + type + '/' + id);
  }


  /***
   * get properties  near
   * @param id
   * @returns {Observable<Object>}
   */

  getLandNearby(id) {
    return this.http
      .get(this.BASE_URL + 'lands/' + id + '/nearby');
  }

  getBuildingsNearby(id) {
    return this.http
      .get(this.BASE_URL + 'buildings/' + id + '/nearby');
  }

  getUnitsNearby(id) {
    return this.http
      .get(this.BASE_URL + 'units/' + id + '/nearby');
  }


  /**
   * get users properties
   * @param parms
   * @returns {Observable<Object>}
   */

  getUserLands(parms) {
    return this.http
      .get(this.BASE_URL + 'user_properties/lands', {params: parms})
  }

  getUserLand(id) {
    return this.http
      .get(this.BASE_URL + 'user_properties/lands/' + id);

  }


  getUserPropertyDetailByTypeAndId(type, id) {
    return this.http
      .get(this.BASE_URL + 'user_properties/' + type + '/' + id);
  }


  /***
   *  get properties images
   * @param id
   * @param propertyType
   * @returns {Observable<Object>}
   */
  getPropertyGallery(id, propertyType) {
    return this.http
      .get(this.BASE_URL + 'gallery/' + propertyType + '?id=' + id);

  }


  /**
   * add properties
   * @param Land
   * @returns {any}
   */

  addNewLand(Land) {
    let url:string = `${this.BASE_URL}properties/create_land`;
    return this.http.post(url, Land);
  }


  addProperty(Property, propertyType) {
    let url:string = `${this.BASE_URL}properties/create/` + propertyType;
    return this.http.post(url, Property);
  }


  getSameAreaProperty(propertyType, id) {
    return this.http
      .get(this.BASE_URL + 'same_property/' + propertyType + '/' + id);
  }


  getPropertiesCount() {
    const url = this.BASE_URL + `lands/properties/count`;
    return this.http.get(url);
  }


}
