<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h2 class=" text-center strong">{{'Request Property Value'|translate}}</h2>


  <div class="title">

    <div class="image">
      <img
        src="{{order_mainImage}}"
        default="./assets/images/aqar-thumb.jpg"
        style="width: 30%"
      >
    </div>
    <h3>{{order_title}}</h3>
  </div>


  <form [formGroup]="orderForm" (ngSubmit)="onSubmit()">

    <div class="form-group">
      <input type="text" class="form-control" formControlName="fullName" [(ngModel)]="customerOrder.fullName"
             placeholder="{{'First & Last Name'|translate}}">
      <div class="form-control-feedback text-left"
           *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
        <p *ngIf="fullName.errors.required">{{'validation.fullName_required'|translate}}</p>
      </div>
    </div>

    <div class="form-group">
      <input type="email" class="form-control" formControlName="email" name="email" [(ngModel)]="customerOrder.email"
             placeholder="{{'Email'|translate}}">
      <div class="form-control-feedback text-left"
           *ngIf="email.errors && (email.dirty || email.touched)">
        <p *ngIf="email.errors.required">{{'validation.email_required'|translate}}</p>
        <p *ngIf="email.invalid">{{'validation.InvalidEmailAddress'|translate}}</p>
      </div>
    </div>

    <div class="form-group">
      <input type="text" class="form-control" formControlName="mobile" name="mobile" [(ngModel)]="customerOrder.mobile"
             placeholder="eg:+972598-000-111">
      <div class="form-control-feedback text-left"
           *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
        <p *ngIf="mobile.errors.required">{{'validation.mobile_required'|translate}}</p>
        <p *ngIf="mobile.invalid">{{'validation.mobile_invalid'|translate}}</p>

      </div>
    </div>

    <div class="form-group">
      <textarea class="form-control" rows="6" formControlName="notes" name="notes" [(ngModel)]="customerOrder.notes"
                placeholder="{{'orderFormNotesPlaceholder'|translate}} ">{{order_text}}</textarea>

      <input type="hidden" formControlName="propertyId" name="property_id" [(ngModel)]="customerOrder.propertyId">
      <input type="hidden" formControlName="propertyType" name="property_type" [(ngModel)]="customerOrder.propertyType">
      <input type="hidden" formControlName="propertyURL" name="property_URL" [(ngModel)]="customerOrder.propertyURL">
    </div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn--primary btn--large btn-block"
        [disabled]="!orderForm.valid"
      >
        {{'send_request'|translate}}
      </button>
    </div>
    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
    <div class="alert alert-{{alertClass}}">
      <strong>{{alertMsgTitle}}</strong><br>
      {{alertMsg}}
    </div>
  </form>
</div>




