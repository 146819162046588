import {Component, OnInit} from '@angular/core';
import {SignUpModalComponent} from '../includes/sign-up-modal/sign-up-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Meta, Title} from '@angular/platform-browser';

import {SeoService} from '../../_services/seo.service';
import {AuthService} from './../../_services/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  title:any;
  keywords:any;
  description:any;
  seoTitle:any = '';
  seoDescription:any = '';
  seoKeywords:any = '';
  lang:any = 'ar';
  seoImage:any = 'http://aqar.ps/assets/images/logo/aqar-logo.png';
  seoUrl:any = 'http://aqar.ps/';
  seoConfig:any;
  isLoggedIn:any;


  constructor(private modalService:NgbModal,
              private seo:SeoService,
              private titleService:Title,
              private metaService:Meta,
              private auth:AuthService
  ) {


    this.seo.setTags();

  }

  ngOnInit() {
    this.isLoggedIn = this.auth.isAuthenticated();

    // let SEO = undefined;
    // this.seo.setTags();
    // this.seo.generateTags();
  }

  openSignUpModal() {
    const modalRef = this.modalService.open(SignUpModalComponent);
    // modalRef.componentInstance.name = 'World';
  }

}
