<agm-map
  [latitude]="lat"
  [longitude]="lng"
  [disableDefaultUI]="false"
  [zoomControl]="true"
  [styles]="customStyle"
  (mapReady)="mapReady($event)"
  [fullscreenControl]='true'
  [mapTypeControl]='true'
  [fitBounds]="true"
  [zoom]="16"
>
  <!--  usePanning-->
  <!--<agm-circle *ngIf="lat && lng"-->
  <!--[latitude]="lat"-->
  <!--[longitude]="lng"-->
  <!--[radius]="1000"-->
  <!--[fillColor]="'#F44336'"-->
  <!--[fillOpacity]="0.2"-->

  <!--&gt;-->
  <!--</agm-circle>-->

  <agm-direction
    [origin]="origin"
    [destination]="destination">
  </agm-direction>


  <agm-marker-cluster [styles]="clusterStyle">
    <agm-marker #gm
                *ngFor="let m of markers; let i = index"
                (markerClick)="clickedMarker(m.label, i)"
                [latitude]="m.lat"
                [longitude]="m.lng"
                [label]="isLoggedIn?m.label:'***'"
                [markerDraggable]="false"
                [iconUrl]="markerIcon"
                [agmFitBounds]="true"
                (dragEnd)="markerDragEnd(m, $event)"
                (markerClick)="gm.lastOpen?.close(); gm.lastOpen = infoWindow">

      <agm-snazzy-info-window [maxWidth]="200" [closeWhenOthersOpen]="true">
        <ng-template>
          <div class="hope">
            <a [routerLink]="['/'+m.propertyType,m.id]" target="_blank">
              <img
                class="img"
                src="{{m.mainImage}}"
                default="./assets/images/aqar-thumb.jpg"
                alt=""/>
              <h1>{{ lang == 'ar' ? m?.ar_title : m?.en_title }} </h1>
              <address class="d-block">
                <span class="gov">   {{ lang == 'ar' ? m?.ar_governmentName : m?.en_governmentName }}</span>
                <span class="city"  *ngIf="m?.data?.en_cityName!==''"> - {{ lang == 'ar' ? m?.ar_cityName : m?.en_cityName }}</span>
                <span class="zone-dash" *ngIf="m?.data?.ar_zoneName"> - </span>

                <!--                {{ lang == 'ar' ? m?.ar_cityName : m?.en_cityName }}-->
                <!--                - {{ lang == 'ar' ? m.ar_zoneName : m.en_zoneName }}-->
              </address>
            </a>
            <div class="hop-footer">
              <span class="price pull-left" *ngIf="isLoggedIn==true">${{ m.price |number:'1.0-0'  }} </span>
              <span class="price pull-left" *ngIf="isLoggedIn==false">***</span>
              <span class="price pull-right" (click)="getDirection(m.lat,m.lng)">
                {{ 'getDirection'|translate}} <i class="fa fa-car"></i>
              </span>
            </div>

          </div>
        </ng-template>
      </agm-snazzy-info-window>

    </agm-marker>
  </agm-marker-cluster>

</agm-map>
