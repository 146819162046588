<app-top-header></app-top-header>


<section class="sell">

  <div class="header-text">
    <h1 class="title">{{'AQAR.PS'|translate}} </h1>
    <h2>{{'featureSubHeader'|translate}}</h2>
  </div>

  <div class="sec1 ">
    <div class="container">
      <div class="d-block mb-4">
        <h2 class="d-block text-center text-uppercase">{{'Selling & rent your property'|translate}}</h2>
        <h3 class="text-center">{{'sellingSubTitleV2'|translate}}</h3>
      </div>

      <div class="mt-5"></div>
      <div class="row mt-4">

        <div class="col-md-6">
          <div class="steps">
            <h2>{{'sellListTitle'|translate}}</h2>
            <ul>
              <li><span>1.</span>
                <p>{{'sellingStep1'|translate}}</p>
              </li>
              <li><span>2.</span>
                <p>{{'sellingStep2'|translate}}</p>
              </li>
              <li><span>3.</span>
                <p>{{'sellingStep3'|translate}}</p>
              </li>
              <li><span>4.</span>
                <p>{{'sellingStep4'|translate}}</p>
              </li>

              <li>
                <a *ngIf="isLoggedIn == true " class="btn btn--primary btn--large start-now"
                   routerLink="/dashboard/add-properties">
                  {{'startNow'|translate}}</a>
                <a *ngIf="isLoggedIn == false " class="btn btn--primary btn--large white start-now " (click)="openLoginModal()">
                  {{'startNow'|translate}}
                </a>
              </li>


            </ul>
          </div>
        </div>

        <div class="col-md-6">
          <img src="/assets/images/steps.svg" style="width: 100%">
        </div>


      </div>
    </div>
  </div>
</section>

<layout-footer></layout-footer>
