<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>
  <div class="dashboard-content">
    <div class="dashboard-header fl">
      <div class="title d-none d-lg-flex">
        <h1 class="text-capitalize mt-2">{{'myProperties'|translate}}</h1>
      </div>
      <div class="action">
        <button class="btn  btn--light--default " (click)="openOrderModal()">{{'requestInfo'|translate}}</button>
        <a class="btn btn--primary m-1" routerLink="/dashboard/add-properties">{{'AddNewProperty'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details">

      <div class="property-breadcrumb">
        <ul>
          <li><a routerLink="/dashboard">{{'Dashboard'|translate}}</a></li>
          <li><a href="/dashboard/properties/{{propertyType}}" class="active">{{propertyType|translate}}</a></li>
        </ul>
      </div>


      <div class="properties-type-list">
        <ul>

          <li>
            <a class=" with-icons {{ propertyType=='lands'?'active':'' }}"
               routerLink="/dashboard/properties/lands">
              <span class="img-circle"><img src="./assets/images/icons/Land.svg"></span>
              {{'land'|translate}} <span class="text-muted small">({{propertiesCounts?.land}})</span>
            </a>
          </li>
          <li>
            <a class=" with-icons {{ propertyType=='apartments'?'active':'' }}"
               routerLink="/dashboard/properties/apartments">
              <span class="img-circle"><img src="./assets/images/icons/Apartment.svg"></span>
              {{'apartment'|translate}} <span class="text-muted small">({{propertiesCounts?.apartment}})</span>
            </a>
          </li>

          <li>
            <a class=" with-icons {{ propertyType=='villas_houses'?'active':'' }}"
               routerLink="/dashboard/properties/villas_houses">
              <span class="img-circle"><img src="./assets/images/icons/Villa.svg"></span>
              {{'house'|translate}} <span class="text-muted small">({{propertiesCounts?.villa_house}})</span>
            </a>
          </li>

          <li>
            <a class=" with-icons {{ propertyType=='offices'?'active':'' }}"
               routerLink="/dashboard/properties/offices">
              <span class="img-circle"><img src="./assets/images/icons/Office.svg"></span>
              {{'office'|translate}} <span class="text-muted small">({{propertiesCounts?.office}})</span>
            </a>
          </li>

          <li>
            <a class=" with-icons {{ propertyType=='stores'?'active':'' }}"
               routerLink="/dashboard/properties/stores">
              <span class="img-circle"> <img src="./assets/images/icons/Store.svg"></span>
              {{'store'|translate}} <span class="text-muted small">({{propertiesCounts?.store}})</span>
            </a>
          </li>
          <li>
            <a class=" with-icons {{ propertyType=='buildings'?'active':'' }}"
               routerLink="/dashboard/properties/buildings"
            >
              <span class="img-circle"><img src="./assets/images/icons/Building.svg"></span>
              {{'building'|translate}} <span class="text-muted small">({{propertiesCounts?.building}})</span>
            </a>
          </li>
        </ul>
      </div>


      <nav class="navbar navbar-expand-sm navbar-light bg-light mb-2  filter-nav">
        <button class="navbar-toggler" (click)="collapse=!collapse" type="button" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon" *ngIf="collapse"></span>
          <span class="fa fa-close text-danger" *ngIf="!collapse"></span>

          <span class="search-btn" *ngIf="collapse"> {{'search'|translate}}</span>
          <span class="search-btn " *ngIf="!collapse"> {{'close'|translate}}</span>

        </button>

        <div class="navbar-brand result-count-brand d-block d-sm-none"> {{'resultCount'|translate}} ({{totalItems}})
        </div>


        <div class="navbar-collapse " [hidden]="collapse">


          <ul class="navbar-nav ">
            <li class="nav-item">
              <div class="select ">
                <div autoClose="false" ngbDropdown #locationsDropdown="ngbDropdown">
                  <button [class.dropdown-selected]="locationSelected" class="btn  btn--light dropdown-toggle type-btn "
                          id="locationsDopdownToggle"
                          ngbDropdownToggle>
                    {{locationSearchText}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="locationsDopdownToggle"
                       class="location-dropdown locationsDropdown">
                    <div class="form-group mb-3 p-1">
                      <label class="mb-1">{{'Governate'|translate}}</label>
                      <ng-select
                        [items]="governateList"
                        bindLabel="{{selectedName}}"
                        bindValue="id"
                        placeholder="{{'SelectGovernorate'|translate}}"
                        [(ngModel)]="selectedGovId"
                        [clearable]="true"
                        (clear)="onClearGov()"
                        (change)="onGovChange($event)"
                      >
                      </ng-select>
                    </div>
                    <div class="form-group mb-3 p-1">
                      <label class="mb-1">{{'Town'|translate}}</label>
                      <ng-select
                        [items]="townList"
                        bindLabel="{{selectedName}}"
                        required
                        bindValue="id"
                        placeholder="{{'SelectTown'|translate}}"
                        [(ngModel)]="selectedTownId"
                        [clearable]="true"
                        (clear)="onClearTown()"
                        (change)="getTown($event)"
                      >
                      </ng-select>
                    </div>
                    <div class="form-group mb-3 p-1">
                      <label class="mb-1">{{'Zone'|translate}}</label>
                      <ng-select
                        [items]="areaList"
                        bindLabel="{{selectedName}}"
                        required
                        bindValue="id"
                        placeholder="{{'SelectArea'|translate}}"
                        [(ngModel)]="selectedAreaId"
                        [clearable]="true"
                        (clear)="onClearArea()"
                        (change)="getArea($event)"
                      >
                      </ng-select>
                    </div>
                    <div class="dropdown-divider"></div>
                    <div class="clearfix"></div>
                    <div class="dropdown-footer">
                      <button class="btn btn--light--default btn--small"
                              (click)="search();$event.stopPropagation();locationsDropdown.close();collapse=!collapse">
                        {{'apply'|translate}}
                      </button>
                      <button
                        class="btn btn--default btn--small"
                        (click)="$event.stopPropagation();locationsDropdown.close();"
                      >
                        {{'cancel'|translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </li>


            <li class="nav-item">

              <div class="select">
                <div autoClose="false" ngbDropdown #propertyNumberDropdownToggle="ngbDropdown" class="ml-1 mr-1">
                  <button [class.dropdown-selected]="propertyNumberSelected"
                          class="btn  btn--light dropdown-toggle type-btn " id="propertyNumberDropdownToggle"
                          ngbDropdownToggle>
                    {{propertyNumberText}}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="locationsDopdownToggle"
                       class="location-dropdown locationsDropdown">
                    <div class="form-group mb-0 p-1">
                      <label class="mb-1">{{'block'|translate}}</label>
                      <ng-select
                        [items]="blockList"
                        bindLabel="{{selectedName}}"
                        bindValue="id"
                        placeholder="{{'SelectBlock'|translate}}"
                        [(ngModel)]="block_number"
                        [clearable]="true"
                        (clear)="onClearBlock()"
                        (change)="onBlockChange($event)"
                        [disabled]="blockDisabled"
                      >
                      </ng-select>
                    </div>

                    <div class="form-group mb-0 p-1" *ngIf="!sectorDisabled">
                      <label class="mb-1">{{'sector'|translate}}</label>
                      <ng-select
                        [items]="sectorList"
                        bindLabel="{{selectedName}}"
                        bindValue="id"
                        placeholder="{{'SelectSector'|translate}}"
                        [(ngModel)]="sector_number"
                        [clearable]="true"
                        (change)="onSectorChange($event)"
                        (clear)="onClearSector()"
                      >
                      </ng-select>
                    </div>

                    <div class="form-group mb-0 p-1">
                      <label class="mb-1">{{'parcelNumber'|translate}}</label>
                      <input type="number" class="form-control" #myInput (keyup)="parcelOutPut(myInput.value)"
                             value="{{parcel_number}}">
                    </div>

                    <div class="dropdown-divider"></div>
                    <div class="clearfix"></div>
                    <div class="dropdown-footer">
                      <button class="btn btn--light--default btn--small"
                              (click)="search();$event.stopPropagation();propertyNumberDropdownToggle.close();collapse=!collapse">
                        {{'apply'|translate}}
                      </button>
                      <button
                        class="btn btn--default btn--small"
                        (click)="$event.stopPropagation();propertyNumberDropdownToggle.close();"
                      >
                        {{'cancel'|translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>


            <li class="nav-item">

              <div class="select search-dropdown-list ">
                <ng-select [items]="propertyAreaList"
                           bindLabel="{{selectedName}}"
                           bindValue="id"
                           placeholder="{{'minArea'|translate}}"
                           [(ngModel)]="area_from"
                           [clearable]="true"
                           (change)="getMinPropertyArea($event)"
                           [searchable]="false"
                           class="transparentt  no-autocomplete"
                >
                </ng-select>
              </div>
            </li>
            <li class="nav-item">

              <div class="select search-dropdown-list ">
                <ng-select [items]="propertyAreaList"
                           bindLabel="{{selectedName}}"
                           bindValue="id"
                           placeholder="{{'maxArea'|translate}}"
                           [(ngModel)]="area_to"
                           [clearable]="true"
                           (change)="getMaxPropertyArea($event)"
                           [searchable]="false"
                           class="transparentt  no-autocomplete"
                >
                </ng-select>
              </div>
            </li>

            <li class="nav-item">


              <div class="select search-dropdown-list" style="">
                <ng-select [items]="priceList"
                           bindLabel="{{selectedName}}"
                           bindValue="id"
                           placeholder="{{'minPrice'|translate}}"
                           [(ngModel)]="price_from"
                           [clearable]="true"
                           (change)="getMinPrice($event)"
                           [searchable]="false"
                           class="transparentt  no-autocomplete"
                >
                </ng-select>
              </div>
            </li>


            <li class="nav-item">
              <div class="select search-dropdown-list   " style="">
                <ng-select [items]="priceList"
                           bindLabel="{{selectedName}}"
                           bindValue="id"
                           placeholder="{{'maxPrice'|translate}}"
                           [(ngModel)]="price_to"
                           [clearable]="true"
                           (change)="getMaxPrice($event)"
                           [searchable]="false"
                           class="transparentt  no-autocomplete"
                >
                </ng-select>
              </div>
            </li>


            <li class="nav-item">
              <div class="select search-dropdown-list   " style="">

                <ng-select [items]="propertyForList"
                           bindLabel="{{selectedName}}"
                           bindValue="id"
                           placeholder="{{'Offer'|translate}}"
                           [(ngModel)]="offer"
                           [clearable]="true"
                           (change)="setPropertyFor($event)"
                           [searchable]="false"
                           class="transparentt  no-autocomplete"
                >
                </ng-select>
              </div>
            </li>

          </ul>
        </div>
      </nav>

      <div class="result-count d-none d-sm-block"> {{'resultCount'|translate}} ({{totalItems}})</div>

      <div class="table-responsive">
        <table class="table table--paginate bg-white " *ngIf="!loading">
          <thead>
          <tr>
            <th class="col-sm-auto"> {{'address'|translate}}</th>
            <th class="td-desktop"> {{'propertyNumber'|translate}}</th>
            <th class="td-desktop"> {{'Details'|translate}}</th>
            <th class="td-desktop" *ngIf="propertyType=='lands'"> {{'classification'|translate}}</th>
            <th class="">{{'offer'|translate}}</th>
            <th> {{'aqarStatus'|translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr *ngIf="totalItems==0">
            <td colspan="7" class="text-center bg-light"> {{'noDataFound'|translate}}</td>
          </tr>

          <tr
            *ngFor="let item of landsTest$?.items   | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems:totalItems,id:'grid_pagination' }"
            class="{{gridCol}}" id="myElement-{{item?.id}}"
          >
            <td style="min-width: 160px" class="col-sm-auto">


              <div class="media">
                <div class="clearfix"></div>
                <img class="align-self-start mr-3 img" src="{{item?.mainImage}}"
                     default="./assets/images/aqar-thumb.jpg" alt="placeholder image">
                <div class="d-block">
                  <h5 class="p-1 pr-1"> {{ lang=='ar'?item?.ar_governmentName : item?.en_governmentName }} </h5>
                  <small class="p-1 pr-1">
                    {{ lang=='ar'?item?.ar_cityName : item?.en_cityName }} <span *ngIf="item?.ar_zoneName!=null"> - {{lang=='ar'?item?.ar_zoneName:item?.en_zoneName}}</span>

                  </small>

                  <div class="property-number land td-mobile-block " *ngIf="propertyType=='lands'">

                <span class="part text-muted">
                  {{'parcelNumber#'|translate}}
                  <strong style="direction: ltr !important;display: inline-block">{{item?.parcelNumber}}</strong>
                </span>

                <span class="part text-muted" *ngIf="item?.sectorNumber!=null">
                   {{'sectorNumber#'|translate}} {{ lang=='ar'?item?.ar_sectorName : item?.en_sectorName }}
                  <strong style="direction: ltr !important;display: inline-block">{{item?.sectorNumber}}</strong>
                </span>

                    <span class="part text-muted">
                      {{'blockNumber#'|translate}} {{ lang=='ar'?item?.ar_blockName : item?.en_blockName }}
                      <strong style="direction: ltr !important;display: inline-block">{{item?.blockNumber}}</strong>
                    </span>

                  </div>

                </div>
              </div>


              <div class="td-mobile-block pl-1 pr-1 mt-1">
                <span class="d-block">{{item.price|number}} <span>$</span></span>
                <span class="d-block">{{item.area|number}} <span class="areaSymbol strong">m²</span></span>
              </div>

              <div class="td-mobile-block pr-1 pl-1 mt-1" *ngIf="propertyType=='lands'">
                {{item.classification}}
              </div>

              <div class="td-mobile-block pr-1 pl-1 " *ngIf="propertyType=='lands'">
                <span *ngIf="item.building_count==0" class="text-muted">{{'no_building_exist'|translate}}</span>
                <span *ngIf="item.building_count > 0" class="text--primary">{{'buildingOnIt'|translate}}({{item.building_count}})</span>
              </div>

            </td>

            <td class="td-desktop">
              <div class="property-number land " *ngIf="propertyType=='lands'">
                <span class="part text-muted">
                  {{'parcelNumber#'|translate}}  <strong style="direction: ltr !important;display: inline-block">{{item?.parcelNumber}}</strong>
                </span>
                <span class="part text-muted" *ngIf="item?.sectorNumber!=null">
                   {{'sectorNumber#'|translate}} {{ lang=='ar'?item?.ar_sectorName : item?.en_sectorName }}  <strong
                  style="direction: ltr !important;display: inline-block">{{item?.sectorNumber}}</strong>
                </span>
                <span class="part text-muted">{{'blockNumber#'|translate}} {{ lang=='ar'?item?.ar_blockName : item?.en_blockName }} <strong>{{item?.blockNumber}}</strong></span>
              </div>
            </td>

            <td class="td-desktop">
              <div class="d-inline pull-left">
                <span><small class="text-muted">{{'price'|translate}}: </small>{{item.price|number}} $</span> <br>
                <span><small class="text-muted">{{'area'|translate}}: </small>{{item.area|number}} <span
                  class="areaSymbol">m²</span></span>
              </div>
            </td>
            <td class="td-desktop" *ngIf="propertyType=='lands'">
              {{item.general_classification|translate}}
            </td>


            <td>
              <span class="badge  {{item.purpose_of_realestate}} ">{{item.purpose_of_realestate|translate}}</span>
              <span class="badge  {{item.offer}} ">{{item.offer|translate}} </span>
            </td>

            <td width="">
              <app-alerts-notice [aqarStatus]="item.aqarStatus" type="badge"></app-alerts-notice>
            </td>

            <td>
              <div class="btn-actions pull-right">
                <a [routerLink]="['/dashboard/properties/'+item?.type,item?.id]"
                   class="btn btn btn--light--default btn--small"><span class="fa fa-cog"></span>
                  {{'details'|translate}}
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="pagination text-center">
          <div class="spinner text-center" [ngClass]="{ 'hidden': !loading }"></div>

          <pagination-controls
            (pageChange)="PageChanged($event)"
            previousLabel="<"
            nextLabel=">"
            directionLinks="true"
            id="grid_pagination"
            maxSize="6"
            autoHide="true"
          >
          </pagination-controls>
        </div>
      </div>

    </div>
  </div>
</div>

<layout-footer></layout-footer>
