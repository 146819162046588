<nav class="menu" tabindex="0">
  <!--<div class="smartphone-menu-trigger"> {{'menu'|translate}}</div>-->
  <!--<header class="avatar">-->
  <!--<img src="./assets/images/icons/profile-icon.png"/>-->
  <!--<h2>{{'hey'|translate}} {{userInformation?.fullName}} </h2>-->
  <!--</header>-->
  <ul>
    <li tabindex="0">
      <a routerLink="/dashboard/" >
        <i class="fa fa-object-group"></i>
        {{'Dashboard'|translate}}
      </a>
    </li>
    <li tabindex="1">
      <a routerLink="/dashboard/properties/lands" [routerLinkActive]="'is-active'">
        <i class="fa  fa-key"></i>
        {{'myProperties'|translate}}
      </a>
    </li>

<!--    <li tabindex="1">-->
<!--      <a routerLink="/dashboard/contracts" [routerLinkActive]="'is-active'">-->
<!--        <i class="fa  fa-file-archive-o"></i>-->
<!--        {{'rentManagement'|translate}}-->
<!--      </a>-->
<!--    </li>-->



    <li><a [routerLinkActive]="'is-active'" routerLink="/dashboard/saved-properties"> <i class="fa fa-heart-o"></i> {{'SavedProperties'|translate}}</a>
    </li>
    <li><a [routerLinkActive]="'is-active'" routerLink="/dashboard/notifications"> <i class="fa fa-bell"></i> {{'requests'|translate}} <span class="badge-notification">{{activeNotificationsCount}}</span></a></li>

    <li tabindex="1">
      <a [routerLinkActive]="'is-active'" routerLink="/dashboard/customers">
        <i class="fa  fa-users"></i>
        {{'customers'|translate}}
      </a>
    </li>

  </ul>

  <div class="navbr-fixed-bottom">
    <ul>
      <div class="dropdown-divider"></div>

      <li><a [routerLinkActive]="'is-active'" routerLink="/dashboard/profile"> <i class="fa fa-user"></i> {{'header.profile'|translate}}</a></li>
      <li><a routerLink="/logout"><i class="fa fa-sign-out"></i> {{'header.logout'|translate}}</a></li>
    </ul>
  </div>
</nav>
