<app-top-header></app-top-header>

<section class="knowledge main-page">
  <div class="knowledge-nav home">
    <nav class="navbar navbar-expand-lg ">
      <button class="navbar-toggler" type="button">
        <i class="fa fa-navicon"></i>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ">
          <li class="nav-fill">
            <a
              href="javascript:void(0)"
              class="nav-link"
              [ngx-scroll-to]="'#home'"
              [ngx-scroll-to-offset]="offset"
            >
              {{'home'|translate}}
            </a>
          </li>

          <li class="nav-fill">
            <a
              href="javascript:void(0)"
              class="nav-link"
              [ngx-scroll-to]="'#guide'"
              [ngx-scroll-to-offset]="offset"
            >
              {{'guide'|translate}}

            </a>
          </li>
          <li class="nav-fill">
            <a
              href="javascript:void(0)"
              class="nav-link"
              [ngx-scroll-to]="'#my-aqar'"
              [ngx-scroll-to-offset]="offset"
            >
              {{'myaqar'|translate}}

            </a>
          </li>
          <li class="nav-fill">
            <a
              href="javascript:void(0)"
              class="nav-link"
              [ngx-scroll-to]="'#tips'"
              [ngx-scroll-to-offset]="offset"
            >
              {{'tips'|translate}}
            </a>
          </li>
          <li class="nav-fill">
            <a
              href="javascript:void(0)"
              class="nav-link"
              [ngx-scroll-to]="'#realeste-in-palestine'"
              [ngx-scroll-to-offset]="offset"
            >
              {{'realestateGuide'|translate}}
            </a>
          </li>
          <li class="nav-fill">
            <a
              href="javascript:void(0)"
              class="nav-link"
              [ngx-scroll-to]="'#news'"
              [ngx-scroll-to-offset]="offset"
            >
              {{'news'|translate}}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="knowledge-content" id="home">


    <ngb-carousel *ngIf="bannersPosts" showNavigationArrows="false" showNavigationIndicators="false">
      <ng-template ngbSlide *ngFor="let post of bannersPosts| async; let i=index">
        <img [src]="post['better_featured_image'].source_url" alt="">
        <div class="carousel-caption">
          <h3> {{ post?.title?.rendered }}</h3>
          <a [routerLink]="getSectionUrl(post?.categories,post?.slug)" class="btn btn--primary">{{'readMore'|translate}}</a>
        </div>
      </ng-template>
    </ngb-carousel>



    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-md-8">

          <h2 class="articles-blocks-title latest"> {{'last-articles'|translate}} </h2>
          <app-articles-blocks [posts]="latestPost" [loading]="latestLoader"></app-articles-blocks>


          <hr>
          <h2 class="articles-blocks-title guide mt-5" id="guide"> {{'guide'|translate}}</h2>
          <div class="guide-block">
            <ul>
              <li>
                <a routerLink="/knowledge-center/guide">
                  <div class="icon">
                    <img src="./assets/images/icons/investments.svg">
                  </div>
                  <p>{{investmentPostCount?.name}}</p>
                  <small>( {{investmentPostCount?.count}} {{'article'|translate}})</small>
                </a>
              </li>
              <li>
                <a routerLink="/knowledge-center/guide">
                  <div class="icon">
                    <img src="./assets/images/icons/renting.svg">
                  </div>
                  <p>{{rentingPostCount?.name}}</p>
                  <small>({{rentingPostCount?.count}} {{'article'|translate}})</small>
                </a>
              </li>
              <li>
                <a routerLink="/knowledge-center/guide">
                  <div class="icon">
                    <img src="./assets/images/icons/buying.svg">
                  </div>
                  <p>{{buyingPostCount?.name}}</p>
                  <small> ({{rentingPostCount?.count}} {{'article'|translate}})</small>
                </a>
              </li>
              <li>
                <a routerLink="/knowledge-center/guide">
                  <div class="icon">
                    <img src="./assets/images/icons/selling.svg">
                  </div>
                  <p>{{sellingPostCount?.name}}</p>
                  <small> ({{sellingPostCount?.count}} {{'article'|translate}})</small>
                </a>
              </li>
            </ul>
          </div>


          <hr>
          <h2 class="articles-blocks-title myaqar mt-5" id="my-aqar">MY AQAR</h2>
          <app-articles-blocks [posts]="myAqarPosts" [category]="myAqar"
                               [loading]="myAqarLoader"></app-articles-blocks>


          <hr>

          <h2 class="articles-blocks-title tips mt-5" id="tips"> {{'tips'|translate}}</h2>
          <app-articles-blocks [posts]="tipsPosts" [category]="tips" [loading]="tipsLoader"></app-articles-blocks>

          <hr>

          <app-realestate-in-palestine-block id="realeste-in-palestine"></app-realestate-in-palestine-block>

          <h2 class="articles-blocks-title news mt-5" id="news">{{'news'|translate}}</h2>
          <app-news-blocks [posts]="newsPosts" [loading]="newsLoader" class="mb-5"></app-news-blocks>


        </div>

        <div class="col-md-4">
          <div class="knowledge-sidebar">

            <div class="newsletter">
              <div class="icon">
                <img src="./assets/images/icons/message.svg">
              </div>
              <p>
                {{'Subscribe to our newsletter to get latest updates & special information'|translate}}
              </p>
              <form>
                <input type="text" class="form-control form-control-sm" name="email" placeholder="email@company.com">
                <button class="btn  btn-block btn--secondary">{{'subscribe'|translate}}</button>
              </form>


            </div>


            <div class="quote">
              <div class="icon">
                <img src="./assets/images/icons/left-quote.svg">
              </div>
              <b class="number">90%</b>
              {{'of Millionaires become so through owning'|translate}}
              <b>{{'Real Estate'|translate}}</b>
              <div class="author">{{'andrew carnegie'|translate}}</div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>
<layout-footer></layout-footer>
