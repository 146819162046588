<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h1 class=" text-center text-capitalize">{{'Find your AQAR account'|translate}}</h1>

  <form #f="ngForm" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">


    <p class="m-0 text-left">{{'forgotPasswordNote'|translate}}</p>
    <div class="form-group used-by">
      <h4 class="mt-4 mb-4 text--primary">{{'Reset The Password by'|translate}}</h4>

      <div class="form-radio">
        <input class="" type="radio" id="inlineRadio1" value="mobile" formControlName="resetBy"
               (change)="changeResetBy('mobile')" checked="checked">
        <label class="" for="inlineRadio1">{{'mobileNumber'|translate}}</label>
      </div>


      <div class="form-radio">
        <input class="" type="radio" id="inlineRadio2" value="email" formControlName="resetBy"
               (change)="changeResetBy('email')">
        <label class="" for="inlineRadio2">{{'email'|translate}}</label>
      </div>
    </div>



    <div class="form-group" *ngIf="isMobile==true">
      <input type="text" class="form-control" formControlName="mobile" placeholder="{{'mobileNumber'|translate}}">
      <div class="form-control-feedback text-left"
           *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
        <p *ngIf="mobile.errors.required">{{'validation.mobile_required'|translate}}</p>
        <p *ngIf="mobile.invalid && mobile.dirty ">{{'validation.mobile_invalid'|translate}}</p>
      </div>
    </div>

    <div class="form-group" *ngIf="isEmail==true">
      <!--<label class="text-left">{{'Email'|translate}}</label>-->
      <input type="email" class="form-control" formControlName="email" placeholder="{{'email'|translate}}" email>
      <div class="form-control-feedback text-left"
           *ngIf="email.errors && (email.dirty || email.touched)">
        <p *ngIf="email.errors.required">{{'validation.email_required'|translate}}</p>
        <p *ngIf="email.invalid && email.dirty ">{{'validation.InvalidEmailAddress'|translate}}</p>
      </div>
    </div>


    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
    <div class="form-group">
      <button
        type="submit"
        class="btn btn--primary btn--large btn-block"
        [disabled]="!forgotPasswordForm.valid"
      >{{'submit'|translate}}
      </button>
    </div>
    <div class="alert alert-{{alertClass}}">
      {{alertMsg}}
    </div>

  </form>
</div>




