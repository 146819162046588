import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserActionsService {


  private BASE_URL:string = environment.CORE_SERVER_API_URL;

  constructor(private http_client:HttpClient) {

  }

  saveLand(id,type) {
    return this.http_client.get(this.BASE_URL + 'user_actions/save/'+type, {
      params: {
        id: id
      }
    });
  }

  unsaveLand(landId) {
    return this.http_client.get(this.BASE_URL + 'user_actions/unsave_land', {
      params: {
        id: landId
      }
    });
  }



  getSavedLands() {
    return this.http_client.get(this.BASE_URL +'user_actions/get_saved_lands', {
    });
  }

  getSavedBuildings() {
    return this.http_client.get(this.BASE_URL +'user_actions/get_saved_buildings', {
    });
  }

  gedSavedUnits() {
    return this.http_client.get(this.BASE_URL +'user_actions/get_saved_units', {
    });
  }



}





