<div class="property-gallery" id="photos">
  <div class="row">
    <div class="col-md-6 col-xs-6 col-sm-6">
      <a
        (click)="setSelectedImage(mainImage);openModal(content)"
        class="bg-image-500"
        [ngStyle]="{'background-image':'url(' + mainImage + ')'}"
      >
      </a>
    </div>
    <div class="col-md-6 col-xs-6 col-sm-6 thumb-4">
      <div class="row">
        <div *ngFor="let image of imagesArray?.items let i=index " style="display: contents">
          <a *ngIf="i < 4"
             class="bg-image-250"
             (click)="setSelectedImage(image.path);openModal(content)"
             [ngStyle]="{'background-image':'url(' + image.path + ')'}"
          >
            <span *ngIf="i == 3  && showMoreImages" class="overlay"> + {{ imagesCount - 5}} {{'moreImages'|translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-c="close" let-d="dismiss" windowClass="gallery-m">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ lang=='ar'?ar_title:en_title }}
      <b> - {{price|currency :'$'}}</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


    <ngb-carousel *ngIf="imagesArray" #myCarousel="ngbCarousel">
      <ng-template ngbSlide *ngFor="let image of imagesArray?.items | slice:0; let i=index " id="{{i}}">
        <img [src]="image.path" alt="Random first slide">
        <div class="carousel-caption">
        </div>
      </ng-template>
    </ngb-carousel>


    <!--<div class="arrow-control">-->
    <!--<div class="right"  (click)="navigate(false)"></div>-->
    <!--<div class="left" (click)="navigate(true)"></div>-->
    <!--</div>-->
    <!--<img *ngIf="selectedImage" src="{{selectedImage}}">-->


  </div>
  <div class="modal-footer">
    <!--<div class="images-thumb">-->
    <!--<ul>-->
    <!--<li *ngFor="let image of imagesArray?.items | slice:1; let i=index ">-->
    <!--<a-->
    <!--(click)="setSelectedImage(image.path)"-->
    <!--&gt;-->
    <!--<img src="{{image.path}}">-->
    <!--</a>-->
    <!--</li>-->
    <!--</ul>-->
    <!--</div>-->
  </div>
</ng-template>
