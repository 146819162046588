<div class="modal-header">
  <!--<h5 class="modal-title" id="exampleModalLabel">login</h5>-->
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!--  <h1 class="text-uppercase">{{'login'|translate}}</h1>-->
  <h2 class="text-capitalize text-center text--primary mb-4">{{'login_description'|translate}}</h2>

<!--  <ul class="list-group mb-3 login-list">-->
<!--    <li>{{'login_text_1'|translate}}</li>-->
<!--    <li>{{'login_text_2'|translate}}</li>-->
<!--    <li>{{'login_text_3'|translate}}</li>-->
<!--  </ul>-->

  <app-login-form></app-login-form>
</div>
<div class="modal-footer">
  <p>{{'dont_have_account'|translate}}
    <button (click)="activeModal.close('Cross click')" class="btn-anchor anchor-primary text-capitalize"
            routerLink="/wizard">{{'header.sign_up'|translate}}</button>
  </p>
</div>
