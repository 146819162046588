<app-top-header></app-top-header>
<div class="dashboard">
  <div class="dashboard-sidebar">
    <app-side-menu></app-side-menu>
  </div>

  <div class="dashboard-content ">
    <div class="dashboard-header">
      <div class="title">
        <h1 class="text-capitalize mt-2">
          {{'payments'|translate}}
        </h1>
      </div>
      <div class="action">
        <!--<button class="btn  btn&#45;&#45;light&#45;&#45;default ml-1 mr-1 " (click)="openOrderModal()">{{'requestInfo'|translate}}-->
        <!--</button>-->
        <!--<a class="btn btn&#45;&#45;primary " routerLink="/dashboard/add-properties">{{'addNewContract'|translate}}</a>-->
        <a class="btn btn--primary " routerLink="/dashboard/add-contract">{{'addNewPayments'|translate}}</a>
      </div>
    </div>
    <div class="dashboard-details ">

      <div class="dashboard-table-details mt-6">

        <div class="row">

          <div class="col">
            <div class="card shadow">

              <table class="table table-hover text-center">
                <thead>
                <tr>
                  <th scope="col">{{'contractId'|translate}}</th>
                  <th scope="col">{{'lesseeName'|translate}}</th>
                  <th scope="col">{{'contractPeriod'|translate}}</th>
                  <th scope="col">{{'deservedAmount'|translate}}</th>
                  <th scope="col">{{'paymentStatus'|translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row"><a href="#">2132222</a></th>
                  <td><a href="">رائف نصار </a></td>
                  <td>12 شهر</td>
                  <td>1400$</td>
                  <td>
                    <span class="badge badge-pill badge-danger text-white p-2">غير مسدد</span>
                  </td>
                </tr>


                <tr>
                  <th scope="row"><a href="#">2132229</a></th>
                  <td><a href="">حمزة عقل </a></td>
                  <td>12 شهر</td>
                  <td>2500$</td>
                  <td>
                    <span class="badge badge-pill badge-danger text-white p-2">غير مسدد</span>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
