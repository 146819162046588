import {Component, OnInit, Input, Output, EventEmitter,ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-articles-blocks',
  templateUrl: './articles-blocks.component.html',
  styleUrls: ['./articles-blocks.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ArticlesBlocksComponent implements OnInit {

  constructor(private route:ActivatedRoute) {
  }

  @Input() posts;
  @Input() category;
  @Input() loading;
  @Output() getCategory;
  catSlug:any;

  categories:any = [
    {id: 1, slug: 'tips'},
    {id: 2, slug: 'news'},
    {id: 3, slug: 'guide'},
    {id: 4, slug: 'myAqar'},
    {id: 5, slug: 'tips'},
    {id: 6, slug: 'realestate-palestine'},
    {id: 7, slug: 'palestine-authority'},
  ];


  ngOnInit() {
  }

  getCategorySlug(categories) {
    let cat = Math.min.apply(Math, categories);
    if(cat > 7){
      cat=2;
    }
    return this.categories[cat - 1]['slug'];
  }


  getSectionUrl(categories, slug) {
    let cat = Math.min.apply(Math, categories);
    if(cat > 7){
      cat=2;
    }
    this.catSlug = this.categories[cat - 1]['slug'];
    let link = '/knowledge-center/' + this.catSlug + '/' + slug;
    return link;

  }

}
