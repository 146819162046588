import {Component, OnInit} from '@angular/core';
import {DashboardService} from './../../_services/dashboard.service';

@Component({
  selector: 'app-contract-dashboard',
  templateUrl: './contract-dashboard.component.html',
  styleUrls: ['./contract-dashboard.component.scss']
})
export class ContractDashboardComponent implements OnInit {


  rentedUnitsCount = 0;
  rentedContractCount = 0;
  expiredContracts: any;
  currency: any;

  constructor(private  dashboardService: DashboardService) {
  }

  ngOnInit() {

    this.getRentedUnitCount();
    this.getRentedContractCount();
    this.getExpiredContractsWithOutstandingPayments();
  }


  getRentedUnitCount() {
    this.dashboardService.getRentedUnitCount().subscribe((res: any) => {
      console.log(res['data']);
      this.rentedUnitsCount = res['data']['count'];
    });
  }


  getRentedContractCount() {
    this.dashboardService.getRentedContractCount().subscribe((res: any) => {
      this.rentedContractCount = res['data']['count'];
    });
  }

  getExpiredContractsWithOutstandingPayments() {
    this.dashboardService.getExpiredContractsWithOutstandingPayments().subscribe((res: any) => {
      if (res.success == true) {
        this.expiredContracts = res.data;
      } else {

      }
    });
  }


}
