<app-top-header></app-top-header>
<section class="inner">
  <div class="container">
    <div class="text-center">
      <img  width="300" src="../../../assets/images/icons/success-page-icon.png">
      <h1>Thank You </h1>
      <h2> you property has been successfully added, we will get back to you with in 48 hours</h2>
          <h3><a routerLink="/dashboard"> Back To Dashboard </a></h3>
    </div>
  </div>
</section>
<layout-footer></layout-footer>
