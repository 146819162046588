<div class="articles-block">
  <div class="row">


    <div class="placeholder-content" [hidden]="!loading">
      <img src="./assets/images/gif/placeholder-articles-content.gif">
    </div>

    <div class="col-md-6">
      <span *ngFor="let post of posts | async; let i=index">

        <div class="block-lg" *ngIf="i < 1">
          <div class="img"
               [ngStyle]="{'background-image': 'url(' + post['better_featured_image'].source_url + ')'}"
          >
          </div>
          <a class="" [routerLink]="getSectionUrl(post?.categories,post?.slug)" >
            {{ post?.title?.rendered }}
          <span class="btn btn--small feature">
          <!--<span class="btn btn&#45;&#45;small feature {{getCategorySlug(post?.categories)}}">-->
           {{getCategorySlug(post?.categories)|translate}}
          </span>
          </a>
        </div>

      </span>

    </div>

    <div class="col-md-5">
      <div class="block-sm">
        <ul>
          <li *ngFor="let post of posts | async | slice:1; let i=index ">

            <a [routerLink]="getSectionUrl(post?.categories,post?.slug)" >
              <span class="feature {{getCategorySlug(post?.categories)}}">{{getCategorySlug(post?.categories)|translate}}</span>
              <div
                class="img"
                [ngStyle]="{'background-image': 'url(' + post['better_featured_image']['media_details'].sizes['thumbnail'].source_url + ')'}"
              >
              </div>
              <p>{{ post?.title?.rendered }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
