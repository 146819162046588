import {Component, OnInit, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {LOCAL_STORAGE, WINDOW} from '@ng-toolkit/universal';


// import {empty} from "rxjs/Observer";

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  bodyClass:string;
  year: number = new Date().getFullYear();


  constructor(@Inject(WINDOW) private window:Window, @Inject(LOCAL_STORAGE) private localStorage:any, public translate:TranslateService) {
    translate.addLangs(["en", "ar"]);
    // console.log('lang: is '+ language);

  }

  // constructor(@Inject(WINDOW) private window: Window, @Inject(LOCAL_STORAGE) private localStorage: any, public translate:TranslateService) {
  //   translate.addLangs(["en", "ar"]);
  //   // console.log('lang: is '+ language);
  //
  // }

  switchLanguage(language:string) {
    this.translate.use(language);
    this.localStorage.setItem('language', language);
    console.log('lang: is ' + language);


    /**
     * add Class to body
     */
    if (language == 'en') {
      this.bodyClass = 'ltr';

    } else {
      this.bodyClass = 'rtl';
    }

    document.body.classList.remove('ltr', 'rtl');
    document.body.classList.add(this.bodyClass);
    this.window.location.reload();

  }


}
