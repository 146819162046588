import {Component, OnInit, Input} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {customerOrder} from '../../models/customer-order';
import {CustomersOrdersService} from '../../_services/customers-orders.service';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from './../../_services/auth.service';
import {UserService} from './../../_services/user.service';


@Component({
  selector: 'app-customers-orders-modal',
  templateUrl: './customers-orders-modal.component.html',
  styleUrls: ['./customers-orders-modal.component.scss']
})
export class CustomersOrdersModalComponent implements OnInit {

  closeResult:string;
  customerOrder:customerOrder = new customerOrder();

  error = '';
  uData:any;
  alert:boolean = false;
  alertClass:string = '';
  alertMsg:string = '';
  alertMsgTitle:string = '';
  thankYouForContacting:string = '';
  receivedEnquiry:string = '';
  loading:boolean = false;

  orderForm:FormGroup;

  fullName:FormControl;
  mobile:FormControl;
  email:FormControl;
  notes:FormControl;
  propertyURL:FormControl;
  propertyType:FormControl;
  propertyId:FormControl;
  lang:any;
  @Input() government;
  @Input() property_URL;
  @Input() property_type;
  @Input() property_id;
  @Input() order_type;
  @Input() order_text;
  @Input() order_title;
  @Input() order_mainImage;
  isLoggedIn:any;


  constructor(public activeModal:NgbActiveModal,
              private modalService:NgbModal,
              private fb:FormBuilder,
              public translate:TranslateService,
              private OrdersService:CustomersOrdersService,
              private auth:AuthService,
              private userService:UserService) {

    this.createFormControls();
    this.createForm();
    this.lang = this.translate.currentLang;

  }

  ngOnInit() {
    this.translate.get("ThankYouForContactingUs").subscribe((res:string) => {
      this.thankYouForContacting = res;
      console.log(res);
    });
    this.translate.get("receivedEnquiry").subscribe((res:string) => {
      this.receivedEnquiry = res;
      console.log(res);

    });

    this.isLoggedIn = this.auth.isAuthenticated();
    if (this.isLoggedIn) {
      this.customerOrder.fullName = this.userService.fullName;
      this.customerOrder.mobile = this.userService.phone;

    }

    this.customerOrder.propertyId = this.property_id;
    this.customerOrder.propertyURL = this.property_URL;
    this.customerOrder.propertyType = this.property_type;
    this.customerOrder.orderType = this.order_type;
    this.customerOrder.notes = this.order_text;


  }


  createFormControls() {
    this.fullName = new FormControl('', Validators.required);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]);
    this.notes = new FormControl('', [
      Validators.required,
    ]);
    this.propertyId = new FormControl('', []);
    this.propertyType = new FormControl('', []);
    this.propertyURL = new FormControl('', []);
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      Validators.email
    ]);
  }

  createForm() {
    this.orderForm = new FormGroup({
      fullName: this.fullName,
      email: this.email,
      mobile: this.mobile,
      notes: this.notes,
      propertyId: this.propertyId,
      propertyType: this.propertyType,
      propertyURL: this.propertyURL,
    });
  }

  onSubmit():void {
    this.loading = true;
    this.alertClass = '';
    this.alertMsg = '';
    this.alert = false;
    this.customerOrder = this.orderForm.value;
    this.customerOrder.orderType = this.order_type;
    this.OrdersService.createOrder(this.customerOrder)
      .subscribe(res => {
        this.loading = false;
        this.alert = true;
        if (res['success']) {
          this.alertClass = 'success';
          this.alertMsg = this.receivedEnquiry;
          // this.alertMsgTitle = this.thankYouForContacting;
          this.orderForm.reset();
          this.customerOrder.propertyId = this.property_id;
          this.customerOrder.propertyURL = this.property_URL;
          this.customerOrder.propertyType = this.property_type;
        } else {
          this.alertClass = 'danger';
          this.alertMsg = res['msg'];
        }

      });

  }

  close() {
    this.activeModal.dismiss();
  }


}
