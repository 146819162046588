import {Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {LandsService} from '../../_services/lands.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersOrdersModalComponent} from  '../customers-orders-modal/customers-orders-modal.component';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {WINDOW} from '@ng-toolkit/universal';

@Component({
  selector: 'app-reneted-units',
  templateUrl: './reneted-units.component.html',
  styleUrls: ['./reneted-units.component.scss']
})
export class RenetedUnitsComponent implements OnInit {


  urlparams:any;
  landsTest$:any;
  totalItems:number;
  MapsArray:any;
  markersArray:any;
  loading:boolean = true;
  page:any = 1;
  currentPage:any = 1;
  itemsPerPage:number = 6;
  selectedName:string;
  lang:string = '';
  propertyType:any;
  queryParameters:any = [];
  noResultDisplay:boolean = false;
  selectedGovId:number = null;
  selectedGovString:string;

  selectedTownId:number;
  selectedTownString:string;

  selectedAreaId:number;
  selectedAreaString:string;

  sortingBy:string;
  selectedSortingBy:string;

  governateList:any = [];
  townList:any = [];
  areaList:any = [];
  type:string;
  locationSearchText:string;
  filterIsAdvanced:boolean;
  selectedPropertyType:string;

  selectedRoomsCount:number;
  roomList:any;

  selectedPropertyStatus:string;
  selectedRangeArea:any;


  /***
   * Price Range
   * @type {number}
   */
  priceFrom:number = 0;
  selectedPriceFrom:number;
  priceTo:number = 1000000;
  selectedPriceTo:number;
  selectedRangePrice:string;
  public priceRange:number[] = [0, 1000000];


  /**
   * Area Range
   * @type {number}
   */
  areaFrom:any = 0;
  selectedAreaFrom:any;
  areaTo:any = 10000;
  selectedAreaTo:any;
  public areaRange:number[] = [0, 10000];
  propertyForChecked:any = [];

  constructor(@Inject(WINDOW) private window:Window,
              private landService:LandsService,
              public  http:HttpClient,
              private route:ActivatedRoute,
              private router:Router,
              public translate:TranslateService,
              private modalService:NgbModal,
              config:NgbDropdownConfig) {

    config.autoClose = "outside";

  }

  ngOnInit() {
    let lang = this.translate.currentLang;
    this.lang = lang;
    if (lang == 'en') {
      this.selectedName = 'en_name';
    } else {
      this.selectedName = 'ar_name';

    }
    this.propertyType = "apartments";

    this.route.queryParams.subscribe((params:any) => {
      this.currentPage = params['page'] || 1;
    });

    this.getProperties();

  }

  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
  }




  getProperties() {


    this.loading = true;
    this.queryParameters = [];
    let page = this.currentPage;
    if (page != null) {
      this.queryParameters['page'] = page;
      // this.showLimitationScreen();

    }
    if (this.selectedSortingBy != null) {
      this.queryParameters['sortingBy'] = this.selectedSortingBy;
    }

    if (this.selectedGovId != null) {
      this.queryParameters['gov'] = this.selectedGovId;
    }

    if (this.selectedTownId != null) {
      this.queryParameters['town'] = this.selectedTownId;
    }

    if (this.selectedAreaId != null) {
      this.queryParameters['area'] = this.selectedAreaId;
    }

    if (this.selectedPropertyStatus != null) {
      this.queryParameters['property_status'] = this.selectedPropertyStatus;
    }
    if (this.selectedPriceFrom != null) {
      this.queryParameters['priceFrom'] = this.selectedPriceFrom;
    }
    if (this.selectedPriceTo != null) {
      this.queryParameters['priceTo'] = this.selectedPriceTo;
    }

    if (this.selectedAreaFrom != null) {
      this.queryParameters['areaFrom'] = this.selectedAreaFrom;
    }

    if (this.selectedAreaTo != null) {
      this.queryParameters['areaTo'] = this.selectedAreaTo;
    }
    if (this.propertyForChecked != null) {
      this.queryParameters['propertyFor'] = this.propertyForChecked;
    }

    this
      .landService
      .getRentedUnits(this.queryParameters, this.propertyType)
      .subscribe((res:any) => {
        this.landsTest$ = res;
        console.log(this.landsTest$);
        let MapsArray = this.landsTest$.items;
        this.totalItems = res.pagination.total_count;
        if (res.pagination.total_count == 0) {
          this.noResultDisplay = true;
        } else {
          this.noResultDisplay = false;
        }
        this.window.scrollTo({
          top: 0,
          behavior: "smooth"
        });

        console.log(this.markersArray);
        this.loading = false;
      });

  }


  abbreviateNumber(number) {
    var SI_PREFIXES = ["", "k", "M", "G", "T", "P", "E"];
    // what tier? (determines SI prefix)
    var tier = Math.log10(number) / 3 | 0;
    // if zero, we don't need a prefix
    if (tier == 0) return number;
    // get prefix and determine scale
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = number / scale;
    // format number and add prefix as suffix
    return scaled.toFixed(1) + prefix;
  }

  PageChanged($event) {

    this.currentPage = $event;
    console.log('pageLandChanged', this.currentPage);
    this.page = $event;
    console.log('call filter  in pageLandChanged');
    this.filter();
    this.loading = true;
  }

  filter() {

    this.router.navigate(['dashboard/properties/' + this.propertyType],
      {
        queryParams: {
          page: this.currentPage,
        }
      }
    );

    this.getProperties();
  }


}
