export class customerOrder {

  fullName:string;
  email:string;
  mobile:string;
  notes:string;
  orderType:string;
  propertyType:string;
  propertyURL:string;
  propertyId:number;

}
