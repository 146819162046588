import {Component, OnInit, Inject, HostListener, ElementRef, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute, Params, NavigationEnd, NavigationStart} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LandsService} from '../../../../_services/lands.service';
import {DOCUMENT} from '@angular/common';
import {CustomersOrdersModalComponent} from '../../../customers-orders-modal/customers-orders-modal.component';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserActionsService} from '../../../../_services/user-actions.service';
import {ToastrService} from 'ngx-toastr';
import {HaversineService, GeoCoord} from 'ng2-haversine';
import {AuthService} from './../../../../_services/auth.service';
import {RouterExtServiceService} from './../../../../_services/router-ext-service.service';
import {WINDOW} from '@ng-toolkit/universal';
import {Meta, Title} from '@angular/platform-browser';

import {SeoService} from '../../../../_services/seo.service';


import {LoginModalComponent} from '../../login-modal/login-modal.component';
import {SignUpModalComponent} from '../../sign-up-modal/sign-up-modal.component';


@Component({
  selector: 'app-single-property',
  templateUrl: './single-property.component.html',
  styleUrls: ['./single-property.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SinglePropertyComponent implements OnInit {
  item: any;
  id: number;
  purpose_of_realestate: any;
  payment_method: any;
  lang: string;
  dirName: string;
  images: any = [{
    small: 'https://dummyimage.com/600x400/ffffff/ffffff.png',
    medium: 'https://dummyimage.com/600x400/dddddd/ffffff.png&text=loading+....',
  }];
  mainImage: any;
  imagesCount: number;
  showMoreImages: boolean;
  public fixed: boolean = false;
  public offset: number = -250;
  lat: number = 31.906590;
  lng: number = 35.211145;
  markersArray: any;
  en_title: any;
  ar_title: any;
  ar_description: any;
  en_description: any;
  price: any;
  nearbies: any = [];
  features: any = null;
  landLan: any;
  landLng: any;
  propertyType: any = '';
  isSaved: boolean = false;
  isLoggedIn: boolean = false;
  mapOn: boolean = true;
  showDestination: boolean = true;
  samePropertyArray: any;
  pageUrl = '';
  whatsappURL = '';

  previousUrl: string = undefined;
  referrerUrl: string = undefined;



  constructor(private landService: LandsService,
              private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              public translate: TranslateService,
              private element: ElementRef,
              private modalService: NgbModal,
              private toastr: ToastrService,
              private UserActionsService: UserActionsService,
              private _haversineService: HaversineService,
              private meta: Meta,
              private title: Title,
              private seo: SeoService,
              @Inject(WINDOW) private window: Window,
              @Inject(DOCUMENT) private document: Document,
              private routerService: RouterExtServiceService) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.referrerUrl = this.routerService.getPreviousUrl();
  }

  ngOnInit() {



    this.pageUrl = window.location.href;
    this.whatsappURL = encodeURI('Hello i\'m interested in this property \n' + this.pageUrl);
    console.log('this.whatsappURL', this.pageUrl);


    this.isLoggedIn = this.auth.isAuthenticated();

    this.lang = this.translate.currentLang;
    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });

    this.propertyType = this.route.snapshot.url[0].path;
    if (this.propertyType == 'buildings' || this.propertyType == 'villas_houses') {
      this.getBuildingDetails(this.id);
      this.getSameAreaProperty('buildings', this.id);
    }
    if (this.propertyType == 'lands') {
      this.getLandDetails(this.id);
      this.getSameAreaProperty(this.propertyType, this.id);

    }
    if (this.propertyType == 'apartments' || this.propertyType == 'stores' || this.propertyType == 'offices') {
      this.getUnitDetails(this.id);
      this.getSameAreaProperty('units', this.id);
    }


    console.log('this.propertyType:', this.propertyType);

  }

  getUnitDetails(id) {
    this
      .landService
      .getUnitById(id, this.propertyType)
      .subscribe(res => {
        this.item = res;
        if (res['success'] == false) {
          this.router.navigateByUrl('/');
        }
        this.en_title = this.item.data.en_title;
        this.ar_title = this.item.data.ar_title;
        this.ar_description = this.item.data.ar_description;
        this.en_description = this.item.data.en_description;
        this.price = this.item.data.price;
        this.mainImage = this.item.data.mainImage;

        this.features = this.item.data.features;
        if (this.features != null || this.features != '') {
          this.features = this.features.split(',');
        }
        this.isSaved = this.item.data.saved;

        this.getPropertyGallery(this.propertyType);
        // this.getUnitNearby(this.id);

        let mapArray = [{
          lat: this.item.data.lat,
          lng: this.item.data.lng,
          ar_title: this.item.data.ar_title,
          en_title: this.item.data.en_title,
          ar_cityName: this.item.data.ar_cityName,
          en_cityName: this.item.data.en_cityName,
          en_zoneName: this.item.data.en_zoneName,
          ar_zoneName: this.item.data.ar_zoneName,
          propertyType: this.propertyType,
          id: this.item.data.id,
          mainImage: this.item.data.mainImage,
          price: this.item.data.price,
          label: {fontSize: '12x', color: '#fff', text: ('$' + this.abbreviateNumber(this.item.data.price)).toString()}
        }];
        this.markersArray = mapArray;
        this.seoSetter();
      });

  }

  getBuildingDetails(id) {
    this
      .landService
      .getBuildingById(id, this.propertyType)
      .subscribe(res => {
        this.item = res;
        if (res['success'] == false) {

          this.router.navigateByUrl('/');
        }

        this.en_title = this.item.data.en_title;
        this.ar_title = this.item.data.ar_title;
        this.ar_description = this.item.data.ar_description;
        this.en_description = this.item.data.en_description;
        this.price = this.item.data.price;
        this.mainImage = this.item.data.mainImage;
        this.features = this.item.data.features;
        if (this.features != null || this.features != '') {
          this.features = this.features.split(',');
        }

        this.isSaved = this.item.data.saved;
        this.getPropertyGallery(this.propertyType);
        // this.getBuildingNearby(this.id);

        let mapArray = [{
          lat: this.item.data.lat,
          lng: this.item.data.lng,
          ar_title: this.item.data.ar_title,
          en_title: this.item.data.en_title,
          ar_cityName: this.item.data.ar_cityName,
          en_cityName: this.item.data.en_cityName,
          en_zoneName: this.item.data.en_zoneName,
          ar_zoneName: this.item.data.ar_zoneName,
          propertyType: this.propertyType,
          id: this.item.data.id,
          mainImage: this.item.data.mainImage,
          price: this.item.data.price,
          label: {fontSize: '12x', color: '#fff', text: ('$' + this.abbreviateNumber(this.item.data.price)).toString()}
        }];
        this.markersArray = mapArray;
        this.seoSetter();

      });

  }

  getLandDetails(id) {
    this
      .landService
      .getPropertyById(id, 'lands')
      .subscribe(res => {
        this.item = res;
        if (res['success'] == false) {
          this.router.navigateByUrl('/');
        }
        // let purpose_of_realestate = this.item.data.purpose_of_realestate.split(",");
        let purpose_of_realestate = this.item.data.purpose_of_realestate;
        let payment_method = this.item.data.payment_method.split(',');
        this.purpose_of_realestate = purpose_of_realestate;
        this.payment_method = payment_method;
        this.dirName = this.item.data.dirName;

        this.en_title = this.item.data.en_title;
        this.ar_title = this.item.data.ar_title;
        this.ar_description = this.item.data.ar_description;
        this.en_description = this.item.data.en_description;

        this.price = this.item.data.price;
        this.mainImage = this.item.data.mainImage;
        this.getPropertyGallery(this.propertyType);
        this.landLan = this.item.data.lng;
        this.landLng = this.item.data.lat;
        // this.getLandNearby(this.id);
        // this.features = this.item.data.features;
        // this.features = this.features.split(",");

        this.features = this.item.data.features;
        if (this.features == null) {
          this.features = [];
        } else {
          this.features = this.features.split(',');
        }

        this.isSaved = this.item.data.saved;


        let mapArray = [{
          lat: this.item.data.lat,
          lng: this.item.data.lng,
          ar_title: this.item.data.ar_title,
          en_title: this.item.data.en_title,
          ar_cityName: this.item.data.ar_cityName,
          en_cityName: this.item.data.en_cityName,
          en_zoneName: this.item.data.en_zoneName,
          ar_zoneName: this.item.data.ar_zoneName,
          id: this.item.data.id,
          propertyType: this.propertyType,
          landId: this.item.data.landId,
          mainImage: this.item.data.mainImage,
          price: this.item.data.price,
          label: {fontSize: '12px', color: '#fff', text: ('$' + this.abbreviateNumber(this.item.data.price)).toString()}
        }];
        this.markersArray = mapArray;
        this.seoSetter();

      });
  }


  seoSetter() {

    if (this.lang == 'en') {
      let seoConfig = {
        title: this.seo.getSiteName() + ' | ' + this.en_title,
        description: this.en_description,
        keywords: this.seo.getDefaultKeywords(this.lang),
        image: this.mainImage,
        imageWidth: 450,
        imageHeight: 375,
        url: window.location.href
      };
      this.seo.setTags(seoConfig);

    } else {
      let seoConfig = {
        title: this.seo.getSiteName() + ' | ' + this.ar_title,
        description: this.ar_description,
        keywords: this.seo.getDefaultKeywords(this.lang),
        image: this.mainImage,
        imageWidth: 450,
        imageHeight: 375,
        url: window.location.href,
      };
      this.seo.setTags(seoConfig);
    }
  }


  getPropertyGallery(propertyType) {
    this
      .landService
      .getPropertyGallery(this.id, propertyType)
      .subscribe(res => {
        this.images = res['items'];
        this.imagesCount = this.images.imagesCount;
        if (this.imagesCount > 5) {
          this.showMoreImages = true;
        } else {
          this.showMoreImages = false;
        }

      });

  }


  getLandNearby(id) {
    this.landService
      .getLandNearby(id)
      .subscribe((res: any) => {
        this.nearbies = res.data;
      });
  }


  getBuildingNearby(id) {
    this.landService
      .getBuildingsNearby(id)
      .subscribe((res: any) => {
        this.nearbies = res.data;
      });
  }

  getUnitNearby(id) {
    this.landService
      .getUnitsNearby(id)
      .subscribe((res: any) => {
        this.nearbies = res.data;
      });
  }


  getSameAreaProperty(type, id) {
    this.landService
      .getSameAreaProperty(type, id)
      .subscribe((res: any) => {
        this.samePropertyArray = res.data;
      });
  }


  getDistanceBetweenTowPoint(lat, lng) {
    let landPosition: GeoCoord = {
      latitude: this.landLan,
      longitude: this.landLng
    };

    let target: GeoCoord = {
      latitude: lat,
      longitude: lng
    };


    let kilometers = this._haversineService.getDistanceInKilometers(landPosition, target);
    return kilometers;

  }

  saveLandAction(id) {
    this.UserActionsService
      .saveLand(id, this.propertyType)
      .subscribe((res: any) => {
        let tr = res.msg;
        this.translate.get(tr).subscribe((res: string) => {
          let landSaveStatus = res;
          this.toastr.success(landSaveStatus);

          if (tr == 'saveToFavorite') {
            this.isSaved = true;
          } else {
            this.isSaved = false;
          }
        });
      });
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const number = $event.target.documentElement.scrollTop;
    if (number > 140) {
      this.fixed = true;
      this.offset = -140;
    } else if (this.fixed && number < 10) {
      this.fixed = false;
      this.offset = -270;
    }
  }

  abbreviateNumber(number) {
    var SI_PREFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    // what tier? (determines SI prefix)
    var tier = Math.log10(number) / 3 | 0;
    // if zero, we don't need a prefix
    if (tier == 0) {
      return number;
    }
    // get prefix and determine scale
    var prefix = SI_PREFIXES[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = number / scale;
    // format number and add prefix as suffix
    return scaled.toFixed(1) + prefix;
  }


  openOrderModal() {
    const modalRef = this.modalService.open(CustomersOrdersModalComponent);
    modalRef.componentInstance.property_URL = this.pageUrl;
    modalRef.componentInstance.property_type = this.propertyType;
    modalRef.componentInstance.property_id = this.id;
    modalRef.componentInstance.order_type = 'enquiry';
    modalRef.componentInstance.order_mainImage = this.mainImage;

    if (this.lang == 'ar') {

      let order_text = 'مرحبا انا مهتم بهذا العقار الرجاء التواصل معي';
      modalRef.componentInstance.order_text = order_text;
      modalRef.componentInstance.order_title = this.ar_title;

    } else {
      let order_text = 'Hello, i\'m interested in this property can you contact me';
      modalRef.componentInstance.order_text = order_text;
      modalRef.componentInstance.order_title = this.en_title;


    }
  }


  // urlencod(text){
  //   return this.urlencod()
  // }

  openLoginModal() {
    const modalRef = this.modalService.open(LoginModalComponent);
    // modalRef.componentInstance.name = 'World';

  }

  openSignUpModal() {
    const modalRef = this.modalService.open(SignUpModalComponent);
    // modalRef.componentInstance.name = 'World';
  }


}
